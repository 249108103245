import {
  Component,
  OnInit,
  OnChanges,
  ViewChild,
  NgModule,
  Input,
  Output,
  SimpleChanges,
  EventEmitter
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyPaginatorModule as MatPaginatorModule, MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatSortModule, MatSort } from '@angular/material/sort';
import { MatLegacyTableModule as MatTableModule, MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { SelectionModel } from '@angular/cdk/collections';

import { TableFilterModule, TableFilterComponent } from './table-filter/table-filter.component';
import { DataTableService } from './data-table.service';
import { ApiService } from '@app/shared/services/api/api.service';
import { RepairOrder } from '@app/shared/services/api/api.models';

@Component({
  selector: 'aar-notification-data-table',
  templateUrl: './notification-data-table.component.html',
  styleUrls: ['./notification-data-table.component.scss']
})

export class NotificationDataTableComponent implements OnInit, OnChanges {

  @Input() title;
  @Input() data = [];
  @Input() columnsSettings = [];
  @Input() selectable = false;
  @Input() pageSizeOptions = [5, 10, 20];
  @Input() filterSettings = null;
  @Input() showPagination = true;
  @Input() showHeader = true;
  @Input() linkPath = '';
  @Input() highlightSelection = true;
  @Input() highlightHover = true;
  @Input() oversizeRow = false;
  @Input() showAvatar = false;
  @Input() openButton = false;
  @Input() enableROSearch = false;
  @Input() loading: boolean;
  @Output() clickItem = new EventEmitter();

  searchQuery: string;
  displayedColumns = [];
  pageSize = 10;
  dataSource = new MatTableDataSource<any>(this.data);
  selection = new SelectionModel<any>(true, []);

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;


  constructor(private dataTableService: DataTableService, private apiService: ApiService) {}

  ngOnInit() {

    this.displayedColumns = this.columnsSettings.map(item => item.name);

    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.dataSource.filterPredicate = (data: any, filterSettings: any) => {
      let match = true;
      for (const key in filterSettings) {
        if (key !== 'searchQuery' && filterSettings[key] && filterSettings[key] !== data[key].toString()) {
          match = false;
          break;
        } else if (key === 'searchQuery' && filterSettings[key] &&
          data.repairOrderNumber.indexOf(filterSettings[key]) < 0 &&
          data.partNumber.indexOf(filterSettings[key]) < 0 &&
          data.serialNumber.indexOf(filterSettings[key]) < 0) {
          match = false;
          break;
        }
      }
      return match;
    };

    this.dataSource.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'supplier': return item.supplier.name;
        case 'assignedUser': return item.assignedUser.name;
        default: return item[property];
      }
    };

    this.dataTableService.onFilterUpdate$
      .subscribe(tableFilters => {
        if (this.filterSettings) {
          this.applyFilter(tableFilters);
        }
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.data) {
      const { filterPredicate, sortingDataAccessor } = this.dataSource;
      this.dataSource = new MatTableDataSource(this.data);
      this.dataSource.filterPredicate = filterPredicate;
      this.dataSource.sortingDataAccessor = sortingDataAccessor;
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
      if (this.selectable && this.displayedColumns[0] !== 'select') {
        this.displayedColumns = ['select', ...this.displayedColumns];
      }
    }
  }

  applyFilter(filters) {
    this.dataSource.filter = filters;
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
    this.selection.clear() :
    this.dataSource.data.forEach(row => this.selection.select(row));
  }

  isNotification() {
    return this.columnsSettings.filter((item) => {
      return (item.type === 'notify');
    }).length > 0;
  }

  selectItem(item, commonClick = true) {
    if (commonClick) {
      this.clickItem.emit(item);
    }
  }





}

@NgModule({
  imports: [
    CommonModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    MatInputModule,
    MatFormFieldModule,
    MatSelectModule,
    MatCheckboxModule,
    TableFilterModule,
    MatButtonModule,
    ReactiveFormsModule,
    RouterModule,
    FormsModule,
    MatIconModule,
    MatProgressSpinnerModule
  ],
  providers: [ DataTableService ],
  exports: [ NotificationDataTableComponent, TableFilterComponent ],
  declarations: [
    NotificationDataTableComponent,
  ]
})
export class NotificationDataTableModule {}
