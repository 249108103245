import { Component, Input, OnChanges } from '@angular/core';
import { QuoteReviewQuoteLine } from '@app/shared/services/api/api.models';
import { DataLookupService } from '@app/shared/services/rcm/data-lookup/data-lookup.service';

interface QuoteReviewDetails {
  category: string;
  partNumber: string;
  partNumberDescription: string;
  serialNumber: string;
  workscope: string;
  unitCost: string;
  quantity: number;
  totalSupplierCost: string;
  currency: string;
  quoteStatus: string;
  masterOption: string;
  multiOption: string;
  rai:string;
  sos:string;
  quoteQueue:string;
}

@Component({
  selector: 'aar-rcm-quote-review-details-quote-line',
  templateUrl: './rcm-quote-review-details-quote-line.component.html',
  styleUrls: ['./rcm-quote-review-details-quote-line.component.scss']
})
export class RcmQuoteReviewDetailsQuoteLineComponent implements OnChanges {
  @Input('selectedQuoteLine') selectedQuoteLine: QuoteReviewQuoteLine;

  private readonly yesText:string = 'Yes';
  private readonly noText:string = 'No';

  quoteDetails: QuoteReviewDetails;

  constructor(private dataLookupService: DataLookupService) { }

  ngOnChanges() {
    if (this.selectedQuoteLine) {
      this.quoteDetails = {

        category: this.selectedQuoteLine?.repairCategoryName,
        partNumber: this.selectedQuoteLine?.partNumberReceived,
        partNumberDescription: this.selectedQuoteLine.partNumberDescription,
        serialNumber: this.selectedQuoteLine?.serialNumberReceived,
        workscope: this.dataLookupService.cachedWorkscopes && this.selectedQuoteLine?.proposedWorkScopeName,
        unitCost: this.selectedQuoteLine?.unitCost ? this.selectedQuoteLine.unitCost.toFixed(2) : '',
        quantity: this.selectedQuoteLine?.qTYRceived,
        totalSupplierCost: this.selectedQuoteLine?.totalCost ? this.selectedQuoteLine.totalCost.toFixed(2) : '',
        currency: this.selectedQuoteLine.currencyName,
        quoteStatus: this.selectedQuoteLine?.quote?.quoteStatusName,
        masterOption: this.selectedQuoteLine?.quote.masterOption ? this.yesText : this.noText,
        multiOption: this.selectedQuoteLine?.quote?.multiOption ? this.yesText : this.noText,
        rai:this.selectedQuoteLine?.rai ? this.selectedQuoteLine.rai.toFixed(2) : '',
        sos:this.selectedQuoteLine?.sos ? this.selectedQuoteLine.sos.toFixed(2) : '',
        quoteQueue: null
      };
    }
  }
}