<form [formGroup]="statusForm" (ngSubmit)="submit()">
  <div class="aar-sliding-form-bdr">
    <div class="aar-sliding-section aar-sliding-section">

      <div class="aar-sliding-form-group">
        <div class="aar-sliding-form-control mb-0" *ngIf="!isFieldHidden('createdDate')">
          <label for="">Created</label>
          <mat-form-field floatLabel="never" class="mat-form-field-date" >
            <input matInput [matDatepicker]="picker1"  [formControlName]="createdDateCtrlName">
            <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
            <mat-datepicker #picker1></mat-datepicker>
            <mat-icon matSuffix>error_outline</mat-icon>
            <mat-error *ngIf="hasError('createdDate')">{{getErrorMessage('createdDate')}}</mat-error>
          </mat-form-field>
        </div>

        <div class="aar-sliding-form-control mb-0" *ngIf="!isFieldHidden('createdByUser')">
          <label>Created By</label>
          <mat-form-field floatLabel="never" class="mat-form-field-date">
            <input type="text" matInput [formControlName]="createdByCtrlName">
            <mat-icon matSuffix>error_outline</mat-icon>
            <mat-error *ngIf="hasError('createdByUser')">{{ getErrorMessage('createdByUser') }}</mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="hline-bottom">&nbsp;</div>

      <div class="aar-sliding-form-group">
        <div class="aar-sliding-form-control mb-0" *ngIf="!isFieldHidden('processedDate')">
          <label for="">Processed</label>
          <mat-form-field floatLabel="never" class="mat-form-field-date">
            <input matInput [matDatepicker]="picker2" [formControlName]="processedDateCtrlName">
            <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
            <mat-datepicker #picker2></mat-datepicker>
            <mat-icon matSuffix>error_outline</mat-icon>
            <mat-error *ngIf="hasError('processedDate')">{{getErrorMessage('processedDate')}}</mat-error>
          </mat-form-field>
        </div>

        <div class="aar-sliding-form-control mb-0" *ngIf="!isFieldHidden('processedByUser')">
          <label>Processed By</label>
          <mat-form-field floatLabel="never" class="mat-form-field-input">
            <input type="text" matInput [formControlName]="processedByCtrlName">
            <mat-icon matSuffix>error_outline</mat-icon>
            <mat-error *ngIf="hasError('processedByUser')">{{ getErrorMessage('processedByUser') }}</mat-error>
          </mat-form-field>
        </div>
      </div>

      <!-- No approval required -->

      <div class="aar-sliding-form-group">
        <div class="aar-sliding-form-control mb-0" *ngIf="!isFieldHidden(noApprovalRequiredDateCtrlName)">
          <label for="">No Approval Required</label>
          <mat-form-field floatLabel="never" class="mat-form-field-date">
            <input matInput [matDatepicker]="picker3" [formControlName]="noApprovalRequiredDateCtrlName">
            <mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
            <mat-datepicker #picker3></mat-datepicker>
            <mat-icon matSuffix>error_outline</mat-icon>
            <mat-error
              *ngIf="hasError(noApprovalRequiredDateCtrlName)">{{getErrorMessage(noApprovalRequiredDateCtrlName)}}</mat-error>
          </mat-form-field>
        </div>

        <div class="aar-sliding-form-control mb-0" *ngIf="!isFieldHidden(noApprovalRequiredByCtrlName)">
          <label>No Approval Required By</label>
          <mat-form-field floatLabel="never" class="mat-form-field-input">
            <input type="text" matInput [formControlName]="noApprovalRequiredByCtrlName">
            <mat-icon matSuffix>error_outline</mat-icon>
            <mat-error *ngIf="hasError(noApprovalRequiredByCtrlName)">{{ getErrorMessage(noApprovalRequiredByCtrlName)
              }}</mat-error>
          </mat-form-field>
        </div>

        <div class="aar-sliding-form-control mb-10">
          <mat-checkbox class="example-margin" [formControlName]="noApprovalRequiredCtrlName"
          (change)="onStatusCheckboxChange($event)"
          [name]="QuoteStatusIdType.Processed"></mat-checkbox>
        </div>

      </div>

      <div class="hline-bottom">&nbsp;</div>

      <div class="aar-sliding-form-group">
        <div class="aar-sliding-form-control mb-0" *ngIf="!isFieldHidden('quotedToCustomerDate')">
          <label for="">Sent For Approval</label>
          <mat-form-field floatLabel="never" class="mat-form-field-date">
            <input matInput [matDatepicker]="picker3" [formControlName]="quotedToCustomerDateCtrlName">
            <mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
            <mat-datepicker #picker3></mat-datepicker>
            <mat-icon matSuffix>error_outline</mat-icon>
            <mat-error *ngIf="hasError('quotedToCustomerDate')">{{getErrorMessage('quotedToCustomerDate')}}</mat-error>
          </mat-form-field>
        </div>

        <div class="aar-sliding-form-control mb-0" *ngIf="!isFieldHidden('quotedToCustomerByUser')">
          <label>Sent For Approval By</label>
          <mat-form-field floatLabel="never" class="mat-form-field-input">
            <input type="text" matInput [formControlName]="quotedToCustomerByCtrlName">
            <mat-icon matSuffix>error_outline</mat-icon>
            <mat-error *ngIf="hasError('quotedToCustomerByUser')">{{ getErrorMessage('quotedToCustomerByUser') }}</mat-error>
          </mat-form-field>
        </div>

        <div class="aar-sliding-form-control mb-0" *ngIf="!isFieldHidden('quotedToCustomer')">
          <mat-checkbox class="example-margin" [formControlName]="quotedToCustomerCtrlName" 
          (change)="onStatusCheckboxChange($event)" 
          [name]="QuoteStatusIdType.SentForAuthorization"></mat-checkbox>
        </div>
      </div>

      <div class="hline-bottom">&nbsp;</div>

      <div class="aar-sliding-form-group">
        <div class="aar-sliding-form-control mb-0" *ngIf="!isFieldHidden('customerApprovedDate')">
          <label for="">Approved</label>
          <mat-form-field floatLabel="never" class="mat-form-field-date">
            <input matInput [matDatepicker]="picker4" [formControlName]="customerApprovedDateCtrlName">
            <mat-datepicker-toggle matSuffix [for]="picker4"></mat-datepicker-toggle>
            <mat-datepicker #picker4></mat-datepicker>
            <mat-icon matSuffix>error_outline</mat-icon>
            <mat-error *ngIf="hasError('customerApprovedDate')">{{getErrorMessage('customerApprovedDate')}}</mat-error>
          </mat-form-field>
        </div>

        <div class="aar-sliding-form-control mb-0" *ngIf="!isFieldHidden('customerApprovedByUser')">
          <label>Approved By</label>
          <mat-form-field floatLabel="never" class="mat-form-field-input">
            <input type="text" matInput [formControlName]="customerApprovedByCtrlName">
            <mat-icon matSuffix>error_outline</mat-icon>
            <mat-error *ngIf="hasError('customerApprovedByUser')">{{ getErrorMessage('customerApprovedByUser') }}</mat-error>
          </mat-form-field>
        </div>

        <div class="aar-sliding-form-control mb-0" *ngIf="!isFieldHidden('customerApproved')">
          <mat-checkbox class="example-margin" [formControlName]="customerApprovedCtrlName" 
          (click)="onCustomerApprovedClick($event)"
          (change)="onStatusCheckboxChange($event)" 
          [name]="QuoteStatusIdType.Authorized"></mat-checkbox>
        </div>
      </div>

      <div class="hline-bottom">&nbsp;</div>
      
      <div class="aar-sliding-form-group">
        <div class="aar-sliding-form-control mb-0" *ngIf="!isFieldHidden('authorizedOnSupplierDate')">
          <label for="">Authorised on Supplier</label>
          <mat-form-field floatLabel="never" class="mat-form-field-date">
            <input matInput [matDatepicker]="picker5" [formControlName]="authorizedOnSupplierDateCtrlName">
            <mat-datepicker-toggle matSuffix [for]="picker5"></mat-datepicker-toggle>
            <mat-datepicker #picker5></mat-datepicker>
            <mat-icon matSuffix>error_outline</mat-icon>
            <mat-error *ngIf="hasError('authorizedOnSupplierDate')">{{getErrorMessage('authorizedOnSupplierDate')}}</mat-error>
          </mat-form-field>
        </div>

        <div class="aar-sliding-form-control mb-0" *ngIf="!isFieldHidden('authorizedOnSupplierByUser')">
          <label>Authorised on Supplier By</label>
          <mat-form-field floatLabel="never" class="mat-form-field-input">
            <input type="text" matInput [formControlName]="authorizedOnSupplierByCtrlName">
            <mat-icon matSuffix>error_outline</mat-icon>
            <mat-error *ngIf="hasError('authorizedOnSupplierByUser')">{{ getErrorMessage('authorizedOnSupplierByUser') }}</mat-error>
          </mat-form-field>
        </div>

        <div class="aar-sliding-form-control mb-0" *ngIf="!isFieldHidden('authorizedOnSupplier')">
          <mat-checkbox [formControlName]="authorizedOnSupplierCtrlName" 
          (click)="onAuthorizedOnSupplierClick($event)"
          (change)="onStatusCheckboxChange($event)" 
          [name]="QuoteStatusIdType.AuthorizedOnSupplier"></mat-checkbox>
        </div>
      </div>

      <div class="hline-bottom">&nbsp;</div>

      <div class="aar-sliding-form-group">
        <div class="aar-sliding-form-control mb-0" *ngIf="!isFieldHidden('canceledDate')">
          <label for="">Cancelled</label>
          <mat-form-field floatLabel="never" class="mat-form-field-date">
            <input matInput [matDatepicker]="picker6"  [formControlName]="canceledDateCtrlName">
            <mat-datepicker-toggle matSuffix [for]="picker6"></mat-datepicker-toggle>
            <mat-datepicker #picker6></mat-datepicker>
            <mat-icon matSuffix>error_outline</mat-icon>
            <mat-error *ngIf="hasError('canceledDate')">{{getErrorMessage('canceledDate')}}</mat-error>
          </mat-form-field>
        </div>

        <div class="aar-sliding-form-control mb-0" *ngIf="!isFieldHidden('canceledByUser')">
          <label>Cancelled By</label>
          <mat-form-field floatLabel="never" class="mat-form-field-input">
            <input type="text" matInput [formControlName]="canceledByCtrlName">
            <mat-icon matSuffix>error_outline</mat-icon>
            <mat-error *ngIf="hasError('canceledByUser')">{{ getErrorMessage('canceledByUser') }}</mat-error>
          </mat-form-field>
        </div>

        <div class="aar-sliding-form-control mb-0" *ngIf="!isFieldHidden('canceled')">

          <mat-checkbox class="example-margin" [formControlName]="canceledCtrlName" 
          (change)="onStatusCheckboxChange($event)" [name]="QuoteStatusIdType.Canceled"></mat-checkbox>
        </div>
      </div>

      <div class="aar-sliding-form-group">
        <div class="aar-sliding-form-control mb-0" *ngIf="!isFieldHidden('cancelationReason')">
          <label>Cancellation Reason</label>
          <mat-form-field floatLabel="never" class="mat-form-field-input">
            <input type="text" maxlength="100" (blur)="cancelationReasonBlur()" (input)="cancelationReasonUpdate()" matInput [formControlName]="cancelationReasonCtrlName">
            <mat-icon matSuffix>error_outline</mat-icon>
             <mat-error *ngIf="hasError('cancelationReason') && submitted">{{ getErrorMessage('cancelationReason') }}</mat-error> 
          </mat-form-field> 
        </div>
      </div>

    </div>
  </div>

  <div class="aar-sliding-form-footer">
    <div class="aar-buttons-list centered ng-star-inserted">
        <button mat-flat-button [disabled]="disableSubmit()" color="primary">Submit</button>
        <button mat-flat-button [disabled]="disableCancel()" color="primary" type="button" (click)="resetForm()">Cancel</button>
    </div>
  </div>
</form>
