import { AuthService } from './../../../auth/services/auth/auth.service';
import { Component, OnInit, Input, OnChanges, SimpleChanges, OnDestroy, ViewChildren, QueryList } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MAT_ACCORDION, MatExpansionPanel } from '@angular/material/expansion';
import { RcmAddShipmentComponent } from '../modals/rcm-add-shipment/rcm-add-shipment.component';
import { RcmEditShipmentComponent } from '../modals/rcm-edit-shipment/rcm-edit-shipment.component';
import { ApiService } from '@app/shared/services/api/api.service';
import { RepairOrder, ShippingInputType, ShippingType } from '@app/shared/services/api/api.models';
import { zip, of, throwError, Subscription, Observable } from 'rxjs';
import { RcmDetailsComponent } from '../rcm-details.component';
import { RcmStepperService } from '@app/rcm/rcm-stepper/rcm-stepper.service';
import { SharedService } from '@app/shared/services/shared.service';
import { PersonaService } from '@app/shared/services/rcm/persona.service';
import { User } from '@app/auth/auth.models';
import { RcmStatusStepperService } from '@app/rcm/rcm-stepper/rcm-status-stepper.service';
import { finalize, take, takeUntil, tap } from 'rxjs/operators';
//Commented as part of bug #43045
//import { ActivityService } from '@app/shared/services/rcm/activity/activity.service';

@Component({
  selector: 'aar-rcm-shipping',
  templateUrl: './rcm-shipping.component.html',
  styleUrls: ['./rcm-shipping.component.scss']
})
export class RcmShippingComponent implements OnInit, OnChanges, OnDestroy {

  @Input('showBoth') showBoth: boolean;
  @Input('isEmpty') isEmpty: boolean;
  @Input('repairOrder') repairOrder: RepairOrder;
  direction: number; // default to outbound
  noActivityMessage = 'No Activity Yet';
  shippings: any;
  user: User;
  showControls: boolean = true;
  personaService: PersonaService;
  loading: boolean = false;

  private updateShippingSubscription: Subscription;
  private deleteShippingSubscription: Subscription;

  //TODO: crate a global spot for these.  I copy-pasted them from rcm-details-component.ts
  statuses = {
    NEW_RO: 0,
    UNIT_SHIPPED_OUTBOUND: 1,
    SUPPLIER_RECEIPT: 2,
    QUOTE_PROCESSING: 3,
    QUOTE_APPROVED: 4,
    IN_FINAL_REPAIR: 5,
    UNIT_SHIPPED_INBOUND: 6,
    'RECEIPT_&_CLOSE': 7,
    RO_CLOSED: 8,
  };

  constructor(
    private apiService: ApiService,
    private dialog: MatDialog,
    private sharedService: SharedService,
    private rcmStepperService: RcmStepperService,
    private authService: AuthService,
    private statusStepperService: RcmStatusStepperService){
    //Commented as part of bug #43045
    //private activityService:ActivityService) {

    this.rcmStepperService.rcmStepChange$.subscribe(value => {
      switch (value) {

        case 'SHIPPING OUTBOUND':
          this.direction = 1;
          break;
        case 'SHIPPING INBOUND':
          this.direction = 2;
          break;
        default:
          break;
      }
    });
    this.personaService = new PersonaService(this.authService.getLoggedUser().personaName);
  }

  init() {

    if (!this.repairOrder) {
      return;
    }

    const apiCalls = zip(
      this.apiService.getOutboundShippingByRoAndRoL(this.repairOrder.repairOrderNumber, this.repairOrder.repairOrderLineId),
      this.apiService.getInboundShippingByRoAndRoL(this.repairOrder.repairOrderNumber, this.repairOrder.repairOrderLineId),
    );

    const that = this;
    apiCalls.subscribe(([outboundShipping, inboundShipping]) => {
      that.shippings = {};
      that.shippings.outbound = outboundShipping;
      that.shippings.inbound = inboundShipping;
      this.generateShippingStage();
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    this.init();
  }

  ngOnInit() {
    this.subscribeToShippingDelete();
    this.subscribeToShippingUpdate();

    this.init();
    this.unloadSomeControls();
  }

  ngOnDestroy(): void {
    this.updateShippingSubscription.unsubscribe();
    this.deleteShippingSubscription.unsubscribe();
  }

  private generateShippingStage(): void {
    const maxObSquence = Math.max(...this.shippings.outbound.map(x => x.sequence));
    this.shippings.outbound.forEach(x => {
      x.shippingStage = `${x.sequence}/${maxObSquence}`;
    });

    const maxIbSequence = Math.max(...this.shippings.inbound.map(x => x.sequence));
    this.shippings.inbound.forEach(x => {
      x.shippingStage = `${x.sequence}/${maxIbSequence}`;
    });
  }

  private subscribeToShippingUpdate() {
    this.updateShippingSubscription = this.sharedService.getUpdatedShipping()
      .subscribe(x => {
        this.showEditShippingDetails(x);
      });
  }

  private subscribeToShippingDelete() {
    this.deleteShippingSubscription = this.sharedService.getDeletedShipping()
      .subscribe((x) => {
        this.processDeleteShippingInfo(x);
      });
  }

  unloadSomeControls() {
    this.user = this.authService.getLoggedUser();

    if (this.user.roleName.toUpperCase() === "CUSTOMER" || this.user.roleName.toUpperCase() === "SUPPLIER" || this.personaService.hideShippingUpdates()) {
      this.showControls = false;
    }
  }

  showAddShippingDetails() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      repairOrder: this.repairOrder,
      direction: Number(this.direction)
    };
    this.openDialog(dialogConfig);
  }

  showEditShippingDetails(shippingToUpdate: ShippingType) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      repairOrder: this.repairOrder,
      shipping: shippingToUpdate,
      direction: Number(this.direction)
    };
    this.openEditDialog(dialogConfig, shippingToUpdate);
  }

  public openDialog(dialogConfig: MatDialogConfig) {
    let that = this;
    const dialogRef = this.dialog.open(RcmAddShipmentComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(
      data => {
        data.directionId = Number(data.directionId);
        this.processCreateShippingResult(data);
      }
    );
  }

  public openEditDialog(dialogConfig: MatDialogConfig, shipping: ShippingType) {
    const dialogRef = this.dialog.open(RcmEditShipmentComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(
      data => {
        if (data) {
          data.directionId = Number(data.directionId)
          this.processEditShippingResult(data);
        }
      }
    );
  }

  public processCreateShippingResult(data) {
    try {
      let that = this;
      if (data) {
        //Selecting the blank option in the carrier dropdown passes empty
        //string - explictily set this to null for the microservice
        if(!data.carrierId)
        {
          data.carrierId = null;
        }
        this.loading = true;
        this.apiService.createShipping(data)
          .pipe(take(1), finalize(() => this.loading = false))
          .subscribe(result => {
            if (result) {
              if (result.directionId == 1) {
                that.shippings.outbound.push(result);
              } else if (result.directionId == 2) {
                that.shippings.inbound.push(result);
              }
            }
            this.generateShippingStage();
            //Commented as part of bug #43045
            //this.activityService.activityChangePublish(this.repairOrder.repairOrderNumber, this.repairOrder.rOLineNumber);
            this.statusStepperService.currentStepChange(this.repairOrder.repairOrderNumber, this.repairOrder.rOLineNumber);
          });
      }
    } catch {
      this.loading = false;
    }
  }

  public processEditShippingResult(data: ShippingType) {
    try {
      let that = this;
      if (data) {
        //Selecting the blank option in the carrier dropdown passes empty 
        //string - explicitly set this to null for the microservice
        if(!data.carrierId)
        {
          data.carrierId = null;
        }
        if (!data.dateShipped) data.dateShipped = null
        if (!data.dateDelivered) data.dateDelivered = null

        this.loading = true;
        this.apiService.updateShipping(data).pipe(take(1), finalize(() => this.loading = false)).subscribe(result => {
          if (result) {
            that.shippings.outbound = that.shippings.outbound.map(x => x.id === result.id ? result : x)
            that.shippings.inbound = that.shippings.inbound.map(x => x.id === result.id ? result : x)
            this.generateShippingStage();
            this.statusStepperService.currentStepChange(this.repairOrder.repairOrderNumber, this.repairOrder.rOLineNumber);
            //Commented as part of bug #43045
            //this.activityService.activityChangePublish(this.repairOrder.repairOrderNumber, this.repairOrder.rOLineNumber);
          }
        },
          error => {
            this.loading = false;
          });
      }
    } catch {
      this.loading = false;
    }
  }

  public processDeleteShippingInfo(data: ShippingType) {

    const shippingDelete: ShippingInputType = {
      id: data.id
    };

    this.loading = true;

    try {
      this.apiService.deleteShipping(shippingDelete).pipe(take(1), finalize(() => this.loading = false))
        .subscribe((x) => {

          const resequenceFunc = (shippingItems: any[]) => {
            shippingItems.filter(x => x.sequence > data.sequence).forEach(x => x.sequence = x.sequence - 1);
          }

          if (data.directionId == 1) {
            this.shippings.outbound = this.shippings.outbound.filter(x => x.id !== data.id)
            resequenceFunc(this.shippings.outbound);
          } else {
            this.shippings.inbound = this.shippings.inbound.filter(x => x.id !== data.id)
            resequenceFunc(this.shippings.inbound);
          }

          this.generateShippingStage();
          this.statusStepperService.currentStepChange(this.repairOrder.repairOrderNumber, this.repairOrder.rOLineNumber);
          //Commented as part of bug #43045
          //this.activityService.activityChangePublish(this.repairOrder.repairOrderNumber, this.repairOrder.rOLineNumber);

        }, (error) => {
          console.log(`Delete shipping id: ${data.id} failed`)
          this.loading = false;
        });
    } catch {
      this.loading = false;
    }
  }
}