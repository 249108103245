//https://stackoverflow.com/questions/51709699/document-viewer-to-view-all-types-of-files-in-angular-4

import { Component, OnInit, ElementRef, Inject, Output, EventEmitter } from '@angular/core';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable } from 'apollo-link';
import { ApiService } from '@app/shared/services/api/api.service';
import {LoadingComponent} from '@app/shared/components/loading/loading.component';
import { SharedService } from '@app/shared/services/shared.service';
import { saveAs } from 'file-saver';
import { AuthService } from '@app/auth/services/auth/auth.service';
import { TagOption} from '@app/shared/services/api/api.models';
import { isEmpty } from '@app/common/utility';
import { DocumentEventService } from '@app/shared/services/rcm/event/event-service/document/document-event.service';



@Component({
  selector: 'aar-rcm-upload-document',
  templateUrl: './rcm-upload-document.component.html',
  styleUrls: ['./rcm-upload-document.component.scss']
})


export class RcmUploadDocumentComponent implements OnInit {
  tagOption = null;
  comments = "";
  public tagModel = [];
  public loading = false;
  private supportedExtensions = [];
  public fileSelected: Boolean = false;
  public documentList = [];
  public file: File;

  public static openDialog(dialog: MatDialog, repairOrderNo, repairOrderLineId: number) {
    const dialogRef = dialog.open(RcmUploadDocumentComponent, {
      data: { repairOrderNo: repairOrderNo, repairOrderId: repairOrderLineId }
    });

    return dialogRef.afterClosed(); // .subscribe(result => { });
  }


  sendMessage(): void {
    // send message to subscribers via observable subject
    this.sharedServices.sendMessage('DocumentUploaded');
   }



  constructor(public dialogRef: MatDialogRef<RcmUploadDocumentComponent>,
    private api: ApiService, private sharedServices: SharedService,
    @Inject(MAT_DIALOG_DATA)
    public data: any,     private snackBar: MatSnackBar, private authService: AuthService,
    private documentEventService:DocumentEventService
    ) {

  }

  ngOnInit() {

    this.api.getDocumentClasses().subscribe(documentClasses => {
      this.tagModel = documentClasses.map(item => {
        const new_item = {documentClassId: item.documentClassId, documentClassName: item.documentClassName};
        return new_item;
      });
    });

    this.api.getDocumentExtensions().subscribe(documentExtensions => {
      this.supportedExtensions = documentExtensions.map(item => {
        const new_item = {documentExtensionId: item.documentExtensionId, extentionName: item.extentionName};
        return new_item;
      });
    });

  }

  onClose() {
    this.dialogRef.close();
  }

  getTagNameById(id) {

    const tag = this.tagModel.filter(item => {
      return item.documentClassId === id;
    });
    if (tag.length > 0) {
      return tag[0].documentClassName;
    } else {
      return null;
    }
  }

  getExtensionId(fileName: String) {

    const ext = this.supportedExtensions.filter(item => {
      return  fileName.toLocaleLowerCase().endsWith(item.extentionName);
    });

    if (ext.length > 0) {
      return ext[0].documentExtensionId;
    } else {
      return -1;
    }
  }


  fileDrop(event) {

    if (event.file) {
      if (this.getExtensionId(event.file.name) === -1 ) {
        this.openSnackBar('Unsupported file type, please try again !');
        event.target.value = null;
        this.fileSelected = false;
        this.file = null;
      } else {
        this.fileSelected = true;
        this.file = event.file;
      }
    }
  }

  checkOnFileChoose(event) {
    if (event.target.files.length > 0) {

      if (this.getExtensionId(event.target.files[0].name) === -1 ) {
        this.openSnackBar('Unsupported file type, please try again !');
        event.target.value = null;
        this.fileSelected = false;
        this.file = null;
      } else {
        this.fileSelected = true;
        this.file = event.target.files[0];
      }
    }

  }

  uploadDocument() {

    if (this.tagOption === null) {
      this.openSnackBar('Please choose a tag!');
      return;
    }

    if (this.tagOption === TagOption.Other && isEmpty(this.comments)) {
      this.openSnackBar('Please provide comments!');
      return;
    }

    if (this.file) {

      if (this.file.size > 30000000) {
        this.openSnackBar('File size must be 30MB less!');
        return;
      }
      this.loading = true;

      const user = this.authService.getLoggedUser();

      const attachmentDetails = {
        documentPath :  this.getServerPath(),
        documentTitle: this.file.name,
        uploadedBy: user.userName,
        repairOrderNo: this.data.repairOrderId,
        companyIdref: user.companyID,
        keyWords: this.getTagNameById(this.tagOption),
        documentClassId: this.tagOption,
        documentExtensionId: this.getExtensionId(this.file.name),
        repaiOrderNumber: this.data.repairOrderNo,
        primary: false
      };

      const attachment = '(document: {documentPath: \\"' + this.getServerPath() + '\\", repaiOrderNumber: \\"' + this.data.repairOrderNo + '\\", documentExtensionId:' + this.getExtensionId(this.file.name) + ', documentClassId:' + this.tagOption + ', keyWords:\\"' + this.getTagNameById(this.tagOption) + '\\", companyIdref : ' + user.companyID + ', documentTitle: \\"' + this.file.name + '\\", uploadedBy:\\"repairAdmin\\", repairOrderNo:' + this.data.repairOrderId + '})';
      const attachmentQuery = 'mutation uploadDoc {uploadDocument' + attachment + '}';

      const fileDetails = {
        documentPath :  this.getServerPath(),
        documentTitle: this.file.name,
        uploadedBy: user.userName,
        repairOrderNo: this.data.repairOrderId,
        companyIdref: user.companyID,
        keyWords: this.getTagNameById(this.tagOption),
        documentClassId: this.tagOption,
        documentExtensionId: this.getExtensionId(this.file.name),
        repaiOrderNumber: this.data.repairOrderNo,
        thumbNailPath : 'assets/images/fpo/ro-detail/no-image.gif',
        primary: false,
        comments: this.comments
      };


      const extension = fileDetails.documentTitle.substr(fileDetails.documentTitle.lastIndexOf('.') + 1);
      const reader = new FileReader();
      const fileByteArray = [];
      const results = reader.readAsArrayBuffer(this.file);
      reader.onload = function (evt) {
      if (evt.eventPhase == FileReader.DONE) {
         const arrayBuffer = evt.target[0],
           array = new Uint8Array(arrayBuffer);
      for (let i = 0; i < array.length; i++) {
           fileByteArray.push(array[i]);
        }
      }
    };

    this.api.uploadFile(fileDetails, this.file)
      .subscribe((res: any) => { 
        this.dialogRef.close({success: true});
        this.sendMessage();
        this.openSnackBar('File Successfully Uploaded!');
        this.loading = false;
      },
      error => {
        this.openSnackBar('File Upload Failed. Please contact Admin!');
        this.loading = false;
      }
      );
    } else {
      this.openSnackBar('Please choose a file!');
    }
  }

  getServerPath(): string {
    const protocol = window.location.protocol;
    const host = window.location.hostname;
    const path = protocol + '/' + host + '/';
   // return path;
   return 'http://primary-ui-demo-2019.9c4dad7ec70c4df58264.centralus.aksapp.io/assets/images/fpo/ro-detail/';
  }

  openSnackBar(message: string): void {
    this.snackBar.open(message, null, {
      duration: 3000
    });
  }
}
