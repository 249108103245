import { Component, OnInit, NgModule, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { AppMaterialModule } from '@app/app-material.module';

@Component({
  selector: 'aar-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss']
})
export class LoadingComponent implements OnInit {
  @Input('error') error: boolean;

  ngOnInit() { }


  refreshPage(): void {
    location.reload();
  }
}

@NgModule({
  imports: [
    CommonModule,
    MatProgressSpinnerModule,
    MatButtonModule,
    AppMaterialModule
  ],
  declarations: [ LoadingComponent ],
  exports: [ LoadingComponent ]
})

export class LoadingModule { }
