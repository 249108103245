import {Component, OnInit} from '@angular/core';
import { ApiService } from '@app/shared/services/api/api.service';
import { AuthService } from '@app/auth/services/auth/auth.service';
import { User } from '@app/auth/auth.models';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { timer, json } from 'd3';
import { Subscription } from 'rxjs';
import { interval } from 'rxjs';

@Component({
  selector: 'aar-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.scss']
})
export class TermsComponent {
  constructor(private api: ApiService) {
  }
 
}
