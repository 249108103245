import { Component, OnInit, NgModule, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { AppMaterialModule } from '@app/app-material.module';

@Component({
  selector: 'aar-loading-local',
  templateUrl: './loading-local.component.html',
  styleUrls: ['./loading-local.component.scss']
})
export class LoadingLocalComponent implements OnInit {
  @Input('error') error: boolean;

  ngOnInit() { }


  refreshPage(): void {
    location.reload();
  }
}

@NgModule({
    imports: [
        CommonModule,
        MatProgressSpinnerModule,
        MatButtonModule,
        AppMaterialModule
    ],
    declarations: [LoadingLocalComponent],
    exports: [LoadingLocalComponent]
})

export class LoadingLocalModule { }
