import { Component, Input, OnChanges } from '@angular/core';
import { QuoteReviewQuoteLine } from '@app/shared/services/api/api.models';

interface CustomerSuppliedValues {
  typeName: string;
  value: string;
  currency: string;
}

@Component({
  selector: 'aar-rcm-quote-review-details-pricing',
  templateUrl: './rcm-quote-review-details-pricing.component.html',
  styleUrls: ['./rcm-quote-review-details-pricing.component.scss']
})
export class RcmQuoteReviewDetailsPricingComponent implements OnChanges {
  @Input('selectedQuoteLine') selectedQuoteLine: QuoteReviewQuoteLine;

  customerSuppliedValues: CustomerSuppliedValues[];
  columnsToDisplay = ['typeName', 'value', 'currency'];
  isLoading = false;
  displayColumns = [
    { name: "typeName", header: "Value Type" },
    { name: "value", header: "Value Amount" },
    { name: "currency", header: "Currency" }
  ];

  ngOnChanges(): void {
    if (this.selectedQuoteLine?.customerSuppliedValues) {
      this.customerSuppliedValues = this.selectedQuoteLine.customerSuppliedValues.map((csv) => {
        return {
          typeName: csv.typeName,
          value: csv.value ? csv.value.toFixed(2) : '',
          currency: csv.currency
        }
      })

    }
  }
}