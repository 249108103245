import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { MatLegacySnackBar as MatSnackBar } from "@angular/material/legacy-snack-bar";
import { AuthService } from "@app/auth/services/auth/auth.service";
import { RcmStepperService } from "@app/rcm/rcm-stepper/rcm-stepper.service";
import {
  CreateQuoteLineInput,
  IsAuthorizedApproverQuery,
  QuoteLine,
  QuoteStatusIdType,
  RepairOrder,
  RepairOrderLine,
  UpdateQuoteLineInput,
} from "@app/shared/services/api/api.models";
import { ApiService } from "@app/shared/services/api/api.service";
import { SharedService } from "@app/shared/services/shared.service";
import { sumBy } from "lodash";
import { BehaviorSubject, interval, of, Subscription } from "rxjs";
import { finalize, switchMap, tap, take } from "rxjs/operators";
import { RcmReviewWorkscopeStatusComponent } from "../rcm-review-workscope-status/rcm-review-workscope-status.component";
import { PersonaService } from '@app/shared/services/rcm/persona.service';
import { User } from '@app/auth/auth.models';
import { DataLookupService } from "@app/shared/services/rcm/data-lookup/data-lookup.service";
import { CurrencyLocalisedPipe } from "@app/shared/custom-pipe/currency-pipe/currency-localised.pipe.";
import { QuoteService } from "@app/shared/services/rcm/quote/quote.service";
import { RcmStatusStepperService } from "@app/rcm/rcm-stepper/rcm-status-stepper.service";
//Commented as part of bug #43045
//import { ActivityService } from "@app/shared/services/rcm/activity/activity.service";

@Component({
  selector: "aar-rcm-quote-processing",
  templateUrl: "./rcm-quote-processing.component.html",
  styleUrls: ["./rcm-quote-processing.component.scss"],
})
export class RcmQuoteProcessingComponent implements OnInit, OnDestroy {
  @Input() repairOrder: RepairOrder;
  @Output("parentNextStep") parentNextStep = new EventEmitter();

  quoteLine: UpdateQuoteLineInput;
  repairOrderLine: RepairOrderLine;
  quoteLines: QuoteLine[];
  quoteLinesTotalCost: number;
  timer: Subscription;
  quoteStatusBtnLabel: string;
  editQuoteBtnLabel: string;
  isUpdated = false;
  show = false;
  step: number;
  showCase: number;
  autoSaveStatus: string;
  autoSaveTime: string;
  loadingROL: boolean;
  approveEnabled: boolean = false;
  saveState: number;
  user: User;
  isAuthorizedApproverQuery: IsAuthorizedApproverQuery;
  canApproveQuote: boolean;
  totalSupplierCost: number;
  selectedQuoteLine: QuoteLine;
  selectedQuoteLineForStatus: QuoteLine;
  selectedQuoteLines: QuoteLine[];
  tableModel: any[] = [];
  showQuoteProcessing: boolean = true;
  hideStartQuoteButton: boolean = false;
  hideEditQuoteButton: boolean = false;
  hidePrevious: boolean = false;
  hideSubmit: boolean = false;
  personaService: PersonaService;
  private totalCostName = 'totalCost';

  private $externalSubscriptions = new Subscription;

  loading:boolean = false;

  preventApprovalState: QuoteStatusIdType | undefined;

  @ViewChild("quoteDateStatus", { static: true })
  quoteDateStatus: RcmReviewWorkscopeStatusComponent;

  steps = ["comparison", "cost", "complete"];

  quoteProcessingDisplayedColumns = [
    { name: "proposedWorkscopeId", header: "Workscope Proposed" },
    { name: "repairCategoryValue", header: "Unit Category" },
    { name: "qTYRceived", header: "Quantity Received" },
    { name: "clientQuoteStatus", header: "Status" },
    { name: "totalSupplierCost", header: "Total Supplier Cost" },
    { name: "masterOption", header: "Is Master" },
  ];

  constructor(
    private rcmStepperService: RcmStepperService,
    private sharedServices: SharedService,
    private snackBar: MatSnackBar,
    private apiService: ApiService,
    private authService: AuthService,
    private cd: ChangeDetectorRef,
    private datalookupService: DataLookupService,
    private currencyLocalisedPipe: CurrencyLocalisedPipe,
    private quoteService:QuoteService,
    private statusStepperService:RcmStatusStepperService
    //Commented as part of bug #43045
    //private activityService:ActivityService
  ) {
    this.personaService = new PersonaService(this.authService.getLoggedUser().personaName);

    this.sharedServices.getMessage().subscribe((message) => {
      if (message == "ShowQuoteProcessingList") {
        this.step = -1;
        this.selectedQuoteLineForStatus = null;
        this.selectedQuoteLine = null;
        this.sharedServices.setSelectedQuoteLine(null);
        this.getProcessedQuotes();
      }
    });
  }

  ngOnDestroy() {
    // unsubscribe to ensure no memory leaks
    //this.timer.unsubscribe();
    this.sharedServices.setSelectedQuoteLine(null);
    this.$externalSubscriptions.unsubscribe();
  }

  calculateQuoteLineTotaLCost() {
    if (this.quoteLines) {
      this.quoteLinesTotalCost = parseFloat(
        sumBy(this.quoteLines, this.totalCostName).toFixed(2)
      );

      const masterQuote = this.quoteLines.find((masterQuote) => masterQuote.quote.masterOption === true);

      if(masterQuote){
        this.quoteService.setOriginalMasterQuote(masterQuote.totalCost, 
          masterQuote.currencyType.id, 
          masterQuote.id);
      }else{
        this.quoteService.unsetOriginalMasterQuote();
      }
    }
  }

  getMasterOptionQuoteToCustomerDate(){
    if(this.quoteLines){
      this.quoteLines.filter(
        quote => {
          if (quote.quote.masterOption) {
            this.sharedServices.setMasterOptionQuoteToCustomerDate(quote.quote.quotedToCustomerDate);
          }
        }
      );
    }
  }

  selectItem(e) {
    // this.loadingROL = true;
    const { quote } = e;
    // this.apiService.getQuotesLinesById(quote.rCMQuoteId).subscribe(quotelineList => {
    //   this.loadingROL = false;
    //   this.setSelectedQuote(quotelineList);
    //   this.sharedServices.setSelectedQuoteLine(quotelineList[0]);
    // },
    // error => {
    //  this.openSnackBar('Error Displaying Quotelines.  Please try again later. ');
    //  this.loadingROL = false;
    // });
  }

  setCreateQuoteBehaviour() {
    if (
      this.user.roleName.toUpperCase() === "CUSTOMER" ||
      this.user.roleName.toUpperCase() === "SUPPLIER" ||
      this.user === null ||
      this.personaService.hideQuotes()
    ) {
      this.showQuoteProcessing = false;
    }

    //Hide the start quote button for non-quoting personas
    if (this.personaService.skipQuoteEntry()) {
      this.hideStartQuoteButton = true;
      this.hidePrevious = true;
      this.hideSubmit = true;
    }

    //Hide the edit quote button for non-quoting personas
    if (this.personaService.hideQuotes()) {
      this.hideEditQuoteButton = true;
    }
  }

  setSelectedQuote(quoteLine: QuoteLine[]) {
    if (quoteLine.length > 0) this.approveEnabled = true;

    const datePosted = new Date(quoteLine[0].quote.datePosted);
    datePosted.setDate(datePosted.getUTCDate());
    const dateReceived = new Date(quoteLine[0].quote.dateReceived);
    dateReceived.setDate(dateReceived.getUTCDate());

    const ecd = new Date(quoteLine[0].ecd);
    ecd.setDate(ecd.getUTCDate());

    this.quoteLine = {
      datePosted: datePosted,
      createdByUserName: "",
      assignedUserId: "",
      finalApproverId: "",
      masterOption: quoteLine[0].masterOption,
      quoteOrderLineNum: quoteLine[0].quoteOrderLineNum,
      mismatchReasonPnId: quoteLine[0].mismatchReasonPnId,
      qtyRceived: quoteLine[0].qTYRceived,
      mismatchReasonPn: quoteLine[0].mismatchReasonPnId,
      repairCategoryId: quoteLine[0].repairCategory.repairCategoryId,
      repairCategoryValue: quoteLine[0].repairCategory.value,
      proposedWorkscopeId: quoteLine[0].proposedWorkscopeId,
      mismatchReasonSnId: quoteLine[0].mismatchReasonSnId,
      serialNumberReceived: quoteLine[0].serialNumberReceived,
      id: quoteLine[0].id,
      quoteId: quoteLine[0].quote.rCMQuoteId,
      quotedTat: quoteLine[0].quotedTat,
      quoteStatus: quoteLine[0].quote.quoteStatusName,
      updatedAt: new Date(quoteLine[0].updatedAt),
      nff: "",
      unitId: "",
      partNumberReceived: quoteLine[0].partNumberReceived,
      mismatchReasonSn: quoteLine[0].mismatchReasonSnId,
      findings: quoteLine[0].findings,
      dateReceived: dateReceived,
      supplierReference: quoteLine[0].quote.supplierReference,
      vendAccount: "",
      vendName: "",
      quoteLineStatus: "",
      webServiceDescription: "",
      roLineNumber: this.repairOrder.repairOrderLine.rOLineNumber,
      roNumber: this.repairOrder.repairOrderNumber,
      currency: quoteLine[0].currencyType.id,
      warrantyStatusId: quoteLine[0].quoteWarrantyStatus ? quoteLine[0].quoteWarrantyStatus.id : null,
      totalCost: quoteLine[0].totalCost,
      nupCurrency: quoteLine[0].nupCurrency,
      nUP: quoteLine[0].nUP,
      nupPercent: quoteLine[0].nupPercent,
      mvCurrency: quoteLine[0].mvCurrency,
      mV: quoteLine[0].mV,
      mvPercent: quoteLine[0].mvPercent,
      laborCost: quoteLine[0].laborCost,
      material: quoteLine[0].material,
      miscellaneous: quoteLine[0].miscellaneous,
      rai: quoteLine[0].rai,
      attachedQuoteID: quoteLine[0].attachedQuoteID,
      sos: quoteLine[0].sos,
      canceledDate: quoteLine[0].quote.canceledDate,
      cancelationReason: quoteLine[0].quote.cancelationReason,
      authorizedOnSupplierDate: quoteLine[0].quote.authorizedOnSupplierDate,
      customerApprovedDate: quoteLine[0].quote.customerApprovedDate,
      quotedToCustomerDate: quoteLine[0].quote.quotedToCustomerDate,
      processedDate: quoteLine[0].quote.processedDate,
      createdDate: quoteLine[0].quote.createdDate,
      internalComments: quoteLine[0].quote.internalComments
    };

    this.totalSupplierCost =
      this.quoteLine.qtyRceived * this.quoteLine.totalCost;
  }

  setQuote(quoteLine: QuoteLine[]) {
    if (quoteLine.length > 0) this.approveEnabled = true;
    const datePosted = new Date(quoteLine[0].quote.datePosted);
    datePosted.setDate(datePosted.getUTCDate());
    const dateReceived = new Date(quoteLine[0].quote.dateReceived);
    dateReceived.setDate(dateReceived.getUTCDate());

    const ecd = new Date(quoteLine[0].ecd);
    ecd.setDate(ecd.getUTCDate());

    this.quoteLine = {
      datePosted: datePosted,
      createdByUserName: "",
      assignedUserId: "",
      finalApproverId: "",
      masterOption: true, //set this value from radio button
      quoteOrderLineNum: quoteLine[0].quoteOrderLineNum,
      mismatchReasonPnId: quoteLine[0].mismatchReasonPnId,
      qtyRceived: quoteLine[0].qTYRceived,
      mismatchReasonPn: quoteLine[0].mismatchReasonPnId,
      repairCategoryValue: quoteLine[0].repairCategory.value,
      proposedWorkscopeId: quoteLine[0].proposedWorkscopeId,
      mismatchReasonSnId: quoteLine[0].mismatchReasonSnId,
      serialNumberReceived: quoteLine[0].serialNumberReceived,
      id: quoteLine[0].id,
      quoteId: quoteLine[0].quote.rCMQuoteId,
      quotedTat: quoteLine[0].quotedTat,
      quoteStatus: quoteLine[0].quote.quoteStatusName,
      updatedAt: new Date(quoteLine[0].updatedAt),
      nff: "",
      unitId: "",
      partNumberReceived: quoteLine[0].partNumberReceived,
      mismatchReasonSn: quoteLine[0].mismatchReasonSnId,
      findings: quoteLine[0].findings,
      dateReceived: dateReceived,
      supplierReference: quoteLine[0].quote.supplierReference,
      vendAccount: "",
      vendName: "",
      quoteLineStatus: "",
      webServiceDescription: "",
      roLineNumber: this.repairOrder.repairOrderLine.rOLineNumber,
      roNumber: this.repairOrder.repairOrderNumber,
      currency: quoteLine[0].currencyType.id,
      warrantyStatusId: quoteLine[0].quoteWarrantyStatus ? quoteLine[0].quoteWarrantyStatus.id: null,
      totalCost: quoteLine[0].totalCost,
      nupCurrency: quoteLine[0].nupCurrency,
      nUP: quoteLine[0].nUP,
      nupPercent: quoteLine[0].nupPercent,
      mvCurrency: quoteLine[0].mvCurrency,
      mV: quoteLine[0].mV,
      mvPercent: quoteLine[0].mvPercent,
      laborCost: quoteLine[0].laborCost,
      material: quoteLine[0].material,
      miscellaneous: quoteLine[0].miscellaneous,
      rai: quoteLine[0].rai,
      attachedQuoteID: quoteLine[0].attachedQuoteID,
      sos: quoteLine[0].sos,
      canceledDate: quoteLine[0].quote.canceledDate,
      cancelationReason: quoteLine[0].quote.cancelationReason,
      authorizedOnSupplierDate: quoteLine[0].quote.authorizedOnSupplierDate,
      customerApprovedDate: quoteLine[0].quote.customerApprovedDate,
      quotedToCustomerDate: quoteLine[0].quote.quotedToCustomerDate,
      processedDate: quoteLine[0].quote.processedDate,
      createdDate: quoteLine[0].quote.createdDate,
      internalComments: quoteLine[0].quote.internalComments,
      unitCost: quoteLine[0].unitCost
    };

    this.totalSupplierCost =
      this.quoteLine.qtyRceived * this.quoteLine.totalCost;
    this.sharedServices.setSelectedQuoteLine(quoteLine[0]);
  }

  showItemStatus(e: any[]) {
    this.selectedQuoteLines = e;
    this.preventApprovalState = undefined;
    if (e.length == 1) {
      this.selectedQuoteLine = e[0];
      this.selectedQuoteLine.roNumber = this.repairOrder.repairOrderNumber;
      this.sharedServices.setSelectedQuoteLine(this.selectedQuoteLine);
      this.selectedQuoteLineForStatus = { ...this.selectedQuoteLine };

      this.quoteLines.forEach((qlItem) => {
        if (qlItem.id !== this.selectedQuoteLineForStatus.id) {
          if (qlItem.quote.quoteStatus.id === QuoteStatusIdType.Authorized) {
            this.preventApprovalState = QuoteStatusIdType.Authorized;
          }
          if (qlItem.quote.quoteStatus.id === QuoteStatusIdType.AuthorizedOnSupplier) {
            this.preventApprovalState = QuoteStatusIdType.AuthorizedOnSupplier
          }
        }
      });

    } else {
      this.selectedQuoteLineForStatus = null;
      this.selectedQuoteLine = null;
      this.sharedServices.setSelectedQuoteLine(null);
    }
  }

  ngOnInit(): void {
    this.user = this.authService.getLoggedUser();
    this.setCreateQuoteBehaviour();
    this.calculateCanApproveQuote();
    this.step = -1;
    this.showCase = 0;
    this.autoSaveStatus = "Autosaved at";
    this.autoSaveTime = new Date().toTimeString();
    this.saveState = 0;

    /*this.timer = interval(600000).subscribe(x => {


      try {
        this.sendMessage();
        this.autoSaveStatus = 'Autosaved at';
        this.autoSaveTime = new Date().toTimeString();
        this.saveState = 1;
        setTimeout(() => {
          this.saveState = 0;
        }, 3000);

        this.saveState = 0;
      } catch {
        this.autoSaveStatus = 'Autosaved failed Please';
        this.autoSaveTime = 'Ensure fields are valid';
        setTimeout(() => {
          this.saveState = 2;
        }, 1000);

        this.saveState = 0;
      }

      this.saveState = 0;
    });*/

    this.getProcessedQuotes();

    this.rcmStepperService.rcmStepChange$.subscribe((value) => {
      this.quoteDateStatus.reset();
      switch (value) {
        case "SUPPLIER RECEIPT":
          this.step = -1;
          break;
        case "QUOTE PROCESSING":
          this.step = 0;
          break;
        case "QUOTE APPROVAL":
          this.loadingROL = true;
          this.apiService
            .getPendngQuoteLineStagging(
              this.quoteLine.roNumber,
              this.quoteLine.roLineNumber
            )
            .subscribe(
              (quotelineList) => {
                this.quoteLines = Array(quotelineList);
                this.loadingROL = false;
                this.buildTableModel();
                this.calculateQuoteLinesTotal();
                //console.log(JSON.stringify(this.quoteLines));
              },
              (error) => {
                this.openSnackBar(
                  "Error Displaying Quotelines.  Please try again later"
                );
                this.loadingROL = false;
              }
            );
          this.step = 3;
          break;
        default:
          break;
      }
    });

    this.repairOrderLine = this.repairOrder.repairOrderLine;

    switch (this.repairOrderLine.status.name) {
      //case 'QUOTE_PROCESSING':
      //  this.step = 0;
      //  break;
      case "QUOTE_APPROVED":
        this.startQuoteProcessing();
        break;
      default:
        break;
    }

    this.quoteStatusBtnLabel = "Create New Quote";

    this.loadingROL = false;

    this.calculateQuoteLinesTotal();

    if (this.hideStartQuoteButton) {
      this.editQuoteBtnLabel = 'Show Selected Quote';
    }
    else {
      this.editQuoteBtnLabel = 'Edit Selected Quote';
    }
  }

  newQuoteLine(): void {
    this.step = -1;
  }

  callUpdatedQuoteLine(value: boolean) {
    if (value) {
      console.log("emitted output called");
      this.ngOnInit();
    }
  }

  buildTableModel() {
    this.tableModel = this.quoteLines.map((quotes) => {
      return { ...quotes, repairCategoryValue: quotes.repairCategory.value, 
        clientQuoteStatus: this.translateQuoteStatus(quotes.quote.quoteStatus.id),
        proposedWorkscopeId: this.datalookupService.cachedWorkscopeForId(quotes.proposedWorkscopeId).formattedName,
        totalSupplierCost: this.currencyLocalisedPipe.transform(quotes.totalCost, quotes.currencyType.name)
      };
    });
  }

  translateQuoteStatus(quoteId: number): string {

    switch (quoteId) {
      case QuoteStatusIdType.Authorized:
        return "Approved"
      case QuoteStatusIdType.AuthorizedOnSupplier:
        return "Authorized On Supplier"
      case QuoteStatusIdType.Canceled:
        return "Canceled"
      case QuoteStatusIdType.Created:
        return "Created"
      case QuoteStatusIdType.SentForAuthorization:
        return "Sent For Approval"
      case QuoteStatusIdType.Processed:
        return "Processed"
    }
  }

  getProcessedQuotes() {
    this.loadingROL = true;

    this.apiService
      .getProcessedQuotes(
        this.repairOrder.repairOrderNumber,
        this.repairOrder.rOLineNumber
      )
      .subscribe(
        (quotelineList) => {
          //this.quoteLines = quotelineList;
          this.loadingROL = false;
          this.buildTableModel();

          this.calculateQuoteLineTotaLCost();
          let selectedQuoteLine = this.sharedServices.getSelectedQuoteLine();
          if(selectedQuoteLine){
            this.sharedServices.setSelectedQuoteLine(this.quoteLines[0]);
            this.sharedServices.setSelectedQuoteLine(this.quoteLines.find((masterQuote) => masterQuote.id == selectedQuoteLine.id));
          } else {
            this.sharedServices.setSelectedQuoteLine(this.quoteLines[0]);
          }
          this.getMasterOptionQuoteToCustomerDate();
        },
        (error) => {
          this.loadingROL = false;
        }
      );
  }

  calculateCanApproveQuote() {
    let canApprove: boolean;
    canApprove = false;

    if (this.user.roleName === "REPAIR_MANAGER") {
      canApprove = true;
    } else if (!this.isAuthorizedApproverQuery) {
      canApprove = false;
    } else if (
      this.isAuthorizedApproverQuery &&
      this.isAuthorizedApproverQuery.isAuthorizedApprover.approvalHierarchy &&
      this.isAuthorizedApproverQuery.isAuthorizedApprover.approvalHierarchy
        .startRange <= this.quoteLinesTotalCost &&
      this.quoteLinesTotalCost <=
      this.isAuthorizedApproverQuery.isAuthorizedApprover.approvalHierarchy
        .endRange
    ) {
      canApprove = true;
    }

    this.canApproveQuote = canApprove;
  }

  calculateQuoteLinesTotal() {
    //NOTE: This only controls if the button shows in the UI.  This check is done on the server side as well.
    if (!this.quoteLines) {
      // can't approve if the quotelines aren't available or loaded.
      return false;
    }
    this.calculateQuoteLineTotaLCost();
    this.apiService
      .getIsAuthorizedApprover(this.user.id, this.quoteLinesTotalCost)
      .subscribe((isAuthorizedApproverQuery) => {
        this.isAuthorizedApproverQuery = isAuthorizedApproverQuery;
        this.calculateCanApproveQuote();
        this.cd.detectChanges();
      });

    //console.log (`total: ${this.quoteLinesTotalCost}`);
  }

  approveQuoteLines() {
    this.apiService
      .updateQuoteLineStatus(
        this.repairOrder.repairOrderNumber,
        this.repairOrder.rOLineNumber,
        this.selectedQuoteLine.quote.rCMQuoteId,
        "Authorized"
      )
      .subscribe(
        (result) => {
          if (result == true) {
            //commented
            //this.sharedServices.setSelectedQuoteLine(null);
            this.finalApprovalStep();
            this.openSnackBar("Quotelines successfully approved");
            this.statusStepperService.currentStepChange(this.quoteLine.roNumber, this.repairOrderLine.rOLineNumber);
            //Commented as part of bug #43045
            //this.activityService.activityChangePublish(this.quoteLine.roNumber, this.repairOrder.rOLineNumber);
          } else {
            this.openSnackBar(
              "Error approving Quotelines, please ensure that you have the rights to approve this transaction"
            );
          }
        },
        (error) => {
          //console.log('Approval Error: ' + error);
          this.openSnackBar(
            "Error approving quoteline.  Please try again later"
          );
        }
      );
  }

  upDateQuoteStatus(status: string) {
    this.apiService
      .updateQuoteLineStatus(
        this.repairOrder.repairOrderNumber,
        this.repairOrder.rOLineNumber,
        this.selectedQuoteLine.quote.rCMQuoteId,
        status
      )
      .subscribe(
        (result) => {
          if (result == true) {
            //commented
            //this.sharedServices.setSelectedQuoteLine(null);
            this.finalApprovalStep();
            this.openSnackBar("Quote Status Successfully Updated");
            this.statusStepperService.currentStepChange(this.quoteLine.roNumber, this.repairOrderLine.rOLineNumber);
            //Commented as part of bug #43045
            //this.activityService.activityChangePublish(this.quoteLine.roNumber, this.repairOrderLine.rOLineNumber);
          } else {
            this.openSnackBar(
              "Error Updating Quote Status, please ensure that you have the rights to approve this transaction"
            );
          }
        },
        (error) => {
          this.sharedServices.setSelectedQuoteLine(null);
          //console.log('Approval Error: ' + error);
          this.openSnackBar(
            "Error Updating Quote Status.  Please try again later"
          );
        }
      );
  }

  openSnackBar(message: string): void {
    this.snackBar.open(message, null, {
      duration: 3000,
    });
  }

  backToQuotes() {
    // this.selectedQuoteLine = null   //this.selectedQuoteLines = [];
    this.step = -1;
  }

  editSelectedQuote() {
    this.loadingROL = true;
    let rcmQuoteId =
      this.sharedServices.getSelectedQuoteLine().quote.rCMQuoteId;
    this.apiService.getQuotesLinesById(rcmQuoteId).subscribe(
      (result) => {
        this.setQuote(result);
        this.nextStep(3, null, this.personaService.skipQuoteEntry());
        this.loadingROL = false;
      },
      (error) => {
        this.loadingROL = false;
      }
    );
  }

  cancelSelectedQuote() {
    this.upDateQuoteStatus("Cancelled");
    this.selectedQuoteLine = null;
    this.selectedQuoteLines = [];
  }

  approveSelectedQuote() {
    this.approveQuoteLines();
    this.selectedQuoteLine = null;
    this.selectedQuoteLines = [];
  }

  startQuoteProcessing() {
   
    this.loadingROL = true;

   this.$externalSubscriptions.add(this.apiService.createQuoteLineStagging(this.repairOrder.repairOrderNumber, this.repairOrder.repairOrderLine.rOLineNumber)
    .pipe(tap(() => this.loadingROL = true, finalize(() => this.loadingROL = false)), take(1),
      switchMap((pendingQuote) => {
        if(pendingQuote) return of(pendingQuote)
        return this.apiService.getPendngQuoteLineStagging(this.repairOrder.repairOrderNumber, this.repairOrder.repairOrderLine.rOLineNumber)
      .pipe(tap(() => this.loadingROL = true, finalize(() => this.loadingROL = false)), take(1));
      })
    ).subscribe(
        (result) => {
          this.setQuote(Array(result));
          this.nextStep(3, null);
          this.statusStepperService.currentStepChange(this.quoteLine.roNumber, this.repairOrderLine.rOLineNumber);
          this.loading = false;
        },
        (error) => {
          this.loading = false;
        }
      ));

  }

  nextStep(parent: any, showCase: any, skipQuoteEntry: boolean = false): void {

    if (skipQuoteEntry) {
      this.step = this.steps.length - 1; // 0 based
    }
    else {
      if (this.step < this.steps.length) {
        this.step++;
      }
    }

    if (parent) {
      this.parentNextStep.emit(parent);
    }

    if (showCase) {
      this.showCase = 1;
    }

    this.manageErrors();
  }

  finalApprovalStep(): void {
    this.parentNextStep.emit(5);
    this.showCase = 1;
    this.step = 5;
    this.manageErrors();
    this.sharedServices.setSelectedQuoteLine(null);
  }

  prevStep(): void {
    if (this.step > 0) {
      this.step--;
    }

    this.manageErrors();
  }

  // TODO: this logic should change in the future with real scenario
  manageErrors() {
    this.addError();
    this.removeError();
  }

  addError() {
    if (this.step === 1) {
      this.parentNextStep.emit("error");
    }
  }

  removeError() {
    if (this.step === 0 || this.step === 2) {
      this.parentNextStep.emit("remove error");
    }
  }

  sortedLines(): QuoteLine[] {
    return this.quoteLines.sort((a, b) => a.id - b.id);
  }

  updateQuoteLine(quoteLine: UpdateQuoteLineInput): void {
    this.quoteLine = quoteLine;
    this.isUpdated = true;
  }

  updateQuoteLineDates(quoteLine: UpdateQuoteLineInput): void {
    this.quoteLine = quoteLine;
    this.loadingROL = true;
    quoteLine = this.sharedServices.preserveQuoteDates(quoteLine);

    this.apiService.updateQuoteLine(quoteLine).subscribe(
      (updatedQuoteLine) => {

        this.statusStepperService.currentStepChange(this.quoteLine.roNumber, this.repairOrderLine.rOLineNumber);
        //Commented as part of bug #43045
        //this.activityService.activityChangePublish(this.quoteLine.roNumber, this.repairOrderLine.rOLineNumber);
        this.getProcessedQuotes();
        this.openSnackBar("Quoteline Successfully Updated. ");
        this.loadingROL = false;
        this.sharedServices.sendMessage("QuoteStatusDatesUpdated");
        if(this.quoteLine.quotedToCustomerDate && this.quoteLine.masterOption){
          this.sharedServices.setMasterOptionQuoteToCustomerDate(this.quoteLine.quotedToCustomerDate);
        }
      },
      (error) => {
        this.openSnackBar(
          "Error Displaying Quotelines.  Please try again later. "
        );
        this.loadingROL = false;
        //console.log('QuoteLine Creation Error: ' + error);
      }
    );
  }



  saveAndClose(event) {
    this.sendMessage();
    //this.step = -1;
  }

  sendMessage(): void {
    if (this.step == 0) {
      this.sharedServices.sendMessage("SaveStep0");
    }
    if (this.step == 1) {
      this.sharedServices.sendMessage("SaveStep1");
    }
    if (this.step == 2) {
      this.sharedServices.sendMessage("SaveStep2");
    }
  }

  submitQuoteLine(quoteLine: UpdateQuoteLineInput): void {
    //this.loadingROL = true;

    /*
    const quoteCreateInput: CreateQuoteLineInput = {
      datePosted: quoteLine.datePosted,
      dateReceived: quoteLine.dateReceived,
      masterOption: true, // set this value from radio button
      assignedUserId: quoteLine.assignedUserId,
      warrantyStatusId: 1, //se this value from drop down
      finalApproverId: quoteLine.finalApproverId,
      findings: quoteLine.findings.replace('"', ''),
      webServiceDescription: quoteLine.webServiceDescription,
      qtyRceived: quoteLine.qtyRceived,
      quoteId: quoteLine.quoteId,
      updatedAt: quoteLine.updatedAt,
      vendAccount: quoteLine.vendAccount,
      // ecd : quoteLine.ecd,
      quotedTat: quoteLine.quotedTat,
      quoteLineStatus: quoteLine.quoteLineStatus,
      quoteOrderLineNum: quoteLine.quoteOrderLineNum,
      serialNumberReceived: quoteLine.serialNumberReceived,
      sos: quoteLine.sos,
      supplierReference: quoteLine.supplierReference,
      rai: quoteLine.rai,
      totalCost: quoteLine.totalCost,
      miscellaneous: quoteLine.miscellaneous,
      mismatchReasonPnId: quoteLine.mismatchReasonPnId,
      mismatchReasonSnId: quoteLine.mismatchReasonSnId,
      createdByUserName: quoteLine.createdByUserName,
      currency: quoteLine.currency,
      unitId: quoteLine.unitId,
      mV: quoteLine.mV,
      material: quoteLine.material,
      nUP: quoteLine.nUP,
      partNumberReceived: quoteLine.partNumberReceived,
      proposedWorkscopeId: quoteLine.proposedWorkscopeId,
      repairCategoryName: quoteLine.repairCategoryName,
      roLineNumber: quoteLine.roLineNumber,
      roNumber: quoteLine.roNumber,
      mismatchReasonPn: quoteLine.mismatchReasonPn,
      mismatchReasonSn: quoteLine.mismatchReasonSn,
      mvCurrency: quoteLine.mvCurrency,
      mvPercent: quoteLine.mvPercent,
      laborCost: quoteLine.laborCost,
      nupCurrency: quoteLine.nupCurrency,
      nff: quoteLine.nff,
      nupPercent: quoteLine.nupPercent,
      vendName: quoteLine.vendName,
      quoteStatus: quoteLine.quoteStatus,
      cancellationReason: quoteLine.cancellationReason,
      cancelledDate: quoteLine.cancelledDate,
      authorizedOnSupplierDate: quoteLine.authorizedOnSupplierDate,
      customerApprovedDate: quoteLine.customerApprovedDate,
      quotedToCustomerDate: quoteLine.quotedToCustomerDate,
      processedDate: quoteLine.processedDate,
      createdDate: quoteLine.createdDate,

    }; */


    let currentQuoteLine = this.sharedServices.getSelectedQuoteLine();

    const $getProcessedQuotes =
      this.apiService.getProcessedQuotes(this.quoteLine.roNumber, quoteLine.roLineNumber)
        .pipe(tap(() => this.loadingROL = true),
          take(1));

    //Only set to processed if it's at create status
    if (currentQuoteLine.quote.quoteStatus.name.toLowerCase() === "created") {
      let quoteLineInput: UpdateQuoteLineInput = {
        onlyUpdateStatus: true,
        roNumber: this.quoteLine.roNumber,
        quoteId: currentQuoteLine.quote.rCMQuoteId,
        quoteStatusId: QuoteStatusIdType.Processed,
        repairCategoryId: currentQuoteLine.repairCategory.repairCategoryId,
        processedDate: new Date()
      }

      const $quoteLineUpdate =
        this.apiService.updateQuoteLine(quoteLineInput)
          .pipe(tap(() => this.loadingROL = true),
            take(1));

      let cont = true;

      const quoteUpdateOps = $quoteLineUpdate.pipe(
        tap((rslt) => {
          cont = rslt !== undefined;
        }),
        switchMap(() => {
          return $getProcessedQuotes
        }),
        tap((getQuotes) => {
          if (cont) {
            //this.quoteLines = getQuotes;
            this.sharedServices.sendMessage("ShowQuoteProcessingList");
            this.calculateQuoteLineTotaLCost();
          } else {
            cont = false;
          }
        })
      );

      quoteUpdateOps.pipe(finalize(() => this.loadingROL = false))
        .subscribe(() => {
          if (cont) {
            this.openSnackBar("Quoteline Successfully created. ")
            this.statusStepperService.currentStepChange(this.quoteLine.roNumber, this.repairOrderLine.rOLineNumber);
            //Commented as part of bug #43045
            //this.activityService.activityChangePublish(this.repairOrder.repairOrderNumber, this.repairOrder.rOLineNumber);
          } else {
            this.openSnackBar("Error creating new Quote Line.  Please try again later. ")
          }
        }),
        (error) => {
          this.openSnackBar(
            "Error creating new Quote Line.  Please try again later. "
          );
        }
    } else {

      $getProcessedQuotes.pipe(finalize(() => this.loadingROL = false)).subscribe((processedQuotes) => {
        this.statusStepperService.currentStepChange(this.quoteLine.roNumber, this.repairOrderLine.rOLineNumber);
        //this.quoteLines = processedQuotes;
        this.calculateQuoteLineTotaLCost();
        this.sharedServices.sendMessage("ShowQuoteProcessingList");
        this.openSnackBar("Quoteline Successfully updated. ")
      }),
        (error) => {
          this.openSnackBar(
            "Error retrieving quotes.  Please try again later. "
          );
        }
    }
  }
}
