import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatLegacyChipsModule as MatChipsModule } from '@angular/material/legacy-chips';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatLegacySlideToggleModule as MatSlideToggleModule } from '@angular/material/legacy-slide-toggle';
import { MatLegacySnackBarModule as MatSnackBarModule } from '@angular/material/legacy-snack-bar';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { RouterModule } from '@angular/router';
import { AppMaterialModule } from '@app/app-material.module';
import { RcmActivityComponent } from '@app/rcm/rcm-details/rcm-activity/rcm-activity.component';
// tslint:disable-next-line:max-line-length
import { RcmCommunicationBlockComponent } from '@app/rcm/rcm-details/rcm-communication/rcm-communication-block/rcm-communication-block.component';
// tslint:disable-next-line:max-line-length
import { RcmCommunicationEmailComponent } from '@app/rcm/rcm-details/rcm-communication/rcm-communication-email/rcm-communication-email.component';
import { RcmCommunicationComponent } from '@app/rcm/rcm-details/rcm-communication/rcm-communication.component';
import { RcmAllDocumentsComponent } from '@app/rcm/rcm-details/rcm-documents/rcm-all-documents/rcm-all-documents.component';
import { RcmDocumentDetailComponent } from '@app/rcm/rcm-details/rcm-documents/rcm-document-detail/rcm-document-detail.component';
import { RcmQuoteProcessingComponent } from '@app/rcm/rcm-details/rcm-quote-processing/rcm-quote-processing.component';
// tslint:disable-next-line:max-line-length
import { RcmReviewWorkscopeComparisonComponent } from '@app/rcm/rcm-details/rcm-review-workscope-comparison/rcm-review-workscope-comparison.component';
// tslint:disable-next-line:max-line-length
import { RcmReviewWorkscopeCompleteComponent } from '@app/rcm/rcm-details/rcm-review-workscope-complete/rcm-review-workscope-complete.component';
import { RcmReviewWorkscopeCostComponent } from '@app/rcm/rcm-details/rcm-review-workscope-cost/rcm-review-workscope-cost.component';
import { RcmShippingBlockComponent } from '@app/rcm/rcm-details/rcm-shipping/rcm-shipping-block/rcm-shipping-block.component';
import { RcmShippingComponent } from '@app/rcm/rcm-details/rcm-shipping/rcm-shipping.component';
import { SharedModule } from '@app/shared.module';
import { DashCardModule, DataTableModule, NoActivityModule, TimelineModule, ToggleGroupModule } from '@app/shared/components';
import { LoadingLocalModule } from '@app/shared/components/loading/loading-local.component';
import { NgFileDropDirectiveModule } from '@app/shared/directives/ng-file-drop/ng-file-drop.directive';
import { RcmAddCommunicationComponent } from './rcm-details/modals/rcm-add-communication/rcm-add-communication.component';
import { RcmAddShipmentComponent } from './rcm-details/modals/rcm-add-shipment/rcm-add-shipment.component';
import { RcmEditShipmentComponent } from './rcm-details/modals/rcm-edit-shipment/rcm-edit-shipment.component';
import { RcmExportComponent } from './rcm-details/modals/rcm-export/rcm-export.component';
import { RcmUploadDocumentComponent } from './rcm-details/rcm-documents/rcm-upload-document/rcm-upload-document.component';
import { RcmDetailsComponent } from './rcm-details/rcm-details.component';
import { RcmQuoteAllDocumentsFilterTypePipe } from './rcm-details/rcm-documents/rcm-all-documents/rcm-all-documents-filter-type.pipe';
import { RcmQuoteAllDocumentsFilterPipe } from './rcm-details/rcm-documents/rcm-all-documents/rcm-all-documents-filter.pipe';
import { RcmQuoteAllDocumentsPrimaryPipe } from './rcm-details/rcm-documents/rcm-all-documents/rcm-all-documents-primary.pipe';
import { RcmQuoteAllDocumentsSortPipe } from './rcm-details/rcm-documents/rcm-all-documents/rcm-all-documents-sort.pipe';
import { RcmReviewWorkscopeStatusComponent } from './rcm-details/rcm-review-workscope-status/rcm-review-workscope-status.component';
import { RcmHeaderComponent } from './rcm-header/rcm-header.component';
import { RcmStepperItemComponent } from './rcm-stepper/rcm-stepper-item/rcm-stepper-item.component';
import { RcmStepperComponent } from './rcm-stepper/rcm-stepper.component';
import { RcmStepperService } from './rcm-stepper/rcm-stepper.service';
import { RcmStatusStepperService } from './rcm-stepper/rcm-status-stepper.service';
import { RcmComponent } from './rcm.component';
import { RcmHistoryComponent } from './rcm-history/rcm-history.component';
import { CommonDialogComponent } from '../shared/components/common-dialog/common-dialog.component';
import { RcmStatusStepperComponent } from './rcm-stepper/rcm-status-stepper/rcm-status-stepper/rcm-status-stepper.component';
import { RcmShippingHeaderComponent } from './rcm-details/rcm-shipping/rcm-shipping-header/rcm-shipping-header/rcm-shipping-header.component';
import { RcmShippingHeaderTitleComponent } from './rcm-details/rcm-shipping/rcm-shipping-header-title/rcm-shipping-header-title/rcm-shipping-header-title.component';
import { RcmDataTableComponent } from './rcm-data-table/rcm-data-table/rcm-data-table.component';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { MatSortModule } from '@angular/material/sort';
import { MatLegacyPaginatorModule as MatPaginatorModule } from '@angular/material/legacy-paginator';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { RcmQuoteReviewComponent } from './rcm-details/rcm-quote-review/rcm-quote-review.component';
import { RcmQuoteReviewOverviewComponent } from './rcm-details/rcm-quote-review/rcm-quote-review-overview/rcm-quote-review-overview.component';
import { RcmQuoteReviewDetailsComponent } from './rcm-details/rcm-quote-review/rcm-quote-review-details/rcm-quote-review-details.component';
import { RcmQuoteReviewDetailsGeneralComponent } from './rcm-details/rcm-quote-review/rcm-quote-review-details/rcm-quote-review-details-general/rcm-quote-review-details-general.component';
import { RcmQuoteReviewDetailsQuoteLineComponent } from './rcm-details/rcm-quote-review/rcm-quote-review-details/rcm-quote-review-details-quote-line/rcm-quote-review-details-quote-line.component';
import { RcmQuoteReviewDetailsMilestonesComponent } from './rcm-details/rcm-quote-review/rcm-quote-review-details/rcm-quote-review-details-milestones/rcm-quote-review-details-milestones.component';
import { RcmQuoteReviewDetailsCostBreakdownComponent } from './rcm-details/rcm-quote-review/rcm-quote-review-details/rcm-quote-review-details-cost-breakdown/rcm-quote-review-details-cost-breakdown.component';
import { RcmQuoteReviewDetailsPricingComponent } from './rcm-details/rcm-quote-review/rcm-quote-review-details/rcm-quote-review-details-pricing/rcm-quote-review-details-pricing.component';
import { RcmQuoteValueDetailsThresholdComponent } from './rcm-details/rcm-quote-review/rcm-quote-review-details/rcm-quote-review-details-threshold/rcm-quote-value-details-threshold.component';





@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        RouterModule,
        MatExpansionModule,
        MatFormFieldModule,
        MatInputModule,
        MatSelectModule,
        MatChipsModule,
        DashCardModule,
        DataTableModule,
        TimelineModule,
        NoActivityModule,
        MatDatepickerModule,
        MatSlideToggleModule,
        MatButtonModule,
        MatCheckboxModule,
        AppMaterialModule,
        FormsModule,
        LoadingLocalModule,
        ReactiveFormsModule,
        NgFileDropDirectiveModule,
        MatSnackBarModule,
        MatAutocompleteModule,
        ToggleGroupModule,
        MatTooltipModule,
        MatTableModule,
        MatSortModule,
        MatPaginatorModule,
        MatProgressSpinnerModule
    ],
    providers: [
        RcmStepperService,
        RcmStatusStepperService
    ],
    declarations: [
        RcmComponent,
        RcmDetailsComponent,
        RcmStepperComponent,
        RcmStepperItemComponent,
        RcmHeaderComponent,
        RcmReviewWorkscopeComparisonComponent,
        RcmReviewWorkscopeCostComponent,
        RcmReviewWorkscopeCompleteComponent,
        RcmReviewWorkscopeStatusComponent,
        RcmAllDocumentsComponent,
        RcmDocumentDetailComponent,
        RcmQuoteProcessingComponent,
        RcmCommunicationComponent,
        RcmCommunicationBlockComponent,
        RcmCommunicationEmailComponent,
        RcmAddShipmentComponent,
        RcmEditShipmentComponent,
        RcmUploadDocumentComponent,
        RcmAddCommunicationComponent,
        RcmExportComponent,
        RcmActivityComponent,
        RcmShippingComponent,
        RcmShippingBlockComponent,
        RcmQuoteAllDocumentsFilterPipe,
        RcmQuoteAllDocumentsPrimaryPipe,
        RcmQuoteAllDocumentsSortPipe,
        RcmQuoteAllDocumentsFilterTypePipe,
        RcmHistoryComponent,
        CommonDialogComponent,
        RcmStatusStepperComponent,
        RcmShippingHeaderComponent,
        RcmShippingHeaderTitleComponent,
        RcmDataTableComponent,
        RcmQuoteReviewComponent,
        RcmQuoteReviewOverviewComponent,
        RcmQuoteReviewDetailsComponent,
        RcmQuoteReviewDetailsGeneralComponent,
        RcmQuoteReviewDetailsQuoteLineComponent,
        RcmQuoteReviewDetailsMilestonesComponent,
        RcmQuoteReviewDetailsCostBreakdownComponent,
        RcmQuoteReviewDetailsPricingComponent,
        RcmQuoteValueDetailsThresholdComponent
    ]
})
export class RcmModule { }
