import { Component, Input, Output, EventEmitter, ViewChild, AfterViewInit, OnInit } from '@angular/core';
import { QuoteReviewQuoteLine } from '@app/shared/services/api/api.models';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';

interface QuoteOverview {
  category: string;
  partNumber: string;
  partNumberDescription: string;
  serialNumber: string;
  workscope: string;
  unitCost: string;
  quantity: number;
  totalSupplierCost: string;
  currency: string;
  quoteStatus: string;
  masterOption: string;
  multiOption: string;
  documents: boolean;
  quoteLineId:number;
}

@Component({
  selector: 'aar-rcm-quote-review-overview',
  styleUrls: ['rcm-quote-review-overview.component.scss'],
  templateUrl: 'rcm-quote-review-overview.component.html'
})

export class RcmQuoteReviewOverviewComponent implements OnInit, AfterViewInit {
  @Input('quoteOverviewData') quoteOverviewData: QuoteReviewQuoteLine[];
  @Output('selectedQuoteLine') selectedQuoteLine = new EventEmitter<QuoteReviewQuoteLine>();

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  overviewData: QuoteOverview[] = [];
  selectedRow: QuoteOverview;
  pageSize = 5;
  pageSizeOptions = [3, 5, 10];

  dataSource: MatTableDataSource<QuoteOverview>;

  columnsToDisplay = ['category', 'partNumber', 'partNumberDescription', 'serialNumber', 'workscope', 'unitCost',
    'quantity', 'totalSupplierCost', 'currency', 'quoteStatus', 'masterOption', 'multiOption', 'documents'];

  constructor() {
    this.dataSource = new MatTableDataSource(this.overviewData);
  }

  displayColumns = [
    { name: 'category', header: 'Category' },
    { name: 'partNumber', header: 'Part Number' },
    { name: 'partNumberDescription', header: 'Description' },
    { name: 'serialNumber', header: 'Serial Number' },
    { name: 'workscope', header: 'Workscope' },
    { name: 'unitCost', header: 'Unit Cost' },
    { name: 'quantity', header: 'Qty' },
    { name: 'totalSupplierCost', header: 'Total Supplier Cost' },
    { name: 'currency', header: 'Currency' },
    { name: 'quoteStatus', header: 'Quote Status' },
    { name: 'masterOption', header: 'Master Option' },
    { name: 'multiOption', header: 'Multi-Option' },
    { name: 'documents', header: 'Documents' }
  ];

  ngOnInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

  }

  ngOnChanges() {
    if (this.quoteOverviewData) {
      this.overviewData = this.quoteOverviewData.map((quoteLine) => {
        return {
          category: quoteLine?.repairCategoryName,
          partNumber: quoteLine.partNumberReceived,
          partNumberDescription: quoteLine.partNumberDescription,
          serialNumber: quoteLine.serialNumberReceived,
          workscope: quoteLine.proposedWorkScopeName,
          unitCost: quoteLine.unitCost ? quoteLine.unitCost.toFixed(2) : '',
          quantity: quoteLine.qTYRceived,
          totalSupplierCost: quoteLine.totalCost ? quoteLine.totalCost.toFixed(2) : '',
          currency: quoteLine.currencyName,
          quoteStatus: quoteLine.quote.quoteStatusName,
          masterOption: quoteLine.quote.masterOption ? 'Yes' : 'No',
          multiOption: quoteLine.quote.multiOption ? 'Yes' : 'No',
          documents: false,
          quoteLineId: quoteLine.id
        } as QuoteOverview;
      });

      this.dataSource = new MatTableDataSource<QuoteOverview>(this.overviewData);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
    }
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  onSelectionChange(selectedQuoteDetails: QuoteOverview): void {
    const selectedQuoteLine = this.quoteOverviewData.find(x => x.id === selectedQuoteDetails.quoteLineId);
    if (this.selectedRow === selectedQuoteDetails) {
      this.selectedRow = null;
      this.selectedQuoteLine.emit(null);

    } else {
      this.selectedRow = selectedQuoteDetails;
      this.selectedQuoteLine.emit(selectedQuoteLine);
    }

  }

  isSelected(row: QuoteOverview) {
    return row === this.selectedRow;
  }

}