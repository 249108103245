import { Component, Input, OnChanges } from '@angular/core';
import { QuoteReviewQuoteLine } from '@app/shared/services/api/api.models';

@Component({
  selector: 'aar-rcm-quote-review-details',
  templateUrl: './rcm-quote-review-details.component.html',
  styleUrls: ['./rcm-quote-review-details.component.scss']
})
export class RcmQuoteReviewDetailsComponent implements OnChanges {

  @Input('selectedQuoteLine') selectedQuoteLine: QuoteReviewQuoteLine

  quoteLine: QuoteReviewQuoteLine;

  selectedTabIndex = 0;

  ngOnChanges(): void {
    this.quoteLine = this.quoteLine;
  }
}