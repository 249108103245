import { AfterViewInit, Component, Input, OnInit, SimpleChanges, ViewChild, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { MatSort } from '@angular/material/sort';
import { SelectionModel } from '@angular/cdk/collections';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { ExcelExportColumnMap } from '@app/shared/components/export-excel/export-excel.component';

interface ControlDetails { name: string, options: any[], filtered: boolean, labelName: string, isFilterable: boolean };
export const NO_VALUE_TEXT = '[No Value]';
export interface RcmGridColumnDetails { name: string, label: string, filterable: boolean, exportable: boolean, showInGrid: boolean };
export interface RcmGridData {
  repairOrderId: number,
  id: number,
  repairOrderNumber: string,
  programmeDescription: string,
  supplierName: string,
  repairOrderLineId: number;
  nTat: string;
  description: string;
  gTat: string;
  partNumber: string;
  priority: string;
  repairLineStatus: string;
  serialNumber: string;
  workscopeName: string;
  repairLineStatusDescription: string;
  documents: string;
  paperClipImage: string;
  customerAccountManager: string;
}

@Component({
  selector: 'aar-rcm-data-table',
  templateUrl: './rcm-data-table.component.html',
  styleUrls: ['./rcm-data-table.component.scss'
  ],
  encapsulation: ViewEncapsulation.None
})

export class RcmDataTableComponent implements OnInit, AfterViewInit {
  @Input('rcmData') rcmData: any[] = [];
  @Input('displayColumns') displayColumns: RcmGridColumnDetails[];

  readonly paperClipImage: 'assets/images/fpo/suppliers/paperclip.png';
  readonly assetPath: 'assets/images/fpo/suppliers/';
  readonly linkPath = '/rcm';
  readonly pageSizeOptions = [5, 10, 20];
  readonly pageSize = 10;
  readonly title = 'Repair Orders'
  readonly excelButtonTitleBase: string = 'Export';
  readonly clearFilterOption = 'clear';
  readonly noValueText = NO_VALUE_TEXT;

  filtered = false;
  excelButtonTitle: string;
  loading: boolean = false;
  displayedColumns: string[] = [];
  excelColumns: string[] = [];
  excelExport: ExcelExportColumnMap[] = [];
  dataSource = new MatTableDataSource<any>(this.rcmData);
  rcmDataTableForm: UntypedFormGroup;
  selection = new SelectionModel<any>(true, []);
  controlDetails: ControlDetails[] = [];

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  ngOnInit(): void {
    this.loading = true;
    this.displayedColumns = [];
    this.displayColumns.forEach((x) => {
      if (x.showInGrid) {
        this.displayedColumns.push(x.name);
      }
    })
    this.displayedColumns.splice(0, 0, 'select');
    this.subscribeToSelectionChange();
    this.buildControlObject();
    this.buildForm();
    this.registerFilterPredicate();
    this.buildOptions();
    this.subscribeToFormEvents();
    this.loading = false;
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  ngOnChanges(changes: SimpleChanges) {
    this.loading = true;
    const { filterPredicate, sortingDataAccessor } = this.dataSource;
    this.dataSource = new MatTableDataSource(this.rcmData);
    this.dataSource.filterPredicate = filterPredicate;
    this.dataSource.sortingDataAccessor = sortingDataAccessor;
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.onClearAllFilters();
    this.selection.clear();
    this.buildOptions();
    this.loading = false;
  }

  //Private functions

  private subscribeToSelectionChange(): void {
    this.excelButtonTitle = `${this.excelButtonTitleBase} (All)`;
    this.selection.changed.subscribe(() => {
      let recordsSelected = 'All';
      if ((this.selection.selected.length === 0)) {
        recordsSelected = 'All'
      } else {
        recordsSelected = this.selection.selected.length.toString();
      }
      this.excelButtonTitle = `${this.excelButtonTitleBase} (${recordsSelected})`;
    });
  }

  private buildControlObject(): void {

    this.controlDetails = [];
    this.displayColumns.filter(x => x.name !== 'select').forEach(x => {

      let cd: ControlDetails = {
        name: x.name,
        options: [],
        filtered: false,
        labelName: x.label.toUpperCase(),
        isFilterable: x.filterable
      }
      this.controlDetails.push(cd);
    });

    this.excelColumns = [];
    this.excelExport = [];
    this.displayColumns.forEach((x) => {
      if (x.exportable) {
        this.excelExport.push({ dataColumn: x.name, columnName: x.label })
        this.excelColumns.push(x.name);
      }
    });
  }

  private buildForm(): void {
    const formFields = {};

    this.controlDetails.forEach(field => {
      formFields[field.name] = new UntypedFormControl({
        value
          : '', disabled: false
      });
    });
    this.rcmDataTableForm = new UntypedFormGroup(formFields);

    this.controlDetails.forEach((ctrl) => {
      this.rcmDataTableForm.controls[ctrl.name].valueChanges.subscribe((change) => {
      });
    });
  }

  private registerFilterPredicate(): void {
    this.dataSource.filterPredicate = (data: any, filter: any) => {
      this.loading = true;
      let match = true;

      for (let key in filter) {
        if (filter[key] && filter[key] !== 0 && filter[key] !== data[key]) {
          match = false;
          break;
        }
      }
      this.loading = false;
      return match;
    };
  }

  private subscribeToFormEvents(): void {
    this.rcmDataTableForm.valueChanges.subscribe((changes) => {
      this.dataSource.filter = changes;
    });
  }

  private buildOptions(): void {
    this.controlDetails.forEach(element => {
      element.options = [];
      element.options = Array.from(new Set(
        this.dataSource.filteredData.map(function (item) {
          if (item[element.name]) {
            return item[element.name];
          }
        })))
      const noValueIndex = element.options.findIndex(x=>x === this.noValueText)
      if(noValueIndex > -1){
        element.options.splice(noValueIndex,1);
        element.options.unshift(this.noValueText);
      }
    });
  }

  //Event Handlers

  onFilterChange(event: MatSelectChange, controlName: string): void {
    const controlDetail = this.controlDetails.find(c => c.name === controlName);
    if (event.value !== 'clear') {
      controlDetail.filtered = true;
      this.buildOptions();
      this.filtered = true;
    } else {
      controlDetail.options = [];
      this.rcmDataTableForm.controls[controlName].setValue(undefined);
      this.buildOptions();
      controlDetail.filtered = null;
      this.filtered = this.controlDetails.findIndex(x => x.filtered) != -1;
    }
  }

  onClearAllFilters(): void {
    this.loading = true;
    let filteredControls = this.controlDetails.filter(c => c.filtered);
    filteredControls.forEach(fc => {
      this.rcmDataTableForm.controls[fc.name].setValue(null)
      fc.filtered = false;
    });
    this.filtered = false;
    this.buildOptions();
    this.loading = false;
  }

  onExcelClick(): any {
    const excelSanitiser = (sourceExcelData: any): any[] => {
      const targetExcelData = [];
      sourceExcelData.forEach((item) => targetExcelData.push(Object.assign({}, item)));
      targetExcelData.map((selected) => {
        return Object.keys(selected).map((key) => {
          if (selected[key] === NO_VALUE_TEXT) {
            selected[key] = null;
          }
        })
      });
      return targetExcelData;
    }
    if (this.selection.selected.length > 0) {
      return excelSanitiser(this.selection.selected);
    } else {
      return excelSanitiser(this.dataSource.filteredData);
    }
  }

  //Functions

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    if (this.dataSource.filteredData) {
      return this.dataSource.filteredData.length === numSelected
    } else {
      return this.dataSource.data.length = numSelected;
    }

  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  toggleAllRows() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }

    if (this.dataSource.filteredData.length > 0) {
      this.selection.select(...this.dataSource.filteredData)
    } else {
      this.selection.select(...this.dataSource.data);
    }
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
  }

  getSelected(): number {
    return this.controlDetails.filter(x => x.filtered).length;
  }

}