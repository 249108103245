<div class="aar-document-container">
  <div class="aar-document-container-list">
    <div class="aar-all-documents" *ngIf="documents">

      <ng-template [ngIf]="isEmpty" [ngIfElse]="content">
        <aar-no-activity [message]="noActivityMessage"></aar-no-activity>
      </ng-template>

      <div class="aar-card">
        <ng-template #content>
          <div class="aar-black-card">
            <div class="rcm-search">
              <mat-icon class="icon-search">search</mat-icon>
              <input type="text" [(ngModel)]="filters.searchQuery" />
              <button class="aar-clear-search" mat-button (click)="clearFilters()">Clear all</button>
            </div>

            <div class="form-group">
              <div class="form-control border-1 mtb-25">
                <label>Sort by</label>
                <mat-form-field floatLabel="never">
                  <mat-select [(ngModel)]="filters.sortBy">
                    <mat-option value="">None</mat-option>
                    <mat-option *ngFor="let sort of documentListSortBy" [value]="sort.value">
                      {{sort.label}}
                    </mat-option>mat-icon
                  </mat-select>
                </mat-form-field>
              </div>
              <!-- Filter by is not a current requirement, so hide it in case it comes back into scope-->
              <div class="form-control mtb-25" *ngIf="false">
                <label>Filter by</label>
                <mat-form-field floatLabel="never">
                  <mat-select [(ngModel)]="filters.filterBy">
                    <mat-option value="">None</mat-option>
                    <mat-option *ngFor="let filter of documentListFilterBy" [value]="filter.value">
                      {{filter.label}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>

              <div class="form-control layout-icons mtb-12">
                <button mat-icon-button (click)="toggleViewType()">
                  <mat-icon [ngClass]="{'selected': !viewList}">view_module</mat-icon>
                </button>
                <button mat-icon-button (click)="toggleViewType()">
                  <mat-icon [ngClass]="{'selected': viewList}">view_list</mat-icon>
                </button>
              </div>
            </div>
          </div>


          <div class="aar-communication-height" *ngIf="!viewList">
            <div class="aar-highlight-box">
              <div class="aar-card">
                <div class="img-group">
                  <div class="img-container" (click)="documentClick(document)" *ngFor="let document of documents |
               rcmQuoteAllDocumentsFilter : filters.searchQuery |
               rcmQuoteAllDocumentsPrimary : true |
               rcmQuoteAllDocumentsFilterType : filters.filterBy |
               rcmQuoteAllDocumentsSort : filters.sortBy">
                    <span class="img">
                      <img [src]="document.document" alt="">
                    </span>
                    <span [ngClass]="{'title': !document.selected, 'title-bold': document.selected}">{{document.name}}</span>
                    <span class="overlay">{{document.tag}}</span>
                  </div>
                </div>
              </div>
            </div>

            <div class="aar-card">
              <div class="img-group">
                <div class="img-container" (click)="documentClick(document)" *ngFor="let document of documents |
             rcmQuoteAllDocumentsFilter : filters.searchQuery |
             rcmQuoteAllDocumentsPrimary : false |
             rcmQuoteAllDocumentsFilterType : filters.filterBy |
             rcmQuoteAllDocumentsSort : filters.sortBy">
                  <div><span class="overlay">{{document.tag}}</span></div>
                  <div><span><img [src]="getFileTypeIconPath(document.type)" /></span></div>
                  <div><span
                      [ngClass]="{'title': !document.selected, 'title-bold': document.selected}">{{document.name}}</span>
                  </div>
                  <div *ngIf="false"><span class="overlayItar">Export Controlled</span></div>
                </div>

              </div>
            </div>
          </div>

          <div class="aar-communication-height" *ngIf="viewList">
            <div class="aar-list-highlight-box">
              <div class="aar-card">
                <div class="aar-list-item" (click)="documentClick(document)" *ngFor="let document of documents |
             rcmQuoteAllDocumentsFilter : filters.searchQuery |
             rcmQuoteAllDocumentsPrimary : true |
             rcmQuoteAllDocumentsFilterType : filters.filterBy |
             rcmQuoteAllDocumentsSort : filters.sortBy">
                  <span [ngClass]="{'title': !document.selected, 'title-bold': document.selected}">{{document.name}}</span>
                  <span class="tag">{{document.tag}}</span>

                </div>
              </div>
            </div>

            <div>
              <div class="aar-card">
                <div class="aar-list-item" (click)="documentClick(document)" *ngFor="let document of documents |
             rcmQuoteAllDocumentsFilter : filters.searchQuery |
             rcmQuoteAllDocumentsPrimary : false |
             rcmQuoteAllDocumentsFilterType : filters.filterBy |
             rcmQuoteAllDocumentsSort : filters.sortBy">
                  <span [ngClass]="{'title': !document.selected, 'title-bold': document.selected}">{{document.name}}</span>

                </div>
              </div>
            </div>

          </div>
        </ng-template>

        <div class="form-footer">
          <div class="form-footer-button">
            <button mat-flat-button color="primary" (click)="showUploadDocument()"
              *ngIf="curUser.personaName !== 'PROGRAMMANAGER'">Upload Document</button>
          </div>
        </div>

      </div>

    </div>

  </div>
  <div *ngIf="selectedDocument">
    <aar-rcm-document-detail [document]="selectedDocument" (refresh)="refreshDocuments()"></aar-rcm-document-detail>
  </div>
</div>