import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatLegacyFormFieldModule as MatFormFieldModule, MatLegacyError as MatError } from '@angular/material/legacy-form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacySelect as MatSelect, MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';
import { AppMaterialModule } from '@app/app-material.module';
import { SuppliersComponent } from './suppliers.component';
import { SupplierWipComponent } from '@app/suppliers/supplier-session/supplier-wip/supplier-wip.component';
import { SupplierArchiveComponent } from '@app/suppliers/supplier-session/supplier-archive/supplier-archive.component';
import { SupplierCarriersComponent } from '@app/suppliers/supplier-session/supplier-carriers/supplier-carriers.component';
import {SupplierQuoteDropComponent} from '@app/suppliers/supplier-session/supplier-quote-drop/supplier-quote-drop.component';
import { DashCardModule, DataTableModule, DragAndDropUploaderModule } from '@app/shared/components';
import { SupplierAddQuoteComponent } from './supplier-session/supplier-add-quote/supplier-add-quote.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    MatInputModule,
    MatDatepickerModule,
    MatSelectModule,
    MatCheckboxModule,
    AppMaterialModule,
    DashCardModule,
    DataTableModule,
    DragAndDropUploaderModule,
    MatIconModule,
    MatFormFieldModule,
    MatTabsModule
  ],
  providers: [],
  declarations: [
    SuppliersComponent,
    SupplierWipComponent,
    SupplierArchiveComponent,
    SupplierCarriersComponent,
    SupplierQuoteDropComponent,
    SupplierAddQuoteComponent
  ]
})
export class SuppliersModule { }
