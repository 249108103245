<mat-accordion *ngIf="cost">

  <mat-expansion-panel [expanded]="expand" #matExpansionPanelHeader (opened)="togglePanelOpen()" (closed)="togglePanelOpen()">

    <mat-expansion-panel-header [ngClass]="{'opened': panelOpenState}"
      (click)="expandPanel(matExpansionPanelHeader, $event)">
      <mat-panel-title>
        <div class="aar-panel-title font-regular">
          <h2>{{ roDetails.repairOrderNumber }}</h2>
          <div class="aar-panel-title-info title-info">

            <form [formGroup]="roTopForm" class="aar-top-form">
              <div class="aar-panel-group">
                <!-- Leave div to preserve the top form spacing. Priority used to be visible. -->
              </div>
              <div class="aar-panel-group" *ngIf="isSupplier">
                <div class="aar-header-label-detail">INTERNAL CONTACT</div>
                <div class="aar-header-field-detail">{{assignedUser.fullName}}</div>
              </div>
              <div class="aar-panel-group">
                <!--There is no provider value for Customer Account Manager-->
                <div class="aar-header-label-detail">User</div>
                <div class="aar-header-field-detail">{{roDetails.repairOrderLine.customerAccountManager}}</div>
              </div>
              <div class="aar-panel-group" *ngIf="canEdit">
                <div class="aar-header-label-detail">Programme</div>
                <div class="aar-header-field-detail">{{roDetails.programmeDescription}}</div>
              </div>
              <div class="aar-panel-group" *ngIf="canEdit">
                <div class="aar-header-label-detail">Account</div>
                <div class="aar-header-field-detail">{{roDetails.customerAccountName}}</div>
              </div>
              <div class="aar-panel-group" *ngIf="canEdit">
                <div class="aar-header-label-detail">Supplier Code</div>
                <div class="aar-header-field-detail">{{roDetails.customerSupplierCode}}</div>
              </div>
              <div class="aar-panel-group" *ngIf="canEdit">
                <div class="aar-header-label-detail">Supplier</div>
                <div class="aar-header-field-detail">{{roDetails.supplier.name}}</div>
              </div>

            </form>


            <div class="aar-panel-group" *ngIf="false && !isFieldHidden('customerROCreatedDate')">
              <div class="aar-header-label-main">Created</div>
              <div class="aar-header-field-main">{{roDetails.customerROCreatedDate | date:'M/d/yyyy'}}</div>
            </div>
            <div class="aar-panel-group">

            </div>
          </div>
          <div class="aar-panel-title-info title-tat" *ngIf="false">

            <div class="aar-panel-group tat">
              <label class="aar-panel-tat-label">TAT</label>

              <div class="aar-panel-tat-days">10 DAYS NET</div>
              <div class="aar-panel-tat-days">20 DAYS GROSS</div>
            </div>

          </div>
        </div>
      </mat-panel-title>
      <mat-panel-description *ngIf="!panelOpenState">
        <div class="aar-panel-description font-regular">
          <div class="aar-panel-description-info large">
            <div class="aar-panel-group">
              <div class="aar-header-label-detail">Inbound Part Number</div>
              <div class="aar-header-field-detail">{{roDetails.partNumber}}</div>
            </div>
            <div class="aar-panel-group">
              <div class="aar-header-label-detail">Inbound Serial Number</div>
              <div class="aar-header-field-detail">{{roDetails.serialNumber}}</div>
            </div>
            <div class="aar-panel-group">
              <div class="aar-header-label-detail">Aircraft Type</div>
              <div class="aar-header-field-detail">{{roDetails.repairOrderLine ?
                roDetails.repairOrderLine.aircraftTypeId : 'n/a'}} </div>
            </div>
            <!-- Commented under #40361
            <div class="aar-panel-group">
              <div class="aar-header-label-detail">FFP</div>
              <div class="aar-header-field-detail">
                <aar-toggle [value]="roDetails.repairOrderLine.contract" disabled="true"></aar-toggle>
              </div>
            </div>
          -->
            <div class="aar-panel-group">
              <div class="aar-header-label-detail">Warranty</div>
              <div class="aar-header-field-detail">
                <aar-toggle [value]="this.dynamicWarranty" disabled="true"></aar-toggle>
              </div>
            </div>
            <!-- <div class="aar-panel-group" *ngIf="isSupplier">
              <div class="aar-header-label-detail">Total Supplier Cost</div>
              <div class="aar-header-field-detail">{{ this.totalSupplierCost }}</div>
            </div> -->
            <div class="aar-panel-group">
              <div class="aar-header-label-detail">Inbound Workscope</div>
              <div class="aar-header-field-detail">{{formattedWorkscope}}</div>
            </div>
          </div>
        </div>
      </mat-panel-description>
    </mat-expansion-panel-header>

    <div class="aar-header-container font-regular">
      <div class="aar-header-content lg">
        <h3>RO Details
          <div class="editSpan" *ngIf="showEditButton" style="display:inline-block; margin-left:7px;">
            EDIT <aar-toggle [showToggleLabels]="false" (isToggleOn)="toggleRoEdit($event)" [disabled]="disableEditBtn"
              style="display:inline-block; margin-left: 5px;height: 7px; vertical-align: baseline; margin-right: 5px ;width: 55px;">
            </aar-toggle> {{ roEdit ? "ON" : "OFF" }}
          </div>
        </h3>

        <form [formGroup]="roDetailsForm">
          <div class="aar-header-content lg display-row">
            <mat-tab-group [selectedIndex]="selectedTabIndex">
              <mat-tab label="General">

                <div class="p-20">


                  <!-- Commented under #40361
                   <div class="aar-header-content display-row">
                    <mat-form-field floatLabel="always" class="sm mr">
                      <input matInput placeholder="PO Number" formControlName="poNumber">
                      <mat-icon matSuffix>error_outline</mat-icon>
                      <mat-error *ngIf="hasError(roDetailsForm, 'poNumber')">{{ getErrorMessage(roDetailsForm,
                        'poNumber') }}</mat-error>
                    </mat-form-field>

                    <mat-form-field floatLabel="always" class="sm">
                      <input matInput placeholder="PO Created Date" formControlName="customerInternalRefNumberDate">
                      <mat-icon matSuffix>error_outline</mat-icon>
                      <mat-error *ngIf="hasError(roDetailsForm, 'customerInternalRefNumberDate')">{{
                        getErrorMessage(roDetailsForm, 'customerInternalRefNumberDate') }}</mat-error>
                    </mat-form-field>
                  </div> -->

                  <div>
                    <div class="aar-header-content display-row">
                      <!--Commented under #40361
                      <mat-form-field floatLabel="always" class="sm mr" *ngIf="isSupplier">
                        <input matInput placeholder="Supplier RMA Number" formControlName="supplierRMANumber"
                          type="text" aria-label="" [type]="canEdit || isSupplier ? 'text' : 'password'">
                        <mat-icon matSuffix>error_outline</mat-icon>
                        <mat-error *ngIf="hasError(roDetailsForm, 'supplierRMANumber')">{{
                          getErrorMessage(roDetailsForm, 'supplierRMANumber') }}</mat-error>
                      </mat-form-field>
                      -->
                    </div>
                  </div>

                  <div class="aar-header-content display-row">
                    <aar-toggle [group]="roDetailsForm" controlName="warranty">
                      <div aar-label>Warranty</div>
                    </aar-toggle>

                    <!-- Commented under #40361
                    <mat-form-field floatLabel="always" class="sm mr" *ngIf="isSupplier">
                      <input readonly="readonly" type="text" aria-label="" min="0" matInput
                        placeholder="Total Supplier Cost" readonly [formControlName]="totalSupplierCostControlName"
                        [type]="canEdit || isSupplier ? 'text' : 'password'">
                      <mat-icon matSuffix>error_outline</mat-icon>
                      <mat-error *ngIf="hasError(roDetailsForm, totalSupplierCostControlName)">{{
                        getErrorMessage(roDetailsForm,
                        'totalSupplierCost') }}</mat-error>
                    </mat-form-field>
                  -->

                    <!--
                      <span class="adjacent-toggle-container" *ngIf="showFFPAndWarrantyOnSameLine">
                        <div class="first-toggle">
                        <aar-toggle [group]="roDetailsForm" controlName="contracted">
                          <div aar-label>FFP</div>
                        </aar-toggle>
                      </div>
                  
                      <div class="second-toggle">
                        <aar-toggle [group]="roDetailsForm" controlName="warranty">
                          <div aar-label>Warranty</div>
                        </aar-toggle>
                      </div>
                    </span>
                    
                  

                    <div class="sm" *ngIf="!showFFPAndWarrantyOnSameLine">
                      <aar-toggle [group]="roDetailsForm" controlName="warranty">
                        <div aar-label>Warranty</div>
                      </aar-toggle>
                    </div>
                  </div>

                  <div class="aar-header-content display-row">
                    <mat-form-field floatLabel="always" class="sm mr">
                      <input matInput placeholder="Aircraft Type" formControlName="airCraftType">
                      <mat-icon matSuffix>error_outline</mat-icon>
                    </mat-form-field>

                    <div class="sm" *ngIf="!showFFPAndWarrantyOnSameLine">
                      <aar-toggle [group]="roDetailsForm" controlName="contracted">
                        <div aar-label>FFP</div>
                      </aar-toggle>
                    </div>
                  -->

                  </div>

                  <div class="aar-header-content display-row">
                    <mat-form-field floatLabel="always" class="sm mr">
                      <input matInput placeholder="Repair Line Status" formControlName="repairLineStatus">
                      <mat-icon matSuffix>error_outline</mat-icon>
                      <mat-error *ngIf="hasError(roDetailsForm, 'repairLineStatus')">{{ getErrorMessage(roDetailsForm,
                        'repairLineStatus') }}</mat-error>
                    </mat-form-field>

                    <mat-form-field floatLabel="always" class="sm">
                      <input matInput placeholder="Status Description" formControlName="statusDescription">
                      <mat-icon matSuffix>error_outline</mat-icon>
                      <mat-error *ngIf="hasError(roDetailsForm, 'statusDescription')">{{ getErrorMessage(roDetailsForm,
                        'statusDescription') }}</mat-error>
                    </mat-form-field>
                  </div>

                  <div class="aar-header-content display-row">
                    <mat-form-field floatLabel="always" class="sm mr">
                      <input matInput placeholder="Logistics Status" formControlName="logisticStatus">
                      <mat-icon matSuffix>error_outline</mat-icon>
                      <mat-error *ngIf="hasError(roDetailsForm, 'logisticStatus')">{{ getErrorMessage(roDetailsForm,
                        'logisticStatus') }}</mat-error>
                    </mat-form-field>

                    <mat-form-field floatLabel="always" class="sm">
                      <input matInput placeholder="Quote Status" formControlName="quoteStatus">
                      <mat-icon matSuffix>error_outline</mat-icon>
                      <mat-error *ngIf="hasError(roDetailsForm, 'quoteStatus')">{{ getErrorMessage(roDetailsForm,
                        'quoteStatus') }}</mat-error>
                    </mat-form-field>
                  </div>

                  <div class="aar-header-content display-row">


                    <!-- Commented under #40361
                    <mat-form-field floatLabel="always" class="sm mr">
                      <mat-select class="font-regular" placeholder="Outbound SN Change Reason"
                        formControlName="outboundSerialNumberChangeReasonId">
                        <mat-option class="font-regular" *ngFor="let mismatchReason of mismatchReasons"
                          [value]="mismatchReason.reason">
                          {{mismatchReason.reasonValue}}
                        </mat-option>
                      </mat-select>
                      <mat-icon matSuffix>error_outline</mat-icon>
                      <mat-error *ngIf="hasError(roDetailsForm, 'outboundSerialNumberChangeReasonId')">{{
                        getErrorMessage(roDetailsForm, 'outboundSerialNumberChangeReasonId') }}</mat-error>
                    </mat-form-field>
                  -->
                  </div>

                  <div class="aar-header-content display-row">
                    <mat-form-field floatLabel="always" class="sm mr">
                      <input readonly="readonly" matInput placeholder="Outbound Unit Category"
                        [formControlName]="obUnitCategoryControlName">
                      <mat-icon matSuffix>error_outline</mat-icon>
                      <mat-error *ngIf="hasError(roDetailsForm, obUnitCategoryControlName)">{{
                        getErrorMessage(roDetailsForm,
                        obUnitCategoryControlName) }}</mat-error>
                    </mat-form-field>
                    <mat-form-field floatLabel="always" class="sm mr">
                      <input readonly="readonly" matInput placeholder="Inbound Unit Category"
                        formControlName="inboundUnitCategory">
                      <mat-icon matSuffix>error_outline</mat-icon>
                    </mat-form-field>

                  </div>

                  <div class="aar-header-content display-row">
                    <mat-form-field floatLabel="always" class="sm mr">
                      <input readonly="readonly" matInput placeholder="Outbound Part Number"
                        [formControlName]="obPartNumberControlName">
                      <mat-icon matSuffix>error_outline</mat-icon>
                      <mat-error *ngIf="hasError(roDetailsForm, obPartNumberControlName)">{{
                        getErrorMessage(roDetailsForm,
                        'description') }}</mat-error>
                    </mat-form-field>
                    <mat-form-field floatLabel="always" class="sm mr">
                      <input matInput placeholder="Inbound Part Number" formControlName="partNumber">
                      <mat-icon matSuffix>error_outline</mat-icon>
                      <mat-error *ngIf="hasError(roDetailsForm, 'partNumber')">{{ getErrorMessage(roDetailsForm,
                        'partNumber') }}</mat-error>
                    </mat-form-field>
                  </div>

                  <div class="aar-header-content display-row">
                    <mat-form-field floatLabel="always" class="sm mr">
                      <input readonly="readonly" matInput placeholder="Outbound Description"
                        [formControlName]="obDescriptionControlName">
                      <mat-icon matSuffix>error_outline</mat-icon>
                      <mat-error *ngIf="hasError(roDetailsForm, obDescriptionControlName)">{{
                        getErrorMessage(roDetailsForm,
                        obDescriptionControlName) }}</mat-error>
                    </mat-form-field>
                    <mat-form-field floatLabel="always" class="sm">
                      <input matInput placeholder="Inbound Description" formControlName="description">
                      <mat-icon matSuffix>error_outline</mat-icon>
                      <mat-error *ngIf="hasError(roDetailsForm, 'description')">{{ getErrorMessage(roDetailsForm,
                        'description') }}</mat-error>
                    </mat-form-field>
                  </div>

                  <div class="aar-header-content display-row">
                    <mat-form-field floatLabel="always" class="sm mr">
                      <input matInput placeholder="Outbound Serial Number" formControlName="outboundSerialNumber"
                        type="text" aria-label="">
                      <mat-icon matSuffix>error_outline</mat-icon>
                      <mat-error *ngIf="hasError(roDetailsForm, 'outboundSerialNumber')">{{
                        getErrorMessage(roDetailsForm, 'outboundSerialNumber') }}</mat-error>
                    </mat-form-field>
                    <mat-form-field floatLabel="always" class="sm mr">
                      <input matInput placeholder="Inbound Serial Number" formControlName="serialNumber">
                      <mat-icon matSuffix>error_outline</mat-icon>
                      <mat-error *ngIf="hasError(roDetailsForm, 'serialNumber')">{{ getErrorMessage(roDetailsForm,
                        'serialNumber') }}</mat-error>
                    </mat-form-field>
                  </div>
                  <div class="aar-header-content display-row">
                    <mat-form-field floatLabel="always" class="sm mr">
                      <input readonly="readonly" matInput placeholder="Outbound Workscope"
                        [formControlName]="obWorkscopeControlName">
                      <mat-icon matSuffix>error_outline</mat-icon>
                      <mat-error *ngIf="hasError(roDetailsForm, obWorkscopeControlName)">{{
                        getErrorMessage(roDetailsForm,
                        'description') }}</mat-error>
                    </mat-form-field>
                    <mat-form-field floatLabel="always" class="sm mr">
                      <mat-select class="font-regular" placeholder="Inbound Workscope" formControlName="workScopeID">
                        <mat-option class="font-regular" *ngFor="let workscope of workscopes" [value]="workscope.id">
                          {{ workscope.formattedName}}
                        </mat-option>
                      </mat-select>
                      <mat-icon matSuffix>error_outline</mat-icon>
                      <mat-error *ngIf="hasError(roDetailsForm, 'workScopeID')">{{ getErrorMessage(roDetailsForm,
                        'workScopeID') }}</mat-error>
                    </mat-form-field>
                  </div>

                  <mat-form-field floatLabel="always" class="sm">
                    <input type="number" min="0" matInput placeholder="Qty" formControlName="quantity">
                    <mat-icon matSuffix>error_outline</mat-icon>
                    <mat-error *ngIf="hasError(roDetailsForm, 'quantity')">{{ getErrorMessage(roDetailsForm,
                      'quantity') }}</mat-error>
                  </mat-form-field>
                </div>
              </mat-tab>

              <mat-tab label="Information">
                <div class="p-20">
                  <div class="aar-header-content display-row">
                    <mat-form-field floatLabel="always" class="sm mr">
                      <textarea matInput placeholder="DEFECT" formControlName="repairOrderDefect" type="textarea"
                        rows="5"></textarea>
                      <mat-icon matSuffix>error_outline</mat-icon>
                      <mat-error *ngIf="hasError(roDetailsForm, 'repairOrderDefect')">{{
                        getErrorMessage(roDetailsForm,
                        'repairOrderDefect') }}</mat-error>
                    </mat-form-field>
                  </div>

                  <div class="aar-header-content display-row">
                    <mat-form-field floatLabel="always" class="sm mr">
                      <input matInput placeholder="TAIL NUMBER" [formControlName]="tailNumberControlName" type="text">
                      <mat-icon matSuffix>error_outline</mat-icon>
                      <mat-error *ngIf="hasError(roDetailsForm, tailNumberControlName)">{{
                        getErrorMessage(roDetailsForm,
                        tailNumberControlName) }}</mat-error>
                    </mat-form-field>

                    <mat-form-field floatLabel="always" class="sm">
                      <input matInput placeholder="PRIORITY" [formControlName]="priorityControlName" type="text">
                      <mat-icon matSuffix>error_outline</mat-icon>
                      <mat-error *ngIf="hasError(roDetailsForm, priorityControlName)">{{
                        getErrorMessage(roDetailsForm,
                        priorityControlName) }}</mat-error>
                    </mat-form-field>

                  </div>

                  <div class="aar-header-content display-row">
                    <mat-form-field floatLabel="always" class="sm mr">
                      <input matInput placeholder="REQUIREMENTS" [formControlName]="requirementsControlName"
                        type="text">
                      <mat-icon matSuffix>error_outline</mat-icon>
                      <mat-error *ngIf="hasError(roDetailsForm, requirementsControlName)">{{
                        getErrorMessage(roDetailsForm,
                        requirementsControlName) }}</mat-error>
                    </mat-form-field>

                    <mat-form-field floatLabel="always" class="sm">
                      <input matInput placeholder="REMOVAL LOCATION" [formControlName]="removalLocationControlName"
                        type="text">
                      <mat-icon matSuffix>error_outline</mat-icon>
                      <mat-error *ngIf="hasError(roDetailsForm, removalLocationControlName)">{{
                        getErrorMessage(roDetailsForm,
                        removalLocationControlName) }}</mat-error>
                    </mat-form-field>

                  </div>

                  <div class="aar-header-content display-row">
                    <div class="sm">
                      <aar-toggle [group]="roDetailsForm" controlName="aOG">
                        <div aar-label>AOG</div>
                      </aar-toggle>
                    </div>
                  </div>

                  <div class="aar-header-content display-row">

                    <!-- 
                    //Commented under #40361
                    <aar-toggle [group]="roDetailsForm" controlName="directShip" class="mr">
                      <div aar-label>Direct Ship</div>
                    </aar-toggle> -->



                    <mat-form-field floatLabel="always" class="sm mr mt">
                      <input matInput placeholder="AOG Aircraft Tail Number"
                        [formControlName]="aogAircraftTailNumberControlName" type="text">
                      <mat-icon matSuffix>error_outline</mat-icon>
                      <mat-error *ngIf="hasError(roDetailsForm, aogAircraftTailNumberControlName)">{{
                        getErrorMessage(roDetailsForm, aogAircraftTailNumberControlName) }}</mat-error>
                    </mat-form-field>
                  </div>

                  <div class="aar-header-content display-row">

                    <mat-form-field floatLabel="always" class="sm mr mt">
                      <input matInput placeholder="AOG Aircraft Tail Serial Number"
                        [formControlName]="aogAircraftSerialNumberControlName" type="text">
                      <mat-icon matSuffix>error_outline</mat-icon>
                      <mat-error *ngIf="hasError(roDetailsForm, aogAircraftSerialNumberControlName)">{{
                        getErrorMessage(roDetailsForm, aogAircraftSerialNumberControlName) }}</mat-error>
                    </mat-form-field>

                  </div>
                </div>
              </mat-tab>

              <mat-tab label="Milestones">
                <div class="p-20">

                  <div class="aar-header-content display-row">
                    <mat-form-field floatLabel="always" class="sm mr">
                      <input matInput placeholder="Supplier Confirmed Receipt Date" [formControlName]="scrdControlName"
                        [matDatepicker]="pickerScrd" [type]='text'>
                      <mat-datepicker-toggle matSuffix [for]="pickerScrd"></mat-datepicker-toggle>
                      <mat-datepicker #pickerScrd></mat-datepicker>
                      <mat-icon matSuffix>error_outline</mat-icon>
                      <mat-error *ngIf="hasError(roDetailsForm, scrdControlName)">{{
                        getErrorMessage(roDetailsForm, scrdControlName) }}</mat-error>
                    </mat-form-field>
                    <mat-form-field floatLabel="always" class="sm">
                      <input matInput placeholder="Original Quote Procesed Date" [formControlName]="oqpdControlName"
                        [matDatepicker]="pickerOqpd" readonly>
                      <mat-datepicker-toggle matSuffix [for]="pickerOqpd"></mat-datepicker-toggle>
                      <mat-datepicker #pickerOqpd></mat-datepicker>
                      <mat-icon matSuffix>error_outline</mat-icon>
                      <mat-error *ngIf="hasError(roDetailsForm, 'closedDate')">{{ getErrorMessage(roDetailsForm,
                        oqpdControlName) }}</mat-error>
                    </mat-form-field>
                  </div>

                  <div class="aar-header-content display-row">
                    <mat-form-field floatLabel="always" class="sm mr" *ngIf="isSupplier">
                      <input matInput placeholder="Supplier Promised Ship Date" formControlName="supplierPromisedShipDt"
                        [matDatepicker]="picker1" [type]="canEdit || isSupplier ? 'text' : 'password'">
                      <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                      <mat-datepicker #picker1></mat-datepicker>
                      <mat-icon matSuffix>error_outline</mat-icon>
                      <mat-error *ngIf="hasError(roDetailsForm, 'supplierPromisedShipDt')">{{
                        getErrorMessage(roDetailsForm, 'supplierPromisedShipDt') }}</mat-error>
                    </mat-form-field>

                    <mat-form-field floatLabel="always" class="sm">
                      <input matInput placeholder="Closed Date" formControlName="closedDate" [matDatepicker]="picker2"
                        readonly>
                      <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                      <mat-datepicker #picker2></mat-datepicker>
                      <mat-icon matSuffix>error_outline</mat-icon>
                      <mat-error *ngIf="hasError(roDetailsForm, 'closedDate')">{{ getErrorMessage(roDetailsForm,
                        'closedDate') }}</mat-error>
                    </mat-form-field>
                  </div>

                  <div class="aar-header-content display-row">
                    <mat-form-field floatLabel="always" class="sm mr">
                      <input matInput placeholder="GROSS TAT" [formControlName]="grossTatControlName" [type]='text'>
                      <mat-icon matSuffix>error_outline</mat-icon>
                      <mat-error *ngIf="hasError(roDetailsForm, grossTatControlName)">{{
                        getErrorMessage(roDetailsForm, grossTatControlName) }}</mat-error>
                    </mat-form-field>

                    <mat-form-field floatLabel="always" class="sm">
                      <input matInput placeholder="NET TAT" [formControlName]="netTatControlName" readonly>
                      <mat-icon matSuffix>error_outline</mat-icon>
                      <mat-error *ngIf="hasError(roDetailsForm, netTatControlName)">{{ getErrorMessage(roDetailsForm,
                        netTatControlName) }}</mat-error>
                    </mat-form-field>
                  </div>
                  <div class="aar-header-content display-row">
                    <mat-form-field floatLabel="always" class="sm mr">
                      <input matInput placeholder="Last Action Date" [formControlName]="lastActionDateControlName"
                        [matDatepicker]="pickerLastActionDate" [type]='text'>
                      <mat-datepicker-toggle matSuffix [for]="pickerLastActionDate"></mat-datepicker-toggle>
                      <mat-datepicker #pickerLastActionDate></mat-datepicker>
                      <mat-icon matSuffix>error_outline</mat-icon>
                      <mat-error *ngIf="hasError(roDetailsForm, lastActionDateControlName)">{{
                        getErrorMessage(roDetailsForm, lastActionDateControlName) }}</mat-error>
                    </mat-form-field>

                    <mat-form-field floatLabel="always" class="sm">
                      <input matInput placeholder="LAST ACTION USER ID" [formControlName]="lastActionUserIdControlName"
                        readonly>
                      <mat-icon matSuffix>error_outline</mat-icon>
                      <mat-error *ngIf="hasError(roDetailsForm, lastActionUserIdControlName)">{{
                        getErrorMessage(roDetailsForm,
                        lastActionUserIdControlName) }}</mat-error>
                    </mat-form-field>
                  </div>
                </div>
              </mat-tab>

              <mat-tab label="Customer" *ngIf="isCustomer">
                <div class="p-20">
                  <div class="aar-header-content display-row">
                    <mat-form-field floatLabel="always" class="sm mr">
                      <input matInput placeholder="Customer Account Name" [formControlName]=customerAccountNameControlName
                        type="text" disabled readonly>
                      <mat-icon matSuffix>error_outline</mat-icon>
                      <mat-error *ngIf="hasError(roDetailsForm, customerAccountNameControlName)">{{
                        getErrorMessage(roDetailsForm, customerAccountNameControlName) }}</mat-error>
                    </mat-form-field>

                    <mat-form-field floatLabel="always" class="sm">
                      <input matInput placeholder="Customer Reference" formControlName="customerReference" type="text"
                        readonly>
                      <mat-icon matSuffix>error_outline</mat-icon>
                      <mat-error *ngIf="hasError(roDetailsForm, 'customerReference')">{{ getErrorMessage(roDetailsForm,
                        'customerReference') }}</mat-error>
                    </mat-form-field>
                  </div>

                  <!-- Commented under #40361
                  <div class="aar-header-content display-row">
                    <mat-form-field floatLabel="always" class="sm mr">
                      <input matInput placeholder="SO Created Date" formControlName="customerROCreatedDt"
                        [matDatepicker]="picker3">
                      <mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
                      <mat-datepicker #picker3></mat-datepicker>
                      <mat-icon matSuffix>error_outline</mat-icon>
                      <mat-error *ngIf="hasError(roDetailsForm, 'customerROCreatedDt')">{{
                        getErrorMessage(roDetailsForm, 'customerROCreatedDt') }}</mat-error>
                    </mat-form-field>

                    
                    <mat-form-field floatLabel="always" class="sm">
                      <input matInput readonly placeholder="Customer RO Received Date" [max]="today"
                        formControlName="customerROReceivedDt" [matDatepicker]="picker4">
                      <mat-datepicker-toggle matSuffix [for]="picker4"></mat-datepicker-toggle>
                      <mat-datepicker #picker4></mat-datepicker>
                      <mat-icon matSuffix>error_outline</mat-icon>
                      <mat-error *ngIf="hasError(roDetailsForm, 'customerROReceivedDt')">{{
                        getErrorMessage(roDetailsForm, 'customerROReceivedDt') }}</mat-error>
                    </mat-form-field>
                  
                  </div>-->

                  <div class="aar-header-content display-row">
                    <!-- Commented under #40361
                    <mat-form-field floatLabel="always" class="sm">
                      <input matInput placeholder="Customer RMA Number" formControlName="customerRMANumber" type="text">
                      <mat-icon matSuffix>error_outline</mat-icon>
                      <mat-error *ngIf="hasError(roDetailsForm, 'customerRMANumber')">{{ getErrorMessage(roDetailsForm,
                        'customerRMANumber') }}</mat-error>
                    </mat-form-field>
                  -->

                  </div>

                  <!-- Commented under #40361
                  <div>
                    <mat-form-field floatLabel="always" class="sm mr">
                      <input matInput placeholder="Quoted To Customer Date"
                        [formControlName]="quotedToCustomerDateControlName" type="text" readonly>
                      <mat-icon matSuffix>error_outline</mat-icon>
                      <mat-error *ngIf="hasError(roDetailsForm, 'quotedToCustomerDate')">{{
                        getErrorMessage(roDetailsForm, 'quotedToCustomerDate') }}</mat-error>
                    </mat-form-field>
                  </div>
                -->


                  <!-- Commented under #40361
                  <div class="aar-header-content display-row">
                    <mat-form-field floatLabel="always" class="sm mr">
                      <div class="currencySymbol">
                        {{getCurrencySymbology('customerPOValueCurrency')}}
                      </div>
                      <input #customerPOVInputElement matInput placeholder="Customer PO Value"
                        [formControlName]="customerPOVControlName" class="currencySymbol" type="text">
                      <mat-icon matSuffix>error_outline</mat-icon>
                      <mat-error *ngIf="hasError(roDetailsForm, 'customerPOValue')">{{ getErrorMessage(roDetailsForm,
                        'customerPOValue') }}</mat-error>
                    </mat-form-field>

                    <mat-form-field floatLabel="always" class="sm">
                      <mat-select placeholder="Customer PO Value Currency" formControlName="customerPOValueCurrency">
                        <mat-option>
                          Choose Currency
                        </mat-option>
                        <mat-option *ngFor="let currency of currencies" [value]="currency.id">
                          {{ currency.name }}
                        </mat-option>
                      </mat-select>
                      <mat-icon matSuffix>error_outline</mat-icon>
                      <mat-error *ngIf="hasError(roDetailsForm, 'customerPOValueCurrency')">{{
                        getErrorMessage(roDetailsForm, 'customerPOValueCurrency') }}</mat-error>
                    </mat-form-field>

                  </div>
                -->

                  <!-- Commented under #40361
                  <div class="aar-header-content display-row">
                    <mat-form-field floatLabel="always" class="sm mr">
                      <div class="currencySymbol">
                        {{getCurrencySymbology('customerResponsibleValueCurrency')}}
                      </div>
                      <input #customerRVInputElement matInput placeholder="Customer Responsible Value"
                        [formControlName]="customerRVControlName" type="text" class="currencySymbol">
                      <mat-icon matSuffix>error_outline</mat-icon>
                      <mat-error *ngIf="hasError(roDetailsForm, 'customerResponsibleValue')">{{
                        getErrorMessage(roDetailsForm, 'customerResponsibleValue') }}</mat-error>
                    </mat-form-field>

                    <mat-form-field floatLabel="always" class="sm">
                      <mat-select placeholder="Customer Responsible Value Currency"
                        formControlName="customerResponsibleValueCurrency">
                        <mat-option>
                          Choose Currency
                        </mat-option>
                        <mat-option *ngFor="let currency of currencies" [value]="currency.id">
                          {{ currency.name }}
                        </mat-option>
                      </mat-select>
                      <mat-icon matSuffix>error_outline</mat-icon>
                      <mat-error *ngIf="hasError(roDetailsForm, 'customerResponsibleValueCurrency')">{{
                        getErrorMessage(roDetailsForm, 'customerResponsibleValueCurrency') }}</mat-error>
                    </mat-form-field>
                  </div>

                -->

                </div>
              </mat-tab>

                <mat-tab label="Documents">
                    <!--<aar-rcm-documents [repairOrder]="roDetails"></aar-rcm-documents>-->
                    <aar-rcm-all-documents [repairOrderDetails]="roDetails">
                    </aar-rcm-all-documents>
                </mat-tab>
  
                <!-- Commented under #40361
                <mat-tab label="Receiving" *ngIf="canEdit">
                  <div class="p-20">
                    <div class="aar-header-content display-row">
                      <aar-toggle [group]="roDetailsForm" controlName="outboundholdTag" class="sm">
                        <div aar-label>Outbound Hold Tag</div>
                      </aar-toggle>
  
                      <aar-toggle [group]="roDetailsForm" controlName="inboundholdTag" class="sm">
                        <div aar-label>Inbound Hold Tag</div>
                      </aar-toggle>
                    </div>
                    <div class="aar-header-content display-row">
                      <mat-form-field floatLabel="always" class="sm mr">
                        <input matInput placeholder="Outbound Hold Tag Created Date" [max]="today"
                          [matDatepicker]="pickerhtcreatedDate" [formControlName]="outboundHoldTagCreateDateControlName"
                          type="text" aria-label="">
                        <mat-datepicker-toggle matSuffix [for]="pickerhtcreatedDate"></mat-datepicker-toggle>
                        <mat-datepicker #pickerhtcreatedDate></mat-datepicker>
                        <mat-icon matSuffix>error_outline</mat-icon>
                        <mat-error *ngIf="hasError(roDetailsForm, outboundHoldTagCreateDateControlName)">{{
                          getErrorMessage(roDetailsForm, outboundHoldTagCreateDateControlName) }}</mat-error>
                      </mat-form-field>
  
                      <mat-form-field floatLabel="always" class="sm mr">
                        <input matInput placeholder="Inbound Hold Tag Created Date" [max]="today"
                          [matDatepicker]="pickerinbundhtcreatedDate"
                          [formControlName]="inboundHoldTagCreateDateControlName" type="text" aria-label="">
                        <mat-datepicker-toggle matSuffix [for]="pickerinbundhtcreatedDate"></mat-datepicker-toggle>
                        <mat-datepicker #pickerinbundhtcreatedDate></mat-datepicker>
                        <mat-icon matSuffix>error_outline</mat-icon>
                        <mat-error *ngIf="hasError(roDetailsForm, inboundHoldTagCreateDateControlName)">{{
                          getErrorMessage(roDetailsForm, inboundHoldTagCreateDateControlName) }}</mat-error>
                      </mat-form-field>
                    </div>
  
                    <div class="aar-header-content display-row">
                      <mat-form-field floatLabel="always" class="sm mr">
                        <input matInput placeholder="Outbound Hold Tag Closed Date" [max]="today"
                          [matDatepicker]="pickeroutbundClosed" [formControlName]="outboundHoldTagClosedDateControlName"
                          type="text" aria-label="">
                        <mat-datepicker-toggle matSuffix [for]="pickeroutbundClosed"></mat-datepicker-toggle>
                        <mat-datepicker #pickeroutbundClosed></mat-datepicker>
                        <mat-icon matSuffix>error_outline</mat-icon>
                        <mat-error *ngIf="hasError(roDetailsForm, outboundHoldTagClosedDateControlName)">{{
                          getErrorMessage(roDetailsForm, outboundHoldTagClosedDateControlName) }}</mat-error>
                      </mat-form-field>
  
                      <mat-form-field floatLabel="always" class="sm mr">
                        <input matInput placeholder="Inbound Hold Tag Closed Date" [max]="today"
                          [matDatepicker]="pickerinbundClosed" [formControlName]="inboundHoldTagClosedDateControlName"
                          type="text" aria-label="">
                        <mat-datepicker-toggle matSuffix [for]="pickerinbundClosed"></mat-datepicker-toggle>
                        <mat-datepicker #pickerinbundClosed></mat-datepicker>
                        <mat-icon matSuffix>error_outline</mat-icon>
                        <mat-error *ngIf="hasError(roDetailsForm, inboundHoldTagClosedDateControlName)">{{
                          getErrorMessage(roDetailsForm, inboundHoldTagClosedDateControlName) }}</mat-error>
                      </mat-form-field>
                    </div>
  
                    <div class="aar-header-content display-row">
                      <mat-form-field floatLabel="always" class="sm mr">
                        <textarea matInput placeholder="Outbound Hold Tag Comments"
                          [formControlName]="outboundHoldTagCommentsControlName" type="textarea"
                          maxlength="1000"></textarea>
                        <mat-icon matSuffix>error_outline</mat-icon>
                        <mat-error *ngIf="hasError(roDetailsForm, outboundHoldTagCommentsControlName)">{{
                          getErrorMessage(roDetailsForm, outboundHoldTagCommentsControlName) }}</mat-error>
                      </mat-form-field>
  
                      <mat-form-field floatLabel="always" class="sm mr">
                        <textarea matInput placeholder="Inbound Hold Tag Comments"
                          [formControlName]="inboundHoldTagCommentsControlName" type="textarea"
                          maxlength="1000"></textarea>
                        <mat-icon matSuffix>error_outline</mat-icon>
                        <mat-error *ngIf="hasError(roDetailsForm, inboundHoldTagCommentsControlName)">{{
                          getErrorMessage(roDetailsForm, inboundHoldTagCommentsControlName) }}</mat-error>
                      </mat-form-field>
                    </div>
  
                    <div class="aar-header-content display-row">
                      <aar-toggle [group]="roDetailsForm" controlName="gRComplete" class="sm" *ngIf="canEdit">
                        <div aar-label>Outbound GR Complete</div>
                      </aar-toggle>
                      <aar-toggle [group]="roDetailsForm" controlName="inboundGRComplete" class="sm">
                        <div aar-label>Inbound GR Complete</div>
                      </aar-toggle>
                    </div>
                    
                    <div class="aar-header-content display-row">
                      <mat-form-field floatLabel="always" class="sm mr">
                        <input matInput placeholder="Outbound GR Complete Date" [max]="today"
                          [matDatepicker]="pickergrcreatedDate" formControlName="outboundGRCompleteDate" type="text"
                          aria-label="">
                        <mat-datepicker-toggle matSuffix [for]="pickergrcreatedDate"></mat-datepicker-toggle>
                        <mat-datepicker #pickergrcreatedDate></mat-datepicker>
                        <mat-icon matSuffix>error_outline</mat-icon>
                        <mat-error *ngIf="hasError(roDetailsForm, 'outboundGRCompleteDate')">{{
                          getErrorMessage(roDetailsForm, 'outboundGRCompleteDate') }}</mat-error>
                      </mat-form-field>
  
                      <mat-form-field floatLabel="always" class="sm mr">
                        <input matInput placeholder="Inbound GR Complete Date" [max]="today"
                          [matDatepicker]="pickerinboundgrcreatedDate" formControlName="inboundGRCompleteDate" type="text"
                          aria-label="">
                        <mat-datepicker-toggle matSuffix [for]="pickerinboundgrcreatedDate"></mat-datepicker-toggle>
                        <mat-datepicker #pickerinboundgrcreatedDate></mat-datepicker>
                        <mat-icon matSuffix>error_outline</mat-icon>
                        <mat-error *ngIf="hasError(roDetailsForm, 'inboundGRCompleteDate')">{{
                          getErrorMessage(roDetailsForm, 'inboundGRCompleteDate') }}</mat-error>
                      </mat-form-field>
                    </div>
  
                  </div>
                </mat-tab>
              -->
  
              </mat-tab-group>
  
              <div class="aar-header-content xs padding divider font-regular" *ngIf="isSupplier">
              </div>
  
            </div>
          </form>
        </div>
  
  
      </div>
  
    </mat-expansion-panel>
  
  </mat-accordion>