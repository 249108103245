import { Component, OnInit, OnDestroy, Output, EventEmitter, Input, NgZone, OnChanges, SimpleChanges, ChangeDetectorRef } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { RcmUploadDocumentComponent } from '../rcm-upload-document/rcm-upload-document.component';
import { RepairOrder } from '@app/shared/services/api/api.models';
import { ApiService } from '@app/shared/services/api/api.service';
import { Subscription } from 'rxjs';
import { SharedService } from '@app/shared/services/shared.service';
import { SafeResourceUrl } from '@angular/platform-browser';
import { AuthService } from '@app/auth/services/auth/auth.service';
import { User } from '@app/auth/auth.models';
import { DocumentEventService } from '@app/shared/services/rcm/event/event-service/document/document-event.service';
import { IMAGE_FILE_EXTENSIONS } from '@app/core/constants/documentConstants';

@Component({
  selector: 'aar-rcm-all-documents',
  templateUrl: './rcm-all-documents.component.html',
  styleUrls: ['./rcm-all-documents.component.scss']
})
export class RcmAllDocumentsComponent implements OnInit, OnChanges, OnDestroy {
  @Input('repairOrderDetails') repairOrderDetails: RepairOrder;

  message: String;
  subscription: Subscription;
  noActivityMessage = 'No Activity Yet';
  curDocument: any;
  curUser: User;
  viewList: boolean;
  documents = [];
  filters = {
    searchQuery: '',
    sortBy: '',
    filterBy: ''
  };
  documentListFilterBy: any[];
  documentListSortBy = [
    { label: 'Name', value: 'name' },
    { label: 'Date', value: 'date' },
    { label: 'Size', value: 'size' },
    { label: 'Type', value: 'type' },
    { label: 'Tag', value: 'tag' },
  ];

  isOpen = false;
  documentPathUrl: SafeResourceUrl;
  selectedDocument: any;

  constructor(
    private ngZone: NgZone,
    private sanitizer: DomSanitizer,
    private dialog: MatDialog,
    private sharedServices: SharedService,
    private authService: AuthService,
    private api: ApiService,
    private documentEventService: DocumentEventService) { }

  ngOnDestroy() {
    // unsubscribe to ensure no memory leaks
    this.subscription.unsubscribe();
  }

  ngOnChanges(change: SimpleChanges) {
    this.curUser = this.authService.getLoggedUser();
    if (change.repairOrderDetails) {
      this.getDocuments();
    }
  }

  ngOnInit() {
    // subscribe to home component messages
    this.subscription = this.sharedServices.getMessage().subscribe(message => {
      this.message = message;
      this.refreshDocuments();
    });

    this.loadFilters();
  }

  getDocuments() {
    if (this.repairOrderDetails) {
      this.api.getDocumentByRoLineId(this.repairOrderDetails.repairOrderLineId, this.curUser.companyID).subscribe(documentByRoId => {
        this.documents = documentByRoId.map((item) => {
          const new_item = {};
          new_item['name'] = item.documentTitle;
          this.documentPathUrl = this.sanitizer.bypassSecurityTrustResourceUrl(item.documentPath);
          new_item['documentPath'] = this.documentPathUrl;
          new_item['document'] = item.thumbNail;
          new_item['documentClassId'] = item.documentClass.documentClassId;
          new_item['type'] = item.documentExtension.extentionName;
          const docExtension = item.documentExtension.extentionName;
          new_item['isImage'] = IMAGE_FILE_EXTENSIONS.includes(docExtension);
          new_item['documentStorageName'] = item.documentStorageName;
          new_item['primary'] = item.primary;
          new_item['uploader'] = item.uploadedBy;
          new_item['createdAt'] = item.uploadDate;
          new_item['size'] = item.fileSize;
          new_item['tag'] = item.documentClass.documentClassName;
          new_item['comments'] = item.comments;
          new_item['documentId'] = item.documentId;
          return new_item;
        });

        this.loadFilters();
        this.reinstateSelected();

        this.documentEventService.documentUpdated.publish(
          {
            repairorderLineId: this.repairOrderDetails.repairOrderLineId,
            hasDocuments: documentByRoId ? documentByRoId.length > 0 : false
          });
      },
        error => {
          //console.log(error);
        });
    }
  }
  refreshDocuments() {
    this.documents = [];
    this.getDocuments();
  }

  reinstateSelected(): void {
    if (this.selectedDocument) {
      let doc = this.documents.find(x => x.documentId === this.selectedDocument.documentId);

      if (doc) {
        doc.selected = true;
      } else {
        this.selectedDocument = null;
      }
    }
  }

  loadFilters(): void {
    this.documentListFilterBy = [];

    this.documents.map(document => {

      let exist = false;
      this.documentListFilterBy.map(documentFilter => {
        if (document.tag === documentFilter.value) {
          exist = true;
        }
      });

      if (!exist) {
        this.documentListFilterBy.push({
          label: document.tag,
          value: document.tag
        });
      }
    });
  }

  documentClick(document): void {

    document.selected = !document.selected;

    if (document.selected) {
      let otherSelectedDocument = this.documents.find(x => x.selected && x.documentId !== document.documentId);
      if (otherSelectedDocument) {
        otherSelectedDocument.selected = false;
      }
    }
    this.selectedDocument = document.selected ? document : undefined;
  }

  toggleViewType(): void {
    this.viewList = !this.viewList;
  }

  clearFilters(): void {
    this.filters = {
      searchQuery: '',
      sortBy: '',
      filterBy: ''
    };
  }

  showUploadDocument() {
    RcmUploadDocumentComponent.openDialog(
      this.dialog, this.repairOrderDetails.repairOrderNumber, this.repairOrderDetails.repairOrderLineId);
  }

  getFileTypeIconPath(documentType: string): string {
    const extensionIcons: Record<string, string> = {
      '.bmp': 'image',
      '.csv': 'excel',
      '.doc': 'word',
      '.docx': 'word',
      '.eml': 'outlook',
      '.gif': 'image',
      '.jfif': 'image',
      '.jpeg': 'image',
      '.jpg': 'image',
      '.msg': 'outlook',
      '.pdf': 'pdf',
      '.png': 'image',
      '.pjpeg': 'image',
      '.svg': 'image',
      '.tiff': 'image',
      '.xls': 'excel',
      '.xlsx': 'excel'
    };

    let icon = extensionIcons[documentType];
    if (icon === undefined) {
      icon = 'unknown';
    }

    return `assets/images/fpo/ro-detail/${icon}128.png`;
  }
}
