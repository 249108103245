<div style="margin-top:20px">
    <div class="aar-display-label-and-value-container">
        <div class="aar-display-label-and-value">
            <span class="aar-display-label">Date Quote Received</span>
            <span class="aar-display-label-value">{{quoteMilestoneData.dateQuoteReceived | dateLocalisedPipe}}</span>
        </div>
        <div class="aar-display-label-and-value">
            <span class="aar-display-label">Date On Quote</span>
            <span class="aar-display-label-value">{{quoteMilestoneData.dateOnQuote | dateLocalisedPipe}}</span>
        </div>
        <div class="aar-display-label-and-value">
            <span class="aar-display-label">Quote Days Elapsed</span>
            <span class="aar-display-label-value">{{quoteMilestoneData.quoteDaysElapsed}}</span>
        </div>
        <div class="aar-display-label-and-value">
            <span class="aar-display-label">Created Date</span>
            <span class="aar-display-label-value">{{quoteMilestoneData.createdDate | dateLocalisedPipe}}</span>
        </div>
        <div class="aar-display-label-and-value">
            <span class="aar-display-label">Processed Date</span>
            <span class="aar-display-label-value">{{quoteMilestoneData.processedDate | dateLocalisedPipe}}</span>
        </div>
    </div>


    <div class="aar-display-label-and-value-container">
        <div class="aar-display-label-and-value">
            <span class="aar-display-label">Sent For Authorisation Date</span>
            <span class="aar-display-label-value">{{quoteMilestoneData.sentForAuthorisationDate | dateLocalisedPipe}}</span>
        </div>
        <div class="aar-display-label-and-value">
            <span class="aar-display-label">Authorised Date</span>
            <span class="aar-display-label-value">{{quoteMilestoneData.authorisedDate | dateLocalisedPipe}}</span>
        </div>
        <div class="aar-display-label-and-value">
            <span class="aar-display-label">Authorised By</span>
            <span class="aar-display-label-value">{{quoteMilestoneData.authorisedBy}}</span>
        </div>
        <div class="aar-display-label-and-value">
            <span class="aar-display-label">Authorised On Supplier Date</span>
            <span class="aar-display-label-value">{{quoteMilestoneData.authorisedOnSupplierDate | dateLocalisedPipe}}</span>
        </div>
        <div class="aar-display-label-and-value">
            <span class="aar-display-label">Rejected Date</span>
            <span class="aar-display-label-value">{{quoteMilestoneData.rejectedDate | dateLocalisedPipe}}</span>
        </div>
    </div>

    <div class="aar-display-label-and-value-container">
        <div class="aar-display-label-and-value">
            <span class="aar-display-label">Rejected On Supplier Date</span>
            <span class="aar-display-label-value">{{quoteMilestoneData.rejectedOnSupplierDate | dateLocalisedPipe}}</span>
        </div>
        <div class="aar-display-label-and-value">
            <span class="aar-display-label">Cancelled Date</span>
            <span class="aar-display-label-value">{{quoteMilestoneData.cancelledDate | dateLocalisedPipe}}</span>
        </div>
        <div class="aar-display-label-and-value">
            <span class="aar-display-label">Cancellation Reason</span>
            <span class="aar-display-label-value">{{quoteMilestoneData.cancellationReason}}</span>
        </div>
        <div class="aar-display-label-and-value">
            <span class="aar-display-label">Superseded Date</span>
            <span class="aar-display-label-value">{{quoteMilestoneData.supersededDate | dateLocalisedPipe}}</span>
        </div>
        <div class="aar-display-label-and-value">
            <span class="aar-display-label">&nbsp;</span>
            <span class="aar-display-label-value">&nbsp;</span>
        </div>
    </div>