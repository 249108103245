import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { QuoteReviewQuoteLine } from '@app/shared/services/api/api.models';
import { DataLookupService } from '@app/shared/services/rcm/data-lookup/data-lookup.service';
import { QuoteReviewEventService } from '@app/shared/services/rcm/event/event-service/quote-review/quote-review-event.service';
import { Subscription } from 'rxjs';

interface GeneralQuote {
  quoteResponsibleUser: string;
  pendingInternalAction: string;
  pendingInternalActiondDate: string;
  quotedPnChangeReason: string;
  quotedSnChangeReason: string;
  supplierRef: string;
  quotedTat: string;
  quotedWarrantyStatus: string;
  quoteFindings: string;
  aircraftName: string;
  tailNumber: string;
}

@Component({
  selector: 'aar-rcm-quote-review-details-general',
  templateUrl: './rcm-quote-review-details-general.component.html',
  styleUrls: ['./rcm-quote-review-details-general.component.scss']
})
export class RcmQuoteReviewDetailsGeneralComponent implements OnInit, OnDestroy, OnChanges {

  @Input('selectedQuoteLine') selectedQuoteLine: QuoteReviewQuoteLine

  quoteLine: QuoteReviewQuoteLine;
  generalQuote: GeneralQuote;
  private externalSubscriptions = new Subscription();

  constructor(
    private dataLookupServce: DataLookupService,
    private quoteReviewEventService: QuoteReviewEventService
  ) { }

  ngOnInit(): void {

    this.externalSubscriptions.add(this.quoteReviewEventService.updatedQuoteLine.subscribe((updatedQuote) => {
      if (this.selectedQuoteLine) {
        this.selectedQuoteLine.quote.pendingInternalActionDateTime = updatedQuote.pendingInternalActionDateTime;
        this.buildGeneralQuote();
      }
    }));
  }

  ngOnDestroy(): void {
    this.externalSubscriptions.unsubscribe();
  }

  ngOnChanges(): void {
    this.buildGeneralQuote();
  }

  private buildGeneralQuote(): void {
    this.quoteLine = this.selectedQuoteLine;
    if (this.selectedQuoteLine) {
      this.generalQuote = {
        quoteResponsibleUser: '',
        pendingInternalAction: this.quoteLine.quote.pendingInternalActionDateTime ? 'Yes' : null,
        pendingInternalActiondDate: this.quoteLine.quote.pendingInternalActionDateTime ? this.quoteLine.quote.pendingInternalActionDateTime.toString() : null,
        quotedPnChangeReason: this.quoteLine.mismatchReasonPnId ?
          this.dataLookupServce.cachedMismatchReasonsForQuoteSerialNumberChanged().find(x => x.reason === this.quoteLine.mismatchReasonPnId)?.reasonValue
          : '',
        quotedSnChangeReason: this.quoteLine.mismatchReasonSnId ?
          this.dataLookupServce.cachedMismatchReasonsForQuoteSerialNumberChanged().find(x => x.reason === this.quoteLine.mismatchReasonSnId)?.reasonValue
          : '',
        supplierRef: this.quoteLine?.quote?.supplierReference,
        quotedTat: this.quoteLine.quotedTat ? this.quoteLine.quotedTat.toFixed(2) : Number(0).toFixed(2),
        quotedWarrantyStatus: this.quoteLine.warrantyStatusName,
        quoteFindings: this.quoteLine.findings,
        aircraftName: this.quoteLine.aircraftName,
        tailNumber: this.quoteLine.tailNumber
      }
    }
  }
}