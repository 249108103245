import { Inject, LOCALE_ID, Pipe, PipeTransform } from "@angular/core";
import { LOCALE_MOMENTDATE_FORMAT, LOCALE_TIME_FORMAT_12, LOCALE_TIME_FORMAT_24} from "@app/core/constants/globalConstants";
import * as moment from "moment-timezone";

@Pipe({ name: 'timezoneFromUtcLocalizedPipe' })
export class TimezoneFromUtcLocalizedPipe implements PipeTransform {

    readonly locale: string;

    constructor(@Inject(LOCALE_ID) localeId) {

        this.locale = localeId.toString();
    }

    transform(value: any | null, outputFormat: '12' | '24' | 'moment' = 'moment'): string | moment.Moment {
 
        let momentObject = value ? moment(value) : moment();

        var zone = moment.tz(moment.tz.guess()).format('Z');
        var zoneValue = zone.replace(/[^0-9: ]/g, '');
        var operator = zone && zone.split('') && zone.split('')[0] === '-' ? '-' : '+';
        var hours = zoneValue.split(':')[0];
        var minutes = zoneValue.split(':')[1];
        if (operator === '-') {
            var localDateTime = momentObject.subtract(hours, 'hours').subtract(minutes, 'minutes');
        } else if (operator) {
            var localDateTime = momentObject.add(hours, 'hours').add(minutes, 'minutes');
        }

        switch(outputFormat){
            case '12':
                return localDateTime.format(`${LOCALE_MOMENTDATE_FORMAT[this.locale]} ${LOCALE_TIME_FORMAT_12[this.locale]}`);
            case '24':
                return localDateTime.format(`${LOCALE_MOMENTDATE_FORMAT[this.locale]} ${LOCALE_TIME_FORMAT_24[this.locale]}`);
            case 'moment':
                return localDateTime;
        }
    }
}