import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import {
  RepairOrderLineInput,
  UpdateQuoteLineInput,
  CreateQuoteLineInput,
  ActivityInputType,
  Currency,
  MismatchReason,
  RepairCategory,
  WorkScope,
} from "@app/shared/services/api/api.models";
import { ApiService } from "@app/shared/services/api/api.service";
import { SharedService } from "@app/shared/services/shared.service";
import { Subscription } from "rxjs";
import { MatSnackBar } from "@angular/material/snack-bar";
import { AuthService } from "@app/auth/services/auth/auth.service";
import { PersonaService } from '@app/shared/services/rcm/persona.service';
import { ComponentType } from '@app/shared/services/rcm/persona.models'
import { DataLookupService } from "@app/shared/services/rcm/data-lookup/data-lookup.service";
//Commented as part of bug #43045
//import { ActivityService } from "@app/shared/services/rcm/activity/activity.service";

@Component({
  selector: "aar-rcm-review-workscope-complete",
  templateUrl: "./rcm-review-workscope-complete.component.html",
  styleUrls: ["./rcm-review-workscope-complete.component.scss"],
})
export class RcmReviewWorkscopeCompleteComponent implements OnInit {
  @Input("quoteLine") quoteLine: UpdateQuoteLineInput;
  @Input("hidePrevious") hidePrevious: boolean;
  @Input("hideSubmit") hideSubmit: boolean;
  @Output("submitQuoteLine") submitQuoteLine = new EventEmitter();
  @Output("previous") previous = new EventEmitter();
  @Output("finish") finish = new EventEmitter();
  @Output("backToQuotes") backToQuotes = new EventEmitter();
  workscope: WorkScope;
  repairCategories: RepairCategory[] = [];
  mismatchReasons: MismatchReason[] = [];
  subscription: Subscription;
  message: string;
  activity: ActivityInputType;
  nonEditableFields: string[] = [];
  personaService: PersonaService;
  fieldsToHide: string[] = [];
  currency: Currency;
  currencyISOName:string = '';
  
  constructor(
    private apiService: ApiService,
    private snackBar: MatSnackBar,
    private sharedService: SharedService,
    private authService: AuthService,
    private datalookupService: DataLookupService
    //Commented as part of bug #43045
    //private activityService: ActivityService
  ) {
    this.subscription = this.sharedService.getMessage().subscribe((message) => {
      this.message = message;
      if (this.message == "SaveStep2") {
        this.apiService.updateQuoteLine(this.quoteLine).subscribe(
          (result) => {
            this.openSnackBar("Quote processing data successfully saved");
            this.sharedService.sendMessage('ShowQuoteProcessingList');
            //Commented as part of bug #43045
            //this.activityService.activityChangePublish(this.quoteLine.roNumber, this.quoteLine.roLineNumber);
          },
          (error) => {
            console.log(error);
          }
        );
      }
    });

    this.personaService = new PersonaService(this.authService.getLoggedUser().personaName);
  }

  openSnackBar(message: string): void {
    this.snackBar.open(message, null, {
      duration: 3000,
    });
  }

  ngOnInit() {
    this.currencyISOName = this.datalookupService.cachedCurrencyForId(this.quoteLine.currency).name;
    this.workscope = this.datalookupService.cachedWorkscopeForId(this.quoteLine.proposedWorkscopeId);
    this.repairCategories = this.datalookupService.cachedRepairCategories;
    this.currency = this.datalookupService.cachedCurrencyForId(this.quoteLine.currency);
    this.mismatchReasons = this.datalookupService.cachedMismatchReasonsForQuoteSerialNumberChanged();
    this.nonEditableFields = this.personaService.getNonEditableFields(ComponentType.RCMReviewWorkscopeComplete);
    this.fieldsToHide = this.personaService.getHiddenFields(ComponentType.RCMReviewWorkscopeComplete);
  }

  //Personas: TODO implement a form here
  // effectRoEdit(quoteLineStatus: string): void {
  //   // enable or disable fields based on roEdit flag
  //   if (quoteLineStatus.toUpperCase() === "CREATED") {
  //     Object.keys(this.quoteForm.controls).forEach(key => {
  //       // skip non editable fields
  //       if (this.nonEditableFields.includes(key)) {
  //         this.quoteForm.get(key).enable();
  //       }
  //     });
  //   } else {
  //     Object.keys(this.quoteForm.controls).forEach(key => {
  //       if (this.nonEditableFields.includes(key)) {
  //         this.quoteForm.get(key).disable();
  //       }
  //     });
  //   }
  // }

  previousSelected(): void {
    this.previous.emit();
  }

  createQuoteLine(): void {
    const user = this.authService.getLoggedUser();

    this.quoteLine.quoteLineStatus = "Created";
    

    
    

    this.submitQuoteLine.emit(this.quoteLine);

    this.finish.emit();
  }

  returnToQuoteList(): void {
    this.finish.emit();
    this.backToQuotes.emit();
  }

  showEntityName(id: number, entities: any[], property = "name"): string {
    return entities && entities.length
      ? entities.find((entity) => entity.id === id)[property]
      : "";
  }

  isFieldHidden(control: string): boolean {
    return this.fieldsToHide.includes(control);
  }


  getMismatchReason():string {
    const { reasonValue = ''} = this.mismatchReasons.find(x => x.reason === this.quoteLine.mismatchReasonSnId) || {}
    return reasonValue
  }
}
