
export interface TATReportFiltersSet {
  suppliers: string[];
  workscopes: string[];
  priorities: string[];
  aircraftTypes: string[];
  partNumbers: string[];
  programs: string[];
  rOAging: string[];
}

//Note - this sequence is how it is in the SS database
//but Custom Service has the sequence starting from 0
export enum QuoteStatusIdType {
  Created = 1,
  Processed = 2,
  SentForAuthorization = 3,
  Authorized = 4,
  AuthorizedOnSupplier = 5,
  Canceled = 6,
}

export enum MismatchReasonType {
  RepairOrder = 0,
  Quote = 1,
}

export enum MismatchReasonCategory {
  ItemNumber = 0,
  SerialNumber = 1,
  Quantity = 2,
}

export enum TagOption {
  Quote = 1,
  Contract = 2,
  Image = 3,
  FAA_8130 = 4,
  Invoice = 5,
  Other = 6
}

export interface RepairOrderLineCriticalStat {
  repairOrderLineId?: number;
  criticalStats: CriticalStats;
}

export interface CriticalStats{
  name: string;
}
export interface RepairOrder {
  returnToAddressCode: any;
  customerRMANumber: any;
  customerROReceivedDt: any;
  /* Commented under #40361
  customerROCreatedDate?: Date;
  */
  repairOrderId?: number;
  buyerCode?: string;
  buyerName?: string;
  repairOrderLineId: number;
  rOLineNumber: string;
  repairOrderNumber: string;
  description: string;

  /* Commented under #40361
  poNumber: string;
  */
  partNumber: string;
  partDescription: string;
  notes: string;
  quantity: number;
  repairOrderDefect: string;
  serialNumber: string;
  warranty: boolean;
  supplierName: string;
  priorityID: number;
  repairOrderPriorityName: string;
  repairOrderStatusID: number;
  repairOrderStatusName: string;
  approvalStatusID: number;
  approvalStatusName: string;
  workScopeID: number;
  workScopeName: string;
  supplierId: number;
  assignedUserID: number;
  daysSinceCreation: number;
  daysSinceStatusUpdated: number;
  roCreatedAt: Date;
  roUpdatedAt: Date;
  priority: Priority;
  repairOrderStatus: Status;
  approvalStatus: Status;
  assignedUser: User;
  supplier: Supplier;
  customerAccountName: string;
  workScope: WorkScope;
  repairOrderLine: RepairOrderLine;
  repairOrderLines: RepairOrderLine[];
  /* Commented under #40361
  pONumber: string;
  */
  /* Commented under #40361
  customerInternalRefNumberDate: Date;
  */
  customerAccountManager?: string;
  directShip?: string;
  cancelationRequired?: string;
  repairLineStatus?: string;
  customerSupplierCode:string;
  programme:string;
  programmeDescription:string;
}

export interface CriticalStatRepairOrder
{
  repairLineStatusDescription:string;
  repairOrderLineId:number;
}

export interface DashboardRepairOrder
{
  daysSinceCreation:number;
  daysSinceStatusUpdated:number;
  repairOrderId:number;
  repairOrderLineId:number;
  repairLineStatusDescription:string;
  repairOrderStatusName:string;
  assignedUserId:number;
}

export interface RcmRepairOrder {
  buyerName : string;
  repairOrderNumber:string;
  repairOrderId:number;
  supplierName : string;
  supplierId:string;
  repairOrderLineId:number;
  programmeDescription:string;
  /* Commented under #40361
  customerRoCreatedDate?: Date;
  */
  repairOrderLine:RcmRepairOrderLine;
}

export interface RcmRepairOrderLine {
  id : number;
  serialNumber:string;
  repairLineStatusDescription:string;
  repairLineStatus:string;
  description:string;
  gTat:number;
  nTat:number;
  roLineNumber:string;
  partNumber:string;
  customerReference:string;
  priority:string;
  workscopeName:string;
  hasDocuments:boolean;
  customerAccountManager:string;
}

export interface CurrentStepQuery{
  currentStep:CurrentStep;
}

export interface CurrentStep{
  logisticStep: number;
  quoteStatusStep: number;
}


export interface RepairOrderLogisticStepName{
  title:string;
  sequence:number;
}

export interface QuoteStatusStepName{
  title:string;
  sequence:number;
}

/*export interface RcmQuote
{
  QuoteId: number;
  repairOrderId: number;
  quote : RmcQuoteDetails;
  repairOrderNumber: string;
  rOLineNumber : string;
  statusId: number;
  supplierId: number;
  dateReceived: Date;
  partNumberReceived: string;
  mismatchReasonPnId: string;
  serialNumberReceived: string;
  mismatchReasonSnId: string;
  qTYRceived: number;
  repairCategoryName: string;
  requestedWorkScopeId: string;
  proposedWorkScopeId: string;
  mismatchReasonPn: string;
  mismatchReasonSn: string;
  proposedWorkscopeId: string;
  ecd: Date;
  quoteOrderLineNum?: string,
  vendName: string;
  unitId: string,
  findings: string;
  attachedQuote: number;
  currency : string;
  currencyId: number;
  totalCost: number;
  laborCost: number;
  nupCurrencyId: number;
  mvCurrencyId: number;
  material? : number;
  miscellaneous? : number;
  rai? : number;
  sos? : number;
  nupCurrency?: string;
  nUP? : number;
  nupPercent? : number;
  mV? : number;
  mvPercent? : number;
  updatedAt: Date;
  QuoteLine? : QuoteLine;
  QuoteLines? : [QuoteLine]
  mvCurrency?: string;
} */

export interface PendingQuote {
  hasPendingQuotes: boolean;
  hasPendingApproval: boolean;
}

export class UpdateQuoteLineInput {
  createdByUserName?: string;
  assignedUserId?: string;
  finalApproverId?: string;
  warrantyStatusId?: number;
  mvCurrency?: string;
  qtyRceived?: number;
  mismatchReasonPnId?: string;
  repairCategoryValue?: string;
  repairCategoryId?: number;
  proposedWorkscopeId?: number;
  mismatchReasonSn?: string;
  mismatchReasonPn?: string;
  currency?: number;
  serialNumberReceived?: string;
  id?: number;
  quoteId?: string;
  quotedTat?: number;
  quoteWarrantyStatus?: string;
  quoteOrderLineNum?: string;
  quoteStatus?: string;
  quoteStatusId?: number;
  updatedAt?: any;
  nff?: string;
  unitId?: string;
  partNumberReceived?: string;
  mismatchReasonSnId?: string;
  roLineNumber?: string;
  roNumber?: string;
  masterOption?: boolean;
  webServiceDescription?: string;
  quoteLineStatus?: string;
  vendName?: string;
  vendAccount?: string;
  datePosted?: any;
  supplierReference?: string;
  dateReceived?: any;
  findings?: string;
  totalCost?: number;
  material?: number;
  laborCost?: number;
  miscellaneous?: number;
  rai?: number;
  sos?: number;
  nupCurrency?: string;
  nUP?: number;
  nupPercent?: number;
  mV?: number;
  mvPercent?: number;
  attachedQuoteID?: number;
  cancelationReason?: string;
  canceledDate?: any;
  authorizedOnSupplierDate?: any;
  customerApprovedDate?: any;
  quotedToCustomerDate?: any;
  // processedBy?: string;
  // quotedToCustomerBy?: string;
  // customerApprovedBy?: string;
  // authorizedOnSupplierBy?: string;
  // cancelledBy?: string;
  processedDate?: any;
  createdDate?: any;
  onlyUpdateStatus?: boolean;
  isCreated?: boolean;
  isProcessed?: boolean;
  isSentForAuthorisation?: boolean;
  isAuthorised?: boolean;
  isAuthorisedOnSupplier?: boolean;
  isRejected?: boolean;
  isSuperseded?: boolean;
  isRejectedOnSupplier?: boolean;
  internalComments?: string;
  unitCost?: number;
  noApprovalRequiredDate?:any;
}

export class CreateQuoteLineInput {
  createdByUserName?: string;
  warrantyStatusId: number;
  assignedUserId?: string;
  finalApproverId?: string;
  mvCurrency?: string;
  qtyRceived?: number;
  mismatchReasonPnId?: string;
  repairCategoryName?: string;
  proposedWorkscopeId?: string;
  mismatchReasonSn?: string;
  mismatchReasonPn?: string;
  currency?: string;
  serialNumberReceived?: string;
  quoteId?: string;
  ecd?: Date;
  quotedTat: number;
  quoteOrderLineNum?: string;
  quoteStatus?: string;
  updatedAt?: Date;
  nff?: string;
  unitId?: string;
  masterOption: boolean;
  partNumberReceived?: string;
  mismatchReasonSnId?: string;
  roLineNumber?: string;
  roNumber?: string;
  webServiceDescription?: string;
  quoteLineStatus?: string;
  vendName?: string;
  vendAccount?: string;
  datePosted?: Date;
  supplierReference?: string;
  dateReceived?: Date;
  findings?: string;
  totalCost?: number;
  material?: number;
  laborCost?: number;
  miscellaneous?: number;
  rai?: number;
  sos?: number;
  nupCurrency?: string;
  nUP?: number;
  nupPercent?: number;
  mV?: number;
  mvPercent?: number;
  cancelationReason: string;
  canceledDate: Date;
  authorizedOnSupplierDate: Date;
  customerApprovedDate: Date;
  quotedToCustomerDate: Date;
  processedDate: Date;
  createdDate: Date;
}

export class QuoteLine {
  id: number;
  quote: RcmQuote;
  createdByUserName: string;
  assignedUserId: string;
  rOLineNumber: string;
  finalApproverId: string;
  mvCurrency: string;
  qTYRceived: number;
  mismatchReasonPnId: string;
  //repairCategoryName: string;
  repairCategory?: RepairCategory;
  proposedWorkscopeId: number;
  mismatchReasonSn: string;
  mismatchReasonPn: string;
  currencyType: Currency;
  serialNumberReceived: string;
  ecd: Date;
  quotedTat: number;
  quotedWarrantyStatus?: string;
  quoteOrderLineNum?: string;
  updatedAt: Date;
  nff: string;
  unitId: string;
  partNumberReceived: string;
  mismatchReasonSnId: string;
  roNumber: string;
  webServiceDescription: string;
  quoteLineStatus: string;
  vendName: string;
  vendAccount: string;
  datePosted: Date;
  supplierReference: string;
  dateReceived: Date;
  findings: string;
  totalCost?: number;
  material?: number;
  laborCost?: number;
  miscellaneous?: number;
  masterOption?: boolean;
  rai?: number;
  sos?: number;
  nupCurrency?: string;
  nUP?: number;
  nupPercent?: number;
  mV?: number;
  mvPercent?: number;
  attachedQuoteID?: number;
  quoteWarrantyStatus: QuoteWarrantyStatus;
  hasWarranty: boolean;
  unitCost: number;
  outboundSerialNumber:string;
}

export interface RcmQuote {
  id: number;
  rCMQuoteId: string;
  rONumber: string;
  rOLineNumber: string;
  dateReceived: Date;
  datePosted: Date;
  supplierReference: string;
  masterOption: boolean;
  quoteStatus?: QuoteStatus;
  canceledDate: Date;
  cancelationReason: string;
  authorizedOnSupplierDate: Date;
  customerApprovedDate: Date;
  quotedToCustomerDate: Date;
  processedDate: Date;
  createdDate: Date;
  quoteStatusName: string;
  internalComments: string;
  canceledBy: string;
  processedBy: string;
  quotedToCustomerBy: string;
  customerApprovedBy: string;
  authorizedOnSupplierBy: string;
  createdByUserName: string;
  noApprovalRequiredDate: Date;
  noApprovalRequiredBy:string;
  multiOption:boolean;

}

export interface QuoteReviewQuote{
  repairOrderLineId:number;
  dateReceived: Date;
  datePosted: Date;
  supplierReference: string;
  masterOption: boolean;
  canceledDate: Date;
  cancelationReason: string;
  authorizedOnSupplierDate: Date;
  customerApprovedDate: Date;
  processedDate: Date;
  createdDate: Date;
  quoteStatusName: string;
  canceledBy: string;
  customerApprovedBy: string;
  authorizedOnSupplierBy: string;
  quotedToCustomerDate:Date;
  pendingInternalActionDateTime:Date;
  multiOption:boolean;
  rejectedDateTime:Date
  rejectedOnSupplierDateTime:Date;
  supersededDateTime:Date;
}

export interface QuoteReviewQuoteLine{
  id: number;
  quote: QuoteReviewQuote;
  qTYRceived: number;
  mismatchReasonPnId: string;
  mismatchReasonSn: string;
  mismatchReasonPn: string;
  serialNumberReceived: string;
  quoteOrderLineNum?: string;
  partNumberReceived: string;
  mismatchReasonSnId: string;
  roNumber: string;
  findings: string;
  totalCost?: number;
  rai: number;
  sos: number;
  quotedTat:number;
  unitCost: number;
  charges: Charges[];
  pricingThresholds:PriceThresholds[];
  rvrThreshold:RepairVsReplaceThresholds;
  customerSuppliedValues:CustomerSuppliedValues[];
  tailNumber:string;
  aircraftName:string;
  warrantyStatusName:string;
  repairCategoryName:string;
  currencyName:string;
  proposedWorkScopeName:string;
  partNumberDescription:string;
}

export interface QuoteStatus {
  name: string;
  id: number;
}


export interface Charges{
  code;string;
  description:string;
  value:number;
  currency:string;
}

export interface CustomerSuppliedValues{
  type:string;
  typeName:string;
  value:number;
  currency:string;
}

export interface RepairVsReplaceThresholds{
  narrative:string;
  overThreshold:boolean;
  ruleReference:string;
  currency:string;
}

export interface PriceThresholds{
  type:string;
  description:string;
  actual:number;
  percentage:number;
  overThreshold:boolean;
}

export interface CriticalStatNames {
  NEW_RO: number;
  UNIT_SHIPPED_OUTBOUND: number;
  SUPPLIER_RECEIPT: number;
  QUOTE_PROCESSING: number;
  QUOTE_APPROVED: number;
  QUOTE_REVIEW: number;
  IN_FINAL_REPAIR: number;
  RECEIPT_INSPECTION: number;
  UNIT_SHIPPED_INBOUND: number;
  RO_CLOSED: number;
  APPROVAL_REQUIRED: number;
  ALL_RO: number;
  REPAIR_IN_PROGRESS: number;
  SUPPLIER_EVALUATION: number;
  PENDING_CUSTOMER_APPROVAL: number;
  PENDING_INTERNAL_AUTHORIZATION: number;
  REQUIRES_S0: number;
  PENDING_CUSTOMER_SHIPMENT: number;
  AOG: number;
  PAST_DUE: number;
  RO_CANCELED: number;
  PENDING_APPROVAL: number;
}

export interface RepairOrderInput {
  id: number;
  repairOrderNumber: string;
  description: string;
  priorityId: number;
  partNumber: string;
  repairOrderStatusId: number;
  approvalStatusId: number;
  notes: string;
  quantity: number;
  workScopeId: number;
  repairOrderDefect: string;
  workRequired: string;
  serialNumber: string;
  warranty: string;
  gtat: number;
  ntat: number;
  signOffDate: Date;
  createdAt: Date;
  updatedAt: Date;
  statusUpdatedAt: Date;
  finalApproverId: number;
  supplierId: number;
  assignedUserId: number;
}

// TODO this is for DEMO only
export interface RepairOrderAx {
  repairOrderNumber: string;
  description: string;
  warranty: string;
  partNumber: string;
  createdAt: Date;
}

export interface RepairOrderAndLine {
  contract: string;
  datePosted: Date;
  gtat: number;
  id: number;
  ntat: number;
  repairOrder: RepairOrder;
  repairOrderDescription: string;
  repairOrderId: number;
  repairOrderNumber: string;
  repairOrderPriorityName: string;
  requestedWorkScope: WorkScope;
  requestedWorkScopeId: number;
  requestedWorkscopeName: string;
  repairOrderStatusName: string;
  rOCombineId: string;
  rOLineNumber: string;
  serialNumber: string;
  status: Status;
  statusId: number;
  statusName: string;
  supplier: Supplier;
  supplierLocation: string;
  supplierName: string;
  totalCost: number;
  updatedAt: Date;
  warranty: string;
}

// TODO Remove this model whe is no longer needed
export interface RepairOrderMocked {
  id: number;
  repairOrderNumber: string;
  description: string;
  priority: string;
  partNumber: string;
  partDescription: string;
  status: string;
  approvalStatus: string;
  notes: string;
  quantity: number;
  requestedWorkScope: string;
  repairOrderDefect: string;
  workRequired: string;
  serialNumber: string;
  warranty: string;
  gtat: number;
  ntat: number;
}

export interface RepairOrderLine {
  inboundGRCompleteDate: any;
  outboundGRCompleteDate: any;
  inboundGRComplete: any;
  quoteStatus: any;
  repairOrderLineId?: number;
  repairOrderId: number;
  statusId: number;
  supplierId: number;
  datePosted: Date;
  contract: string;
  dateReceived: Date;
  inboundUnitCategory;
  outboundSerialNumber: string;
  outboundSerialNumberChangeReason: string;
  customerAccountManager: string;
  partNumberReceived: number;
  mismatchReasonPnId: number;
  serialNumberReceived: string;
  mismatchReasonSnId: number;
  qtyReceived: number;
  repairCategoryId: number;
  proposedWorkScopeId: number;
  aircraftSubTypeName: string;
  eCD: Date;
  quotedTat: number;
  findings: string;
  attachedQuote: number;
  currencyId: number;
  totalCost: number;
  laborCost: number;
  materialsCost: number;
  miscellaneousCost: number;
  rAI: number;
  sOS: number;
  nupCurrencyId: number;
  nUP: number;
  nupPercent: number;
  mvCurrencyId: number;
  mV: number;
  mvPercent: number;
  updatedAt: Date;
  status?: Status;
  repairCategory?: RepairCategory;
  currency?: Currency;
  supplier?: Supplier;
  rOLineNumber?: string;
  customerROCreatedDt: Date;
  customerReference: string;
  closedDate: Date;
  supplierPromisedShipDt: Date;
  directShip: boolean;
  aOG: boolean;
  aogAircraftSerialNumber: string;
  aogAircraftTailNumber: string;
  supplierRMANumber: string;
  gRComplete?: boolean;
  logisticStatus: string;
  customerPOValue: number;
  customerPOValueCurrency: string;
  customerResponsibleValue: number;
  customerResponsibleValueCurrency: string;
  quote: RcmQuote;
  workRequired: string;
  customerROReceivedDt: Date;
  customerRMANumber: string;
  returnToAddressCode: string;
  repairLineStatus: string;
  repairLineStatusDescription: string;
  quoteStatusId: number;
  quoteStatusDescription: string;
  logisticStatusId: number;
  masterQuotedToCustomerDate?: Date;
  workScope: WorkScope;
  holdTags: HoldTag[];
  description:string;
  outboundWorkScope:WorkScope;
  outboundUnitCategory:RepairCategory;
  outboundPartNumber:string;
  outboundPartDescription:string;
  tailNumber:string;
  originalQuoteProcessedDateTime?:Date
  returnToAddress:string;
  gtat:number;
  ntat:number;
  supplierConfirmedReceiptDateTime?:Date;
  priority?:string;
  removalLocation?:string;
}

export interface RepairOrderLineInput {
  repairOrderId: number;
  repairOrderNumber: string;
  roLineNumber: string;
  suppId: string;
  statusId: number;
  supplierId: number;
  datePosted: Date;
  dateReceived: Date;
  partNumberReceived: string;
  mismatchReasonPnId: string;
  serialNumberReceived: string;
  mismatchReasonSnId: string;
  qtyReceived: number;
  repairCategoryId: string;
  requestedWorkScopeId: number;
  proposedWorkScopeId: string;
  ecd: Date;
  quotedTat: number;
  findings: string;
  attachedQuote: number;
  currencyId: number;
  totalCost: number;
  laborCost: number;
  materialsCost: number;
  miscellaneousCost: number;
  rai: number;
  sos: number;
  nupCurrencyId: string;
  nup: number;
  nupPercent: number;
  mvCurrencyId: string;
  mv: number;
  mvPercent: number;
  updatedAt: Date;
  supplierRef?: string;
  currency?: string;
  customerROCreatedDt: Date;
  customerReference: string;
  closedDate: Date;
  supplierPromisedShipDt: Date;
  directShip: boolean;
  cd: boolean;
  supplierRMANumber: string;
  gRComplete: boolean;
  logisticStatus: string;
  customerROReceivedDt: Date;
  customerRMANumber: string;
  returnToAddressCode: string;
  customerPOValue: number;
  customerPOValueCurrency: string;
  customerResponsibleValue: number;
  customerResponsibleValueCurrency: string;
}

export interface RepairOrderActivity {
  id?: number;
  activityDate?: Date;
  description?: string;
  icon?: string;
  rONumber?: string;
  userId: number;
  rOLNumber?: string;
  rOCombineID?: string;
  userFullName?: string;
  activityType?: ActivityType;
  displayDate?:string;
}

export interface ActivityType {
  description: string;
  activityTypeID: number
}

export interface ActivityInputType {
  rONumber: string;
  description: string;
  rOLNumber: string;
  icon: string;
  userId: number;
  activityDate: Date;
}

export interface User {
  id: number;
  userName: string;
  fullName?: string;
  name?: string;
  BuyerCode?: string;
  email: string;
  roleName: string;
  //Personas: Add Persona name to the User
  personaName: string;
}

export interface Role {
  id: number;
  name: string;
}

export interface AirlinNe {
  id: number;
  name: string;
}

export interface Status {
  id: number;
  name: string;
}

export interface ShippingStatus {
  id: number;
  name: string;
  shippingStatusId: string;
}

export interface EndCustomer {
  endCustomerCode: string;
  endCustomerID: number;
  endCustomerName: string;
}
export interface Carrier {
  id: number;
  name: string;
}

export interface Supplier {
  id: number;
  name: string;
  location: string;
  avatar?: string;
  primaryContact?: any;
  mainPhone?: string;
  totalContacts?: number;
}

export interface Priority {
  id: number;
  priorityName: string;
}

export interface WorkScope {
  id: number;
  name: string;
  workScopeId: string;
  formattedName: string;
}

export interface Part {
  id: number;
  partNumber: string;
}

export interface QuoteWarrantyStatus {
  id: number;
  quoteWarrantyStatusID: string;
  reason: string;
}

export interface Currency {
  id: number;
  name: string;
}

export interface MismatchReason {
  id: number;
  reason: string;
  typeId: number;
  categoryId: number;
  reasonValue: string;
  internalOnly:boolean;
}

export interface RepairCategory {
  repairCategoryId: number;
  name: string;
  value: string;
}

export interface Frequency {
  frequencyID: number;
  freqencyName: string;
}

export interface SelectedFrequency {
  SubscriptionID: number;
  FrequencyID: number;
}

export interface AARSubscription {
  frequencyId: number;
  userName: string;
  frequencyName: string;
  subscriptionName: string;
  isSubscribed: boolean;
  triggerId: number;
}

export interface SubscriptionInputType {
  frequencyId: number;
  subscriberId: number;
  triggerId: number;
  companyID: string;
  isEmail: boolean;
  userName: string;
}

export interface Pricing {
  id: number;
  contracted: string;
  cost: number;
  date: Date;
  supplierID: number;
  supplierName: string;
  supplier: Supplier;
}

export interface EmailMetadataInput {
  tagId: number;
  ccEmail: string;
  toEmail: string;
  bccEmail: string;
  fromEmail: string;
  mailboxEmailAddress: string;
  subject: string;
  body: string;
  importance: string;
  sensitivity: string;
  markAsRead: string;
  requireReply: string;
  emailImportLogUnreviewed: string;
  emailImportLogFailed: string;
  isBodyHTML: string;
  serviceOrderId: string;
  priority: string;
  priorityId: string;
  categoryId: string;
  templateId: string;
  customerId: string;
  senderUserId: number;
  rolNumber: string;
  parentEmailId?: string;
  mailboxRecId?: number;
}

export interface EmailMetadataResult {
  bccEmail: string;
  body: string;
  categoryId: string;
  ccEmail: string;
  customerId: string;
  dataAreaId: string;
  direction: string;
  emailId: string;
  fromEmail: string;
  importance: string;
  markAsRead: string;
  priority: string;
  receivedDateTime: Date;
  requireReply: string;
  senderUser: string;
  senderUserId: number;
  sensitivity: string;
  sentDateTime: Date;
  serviceOrderId: string;
  subject: string;
  templateId: string;
  toEmail: string;
  mailboxId?: string;
  parentEmailId?: string;
}

export interface EmailMetadata {
  bccEmail: string;
  body: string;
  categoryId: string;
  ccEmail: string;
  customerId: string;
  direction: string;
  emailImportLogFailed: string;
  emailImportLogUnreviewed: string;
  fromEmail: string;
  importance: string;
  isBodyHTML: string;
  mailboxEmailAddress: string;
  mailboxRecId: number;
  markAsRead: string;
  parentEmailId: string;
  priority: string;
  receivedDateTime: Date;
  requireReply: string;
  sensitivity: string;
  sentDateTime: Date;
  serviceOrderId: string;
  subject: string;
  templateId: string;
  toEmail: string;
}

export interface EmailAttachment {
  companyId: string;
  documentName: string;
  documentTypeID: string;
  emailId: string;
  isDefaultAttachment: string;
  uRL: string;
}

export interface EmailTemplate {
  iD: number;
  templateID: string;
  templateName: string;
}

export interface EmailTags {
  iD: number;
  name: string;
}

export interface EmailCategories {
  iD: number;
  categoryID: string;
  categoryName: string;
}

export interface EmailPriorities {
  iD: number;
  priorityID: string;
  priorityName: string;
}

export interface Email {
  companyName: string;
  attachments?: EmailAttachment[];
  metaData?: EmailMetadata;
  metaDataResults?: EmailMetadataResult;
}

export interface AARNotification {
  notificationID: number;
  subject: string;
  body: string;
  fromEmail: string;
  toEmail: string;
  userName: string;
  companyID: number;
  flag: string;
  createDate: Date;
  type: string;
  isRead: boolean;
  isSent: boolean;
  isEmail: boolean;
}

export interface HoldTag {
  id: number;
  directionId: number;
  comments: string;
  createDate: any;
  closedDate: any;
  sysCreateDate: any;
}

/**
 * Queries
 */

export interface RepairOrderQuery {
  repairOrder: RepairOrder;
}

export interface RepairOrderStepNames{
  logisticStepNames: RepairOrderLogisticStepName[];
  quoteStatusStepNames: QuoteStatusStepName[];
}

export interface RepairOrderStepNamesQuery{
  repairOrderStepNames: RepairOrderStepNames;
}

export interface RepairOrderByLineNumberQuery {
  repairOrderByLineNumber: RepairOrder;
}

export interface RcmRepairOrderQuery{
  rcmRepairOrders:RcmRepairOrder[];
}

export interface DashboardRepairOrderQuery{
  dashboardRepairOrders:DashboardRepairOrder[];
}

export interface CriticalStatsQuery{
  repairOrderLineCriticalStats:RepairOrderLineCriticalStat[];
}

export interface RepairOrdersQuery{
  repairOrders:RepairOrder[];
}

export interface RepairOrdersAxQuery {
  repairOrdersAxDemo: RepairOrderAx[];
}

export interface ByUserQuery {
  repairOrdersByUserId: RepairOrder[];
}

export interface SuppliersQuery {
  suppliers: Supplier[];
}

export interface TatReportFiltersQuery {
  tatReportFilters: TATReportFiltersSet;
}

export interface PrioritiesQuery {
  priorities: Priority[];
}

export interface StatusesQuery {
  repairOrderStatuses: Status[];
}

export interface WorkscopesQuery {
  workscopes: WorkScope[];
}

export interface PartsQuery {
  parts: Part[];
}

export interface QuoteWarrantyQuery {
  quoteWarrantyStatuses: QuoteWarrantyStatus[];
}

export interface RepairCategoriesQuery {
  repairCategoryList: RepairCategory[];
}

export interface CurrencyQuery {
  currencyList: Currency[];
}

export interface MismatchReasonQuery {
  mismatchReasons: MismatchReason[];
}

export interface RepairOrdersFiltersQuery {
  priorities: Priority[];
  repairOrderStatuses: Status[];
  workscopes: WorkScope[];
  suppliers: Supplier[];
}

export interface RepairOrderActivityQuery {
  activitiesByRoAndRoL: RepairOrderActivity[];
}

export interface RepairOrderLineMutation {
  createRepairOrderLine: RepairOrderLine;
}

export interface EmailMetadataInputMutation {
  sendEmail: EmailMetadataResult;
}

export interface EmailTemplatesQuery {
  emailTemplates: EmailTemplate[];
}

export interface EmailTagsQuery {
  emailTags: EmailTags[];
}

export interface EmailCategoriesQuery {
  emailCategories: EmailCategories[];
}

export interface EmailPrioritiesQuery {
  emailPriorities: EmailPriorities[];
}

export interface EmailQuery {
  emailByRO: Email[];
}

export interface RepairOrderPayload {
  repairOrderUpdate: RepairOrderInput;
}

export interface RepairOrderMutation {
  updateRepairOrder: RepairOrderLine; 
}

export interface ChangeRepairOrderLineStatusMutation{
  changeRepairOrderLineStatus:boolean;
}

export interface RepairOrderAndLineQuery {
  repairOrderLineAndRo: RepairOrderAndLine[];
}

export interface ShippingType {
  id: number;
  shippingId?: string;
  rONumber: string;
  rOLineNumber: number;
  carrierId?: number;
  trackingNumber: string;
  trackingLink: string;
  shippingStatus?: string;
  dateShipped: Date;
  dateDelivered: Date;
  directionId: number;
  rOLNumber?: number;
  shippingLogo?: String;
  sequence?: number;
  address: string;
  shippingReference: string;
  isPreventFeedUpdate: boolean;
  directionDescription?: string;
  directShip?:boolean
  /*
  ShippingDirection: String;
  ShippingStatus: String;
  Carrier: String;
*/
}

export interface GetInboundShippingByRoAndRoLQuery {
  inboundShippingByRoAndRoL: ShippingType[];
}

export interface GetOutboundShippingByRoAndRoLQuery {
  outboundShippingByRoAndRoL: ShippingType[];
}

export interface SearchShippingQuery {
  searchShipping: ShippingType[];
}

export interface ShippingInputType {
  id?: number;
  rONumber?: String;
  rOLineNumber?: number;
  carrierId?: number;
  trackingNumber?: String;
  trackingLink?: String;
  shippingStatus?: String;
  shippingReference?: String;
  dateShipped?: Date;
  directionId?: number;
  dateDelivered?: Date;
  address?: string;
}

export interface CreateActivityMutation{
  createActivity:RepairOrderActivity;
}

export interface ShippingMutations{
  createShippingInfo:ShippingType;
  deleteShippingInfo:ShippingType;
  updateShippingInfo:ShippingType;
}

export interface ShippingStatusesQuery {
  shippingStatuses: ShippingStatus[];
}
export interface EndCustomerQuery {
  endCustomer: EndCustomer[];
}
export interface CarrierQuery {
  carriers: Carrier[];
}

export interface IsAuthorizedApprover {
  isAuthorized: Boolean;
  approvalHierarchy: ApprovalHierarchy | null;
}

export interface ApprovalHierarchy {
  id: number;
  userId: number;
  startRange: number;
  endRange: number;
}

export interface IsAuthorizedApproverQuery {
  isAuthorizedApprover: IsAuthorizedApprover;
}
export class CommonContact {
  contactID: number;
  companyID: number;
  firstName: string;
  lastName: string;
  title: string;
  emailAddress: string;
  mainPhoneNumber: string;
  alternatePhoneNumber: string;
  comments: string;
  isPrimaryContact: boolean;
  contactTypeID: number;
  endCustomerID: number;
  supplierID: number;
  name?:string
}
export interface CommonContactsQuery {
  contactsByContactTypeId: CommonContact[];
  deleteContact: boolean;
  createContact: CommonContact;
  updateContact: CommonContact;
}



export class SupplierElement {
  supplierName?: string;
  primaryContect?: string;
  mainPhoneNumber?: number;
  address?: string;
  totalCount?: number;
  id?: number;
  location?: string;
  name?: string;
  contactList?: CommonContact;
}
