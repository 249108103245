import { Component, OnInit, NgModule, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatLegacySliderModule as MatSliderModule } from '@angular/material/legacy-slider';
import { BrowserModule } from '@angular/platform-browser';

@Component({
  selector: 'aar-slider-group',
  templateUrl: './slider-group.component.html',
  styleUrls: ['./slider-group.component.scss']
})
export class SliderGroupComponent implements OnInit {
  @Input('slideData') slideData = [];


  constructor() { }

  ngOnInit() { }
}


@NgModule({
  imports: [ MatSliderModule, BrowserModule, CommonModule ],
  exports: [ SliderGroupComponent ],
  declarations: [ SliderGroupComponent ],
})
export class SliderGroupModule {}
