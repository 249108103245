<div class="quote-review-title">
    <h4>Quote Review</h4>
</div>
<div class="aar-quote-button-container">
    <div class="right-justified-label">
        <div class="inner-button"><button type="button" (click)="onClick(actionButtons.query)" mat-flat-button
                color="primary" [disabled]="!selectedQuoteLine">Query</button></div>
        <div class="inner-button"><button type="button" (click)="onClick(actionButtons.approve)" mat-flat-button
                color="primary" [disabled]="!selectedQuoteLine">Approve</button></div>
        <div><button type="button" (click)="onClick(actionButtons.pendingInternalAction)" mat-flat-button
                color="primary" [disabled]="disablePendingButton">{{piaButtonText}} <mat-spinner
                    *ngIf="pendingInternalActionWorking" diameter="20"></mat-spinner></button></div>
    </div>
</div>
<div>
    <div class="aar-sliding-container">
        <div class="aar-sliding-section">
            <aar-rcm-quote-review-overview [quoteOverviewData]="quoteLines"
                (selectedQuoteLine)="onSelectedQuoteLine($event)"></aar-rcm-quote-review-overview>
        </div>
    </div>
</div>

<div *ngIf="selectedQuoteLine">
    <div class="aar-sliding-container">
        <div class="aar-sliding-section">
            <aar-rcm-quote-review-details [selectedQuoteLine]="selectedQuoteLine"></aar-rcm-quote-review-details>
        </div>
    </div>
</div>