<div style="display: none">
  <aar-rcm-stepper
    [currentSelected]="currentStepSelected"
    [steps]="steps"
    [hasError]="stepError"
    [xs]="stepsXs"
    [clickables]="[0, 1, 2, 3, 4, 5, 6, 7, 8]"
    (changeStep)="setStep($event)"
  ></aar-rcm-stepper>
</div>
<mat-accordion>
  <mat-expansion-panel expanded="true">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <div class="aar-panel-title font-regular">
          <h3>LOGISTICS STATUS</h3>
        </div>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div style="margin-bottom:5px" style="min-height: 70px;">
      <aar-rcm-status-stepper [xs]="stepsXs"
        [type]="RcmStepType.Logistic"></aar-rcm-status-stepper>
    </div>
  </mat-expansion-panel>
</mat-accordion>

<mat-accordion>
  <mat-expansion-panel expanded="true">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <div class="aar-panel-title font-regular">
          <h3>QUOTE STATUS</h3>
        </div>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div style="min-height:70px;">
      <aar-rcm-status-stepper [xs]="stepsXs"
        [type]="RcmStepType.QuoteStatus"></aar-rcm-status-stepper>
    </div>
  </mat-expansion-panel>
</mat-accordion>

<aar-rcm-header
  *ngIf="!loading"
  [roDetails]="repairOrderDetails"
></aar-rcm-header>

<div class="content">
  <div class="aar-sliding-container">
    <div class="aar-sliding-section">
      <aar-rcm-quote-review [quoteLines]="quoteLines"></aar-rcm-quote-review>
      </div>
  </div>
</div>

<div class="content">
  <div class="aar-sliding-container">
    <div class="aar-sliding-section">
    <aar-rcm-shipping
           [repairOrder]="repairOrderDetails"
           [isEmpty]="hideContent(1)"
            [showBoth]="currentStepSelected > 1"
            >
          </aar-rcm-shipping>
          </div>
  </div>
</div>