<div class="title-section" *ngIf="title">
  <h4>{{ title }}</h4>
  <div class="export-excel">
    <aar-export-excel [dataSource]="onExcelClick()" [fileName]="title" [displayedColumns]="excelColumns"
      [buttonTitle]="excelButtonTitle" [columnMap]="excelExport">
    </aar-export-excel>
  </div>
</div>
<div>

  <div [hidden]="!loading">
    <div class="loading-container">
      <mat-progress-spinner color="primary" mode="indeterminate" [value]="50" [diameter]="70" [strokeWidth]="5">
      </mat-progress-spinner>
    </div>
  </div>

  <div [hidden]="loading">
    <form [formGroup]="rcmDataTableForm">
      <div class="filter-control-container" style="min-height: 40px;">
        <button type="button" (click)="onClearAllFilters()" mat-flat-button [hidden]="!filtered">{{getSelected() === 0 ?
          '' : getSelected() > 1 ? 'Clear all filters' : 'Clear
          filter'}}</button>
      </div>

      <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" matSort>

        <!-- Checkbox Column -->
        <ng-container matColumnDef="select">
          <th mat-header-cell *matHeaderCellDef>
            <mat-checkbox (change)="$event ? toggleAllRows() : null" [checked]="selection.hasValue() && isAllSelected()"
              [indeterminate]="selection.hasValue() && !isAllSelected()" [aria-label]="checkboxLabel()">
            </mat-checkbox>
          </th>
          <td mat-cell *matCellDef="let row">
            <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null"
              [checked]="selection.isSelected(row)" [aria-label]="checkboxLabel(row)">
            </mat-checkbox>
          </td>
        </ng-container>

        <!-- Spacer Column (no Checkbox filter) -->
        <ng-contaner matColumnDef="blank">
          <th mat-header-cell *matHeaderCellDef>
            &nbsp;
          </th>
        </ng-contaner>

        <ng-container *ngFor="let controlDetail of controlDetails" matColumnDef="{{controlDetail.name}}"
          style="margin-top:10px">
          <th style="margin-bottom:5px; margin-top:10px; align-content: flex-start;" mat-header-cell *matHeaderCellDef>
            {{controlDetail.filtered ?
            controlDetail.labelName +' (filtered)' : controlDetail.labelName}}

            <div mat-sort-header style="display: inline-block; border-top: 0px !important;margin-right:20px;"></div>

            <div *ngIf="controlDetail.isFilterable">
              <div class="selectors">
                <mat-form-field floatLabel="always" style="padding-top:0px !important; margin-bottom: 20px">
                  <mat-select placeholder="" formControlName="{{controlDetail.name}}"
                    (selectionChange)="onFilterChange($event, controlDetail.name)">
                    <mat-option *ngIf="controlDetail.filtered" [value]="clearFilterOption">Clear filter</mat-option>
                    <mat-option #matOption *ngFor="let buildOption of controlDetail.options" [value]="buildOption">
                      <span style="word-wrap: break-word;">{{buildOption}}</span>
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
            <div *ngIf="!controlDetail.isFilterable" class="non-filterable-column">&nbsp;</div>
          </th>
          <td mat-cell *matCellDef="let element">
            <a class="cell-link" [routerLink]="[linkPath, element.repairOrderNumber]"
              [queryParams]="{rol:element.repairOrderLineId, ro:element.repairOrderNumber, navigateToDocumentTab:controlDetail.name === 'documents' && element[controlDetail.name] === 'Yes' ? true : undefined}"></a>
            <span *ngIf="controlDetail.name === 'documents' && element[controlDetail.name] === 'Yes'">
              <img class="paperclip" [src]="element.paperClipImage">
            </span>
            <div *ngIf="controlDetail.name !== 'documents'" style="word-wrap:break-word;">
              {{element[controlDetail.name] !== noValueText ? element[controlDetail.name] : ''}}</div>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="[]" style="height:20px"></tr>
        <tr mat-header-row *matHeaderRowDef="displayedColumns" style="margin-bottom:20px"></tr>
        <tr style="margin-top:20px;max-width: 100px; word-wrap: break-word" mat-row
          *matRowDef="let row; columns: displayedColumns;" class="highlight-hover"
          [class.highlight]="selection.isSelected(row)">
        </tr>
      </table>
    </form>
    <mat-paginator [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions" showFirstLastButtons>
    </mat-paginator>
  </div>
</div>