<div class="aar-processing-quote-steps" *ngIf="showQuoteProcessing">
  <div class="aar-processing-quote-steps-title">
    <h4>Quote Processing</h4>
  </div>

  <ng-template [ngIf]="step === -1 || step >= steps.length" [ngIfElse]="stepper">
    <aar-data-table [loading]="loadingROL" [data]="tableModel" [columnsSettings]="quoteProcessingDisplayedColumns" [showPagination]="false" [selectable]="false"
      [selectOnClick]="true" [multiSelect] = "false" (selectionChange) = "showItemStatus($event)" (callUpdatedQuoteLine)="callUpdatedQuoteLine($event)">
    </aar-data-table>
    
    <ng-template [ngIf]="step === -1 || step >= steps.length" >
      <div *ngIf ="!loadingROL">
        <div class="aar-buttons-list centered">
          <button mat-flat-button color="primary" class="aar-button" (click)="startQuoteProcessing()" *ngIf="step ===-1 && !hideStartQuoteButton">{{quoteStatusBtnLabel}}</button>
          <button mat-flat-button color="primary" class="aar-button" (click)="editSelectedQuote()" *ngIf="selectedQuoteLine && step ===-1 && !hideEditQuoteButton">{{editQuoteBtnLabel}}</button>
         <!--- <button mat-flat-button color="primary" class="aar-button" (click)="approveSelectedQuote()" *ngIf="canApproveQuote && selectedQuoteLine"  [disabled]="!canApproveQuote  ? 'disabled': null">Approve Selected Quote</button>
          <button mat-flat-button color="primary" class="aar-button" (click)="cancelSelectedQuote()"  *ngIf="selectedQuoteLine">Cancel Selected Quote</button> -->
          <button mat-flat-button color="primary" class="aar-button" (click)="backToQuotes()" *ngIf="step === 3">Back to Quotes</button>

          <!-- <button mat-flat-button color="primary" class="aar-button">Request Supplier EQD</button> -->
        </div>
      </div>
    </ng-template>

    <!-- <ng-template #lastStep>
      <div class="aar-buttons-list">
        <button *ngIf="show" mat-flat-button color="primary" class="aar-button">Query Supplier</button>
        <button mat-flat-button color="primary" class="aar-button" (click)="approveQuoteLines()" [disabled]="!canApproveQuote  ? 'disabled': null" *ngIf="step < 4">Approve</button>
        <button mat-flat-button color="primary" class="aar-button" (click)="newQuoteLine()">Add New Quote</button>
        <button *ngIf="show" mat-flat-button color="primary" class="aar-button">View Details</button>
      </div>
    </ng-template> -->

  </ng-template>

  <div *ngIf="step === -1 || step >= steps.length">
    <div class="m-spacing" *ngIf="selectedQuoteLineForStatus">
      <aar-rcm-review-workscope-status #quoteDateStatus (updateQuoteLine)="updateQuoteLineDates($event)" [preventApprovalState]="preventApprovalState"></aar-rcm-review-workscope-status>
    </div>
    <div class="m-spacing" *ngIf="selectedQuoteLines && selectedQuoteLines.length != 1">
      Select only one item to view status
    </div>
  </div>

  <ng-template #stepper>
    <div class="aar-processing-quote-steps-header">
      <aar-rcm-stepper [currentSelected]="step" [steps]="steps" [xs]="true"></aar-rcm-stepper>
      <div class="aar-processing-quote-steps-save">
        <div class="aar-processing-quote-steps-save-time">
          <label [ngClass]="{'saveSucceed': saveState == 1, 'saveNuteral':  saveState == 0, 'saveFailed':  saveState == 2}">{{autoSaveStatus}}</label>
          <label [ngClass]="{'saveSucceed': saveState == 1, 'saveNuteral':  saveState == 0, 'saveFailed':  saveState == 2}">{{autoSaveTime}}</label>
        </div>
        <button mat-flat-button (click)="saveAndClose($event)" color="primary" class="aar-button" *ngIf = "quoteLine.quoteStatus === 'Created'">Save & Close</button>
      </div>
    </div>

    <ng-template [ngIf]="step === 0">
      <aar-rcm-review-workscope-comparison (stepForward)="nextStep()" (updateQuoteLine)="updateQuoteLine($event)" [repairOrder]="repairOrder" [quoteLine]="quoteLine"></aar-rcm-review-workscope-comparison>
    </ng-template>

    <ng-template [ngIf]="step === 1">
      <aar-rcm-review-workscope-cost (stepForward)="nextStep()" (previous)="prevStep()" (updateQuoteLine)="updateQuoteLine($event)" [quoteLine]="quoteLine"></aar-rcm-review-workscope-cost>
    </ng-template>

    <ng-template [ngIf]="step === 2">
      <aar-rcm-review-workscope-complete (finish)="nextStep(4)" (backToQuotes)="backToQuotes()" (previous)="prevStep()" (submitQuoteLine)="submitQuoteLine($event)" [quoteLine]="quoteLine" [hidePrevious]="hidePrevious" [hideSubmit]="hideSubmit"></aar-rcm-review-workscope-complete>
    </ng-template>
  </ng-template>

 
</div>
