import { Component, Input, OnChanges } from '@angular/core';
import { QuoteReviewQuoteLine } from '@app/shared/services/api/api.models';

interface PriceThreshold {
  type: string;
  percentage: number;
  actual: string;
  overThreshold: string;
}

@Component({
  selector: 'aar-rcm-quote-value-details-threshold',
  templateUrl: './rcm-quote-value-details-threshold.component.html',
  styleUrls: ['./rcm-quote-value-details-threshold.component.scss']
})
export class RcmQuoteValueDetailsThresholdComponent implements OnChanges {
  @Input('selectedQuoteLine') selectedQuoteLine: QuoteReviewQuoteLine;

  priceThreshold: PriceThreshold[] = []

  columnsToDisplay = ['type', 'percentage', 'actual', 'overThreshold'];
  isLoading = false;
  displayColumns = [
    { name: 'type', header: 'Threshold Type' },
    { name: 'percentage', header: 'Threshold Percentage' },
    { name: 'actual', header: 'Actual' },
    { name: 'overThreshold', header: 'Over Threshold?' }
  ];

  ngOnChanges(): void {
    if (this.selectedQuoteLine?.pricingThresholds) {
      this.priceThreshold = this.selectedQuoteLine?.pricingThresholds.map((pt) => {
        return {
          type: pt.type,
          percentage: pt.percentage,
          actual: pt.actual ? pt.actual.toFixed(2) : '',
          overThreshold: pt.overThreshold ? 'Yes' : 'No'
        }
      })
    }
  }
}