import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Frequency, AARSubscription, SubscriptionInputType, SelectedFrequency } from '@app/shared/services/api/api.models';
import { ApiService } from '@app/shared/services/api/api.service';
import { AuthService } from '@app/auth/services/auth/auth.service';
import { Subscription } from 'apollo-client/util/Observable';
import { FormsModule } from '@angular/forms';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { User } from '@app/auth/auth.models';
import { DialogOverviewExampleDialog } from '@app/main/main.component';

@Component({
  selector: 'aar-my-account',
  templateUrl: './my-account.component.html',
  styleUrls: ['./my-account.component.scss']
})
export class MyAccountComponent implements OnInit {
  frequencies: Frequency[];
  /* Commented under 40896
  subscriptions: AARSubscription[];
  */
  timeZones: any[] = [{ label: '', value: 2 }];
  selectedTabsIndex = 0;
  currentUser: User;
  firstname: string;
  lastname: string;
  curSubscription: SubscriptionInputType;
  private selectedFrequencies: SelectedFrequency[] = [];
  private newSelection: SelectedFrequency = { SubscriptionID: 0, FrequencyID: 0 };
  showPassSection: boolean = false;

  constructor(public route: ActivatedRoute, public dialog: MatDialog, public authService: AuthService, private snackBar: MatSnackBar, public apiService: ApiService) { }

  ngOnInit() {
    this.selectedFrequencies.push(this.newSelection);
    this.getFrequencies();
    /* Commented under 40896
    this.getSubscriptions();
    */
    this.currentUser = this.authService.getLoggedUser();
    var separator = this.currentUser.fullName.indexOf(' ');
    this.firstname = this.currentUser.fullName.substring(0, 1);
    this.lastname = this.currentUser.fullName.substring(separator, separator + 2);
  }
  openSnackBar(message: string): void {
    this.snackBar.open(message, null, {
      duration: 3000
    });
  }

  /* Commented under 40896
  changeSubscription(subscription: AARSubscription) {
    this.curSubscription = { frequencyId: subscription.frequencyId, userName: subscription.userName, subscriberId: 0, companyID: 'AAR', triggerId: subscription.triggerId, isEmail: true };

    if (subscription.isSubscribed) {
      this.apiService.unSubscribe(this.curSubscription).subscribe(result => {
        this.openSnackBar(subscription.subscriptionName + ' notification successfully unsubscribed');
        this.getSubscriptions();
      });
    } else {
      const selected = this.selectedFrequency(subscription.triggerId);
      if (selected !== null) {
        this.curSubscription.frequencyId = selected.FrequencyID;
      } else {
        this.openSnackBar('Please select a frequency first');
        this.getSubscriptions();
        return;
      }

      this.apiService.subscribe(this.curSubscription).subscribe(result => {
        this.openSnackBar(subscription.subscriptionName + ' notification Successfully subscribed');
        this.getSubscriptions();
      });
    }
  }
*/

  showChangePassModal() {

    this.dialog.open(DialogOverviewExampleDialog, { width: '300px', data: { type: 'change' } })
      .afterClosed()
      .subscribe(result => {
        if (!result) { return; }

        this.authService.changePassword({
          ...result,
          userName: this.currentUser.userName
        })
          .subscribe(
            this.showSuccessModal.bind(this),
            this.showErrorModal.bind(this)
          );
      });
  }

  showSuccessModal() {
    this.dialog.open(DialogOverviewExampleDialog, { width: '300px', data: { type: 'login' } })
      .afterClosed()
      .subscribe(
        res => {
          if (res) { }
        }
      );
  }

  showErrorModal() {
    this.dialog.open(DialogOverviewExampleDialog, { width: '300px', data: { type: 'error' } })
      .afterClosed()
      .subscribe(this.showChangePassModal.bind(this));
  }

  selectedFrequency(subsription: number): SelectedFrequency {

    for (let i = 0; i < this.selectedFrequencies.length; i++) {
      if (subsription == this.selectedFrequencies[i].SubscriptionID) {

        return this.selectedFrequencies[i];
      }

      return null;
    }
  }
  changeFrequency(payload) {
    const selected = this.selectedFrequency(payload.source.id);
    if (selected !== null) {
      for (let i = 0; i < this.selectedFrequencies.length; i++) {
        if (this.selectedFrequencies[i].SubscriptionID === selected.SubscriptionID) {
          this.selectedFrequencies.splice(i, 1);
          i--;
        }
      }

    }
    this.newSelection.SubscriptionID = payload.source.id;
    this.newSelection.FrequencyID = payload.value;
    this.selectedFrequencies.push(this.newSelection);
  }
  getFrequencies() {
    this.apiService.getNotificationFreqencies().subscribe(result => {
      this.frequencies = result;

    });
  }


  /* Commented under 40896
  getSubscriptions() {
    this.apiService.getUserSubscriptions().subscribe(result => {
      this.subscriptions = result;

    });
  }
    */

}