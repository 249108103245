import { Component, OnInit } from '@angular/core';
import { LoadingService } from '@app/shared/services/loading/loading.service';
import { ApiService } from '@app/shared/services/api/api.service';
import { RepairOrder } from '@app/shared/services/api/api.models';
import { User } from '@app/auth/auth.models';

import { AuthService } from '@app/auth/services/auth/auth.service';

@Component({
  selector: 'aar-supplier-wip',
  templateUrl: './supplier-wip.component.html',
  styleUrls: ['./supplier-wip.component.scss']
})
export class SupplierWipComponent implements OnInit {
  selectedRO: RepairOrder;
  repairOrders: RepairOrder[];
  repairOrdersUnderEvaluation: RepairOrder[];
  repairOrdersWIP: RepairOrder[];
  tableFilterSettings: any[];
  loadingTable: boolean;

  stats = {
    open: 0,
    urgent: 0,
    evaluation: 0,
    pending: 0,
    queried: 0,
    final: 0,
    tatEvaluation: 0,
    tatFinal: 0
  };

  columnsSettings = [
    { name: 'supplierRONumber', header: '' },
    { name: 'supplierProvider', header: '' },
    { name: 'workScopeName', header: '' },
    { name: 'priorityName', header: '' },
    { name: 'repairOrderStatusName', header: '' },
    { name: 'supplierTAT', header: '' }
  ];
  private currentUser: User;
  constructor(
    private authService: AuthService,
    private apiService: ApiService,
    private loadingService: LoadingService) { }

  getCurrentUser(): void {

    if (this.authService.isUserLoggedIn()) {
      this.currentUser = this.authService.getLoggedUser();

      if (this.currentUser) {

      }
    } else {

    }

    this.authService.user$.subscribe((user: User) => {
      this.currentUser = user;

    });
  }

  ngOnInit() {
    this.getCurrentUser();
    this.loadingService.startLoading();
    this.loadingTable = true;
    this.getRepairOrders();
    this.getRepairOrdersFilters();
  }

  getRepairOrders(): void {

    this.apiService.getRepairOrders(this.currentUser.roleName, this.currentUser.id)
      .subscribe(repairOrders => {
        this.repairOrders = repairOrders;
        //this.filterROBySupplierId(this.currentUser.id);
      
        this.repairOrders = this.sortROByTAT();
       
        this.processRepairOrders();
        this.getROUnderEvaluation();
        this.getROWip();
       
        this.loadingService.stopLoading();
        this.loadingTable = false;
      }, err => this.loadingService.loadingError());
  }

  getRepairOrdersFilters(): void {
    this.apiService.getRepairOrdersFilters().subscribe(filterData => {
      this.tableFilterSettings = [
        {
          name: 'priorityName',
          type: 'dropdown',
          placeholder: 'Priority',
          options: this.convertFilters(filterData.priorities)
        },
        {
          name: 'customerName',
          type: 'dropdown',
          placeholder: 'Customer',
          options: this.convertFilters([])
        },
        {
          name: 'repairOrderStatusName',
          type: 'dropdown',
          placeholder: 'Status',
          options: this.convertFilters(filterData.repairOrderStatuses)
        },
      ];
    });
  }

  convertFilters(filters): any[] {
    const regex = /_/g;
    return filters.map(filter => {
      return {
        label: filter.name.replace(regex, ' '),
        value: filter.name
      };
    });
  }

  selectRO(RO: RepairOrder): void {
    this.selectedRO = RO;
  }

  // TODO This is hardcoded for now but in the future should use the id from the current supplier or use a query
  filterROBySupplierId(supplierId = 5): void {
    this.repairOrders = this.repairOrders.filter(ro => ro.supplierId === supplierId);
   
  }

  sortROByTAT(): RepairOrder[] {
    return this.repairOrders.sort((a: RepairOrder, b: RepairOrder) => {
      const atat = (a.repairOrderLine.ntat + a.repairOrderLine.gtat) / 2;
      const btat = (b.repairOrderLine.ntat + b.repairOrderLine.gtat) / 2;
      if (atat > btat) {
        return -1;
      }
      if (atat < btat) {
        return 1;
      }
      
      return 0;
    });
  }

  processRepairOrders(): void {
    const tats = {
      tatEvaluation: [],
      tatFinal: []
    };
    this.repairOrders.map(ro => {
     
      // Total Open ROs
      if (ro.repairOrderStatusName !== 'RO_CLOSED') {
        this.stats.open++;
      }

      // Urgent ROs
      if (['AOG', 'EXPEDITE'].indexOf(ro.repairOrderPriorityName) > -1) {
        this.stats.urgent++;
      }

      // Under Evaluation
      if ('QUOTE_PROCESSING' === ro.repairOrderStatusName) {
        this.stats.evaluation++;
      }

      // Pending Approval
      if ('QUOTE_REVIEW' === ro.repairOrderStatusName) {
        this.stats.pending++;
      }

      // Customer Queried
      // TODO Since we don't have how to calculate this we just used 'QUOTE_REVIEW' only
      /**
       * We dont have how to know this
       * ROs that have a new message & the status is Awaiting Approval.
       */
      // if ('QUOTE_REVIEW' === ro.repairOrderStatusName) {
      //   this.stats.queried++;
      // }

      // In Final Repair
      if ('IN_FINAL_REPAIR' === ro.repairOrderStatusName) {
        this.stats.final++;
      }

      // Days Average TAT In Evaluation
      // TODO Since we don't have how to calculate this we just used 'daysSinceCreation'
      /**
       * We dont have how to know this
       * The average days of all ROs from the day they arrive to be quoted to the day they are quoted.
       */
      tats.tatEvaluation.push(ro.daysSinceCreation);

      // Days Average TAT In Final Repair
      // TODO Since we don't have how to calculate this we just used 'daysSinceStatusUpdated'
      /**
       * We dont have how to know this
       * The average days of all ROs from the day they are approved to the day they are shipped back to the Customer/Carrier.
       */
      tats.tatFinal.push(ro.daysSinceStatusUpdated);
    });

    this.stats.tatEvaluation = this.calcAverage(tats.tatEvaluation);
    this.stats.tatFinal = this.calcAverage(tats.tatFinal);
  }

  calcAverage(values: number[]): number {
    const sum = values.reduce((previous, current) => current += previous);
    return Math.round(sum / values.length);
  }

  getROUnderEvaluation(): void {
    this.repairOrdersUnderEvaluation = this.repairOrders.filter(ro => {
      return ro.repairOrderStatusName === 'QUOTE_REVIEW';
    });
  }

  getROWip(): void {
    this.repairOrdersWIP = this.repairOrders.filter(ro => {
      return ro.repairOrderStatusName === 'NEW_RO' ||
        ro.repairOrderStatusName === 'QUOTE_REVIEW' ||
        ro.repairOrderStatusName === 'UNIT_SHIPPED_OUTBOUND';
    });
  }
}
