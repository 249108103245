import { Component, NgModule, OnInit, Inject } from '@angular/core';
import { ViewChild, ElementRef } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ApiService } from '@app/shared/services/api/api.service';
import { SharedService } from '@app/shared/services/shared.service';
import { Router } from '@angular/router';
import { AuthService } from '@app/auth/services/auth/auth.service';
import { LoadingService } from '@app/shared/services/loading/loading.service';
import { User } from '@app/auth/auth.models';
import { UntypedFormControl, Validators } from '@angular/forms';
import { DataLookupService } from '@app/shared/services/rcm/data-lookup/data-lookup.service';

@Component({
  selector: 'aar-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit {
  loading: boolean;
  loadingError: boolean;
  title = 'Airvolution';
  titleSub1 = 'Air';
  titleSub2 = 'volution';
  sidebarCollapsed = false;
  user: User;

  private notifications: number;
  @ViewChild('pageContainer') pageContainerRef: ElementRef;

  constructor(
    private authService: AuthService,
    private sharedService: SharedService,
    public router: Router,
    private apiService: ApiService,
    private loadingService: LoadingService,
    public dialog: MatDialog,
    private datalookupService: DataLookupService) {
  }

  ngOnInit() {  

    this.loadingService.loading$
      // Do not remove the 0 for the timeout, it will throw an angular error
      .subscribe(loading => {
        setTimeout(() => this.loading = loading, 0)
      });

    this.loadingService.error$
      // Do not remove the 0 for the timeout, it will throw an angular error
      .subscribe(error => setTimeout(() => this.loadingError = error, 0));

    this.loadUser();
    this.getNotificationCount();

  }


  getNotificationCount() {

    this.apiService.getUnreadNotifications().subscribe(result => {
      this.notifications = result;

    });
  }

  upDateNotifications(): void {

    // send message to subscribers via observable subject
    this.apiService.getUnreadNotifications().subscribe(result => {

      if (this.notifications != result) {
        this.notifications = result;


      }
    });

  }

  toggleSidebar() {
    this.sidebarCollapsed = !this.sidebarCollapsed;
    this.titleSub1 = this.sidebarCollapsed ? 'A' : 'Air';
    this.titleSub2 = this.sidebarCollapsed ? '' : 'volution';
  }

  loadUser(): void {

    this.user = this.authService.getUserInfo();

        if (this.user) {
      // TODO: This will be uncommented once hardcoded users are removed
      this.authService.getUserById(this.user.id)
        .subscribe((u: User) => {
          this.authService.setUser(this.user);
        },
          error => {
            alert('invalid user');
          });
    }

    if (this.user && this.user.hasTemporaryPassword) {
      this.showChagePassModal();
    }

  }

  showChagePassModal() {

    this.dialog.open(DialogOverviewExampleDialog, { width: '300px', data: {} })
      .afterClosed()
      .subscribe(result => {
        if (!result) { return; }

        this.authService.changePassword({
          ...result,
          userName: this.user.userName
        })
          .subscribe(
            this.showSuccessModal.bind(this),
            this.showErrorModal.bind(this)
          );
      });
  }

  showSuccessModal() {
    this.dialog.open(DialogOverviewExampleDialog, { width: '300px', data: { type: 'login' } })
      .afterClosed()
      .subscribe(
        res => {
          if (res) { this.logout(); }
        }
      );
  }

  showErrorModal() {
    this.dialog.open(DialogOverviewExampleDialog, { width: '300px', data: { type: 'error' } })
      .afterClosed()
      .subscribe(this.showChagePassModal.bind(this));
  }

  logout(): void {
    console.log(`User ${this.user.userName} has logged out.`);
    this.authService.processLogout();
  }

  //TODO: Move "canShow" methods to configuration service
  canShowAnalyticsAndBI(){
    return this.authService.isUserInCompanyWithModule('AnalyticsAndBI') && this.user.personaName.toUpperCase() !== 'WAREHOUSE';
  }

  canShowDashboard(){
    return this.user.personaName.toUpperCase() !== 'WAREHOUSE'
  }

  canShowCustomerContacts(){
    return this.user.personaName.toUpperCase() == 'CAM' || this.user.personaName.toUpperCase() == 'PROGRAMMANAGER'
  }

  canShowSupplierContacts(){
    return this.user.personaName.toUpperCase() == 'BUYER'
  }
}

@Component({
  selector: 'new-pass',
  templateUrl: 'new-pass.component.html',
  styleUrls: ['new-pass.component.scss']
})
export class DialogOverviewExampleDialog {

  currentPassword = new UntypedFormControl('', [Validators.required]);
  newPassword = new UntypedFormControl('', [Validators.required]);
  repeatPassword = new UntypedFormControl('', [Validators.required]);
  notEqual = false;

  constructor(
    public dialogRef: MatDialogRef<DialogOverviewExampleDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  cancel(): void {
    this.dialogRef.close();
  }

  get invalid() {
    this.notEqual = this.newPassword.value !== this.repeatPassword.value;

    return (this.currentPassword.invalid && this.currentPassword.touched)
      || (this.newPassword.invalid && this.newPassword.touched)
      || this.notEqual;
  }

  apply(): void {

    if (this.data.type === 'login') {
      this.dialogRef.close(true);
      return;
    }

    if (this.invalid) { return; }

    this.dialogRef.close({
      currentPassword: this.currentPassword.value,
      newPassword: this.newPassword.value
    });
  }

}
