import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { FormsModule } from '@angular/forms';
import { AppMaterialModule } from '@app/app-material.module';
import { DashCardModule, SliderCardModule, ChartCardModule } from '@app/shared/components';
import { TatReportComponent } from '@app/analytics-bi/tat-report.component';
import { AarDomoReportComponent } from './aar-domo-report/aar-domo-report.component';
import { StatusReportComponent } from '@app/analytics-bi/status-report.component';
import { SharedModule } from '@app/shared.module';

@NgModule({
    imports: [
        AppMaterialModule,
        CommonModule,
        MatInputModule,
        FormsModule,
        DashCardModule,
        SliderCardModule,
        ChartCardModule,
        SharedModule
    ],
    providers: [],
    declarations: [
        TatReportComponent,
        AarDomoReportComponent,
        StatusReportComponent
    ]
})

export class AnalyticsBIModule { }
