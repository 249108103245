import { NgModule } from '@angular/core';
import { AppMaterialModule } from '@app/app-material.module';
import { CommonModule } from '@angular/common';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { FormsModule } from '@angular/forms';
import { AboutComponent } from './about.component';

@NgModule({
  imports: [
    AppMaterialModule,
    CommonModule,
    MatInputModule,
    FormsModule,
  ],
  providers: [],
  declarations: [
    AboutComponent
  ]
})
export class AboutModule { }
