import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as Fragments from '@app/common/fragments';
import {
  AARNotification, AARSubscription, ActivityInputType, ByUserQuery, Carrier, CarrierQuery, ChangeRepairOrderLineStatusMutation, CommonContact, CommonContactsQuery, CreateActivityMutation, CreateQuoteLineInput, CriticalStatsQuery, Currency,
  CurrencyQuery, DashboardRepairOrder, DashboardRepairOrderQuery, Email, EmailCategories,
  EmailCategoriesQuery, EmailMetadataInput, EmailMetadataInputMutation, EmailMetadataResult, EmailPriorities,
  EmailPrioritiesQuery, EmailQuery, EmailTags,
  EmailTagsQuery, EmailTemplate,
  EmailTemplatesQuery, EndCustomerQuery, Frequency, GetInboundShippingByRoAndRoLQuery,
  GetOutboundShippingByRoAndRoLQuery,HoldTag,IsAuthorizedApproverQuery, MismatchReason,
  MismatchReasonQuery, Part,
  PartsQuery, PendingQuote, PrioritiesQuery, Priority, QuoteLine, QuoteWarrantyQuery, QuoteWarrantyStatus, RcmQuote, RcmRepairOrder, RcmRepairOrderQuery, RepairCategoriesQuery, RepairCategory, RepairOrder, RepairOrderActivity,
  RepairOrderActivityQuery, RepairOrderAndLine,
  RepairOrderAndLineQuery,
  // TODO this interface is user for DEMO only
  RepairOrderAx, RepairOrderByLineNumberQuery, RepairOrderLine,
  RepairOrderLineInput,
  RepairOrderLineMutation, RepairOrderMutation, RepairOrderQuery, RepairOrdersAxQuery, RepairOrdersFiltersQuery, RepairOrdersQuery, RepairOrderLineCriticalStat, SearchShippingQuery, ShippingInputType,
  ShippingMutations,
  ShippingStatus, ShippingStatusesQuery, ShippingType, Status,
  StatusesQuery, SubscriptionInputType, Supplier, SuppliersQuery, UpdateQuoteLineInput,
  WorkScope, WorkscopesQuery,
  QuoteReviewQuoteLine
} from '@app/shared/services/api/api.models';
import { environment } from '@env/environment';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UsersQuery, User } from '@app/auth/auth.models';


@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(private apollo: Apollo, private http: HttpClient, private httpClient: HttpClient) { }

   getCriticalStats() : Observable<RepairOrderLineCriticalStat[]> {  
        return this.apollo
          .watchQuery<CriticalStatsQuery>({
            fetchPolicy: 'network-only',
            query: gql`
            query GetRepairOrderLineCriticalStats {
              repairOrderLineCriticalStats {
                repairOrderLineId,
                criticalStats{
                  name
                }
              }
            }
          `,
          })
          .valueChanges
          .pipe(
            map(result => {
              return result.data.repairOrderLineCriticalStats})
          );
    }

    getRcmRepairOrders(): Observable<RcmRepairOrder[]> {
      return this.apollo
        .watchQuery<RcmRepairOrderQuery>({
          fetchPolicy: 'network-only',
        query: gql`
        query getRcmRepairOrders {
            rcmRepairOrders {
              repairOrderNumber
              repairOrderId
              supplierName
              repairOrderLineId 
              programmeDescription
              repairOrderLine {
                description
                id               
                priority
                serialNumber
                repairLineStatus
                repairLineStatusDescription
                gTat
                nTat
                roLineNumber
                workscopeName
                partNumber
                customerAccountManager
              }
            }
          }
      `,
      })
        .valueChanges
        .pipe(
          map(result => result.data.rcmRepairOrders)
        );
  }

   getDashboardRepairOrders(): Observable<DashboardRepairOrder[]> {
    return this.apollo
      .watchQuery<DashboardRepairOrderQuery>({
        fetchPolicy: 'network-only',
        query: gql`
          query getDashboardRepairOrders {
            dashboardRepairOrders {
              createdAt
              updatedAt
              repairLineStatusDescription
              repairOrderStatusName
              daysSinceCreation
              daysSinceStatusUpdated
              repairOrderId
              repairOrderLineId
              assignedUserId
            }
          }
        `,
        })
        .valueChanges
        .pipe(
          map(result => result.data.dashboardRepairOrders)
        );
  }

  getRepairOrders(roleName: string, userId: number): Observable<RepairOrder[]> {
      return this.apollo
        .watchQuery<RepairOrdersQuery>({
          fetchPolicy: 'network-only',
          query: gql`
          query getRepairOrders {
            repairOrders {
              repairOrderLineId
              rOLineNumber
              assignedUser{
                buyerCode
                name
                userName
              }
              buyerCode
              buyerName
              customerAccountName
              repairOrderId
              repairOrderNumber
              priorityID
              assignedUserID
              roCreatedAt
              roUpdatedAt
              pONumber
              partNumber
              serialNumber
              warranty
              supplierName
              supplierId
              repairOrderStatusName
              description
              repairOrderDefect
              workRequired
              quantity
              ntat
              gtat
              priority {
                id
                priorityName
              }
              supplier {
                id
                name
              }
              repairOrderLine {
                repairOrderId
                partNumber
                description
                directShip
                repairOrderDefect
                inboundUnitCategory
                outboundSerialNumber
                outboundSerialNumberChangeReason
                customerAccountManager
                serialNumber
                supplierRMANumber
                repairLineStatus
                repairLineStatusDescription
                supplierPromisedShipDt
                customerReference
                aOG
                rOLineNumber
                quantity
                status {
                  id
                  name
                }
                warranty
                ntat
                gtat
                workRequired
                workScope {
                  id
                  name
                }
                repairOrderStatus {
                  id
                  name
                },
                logisticStatus,
                inboundGRComplete,
                gRComplete,
                customerRMANumber
              }
            }
          }
        `,
        })
        .valueChanges
        .pipe(
          map(result => result.data.repairOrders)
        );
  }

  searchShipping(searchString:string):Observable<ShippingType[]>{
    return this.apollo
    .watchQuery<SearchShippingQuery>({
      fetchPolicy: 'network-only',
      query:gql`
      query searchShipping($searchString: String){
        searchShipping(searchString:$searchString){
          id
          shippingId
          rONumber
          rOLNumber
          rOLineNumber
          trackingNumber
          shippingReference
        }
      }
      `,
      variables:{ searchString }
          })
          .valueChanges
          .pipe(
            map(result => result.data.searchShipping)
          );
  }


  searchRepairOrders(keyword : string): Observable<RepairOrder[]> {
    // console.log("getRepairOrders");
        return this.apollo
          .watchQuery<RepairOrdersQuery>({
            fetchPolicy: 'network-only',
            query: gql`
            query searchRepairOrders($keyword : String) {
              searchRepairOrders(keyword : $keyword) {
                repairOrderLineId
                rOLineNumber
                assignedUser{
                  buyerCode
                  name
                  userName
                }
                buyerCode
                buyerName
                customerAccountName
                repairOrderId
                repairOrderNumber
                priorityID
                assignedUserID
                roCreatedAt
                roUpdatedAt
                partNumber
                serialNumber
                warranty
                workScopeID
                workScopeName
                supplierName
                supplierId
                repairOrderStatusName
                description
                repairOrderDefect
                workRequired
                quantity
                ntat
                gtat
                priority {
                  id
                  priorityName
                }
                supplier {
                  id
                  name
                }
                repairOrderLine {
                  repairOrderId
                  partNumber
                  description
                  repairOrderDefect
                  inboundUnitCategory
                  outboundSerialNumber
                  outboundSerialNumberChangeReason
                  customerAccountManager
                  serialNumber
                  supplierRMANumber
                  supplierPromisedShipDt
                  aOG
                  rOLineNumber
                  quantity
                  status {
                    id
                    name
                  }
                  workScopeID
                  warranty
                  ntat
                  gtat
                  workRequired
                  workScope {
                    id
                    name
                  }
                  repairOrderStatus {
                    id
                    name
                  }
                }
              }
            }
          `,
          variables:{ keyword }
          })
          .valueChanges
          .pipe(
            map(result => result.data.repairOrders )
          );
    }
  getUnreadNotifications(): Observable<number> {
    //console.log("getUnreadNotifications");
    return this.apollo
      .watchQuery<any>({
        fetchPolicy: 'network-only',
        query: gql`
        query
        {
          unreadNotifications(userName : "repairadmin")
        }
      `,
      })
      .valueChanges
      .pipe(
        map(result => result.data.unreadNotifications)
      );
  }

  getAboutUs() : string
  {
    return "This is the text for about us";
  }

  /* Commented under 40896
  getUserSubscriptions(): Observable<AARSubscription[]> {
    //console.log("getUserSubscriptions");
    return this.apollo
      .watchQuery<any>({
        fetchPolicy: 'network-only',
        query: gql`
        query {
          subscriptionsByUserID(userID: "repairadmin")
          {
            frequencyId
            userName
            subscriptionName
            isSubscribed
            triggerId
          }
        }
      `,
      })
      .valueChanges
      .pipe(
        map(result => result.data.subscriptionsByUserID)
      );
  }
      */

  // TODO This call is for DEMO only
  getRepairOrdersAx(): Observable<RepairOrderAx[]> {
    //console.log("getRepairOrdersAx");
    return this.apollo
      .watchQuery<RepairOrdersAxQuery>({
        query: gql`,

          query getRepairOrdersAx {
            repairOrdersAxDemo {
              ... RepairOrderAxAttributes
            }
          }
          ${Fragments.RepairOrderAx.attributes}
        `,
      })
      .valueChanges
      .pipe(
        map(result => result.data.repairOrdersAxDemo)
      );
  }

  // TODO This call is for DEMO only
  getRepairOrdersAndLine(): Observable<RepairOrderAndLine[]> {
    //console.log("getRepairOrdersAndLine");
    return this.apollo
      .watchQuery<RepairOrderAndLineQuery>({
        query: gql`
          query repairOrderLineAndRo {
            repairOrderLineAndRo {
              ... RepairOrderAndLineAttributes
              repairOrder {
                ... RepairOrderDetailsAttributes
              }
              requestedWorkScope {
                ... WorkScopeAttributes
              }
              status {
                ... StatusAttributes
              }
              supplier {
                ... SupplierAttributes
              }
            }
          }
          ${Fragments.RepairOrderAndLine.attributes}
          ${Fragments.RepairOrderDetails.attributes}
          ${Fragments.WorkScope.attributes}
          ${Fragments.Status.attributes}
          ${Fragments.Supplier.attributes}
        `,
      })
      .valueChanges
      .pipe(
        map(result => result.data.repairOrderLineAndRo)
      );
  }

  getRepairOrdersByUserId(assignedUserID: number): Observable<RepairOrder[]> {
    //console.log("getRepairOrdersByUserId");
    return this.apollo
      .watchQuery<ByUserQuery>({
        query: gql`
        query getRepairOrdersByUserID($assignedUserID : Int) {
          repairOrdersByUserId(assignedUserID: $assignedUserID) {
            repairOrderLineId
            rOLineNumber
            repairOrderId
            repairOrderNumber
            repairOrderPriorityName
            priorityID
            assignedUserID
            roCreatedAt
            roUpdatedAt
            partNumber
            serialNumber
            warranty
            workScopeID
            workScopeName
            supplierName
            supplierId
            repairOrderStatusName
            description
            repairOrderDefect
            workRequired
            quantity
            ntat
            gtat
            priority {
              id
              priorityName
            }
            supplier {
              id
              name
            }
            repairOrderLine {
              repairOrderId
              partNumber
              repairOrderDefect
              inboundUnitCategory
              outboundSerialNumber
              outboundSerialNumberChangeReason
              customerAccountManager
              description
              repairOrderDefect
              serialNumber
              rOLineNumber
              quantity
              status {
                id
                name
              }
              workScopeID
              warranty
              ntat
              gtat
              workRequired
              workScope {
                id
                name
              }
              repairOrderStatus {
                id
                name
              }
            }
          }
        }

        `,
        variables: { assignedUserID }
      })
      .valueChanges
      .pipe(
        map(result => result.data.repairOrdersByUserId)
      );
  }

  getRepairOrder(id: number): Observable<RepairOrder> {
    //console.log("getRepairOrder");
    return this.apollo
      .watchQuery<RepairOrderQuery>({
        query: gql`
          query getRepairOrder($id: Int!) {
            repairOrder(id: $id) {
              ... RepairOrderDetailsAttributes
              historicalPricingList {
                ... HistoricalPricingListAttributes
              }
            }
          }
          ${Fragments.RepairOrderDetails.attributes}
          ${Fragments.HistoricalPricingList.attributes}
        `,
        variables: { id }
      })
      .valueChanges
      .pipe(
        map(result => result.data.repairOrder)
      );
  }

  //TODO: Chat with Kwame/Dmytro Secure this properly
  getIsAuthorizedApprover(userId: number, amount: number): Observable<IsAuthorizedApproverQuery> {
    //console.log("getRepairOrder");
    return this.apollo
      .watchQuery<IsAuthorizedApproverQuery>({
        query: gql`
        query ($userId: Int!, $amount: Float!) {
          isAuthorizedApprover(userId: $userId, amount: $amount) {
            isAuthorized
            approvalHierarchy {
              id
              userId
              startRange
              endRange
            }
          }
        }
        `,
      variables: { userId, amount }
      })
      .valueChanges
      .pipe(
        map(result => {
          return result.data
        })
      );
  }


 /* Commented under #40361
 
  getRepairOrderByLineNumber(repairOrderLineNumber: number, repairOrderNumber: string): Observable<RepairOrder> {
    const query = gql`
    query repairOrderByLineNumber($repairOrderLineNumber: Int!, $repairOrderNumber:String!) {
      repairOrderByLineNumber(repairOrderLineNumber: $repairOrderLineNumber, repairOrderNumber : $repairOrderNumber) {
        repairOrderId
        rOLineNumber
        repairOrderLineId
        description
        repairOrderNumber
        repairOrderStatusName
        partNumber
        serialNumber
        workScopeID
        workScopeName
        assignedUserID
        workRequired
        warranty
        roUpdatedAt
        roCreatedAt
        quantity
        repairOrderDefect
        customerAccountName
        customerROReceivedDt
        returnToAddressCode
        customerRMANumber
        daysSinceCreation
        daysSinceStatusUpdated
        priorityID
        supplierId
        pONumber
        customerInternalRefNumberDate
        customerROCreatedDate
        supplier {
          id
          name
        }
        supplierName
        repairOrderLine {
          workScopeID
          rOLineNumber
          repairOrderDefect
          inboundUnitCategory
          outboundSerialNumber
          outboundSerialNumberChangeReason
          customerAccountManager
          description
          closedDate
          customerROReceivedDt
          returnToAddressCode
          customerRMANumber
          repairOrderStatusName
          aircraftTypeId
          repairOrderLineId
          repairLineStatus
          repairLineStatusDescription
          quoteStatusId
          quoteStatusDescription
          logisticStatusId
          logisticStatus
          holdTags {
            closedDate
            comments
            createDate
            directionId
            id
            sysCreateDate
          }
          workScope {
            id
            name
            workScopeId
          }
          repairOrderDefect
          warranty
          contract
          workRequired
          quantity
          serialNumber
          partNumber
          customerReference
          customerROCreatedDt
          gRComplete
          outboundGRCompleteDate
          inboundGRComplete
          inboundGRCompleteDate
          directShip
          aOG
          aogAircraftTailNumber
          aogAircraftSerialNumber
          supplierRMANumber
          supplierPromisedShipDt
          customerPOValue
          customerPOValueCurrency
          customerResponsibleValue
          customerResponsibleValueCurrency
          logisticStatus
          masterQuotedToCustomerDate
          status {
            id
            name
          }
          quote {
            quoteId
            vendAccount
            currencyCode
            supplierReference
            customerApprovedDate
            datePosted
            createdDate
            processedDate
            quotedToCustomerDate
            authorizedOnSupplierDate
            canceledDate
            cancelationReason
            datePosted
            dateReceived
            rCMQuoteId
            quoteLine {
              id
              findings
              qTYRceived
              quoteOrderLineNum
              mismatchReasonPnId
              mvPercent
              mismatchReasonSnId
              rONumber
              unitId
              laborCost
            }
          }
        }
      }
    }
    `;
    const variables = {repairOrderLineNumber, repairOrderNumber}

    console.log(query);

    return this.apollo
    .watchQuery<RepairOrderByLineNumberQuery>({
      fetchPolicy:"network-only",
      query: query,
      variables: variables
      })
      .valueChanges
      .pipe(
        map(result =>
          result.data.repairOrderByLineNumber)
      );

  }
*/

  getRepairOrderByLineNumber(repairOrderLineNumber: number, repairOrderNumber: string): Observable<RepairOrder> {
    const query = gql`
    query repairOrderByLineNumber($repairOrderLineNumber: Int!, $repairOrderNumber:String!) {
      repairOrderByLineNumber(repairOrderLineNumber: $repairOrderLineNumber, repairOrderNumber : $repairOrderNumber) {
        repairOrderId
        rOLineNumber
        repairOrderLineId
        description
        repairOrderNumber
        repairOrderStatusName
        partNumber
        serialNumber
        assignedUserID
        workRequired
        warranty
        roUpdatedAt
        roCreatedAt
        quantity
        repairOrderDefect
        customerAccount
        customerAccountName
        customerROReceivedDt
        returnToAddressCode
        daysSinceCreation
        daysSinceStatusUpdated
        priorityID
        supplierId
        supplier {
          id
          name
        }
        supplierName
        customerSupplierCode
        programmeDescription
        repairOrderLine {
          rOLineNumber
          repairOrderDefect
          inboundUnitCategory
          outboundSerialNumber
          customerAccountManager
          description
          closedDate
          returnToAddressCode
          repairOrderStatusName
          aircraftSubTypeName
          repairOrderLineId
          repairLineStatus
          repairLineStatusDescription
          quoteStatusId
          quoteStatusDescription
          logisticStatusId
          logisticStatus
          repairOrderDefect
          warranty
          workRequired
          quantity
          serialNumber
          partNumber
          customerReference
          customerROCreatedDt
          gRComplete
          outboundGRCompleteDate
          inboundGRComplete
          inboundGRCompleteDate
          aOG
          aogAircraftTailNumber
          aogAircraftSerialNumber
          supplierPromisedShipDt
          logisticStatus
          status {
            id
            name
          }
          outboundUnitCategory{
            value
          }
          outboundWorkScope{
            name
          }
          outboundPartNumber
          outboundPartDescription
          tailNumber
          originalQuoteProcessedDateTime
          returnToAddressCode
          returnToAddress
          ntat
          gtat
          supplierConfirmedReceiptDateTime
          priority
          removalLocation
         
        }
      }
    }
    `;
    const variables = {repairOrderLineNumber, repairOrderNumber}

    console.log(query);

    return this.apollo
    .watchQuery<RepairOrderByLineNumberQuery>({
      fetchPolicy:"network-only",
      query: query,
      variables: variables
      })
      .valueChanges
      .pipe(
        map(result =>
          result.data.repairOrderByLineNumber)
      );

  }

  getMockRepairOrders(): Observable<any> {
    //console.log("getMockRepairOrders");
    return this.http.get('/assets/mocks/rcm.json');
  }

  getMockUrgentRepairOrders(): Observable<any> {
    //console.log("getMockUrgentRepairOrders");
    return this.http.get('/assets/mocks/urgent-table.json');
  }

  getSuppliers(): Observable<Supplier[]> {
    //console.log("getSuppliers");
    return this.apollo
      .watchQuery<SuppliersQuery>({
        query: gql`
          query getSuppliers {
            suppliers {
              ... SupplierAttributes
            }
          }
          ${Fragments.Supplier.attributes}
        `,
      })
      .valueChanges
      .pipe(
        map(result => result.data.suppliers)
      );
  }

  getPriorities(): Observable<Priority[]> {
    //console.log("getPriorities");
    return this.apollo
      .watchQuery<PrioritiesQuery>({
        query: gql`
        query getPriorities {
          priorities {
            ... PriorityAttributes
          }
        }
        ${Fragments.Priority.attributes}
      `,
      })
      .valueChanges
      .pipe(
        map(result => result.data.priorities)
      );
  }

  getShippingStatuses(): Observable<ShippingStatus[]> {
    //console.log("getShippingStatuses");
    return this.apollo
      .watchQuery<ShippingStatusesQuery>({
        query: gql`
        query shippingStatuses {
          shippingStatuses {
            id
            name
            shippingStatusId
          }
        }
      `,
      })
      .valueChanges
      .pipe(
        map(result => result.data.shippingStatuses)
      );
  }

  getEndCustomers(): Observable<EndCustomerQuery> {
    //console.log("getShippingStatuses");
    return this.apollo
      .watchQuery<EndCustomerQuery>({
        query: gql`
        query endCustomers {
          endCustomers {
            endCustomerCode
            endCustomerID
            endCustomerName
          }
        }
      `,
      })
      .valueChanges
      .pipe(
        map(result => result.data)
      );
  }

  getCarriers(): Observable<Carrier[]> {
    //console.log("getCarriers");
    return this.apollo
      .watchQuery<CarrierQuery>({
        query: gql`
        query carriers {
          carriers {
          ... CarrierAttributes}
        }
          ${Fragments.Carrier.attributes}
      `,
      })
      .valueChanges
      .pipe(
        map(result => result.data.carriers)
      );
  }

  getStatuses(): Observable<Status[]> {
    //console.log("getStatuses");
    return this.apollo
      .watchQuery<StatusesQuery>({
        query: gql`
        query getStatuses {
          repairOrderStatuses {
            ... RepairOrderStatusAttributes
          }
        }
        ${Fragments.RepairOrderStatus.attributes}
      `,
      })
      .valueChanges
      .pipe(
        map(result => result.data.repairOrderStatuses)
      );
  }

  getWorkscopes(): Observable<WorkScope[]> {
    //console.log("getWorkscopes");
    return this.apollo
      .watchQuery<WorkscopesQuery>({
        query: gql`
        query getWorkscopes {
          workscopes {
            ... WorkScopeAttributes
          }
        }
        ${Fragments.WorkScope.attributes}
      `,
      })
      .valueChanges
      .pipe(
        map(result => result.data.workscopes)
      );
  }

  getPartNumbers(): Observable<Part[]> {
    //console.log("getWorkscopes");
    return this.apollo
      .watchQuery<PartsQuery>({
        fetchPolicy: 'network-only',
        query: gql`
        query getParts {
          parts {
            ... PartAttributes
          }
        }
        ${Fragments.Part.attributes}
      `,
      })
      .valueChanges
      .pipe(
        map(result => result.data.parts)
      );
  }

  getQuoteWarrantyStatuses(): Observable<QuoteWarrantyStatus[]> {
    console.log('getQuoteWarrantyStatuses');
    return this.apollo
      .watchQuery<QuoteWarrantyQuery>({
        fetchPolicy: 'network-only',
        query: gql`
        query getQuoteWarrantyStatuses {
          quoteWarrantyStatuses {
            ... QuoteWarrantyStatusesAttributes
          }
        }
        ${Fragments.QuoteWarrantyStatus.attributes}
      `,
      })
      .valueChanges
      .pipe(
        map(result => result.data.quoteWarrantyStatuses)
      );
  }

  getRepairOrdersFilters(): Observable<any> {
    //console.log("getRepairOrdersFilters");
    return this.apollo
      .watchQuery<RepairOrdersFiltersQuery>({
        query: gql`
        query getRepairOrdersFilters {
          priorities {
            ... PriorityAttributes
          }
          repairOrderStatuses {
            ... RepairOrderStatusAttributes
          }
          workscopes {
            ... WorkScopeAttributes
          }
          suppliers {
            ... SupplierAttributes
          }
        }
        ${Fragments.Priority.attributes}
        ${Fragments.RepairOrderStatus.attributes}
        ${Fragments.WorkScope.attributes}
        ${Fragments.Supplier.attributes}
      `,
      })
      .valueChanges
      .pipe(
        map(result => {
          const { priorities, repairOrderStatuses, workscopes, suppliers } = result.data;
          return { priorities, repairOrderStatuses, workscopes, suppliers };
        })
      );
  }

  getUsers(): Observable<User[]> {
    return this.apollo
      .watchQuery<UsersQuery>({
        query: gql`
          query getUsers {
            security_users {
              ... UserAttributes
            }
          }
          ${Fragments.User.attributes}
        `,
      })
      .valueChanges
      .pipe(
        map(result => result.data.security_users)
      );
  }

  extractKeywords(quoteId: number): Observable<string> {
    //console.log("extractKeywords");
    return this.apollo
      .watchQuery<any>({
        query: gql`
        query{
          extractKeywords(quotelineID :${quoteId})

        }
        `,
      })
      .valueChanges
      .pipe(
        map(result => result.data.extractKeywords)
      );
  }
  
  createRepairOrderLine(repairOrderLineAdd: RepairOrderLineInput): Observable<RepairOrderLine> {
    //console.log("createRepairOrderLine");
    return this.apollo.mutate<RepairOrderLineMutation>({
      mutation: gql`
        mutation createRepairOrderLine($repairOrderLineAdd: NewRepairOrderLineInput!) {
          createRepairOrderLine(repairOrderLineAdd: $repairOrderLineAdd) {
            ... RepairOrderLineAttributes
            status {
                ... RepairOrderLineStatusAttributes
              }
              requestedWorkScope {
                ... WorkScopeAttributes
              }
              repairCategory {
                ... RepairCategoryAttributes
              }
          }
        }
        ${Fragments.RepairOrderLine.attributes}
        ${Fragments.RepairOrderLineStatus.attributes}
        ${Fragments.WorkScope.attributes}
        ${Fragments.RepairCategory.attributes}
      `,
      variables: {
        repairOrderLineAdd
      }
    })
      .pipe(
        map(result => result.data.createRepairOrderLine)
      );
  }

  createQuoteLine(quotelineAdd: CreateQuoteLineInput): Observable<any> {

    //console.log("createQuoteLine");
    //console.log("We in HEre: "+JSON.stringify(quotelineAdd));
    return this.apollo.mutate<any>({
      mutation: gql`
      mutation {
        createQuoteLine(quoteLineAdd:
          {datePosted: "${quotelineAdd.datePosted.toISOString()}",
          dateReceived: "${quotelineAdd.dateReceived.toISOString()}",
          assignedUserId: "${quotelineAdd.assignedUserId}",
          finalApproverId: "${quotelineAdd.finalApproverId}",
          findings: "${quotelineAdd.findings}",
          masterOption : ${quotelineAdd.masterOption},
          webServiceDescription: "${quotelineAdd.webServiceDescription}",
          qtyRceived: ${quotelineAdd.qtyRceived},
          warrantyStatusId : ${quotelineAdd.warrantyStatusId},
          quoteId: "${quotelineAdd.quoteId}",
          updatedAt: "${quotelineAdd.updatedAt.toISOString()}",
          vendAccount: "${quotelineAdd.vendAccount}",
          ecd: "${new Date().toISOString()}",
          quotedTat: ${quotelineAdd.quotedTat},
          quoteLineStatus: "${quotelineAdd.quoteLineStatus}",
          quoteOrderLineNum: "${quotelineAdd.quoteOrderLineNum}",
          serialNumberReceived: "${quotelineAdd.serialNumberReceived}",
          sos: ${quotelineAdd.sos},
          supplierReference: "${quotelineAdd.supplierReference}",
          rai: ${quotelineAdd.rai},
          totalCost: ${quotelineAdd.totalCost},
          miscellaneous: ${quotelineAdd.miscellaneous},
          mismatchReasonPnId: "${quotelineAdd.mismatchReasonPnId}",
          mismatchReasonSnId: "${quotelineAdd.mismatchReasonSnId}",
          createdByUserName: "${quotelineAdd.createdByUserName}",
          currency: "${quotelineAdd.currency}",
          unitId: "${quotelineAdd.unitId}",
          mV: ${quotelineAdd.mV},
          material: ${quotelineAdd.material},
          nUP: ${quotelineAdd.nUP},
          partNumberReceived: "${quotelineAdd.partNumberReceived}",
          proposedWorkscopeId: "${quotelineAdd.proposedWorkscopeId}",
          repairCategoryName: "${quotelineAdd.repairCategoryName}",
          roLineNumber: "${quotelineAdd.roLineNumber}",
          roNumber: "${quotelineAdd.roNumber}",
          mismatchReasonPn: "${quotelineAdd.mismatchReasonPn}",
          mismatchReasonSn: "${quotelineAdd.mismatchReasonSn}",
          mvCurrency: "${quotelineAdd.mvCurrency}",
          mvPercent: ${quotelineAdd.mvPercent},
          laborCost: ${quotelineAdd.laborCost},
          nupCurrency: "${quotelineAdd.nupCurrency}",
          nff: "${quotelineAdd.nff}",
          nupPercent: ${quotelineAdd.nupPercent},
          quoteStatus: "${quotelineAdd.quoteStatus}"}) {
          rOLineNumber
          quote {
            quoteId
            rCMQuoteId
            rONumber
            dateReceived
            supplierReference
            datePosted
            rOLineNumber
          }
          currencyType {
            id
            name
          }
          mvCurrencyType {
            id
            name
          }
          nupCurrencyType {
            id
            name
          }
          unitId
          findings
          proposedWorkscopeId
          laborCost
          material
          miscellaneous
          rai
          sos
          nupCurrency
          nUP
          nupPercent
          mV
          mvCurrency
          mvPercent
          totalCost
          updatedAt
          currency
          repairCategory {
            id
          }
          serialNumberReceived
          mismatchReasonSnId
          mismatchReasonPnId
          partNumberReceived
          qTYRceived
          ecd
        }
      }

      `,
      variables: {
        quotelineAdd
      }
    })
      .pipe(
        map(result => result.data.createQuoteLine)
      );
  }

  getQuotesLinesByRoAndRol(roNumber: string, roLineNumber: string): Observable<QuoteLine[]> {
    //console.log("getQuotesLinesByRoAndRol");
    return this.apollo
      .watchQuery<any>({
        fetchPolicy: 'no-cache',
        query: gql`
        {
          quoteLineByRoAndRol(repairOrderNumber: "${roNumber}", rolNumber: "${roLineNumber}") {
            id
            quote {
              quoteId
              rCMQuoteId
              quoteStatusName
              rONumber
              dateReceived
              supplierReference
              datePosted
              rOLineNumber
              masterOption
              customerApprovedDate
              createdByUserName
              createdDate
              processedDate
              authorizedOnSupplierDate
              canceledDate
              cancelationReason
              quoteStatus
              {
                name
              }
            }
            mvCurrencyType {
              id
              name
            }
            nupCurrencyType {
              id
              name
            }
            repairCategory {
              id
              name
            }
            currencyType {
              id
              name
            }
            unitId
            findings
            proposedWorkscopeId
            laborCost
            material
            miscellaneous
            rai
            sos
            quoteOrderLineNum
            nupCurrency
            nUP
            nupPercent
            mV
            mvCurrency
            mvPercent
            totalCost
            quoteLineStatus
            updatedAt
            currency
            repairCategoryName
            serialNumberReceived
            mismatchReasonSnId
            mismatchReasonPnId
            partNumberReceived
            qTYRceived
            ecd
            quotedTat
          }
        }
      `
      })
      .valueChanges
      .pipe(
        map(result => result.data.quoteLineByRoAndRol)
      );
  }
  getQuotesLinesById(quoteId: string): Observable<QuoteLine[]> {
    return this.apollo
      .watchQuery<any>({
        fetchPolicy: 'no-cache',
        query: gql`
        {
          quoteLines(quoteId: "${quoteId}") {
            id
            quote {
              id
              quoteStatusName
              rCMQuoteId
              rONumber
              dateReceived
              supplierReference
              customerApprovedDate
              datePosted
              createdDate
              processedDate
              quotedToCustomerDate
              authorizedOnSupplierDate
              canceledDate
              cancelationReason
              rOLineNumber
              masterOption
              internalComments
              quoteStatus {
                name
                id
              }
            }
            mvCurrencyType {
              id
              name
            }
            nupCurrencyType {
              id
              name
            }
            repairCategory {
              repairCategoryId
              name
              value
            }
            currencyType {
              id
              name
            }
            unitId
            findings
            proposedWorkscopeId
            laborCost
            material
            miscellaneous
            rai
            sos
            quoteOrderLineNum
            nupCurrency
            nUP
            nupPercent
            mV
            quoteWarrantyStatus{
              id
              quoteWarrantyStatusID
              reason
            }
            mvCurrency
            mvPercent
            totalCost
            quoteLineStatus
            updatedAt
            currency
            serialNumberReceived
            mismatchReasonSnId
            mismatchReasonPnId
            partNumberReceived
            qTYRceived
            ecd
            quotedTat
            unitCost
            outboundSerialNumber
          }
        }
      `
      })
      .valueChanges
      .pipe(
        map(result => result.data.quoteLines)
      );
  }
  getROQuotelineStatus(repairOrderNumber: string, rolNumber: string): Observable<PendingQuote> {
  //console.log("getROQuotelineStatus");
    return this.apollo
      .watchQuery<any>({
        fetchPolicy: 'network-only',
        query: gql`
      query
      {
        pendingQuotes(repairOrderNumber:"${repairOrderNumber}", rolNumber:"${rolNumber}")
        {
          hasPendingQuotes
          hasPendingApproval
        }
      }


    `
      })
      .valueChanges
      .pipe(
        map(result => result.data.pendingQuotes)
      );
  }

  extractQuotelineDetails(documentId: number): Observable<QuoteLine> {
    //console.log("extractQuotelineDetails");
    return this.apollo
      .watchQuery<any>({
        fetchPolicy: 'no-cache',
        query: gql`
        query{
          extractQuoteDetails(documentID: ${documentId}) {
            id
            quote {
              id
              rCMQuoteId
              rONumber
              dateReceived
              supplierReference
              datePosted
              rOLineNumber
            }
            currencyType {
              id
              name
            }
            unitId
            findings
            proposedWorkscopeId
            laborCost
            material
            miscellaneous
            rai
            nupCurrencyType {
              id
              name
            }
            sos
            quoteOrderLineNum
            nupCurrency
            nUP
            nupPercent
            mV
            mvCurrency
            mvPercent
            totalCost
            quoteLineStatus
            updatedAt
            currency
            mvCurrencyType
            {
              id
              name
              value
            }
            nupCurrencyType {
              id
              name
            }
            repairCategory {
              id
              name
            }
            serialNumberReceived
            mismatchReasonSnId
            mismatchReasonPnId
            partNumberReceived
            qTYRceived
            ecd

          }
        }

      `
      })
      .valueChanges
      .pipe(
        map(result => result.data.extractQuoteDetails)
      );
  }

  getPendngQuoteLineStagging(roNumber: string, roLineNumber: string): Observable<QuoteLine> {
    return this.apollo
      .watchQuery<any>({
        fetchPolicy: 'no-cache',
        query: gql`
      query {
        pendingQuoteLineStagging(repairOrderNumber: "${roNumber}", repairOrderLineNumber:"${roLineNumber}") {
          id
          quoteId
          quoteLineStatus
          rOLineNumber
          quote {
            id
            quoteStatusName
            rCMQuoteId
            rONumber
            dateReceived
            supplierReference
            datePosted
            createdDate
            processedDate
            quotedToCustomerDate
            authorizedOnSupplierDate
            canceledDate
            cancelationReason
            customerApprovedDate
            rOLineNumber
            masterOption
            quoteStatus
            {
              name
              id
            }
          }
          attachedQuoteID
          repairCategory {
            repairCategoryId
            name
            value
          }
          nupCurrencyType {
            id
            name
          }
          currencyType {
            id
            name
          }
          mvCurrencyType {
            id
            name
          }
          unitId
          findings
          proposedWorkscopeId
          laborCost
          material
          miscellaneous
          rai
          sos
          quoteOrderLineNum
          nupCurrency
          nUP
          nupPercent
          mV
          quoteWarrantyStatus{
            id
            quoteWarrantyStatusID
            reason
          }
          mvCurrency
          mvPercent
          totalCost
          updatedAt
          currency
          serialNumberReceived
          mismatchReasonSnId
          mismatchReasonPnId
          partNumberReceived
          qTYRceived
          ecd
          quotedTat
          hasWarranty
        }
      }


      `
      })
      .valueChanges
      .pipe(
        map(result => result.data.pendingQuoteLineStagging)
      );
  }

  getProcessedQuotes(roNumber: string, roLineNumber: string): Observable<QuoteReviewQuoteLine[]> {
    return this.apollo
      .watchQuery<any>({
        fetchPolicy: 'no-cache',
        query: gql`
      query {
        processedQuotes(repairOrderNumber: "${roNumber}", rolNumber:"${roLineNumber}") {
          id
          findings
          rai
          sos
          quoteOrderLineNum
          totalCost
          currency
          serialNumberReceived
          mismatchReasonSnId
          mismatchReasonPnId
          partNumberReceived
          qTYRceived
          unitCost
          tailNumber
          warrantyStatusName
          currencyName
          proposedWorkScopeName
          repairCategoryName
          partNumberDescription
          aircraftName
          quotedTat
          customerSuppliedValues {
            currency
            type
            typeName
            value
          }
          pricingThresholds {
            type
            description
            actual
            overThreshold
            percentage
          }
          rvrThreshold {
            ruleReference
            narrative
            currency
            overThreshold
          }
          charges{
            code
            description
            value
            currency
          }
          quote {
            repairOrderLineId
            masterOption
            multiOption
            rejectedDateTime
            rejectedOnSupplierDateTime
            supersededDateTime
            quoteStatusName
            supplierReference
            createdDate
            processedDate
            dateReceived
            datePosted
            quotedToCustomerDate
            customerApprovedDate
            customerApprovedBy
            canceledDate
            cancelationReason
            authorizedOnSupplierDate
            quotedToCustomerDate
            pendingInternalActionDateTime
            charges{
              code
              description
              value
              currency
            }
          }
        }
      }
      `
      })
      .valueChanges
      .pipe(
        map(result => result.data.processedQuotes)
      );
  }

  updateQuoteLineStatus(repairOrderNumber: string, rolNumber: string, quoteId: string, status: string): Observable<boolean> {
    //console.log("updateQuoteLineStatus");
    return this.apollo.mutate<any>({
      mutation: gql`
      mutation{
        updateQuoteLineStatus(repairOrderNumber:"${repairOrderNumber}", rolNumber:"${rolNumber}", quoteId:"${quoteId}", status:"${status}")

      }
      `
    })
      .pipe(
        map(result => result.data.updateQuoteLineStatus)
      );
  }



  createQuoteLineStagging(repairOrderNumber:string, repairOrderLineNumber:string):Observable<QuoteLine>{
      return this.apollo.mutate<any>({
        fetchPolicy: 'no-cache',
        mutation: gql`
        mutation{ 
          createPendingQuoteLineStagging(repairOrderNumber:"${repairOrderNumber}", repairOrderLineNumber:"${repairOrderLineNumber}" ){
            id
          quoteId
          quoteLineStatus
          rOLineNumber
          quote {
            id
            quoteStatusName
            rCMQuoteId
            rONumber
            dateReceived
            supplierReference
            datePosted
            createdDate
            processedDate
            quotedToCustomerDate
            authorizedOnSupplierDate
            canceledDate
            cancelationReason
            rOLineNumber
            masterOption
            quoteStatus
            {
              name
              id
            }
          }
          attachedQuoteID
          repairCategory {
            repairCategoryId
            name
            value
          }
          nupCurrencyType {
            id
            name
          }
          currencyType {
            id
            name
          }
          mvCurrencyType {
            id
            name
          }
          unitId
          findings
          proposedWorkscopeId
          laborCost
          material
          miscellaneous
          rai
          sos
          quoteOrderLineNum
          nupCurrency
          nUP
          nupPercent
          mV
          quoteWarrantyStatus{
            id
            quoteWarrantyStatusID
            reason
          }
          mvCurrency
          mvPercent
          totalCost
          updatedAt
          currency
          serialNumberReceived
          mismatchReasonSnId
          mismatchReasonPnId
          partNumberReceived
          qTYRceived
          ecd
          quotedTat
          hasWarranty
          }
        }
  
        `
      })
        .pipe(
          map(result => result.data.createPendingQuoteLineStagging)
        );
  
    }

  updateQuoteLine(updateQuoteLineStagging: UpdateQuoteLineInput): Observable<RcmQuote> {

    return this.apollo.mutate<any>({
      fetchPolicy: 'no-cache',
      mutation: gql`
      mutation updateQuoteLineStagging($updateQuoteLineStagging: UpdateQuoteLineStaggingInput!) {
        updateQuoteLineStagging(quoteLineStaggingUpdate: $updateQuoteLineStagging) {
          id
          currencyType {
            id
            name
          }
          currency
          partNumberReceived
          mismatchReasonPn
          serialNumberReceived
          mV
          mismatchReasonSn
          qTYRceived
          findings
          repairCategoryId
          proposedWorkscopeId
          unitCost
          quote {
            supplierReference
            datePosted
            dateReceived
            createdDate
            processedDate
            quotedToCustomerDate
            customerApprovedDate
            authorizedOnSupplierDate
            canceledDate
            cancelationReason
            internalComments
          }
        }
      }

      `,
      variables: {
        updateQuoteLineStagging
      }
    })
      .pipe(
        map(result => result.data.updateQuoteLineStagging)
      );

  }

  /* updateQuoteLine(quoteLineUpdate: UpdateQuoteLineInput): Observable<RcmQuote> {

     return this.apollo.mutate<any>({
       fetchPolicy: 'no-cache',
       mutation: gql`
       mutation {
         updateQuoteLineStagging(quoteLineUpdate:
         {updatedAt: "${quoteLineUpdate.updatedAt.toUTCString()}",
         mismatchReasonPnId:"${quoteLineUpdate.mismatchReasonPn}",
         vendAccount:"",
         vendName:"",
         findings:"${quoteLineUpdate.findings}",
         mismatchReasonSnId:"${quoteLineUpdate.mismatchReasonSnId}",
         repairCategoryId:"${quoteLineUpdate.repairCategoryId}",
         proposedWorkscopeId:"${quoteLineUpdate.proposedWorkscopeId}",
         mismatchReasonSn:"${quoteLineUpdate.mismatchReasonSn}",
         quoteId:"${quoteLineUpdate.quoteId}",
         mismatchReasonPn:"${quoteLineUpdate.mismatchReasonPn}",
         roLineNumber:"${quoteLineUpdate.roLineNumber}",
         createdByUserName: "",
         assignedUserId: "",
         finalApproverId: "",
         qtyRceived: ${quoteLineUpdate.qtyRceived},
         serialNumberReceived: "${quoteLineUpdate.serialNumberReceived}",
         id: ${quoteLineUpdate.id},
         currency: "${quoteLineUpdate.currency}",
         ecd: "${quoteLineUpdate.ecd.toUTCString()}",
         quoteOrderLineNum: "${quoteLineUpdate.quoteOrderLineNum}",
         quoteStatus: "Created",
         nff: "",
         unitId: "",
         partNumberReceived: "${quoteLineUpdate.partNumberReceived}",
         dateReceived: "${quoteLineUpdate.dateReceived.toUTCString()}",
         supplierReference: "${quoteLineUpdate.supplierReference}",
         datePosted: "${quoteLineUpdate.datePosted.toUTCString()}",
         quoteLineStatus: "Created",
         webServiceDescription: "",
         totalCost:${quoteLineUpdate.totalCost},
         nupCurrency:"${quoteLineUpdate.nupCurrency}",
         nUP:${quoteLineUpdate.nUP},
         nupPercent:${quoteLineUpdate.nupPercent},
         mvCurrency:"${quoteLineUpdate.mvCurrency}",
         mV:${quoteLineUpdate.mV},
         mvPercent:${quoteLineUpdate.mvPercent},
         laborCost:${quoteLineUpdate.laborCost},
         material:${quoteLineUpdate.material},
         miscellaneous:${quoteLineUpdate.miscellaneous},
         rai:${quoteLineUpdate.rai},
         sos:${quoteLineUpdate.sos},
         roNumber: "${quoteLineUpdate.roNumber}"}) {
       id
       currency
       partNumberReceived
       mismatchReasonPn
       serialNumberReceived
       mismatchReasonSn
       qTYRceived
       findings
       repairCategoryId
       proposedWorkscopeId
       quote {
         supplierReference
       }
     }
   }

       `
     })
     .pipe(
       map(result => result.data.updateQuoteLineStagging)
     );


   }

       */

  subscribe(subscription: SubscriptionInputType): Observable<boolean> {
    //console.log("subscribe");
    return this.apollo.mutate<any>({
      mutation: gql`
      mutation subscribe($subscription: SubscriptionInputType!) {
        subscribe(subscription: $subscription)
      }
      `,
      variables: {
        subscription
      }
    })
      .pipe(
        map(result => result.data.subscribe)
      );
  }

  unSubscribe(subscription: SubscriptionInputType): Observable<boolean> {
    //console.log("unSubscribe");
    return this.apollo.mutate<any>({
      mutation: gql`
      mutation unsubscribe($subscription: SubscriptionInputType!) {
        unsubscribe(subscription: $subscription)
      }
      `,
      variables: {
        subscription
      }
    })
      .pipe(
        map(result => result.data.unsubscribe)
      );
  }

  getRepairCategories(): Observable<RepairCategory[]> {
    //console.log("getRepairCategories");
    return this.apollo
      .watchQuery<RepairCategoriesQuery>({
        query: gql`
        query getRepairCategories{
          repairCategoryList {
            ... RepairCategoryAttributes
          }
        }
        ${Fragments.RepairCategory.attributes}
      `,
      })
      .valueChanges
      .pipe(
        map(result => result.data.repairCategoryList)
      );
  }

  getCurrencies(): Observable<Currency[]> {
    //console.log("getCurrencies");
    return this.apollo
      .watchQuery<CurrencyQuery>({
        query: gql`
        query getCurrencies{
          currencyList {
            ... CurrencyAttributes
          }
        }
        ${Fragments.Currency.attributes}
      `,
      })
      .valueChanges
      .pipe(
        map(result => result.data.currencyList)
      );
  }

  getMismatchReasons(): Observable<MismatchReason[]> {

    //console.log("getMismatchReasons");
    return this.apollo
      .watchQuery<MismatchReasonQuery>({
        query: gql`
        query getMismatchReasons{
          mismatchReasons {
            ... MismatchReasonsAttributes
          }
        }
        ${Fragments.MismatchReasons.attributes}
      `,
      })
      .valueChanges
      .pipe(
        map(result => result.data.mismatchReasons)
      );
  }

  sendCommunicationEmail(emailMetaData: EmailMetadataInput): Observable<EmailMetadataResult> {
    //console.log("sendCommunicationEmail");
    return this.apollo.mutate<EmailMetadataInputMutation>({
      mutation: gql`
      mutation sendEmail($emailMetaData: EmailMetaDataInputType!) {
        sendEmail(emailMetaData: $emailMetaData) {
          body
          emailId
          body
          toEmail
          fromEmail
          subject
        }
      }
      `,
      variables: {
        emailMetaData
      }
    })
      .pipe(
        map(result => result.data.sendEmail)
      );
  }

  createActivity(addActivity: ActivityInputType): Observable<RepairOrderActivity> {
    //console.log("createActivity");
    return this.apollo.mutate<CreateActivityMutation>({
      fetchPolicy: "no-cache",
      mutation: gql`
       mutation createActivity($addActivity : ActivityInputType!)
       {
         createActivity(activityAdd:$addActivity)
         {
            id
           rONumber
           rOLNumber

         }
       }
       `,
      variables: {
        addActivity
      }
    })
      .pipe(
        map(result => result.data.createActivity)
      );
  }
  
  createShipping(shippingInfoAdd: ShippingInputType): Observable<ShippingType> {
    //console.log("createShipping");
    return this.apollo.mutate<ShippingMutations>({
      fetchPolicy: "no-cache",
      mutation: gql`
      mutation createShippingInfo($shippingInfoAdd: NewShippingInput!) {
        createShippingInfo(shippingInfoAdd: $shippingInfoAdd) {
          ... ShippingTypeAttributes
         }
      }
      ${Fragments.ShippingType.attributes}
      `,
      variables: {
        shippingInfoAdd
      }
    })
      .pipe(
        map(result => {
          return result.data.createShippingInfo;
        })
      );
  }

  updateShipping(shippingInfoUpdate: ShippingInputType): Observable<ShippingType> {
      return this.apollo.mutate<ShippingMutations>({
      fetchPolicy: "no-cache",
      mutation: gql`
      mutation updateShippingInfo($shippingInfoUpdate: UpdateShippingInput!) {
        updateShippingInfo(shippingInfoUpdate: $shippingInfoUpdate) {
          ... ShippingTypeAttributes
        }
      }      
      ${Fragments.ShippingType.attributes}       `,
      variables: {
        shippingInfoUpdate
      }
    })
      .pipe(
        map(result => {
          return result.data.updateShippingInfo;
        })
      );
  }

  deleteShipping(shippingInfoDelete: ShippingInputType): Observable<ShippingType> {
    return this.apollo.mutate<ShippingMutations>({
      fetchPolicy: "no-cache",
      mutation: gql`
      mutation deleteShippingInfo($shippingInfoDelete: DeleteShippingInput!) {
        deleteShippingInfo(shippingInfoDelete: $shippingInfoDelete) {
          ... ShippingTypeAttributes
        }
      }
      ${Fragments.ShippingType.attributes}
       `,
      variables: {
        shippingInfoDelete
      }
    })
      .pipe(
        map(result => {
          return result.data.deleteShippingInfo;
        })
      );
  }

  getEmailTemplates(): Observable<EmailTemplate[]> {
    //console.log("getEmailTemplates");
    return this.apollo
      .watchQuery<EmailTemplatesQuery>({
        query: gql`
        query emailTemplates {
          emailTemplates {
            ... EmailTemplateAttributes
          }
        }
        ${Fragments.EmailTemplate.attributes}
      `,
      })
      .valueChanges
      .pipe(
        map(result => result.data.emailTemplates)
      );
  }

  getNotificationFreqencies(): Observable<Frequency[]> {
    //console.log("getNotificationFreqencies");
    return this.apollo
      .watchQuery<any>({
        query: gql`
        query {
          subscriptionFrequency{
            frequencyID
            freqencyName
          }

          }
      `,
      })
      .valueChanges
      .pipe(
        map(result => result.data.subscriptionFrequency)
      );
  }

  getEmailTags(): Observable<EmailTags[]> {
    //console.log("getEmailTags");
    return this.apollo
      .watchQuery<EmailTagsQuery>({
        query: gql`
        query emailTags {
          emailTags {
            ... EmailTagAttributes
          }
        }
        ${Fragments.EmailTag.attributes}
      `,
      })
      .valueChanges
      .pipe(
        map(result => result.data.emailTags)
      );
  }

  getInboundShippingByRoAndRoL(repairOrderNumber: String, rolNumber: number): Observable<ShippingType[]> {
    //console.log("getShipping");
    return this.apollo
      .watchQuery<GetInboundShippingByRoAndRoLQuery>({
        fetchPolicy: 'no-cache',
        query: gql`
        query inboundShippingByRoAndRoL($rolNumber: Int!, $repairOrderNumber:String!) {
          inboundShippingByRoAndRoL(rolNumber: $rolNumber, repairOrderNumber : $repairOrderNumber) {
            ... ShippingTypeAttributes
          }
        }
        ${Fragments.ShippingType.attributes}
      `,
      variables: {
        repairOrderNumber,
        rolNumber
       }
      })
      .valueChanges
      .pipe(
        map(result => result.data.inboundShippingByRoAndRoL)
      );
  }

  getOutboundShippingByRoAndRoL(repairOrderNumber: String, rolNumber: number): Observable<ShippingType[]> {
    //console.log("getShipping");
    return this.apollo
      .watchQuery<GetOutboundShippingByRoAndRoLQuery>({
        fetchPolicy: 'no-cache',
        query: gql`
        query outboundShippingByRoAndRoL($rolNumber: Int!, $repairOrderNumber:String!) {
          outboundShippingByRoAndRoL(rolNumber: $rolNumber, repairOrderNumber : $repairOrderNumber) {
            ... ShippingTypeAttributes
          }
        }
        ${Fragments.ShippingType.attributes}
      `,
      variables: {
        repairOrderNumber,
        rolNumber
       }
      })
      .valueChanges
      .pipe(
        map(result => result.data.outboundShippingByRoAndRoL)
      );
  }

  getShipping(): Observable<EmailTags[]> {
    //console.log("getShipping");
    return this.apollo
      .watchQuery<EmailTagsQuery>({
        query: gql`
        query emailTags {
          emailTags {
            ... EmailTagAttributes
          }
        }
        ${Fragments.EmailTag.attributes}
      `,
      })
      .valueChanges
      .pipe(
        map(result => result.data.emailTags)
      );
  }

  getEmailCategories(): Observable<EmailCategories[]> {
    //console.log("getEmailCategories");
    return this.apollo
      .watchQuery<EmailCategoriesQuery>({
        query: gql`
        query emailTemplates {
          emailCategories {
            ... EmailCategoryAttributes
          }
        }
        ${Fragments.EmailCategory.attributes}
      `,
      })
      .valueChanges
      .pipe(
        map(result => result.data.emailCategories)
      );
  }

  getEmailPriorities(): Observable<EmailPriorities[]> {
    //console.log("getEmailPriorities");
    return this.apollo
      .watchQuery<EmailPrioritiesQuery>({
        query: gql`
        query emailPriorities {
          emailPriorities {
            ... EmailPriorityAttributes
          }
        }
        ${Fragments.EmailPriority.attributes}
      `,
      })
      .valueChanges
      .pipe(
        map(result => result.data.emailPriorities)
      );
  }

  getEmailByRO(repairOrder: string): Observable<Email[]> {
    //console.log("getEmailByRO");
    return this.apollo
      .watchQuery<EmailQuery>({
        fetchPolicy: 'network-only',
        query: gql`
        query emailByRO($repairOrder: String!) {
          emailByRO(repairOrder: $repairOrder) {
            metaDataResults
            {
              body
              subject
              fromEmail
              toEmail
              sentDateTime
              receivedDateTime
            }
          }
        }
      `,
        variables: { repairOrder }
      })
      .valueChanges
      .pipe(
        map(result => result.data.emailByRO)
      );

  }

  getRepairOrderActivity(repairOrderNumber: string, rolNumber: string): Observable<RepairOrderActivity[]> {
    //console.log("getRepairOrderActivity");
    return this.apollo
      .watchQuery<RepairOrderActivityQuery>({
        fetchPolicy: "network-only",
        query: gql`
        query activitiesByRoAndRoL($repairOrderNumber: String!, $rolNumber: String!) {
          activitiesByRoAndRoL(repairOrderNumber: $repairOrderNumber, rolNumber:$rolNumber) {
            id
            description
            userId
            activityDate
            icon
            activityType {
              description
              activityTypeID
            }
          }
        }
      `,
        variables: { repairOrderNumber, rolNumber }
      })
      .valueChanges
      .pipe(
        map(result => result.data.activitiesByRoAndRoL)
      );
  }

  getDocumentClasses(): Observable<any[]> {
    //console.log("getDocumentClasses");
    return this.apollo
      .watchQuery<any>({
        query: gql`
        query Query {
          documentClasses{
            documentClassId
            documentClassName
          }
         }
      `,
      fetchPolicy: 'no-cache'
      })
      .valueChanges
      .pipe(
        map(result => result.data.documentClasses)
      );
  }

  getDocumentExtensions(): Observable<any[]> {
    //console.log("getDocumentExtensions");
    return this.apollo
      .watchQuery<any>({
        query: gql`
        query Query {
          documentExtensions{
            documentExtensionId
            extentionName
          }
         }
      `,
      })
      .valueChanges
      .pipe(
        map(result => result.data.documentExtensions)
      );
  }

  getHistoricalPricingByRoNumber(roId: number): Observable<any[]> {
    return this.apollo
      .watchQuery<any>({
        query: gql`
        query historicalPricingByRoNumber {
          historicalPricingByRoNumber(repairOrderNumber:"${roId}"){
          contracted
          cost
          date
          id
          rCMQuoteId
          repairOrderId
          repairOrderNumber
          rOCombineID
          rOLNumber
          suppId
          supplier {
            id
            name
          }
          }
          }
      `,
      })
      .valueChanges
      .pipe(
        map(result => result.data.historicalPricingByRoNumber)
      );
  }

  getDocumentByName(documentName: String): Observable<any> {
    //console.log("getDocumentByName");
    return this.apollo
      .watchQuery<any>({
        fetchPolicy: 'network-only', query: gql`
      query
     {
       documentByName(documentName:"${documentName}")
      {
         documentId
         thumbNail
         uploadDate
         documentStorageName
         documentPath
         comments
        documentClass
          {
          documentClassId
          documentClassName
          }
         documentTitle
         documentExtension
          {
          documentExtensionId
          extentionName
          }
       }
    }
    `,
      })
      .valueChanges
      .pipe(
        map(result => result.data.documentByName)
      );
  }

  getDocumentByRoId(roId: number): Observable<any[]> {
    return this.apollo
      .watchQuery<any>({
        fetchPolicy: 'network-only', query: gql`
      query
     {
       documentByName(documentName:"${roId}")
      {
         documentId
         thumbNail
         uploadDate
         documentStorageName
         documentPath
         comments
        documentClass
          {
          documentClassId
          documentClassName
          }
         documentTitle
         documentExtension
          {
          documentExtensionId
          extentionName
          }
       }
    }
    `,
      })
      .valueChanges
      .pipe(
        map(result => result.data.documentByName)
      );
  }

  getDocumentById(documentId: number): Observable<any> {
  //console.log("getDocumentById");
    return this.apollo
      .watchQuery<any>({
        fetchPolicy: 'network-only', query: gql`
   query
  {
    documentById(documentId:${documentId})
   {
      documentId
      thumbNail
      uploadDate
      documentStorageName
      documentPath
      comments
     documentClass
       {
       documentClassId
       documentClassName
       }
      documentTitle
      documentExtension
       {
       documentExtensionId
       extentionName
       }
    }
 }
 `,
      })
      .valueChanges
      .pipe(
        map(result => result.data.documentById)
      );
  }

  getDocumentByRoLineId(roLineId: number, companyID: number): Observable<any[]> {
    //console.log("getDocumentByRoLineId");
    return this.apollo
      .watchQuery<any>({
        fetchPolicy: 'network-only', query: gql`
        query Query {
          documentByRoId(repairOrderId:${roLineId}, companyID:${companyID}){
            documentClass {
              documentClassId
              documentClassName
            }
            documentExtension {
              documentExtensionId
              extentionName
            }
            documentId
            documentPath
            comments
            documentStorageName
            documentTitle
            keyWords
            primary
            repaiOrderNumber
            repairOrderNo
            uploadDate
            uploadedBy
            thumbNail
            fileSize
         }
        }
      `,
      })
      .valueChanges
      .pipe(
        map(result => result.data.documentByRoId)
      );
  }

  documentByRoNumber(repairOrderNumber: String): Observable<any[]> {
    //console.log("documentByRoNumber");
    return this.apollo
      .watchQuery<any>({
        fetchPolicy: 'network-only', query: gql`
        query Query {
          documentByRoNumber(repairOrderNumber:1){
            documentClass {
              documentClassId
              documentClassName
            }
            documentExtension {
              documentExtensionId
              extentionName
            }
            documentId
            documentPath
            comments
            documentStorageName
            documentTitle
            keyWords
            primary
            repaiOrderNumber
            repairOrderNo
            uploadDate
            uploadedBy
            thumbNail
         }
        }
      `,
      })
      .valueChanges
      .pipe(
        map(result => result.data.documentByRoNumber)
      );
  }

  uploadFile(fileDetails, file: File) { 
    
    // Formdata to store files to send it 
    // as a multipart/form-data post request 
    const formData = new FormData(); 

    formData.append('file', file); 
    formData.append('fileDetails', JSON.stringify(fileDetails))
    return this.http.post(`${environment.fileTransferEndpoint}/upload`, formData, {
      responseType: 'text'
    }); 
  }

  uploadDocument(input, file: File): Observable<any> {
    //console.log("uploadDocument");
    return this.apollo
      .mutate<RepairOrder>({
        mutation: gql`
      mutation {
        uploadDocument(document:{
          documentTitle: "${input.documentTitle}",
          uploadedBy: "${input.uploadedBy}",
          repairOrderNo: ${input.repairOrderNo},
          documentPath : "${input.documentPath}",
          companyIdref: ${input.companyIdref},
          keyWords: "${input.keyWords}",
          documentClassId: ${input.documentClassId},
          documentExtensionId: ${input.documentExtensionId},
          repaiOrderNumber: "${input.repaiOrderNumber}",
          primary: ${input.primary},
          thumbNailPath : "@thumbNailPath",
          attachmentQuery: "${input.attachmentQuery}",
          comments: "${input.comments}"
         }) {
          documentId
          documentClass {
            documentClassId
            documentClassName
          }
          repairOrderNo
          documentTitle
          documentClassId
          thumbNail
          documentExtension {
            extentionName
            documentExtensionId
          }
          documentExtensionId
          documentStorageName
          keyWords
          uploadDate
          uploadedBy
          companyIdref
          companyIdrefNavigation {
            companyId
            companyName
            companyIdref
          }
        }
       }
      `,
        variables: { file: file }
      })
      .pipe(
        map(result => {

          return result.data;
        })
      );
  }

  deleteDocument(remoteFileName: string, companyID: number): Observable<any> {
      //console.log("deleteDocument");
    return this.apollo
      .mutate<RepairOrder>({
        mutation: gql`
      mutation {
        deleteDocument(fileName: "${remoteFileName}", companyID: ${companyID})
       }
      `
      })
      .pipe(
        map(result => {
          return result.data;
        })
      );
  }

  markRead(notificationID: number): Observable<any> {
      //console.log("markRead");
    return this.apollo
      .mutate<RepairOrder>({
        mutation: gql`
        mutation {
          markRead(notificationID: ${notificationID})
        }

        `
      })
      .pipe(
        map(result => {
          return result.data;
        })
      );
  }

  markUnRead(notificationID: number): Observable<any> {
        //console.log("markUnRead");
    return this.apollo
      .mutate<RepairOrder>({
        mutation: gql`
          mutation {
            markUnRead(notificationID: ${notificationID})
          }

          `
      })
      .pipe(
        map(result => {
          return result.data;
        })
      );
  }

  changeRepairOrderLineStatus(repairOrderLineId:number, statusId:number): Observable<boolean> {
    return this.apollo
      .mutate<ChangeRepairOrderLineStatusMutation>({
        mutation: gql`
        mutation {
          changeRepairOrderLineStatus(repairOrderLineId: ${repairOrderLineId}, statusId: ${statusId})
          }
        `,
      })
      .pipe(
        map(result => result.data.changeRepairOrderLineStatus)
      );
  }

  getNotifications(userName: string): Observable<AARNotification[]> {
      //console.log("getNotifications");
    return this.apollo
      .watchQuery<any>({
        fetchPolicy: 'network-only', query: gql`
        query getNotificationByUserID {
          notificationByUserID(userID: "${userName}") {
            notificationID
            subject
            body
            userName
            companyID
            flag
            createDate
            type
            isRead
            isSent
            isEmail
          }
        }
      `,
      })
      .valueChanges
      .pipe(
        map(result => result.data.notificationByUserID)
      );
  }

  getFileToDownload(remoteFileName, documentname, companyID): Observable<any> {
    const call_url = `${environment.fileTransferEndpoint}/download/${remoteFileName}/${companyID}`;
    return this.http
      .get(call_url, {
        responseType: 'blob'
      })
      .pipe(
        map(res => {
          return {
            filename: documentname,
            data: res
          };
        })
      );
  }

  updateRepairOrder(repairOrderUpdate): Observable<HoldTag[]> {
    return this.apollo
      .mutate<RepairOrderMutation>({
        mutation: gql`
        mutation repairOrderUpdate($repairOrderUpdate: UpdateRepairOrderInput!) {
          updateRepairOrder(repairOrderUpdate: $repairOrderUpdate) {
            repairOrderLine{
                holdTags{
                id,
                directionId,
                createDate,
                closedDate,
                sysCreateDate,
                comments
                }
          }
        }
      }
        `,
        variables: {
          repairOrderUpdate
        }
      })
      .pipe(map((result)=>{
        return result.data.updateRepairOrder.holdTags
    }));
  }

  removeTypename(value: any) {
    if (value === null || value === undefined) {
      return value;
    } else if (Array.isArray(value)) {
      return value.map(v => this.removeTypename(v));
    } else if (typeof value === 'object') {
      const newObj = {};
      Object.entries(value).forEach(([key, v]) => {
        if (key !== '__typename') {
          newObj[key] = this.removeTypename(v);
        }
      });
      return newObj;
    }
    return value;
  }

  GetContactByContactTypeId(contactTypeId : number): Observable<CommonContact[]> {
    return this.apollo
      .watchQuery<CommonContactsQuery>({
        query: gql`
        query contactsByContactTypeId($contactTypeId: Int!) {
            contactsByContactTypeId(contactTypeId: $contactTypeId) {
              ... CommonContactAttributes
            }
          }
          ${Fragments.CommonContact.attributes}
        `,
        variables:{ contactTypeId }
      })
      .valueChanges
      .pipe(
        map(result => result.data.contactsByContactTypeId)
      );
  }


  GetContact(contactTypeId : number): Observable<CommonContact[]> {
    return this.apollo
      .watchQuery<CommonContactsQuery>({
        query: gql`
        query contactsByContactTypeId($contactTypeId: Int!) {
            contactsByContactTypeId(contactTypeId: $contactTypeId) {
              ... CommonContactAttributes
            }
          }
          ${Fragments.CommonContact.attributes}
        `,
        variables:{ contactTypeId }
      })
      .valueChanges
      .pipe(
        map(result => result.data.contactsByContactTypeId)
      );
  }

  DeleteContactByContactId(contactId : number): Observable<boolean> {
    return this.apollo
      .mutate<CommonContactsQuery>({
        mutation: gql`
          mutation deleteContact($contactId: Int!) {
            deleteContact(contactId: $contactId)
          }
        `,
        variables:{ contactId }
      })
      .pipe(
        map(result => result.data.deleteContact)
      );
  }

  CreateContact(contactAdd: CommonContact): Observable<CommonContact>{
    return this.apollo
    .mutate<CommonContactsQuery>({
      mutation: gql`
        mutation contactAdd($contactAdd: NewContactInput!) {
          createContact(contactAdd: $contactAdd) {
            ...CommonContactAttributes
          }
        }
        ${Fragments.CommonContact.attributes}
      `,
      variables:{ contactAdd }
    })
    .pipe(
      map(result => result.data.createContact)
    );
  }

  UpdateContact(contactUpdate: CommonContact): Observable<CommonContact>{
    return this.apollo
    .mutate<CommonContactsQuery>({
      mutation: gql`
        mutation contactUpdate($contactUpdate: UpdateContactInput!) {
          updateContact(contactUpdate: $contactUpdate) {
            ...CommonContactAttributes
          }
        }
        ${Fragments.CommonContact.attributes}
      `,
      variables:{ contactUpdate }
    })
    .pipe(
      map(result => result.data.updateContact)
    );
  }
}
