import { Component, Input, OnChanges, OnDestroy } from '@angular/core';
import { QuoteReviewQuoteLine } from '@app/shared/services/api/api.models';
import { QuoteMutationApiService } from '@app/shared/services/api/quote-mutation-api/quote-mutation-api.service';
import { QuoteReviewUpdate, UpdateQuoteReview } from '@app/shared/services/api/quote-mutation-api/quote-mutation-models';
import { QuoteReviewEventService } from '@app/shared/services/rcm/event/event-service/quote-review/quote-review-event.service';
import { Subscription } from 'rxjs';
import { finalize, take } from 'rxjs/operators';

enum ActionButtons {
  pendingInternalAction = 0,
  approve = 1,
  query = 2,
}

@Component({
  selector: 'aar-rcm-quote-review',
  templateUrl: './rcm-quote-review.component.html',
  styleUrls: ['./rcm-quote-review.component.scss']
})
export class RcmQuoteReviewComponent implements OnChanges, OnDestroy {
  @Input('quoteLines') quoteLines: QuoteReviewQuoteLine[];

  private piaButtonTextDefault = 'Set Pending Internal Action';
  private piaButtonTextUnset = 'Remove Pending Internal Action';
  private externalSubscriptions = new Subscription();

  selectedQuoteLine: QuoteReviewQuoteLine = null;
  actionButtons = ActionButtons;
  piaButtonText = this.piaButtonTextDefault;
  pendingInternalActionWorking = false;
  disablePendingButton = true;


  constructor(
    private quoteMutationApiService: QuoteMutationApiService,
    private quoteReviewEventService: QuoteReviewEventService) { }


  ngOnChanges(): void {
    if (this.quoteLines && this.quoteLines.length > 0) {
      this.disablePendingButton = false;
      if (this.quoteLines[0].quote.pendingInternalActionDateTime) {
        this.piaButtonText = this.piaButtonTextUnset;
      } else {
        this.piaButtonText = this.piaButtonTextDefault;
      }
    } else {
      this.disablePendingButton = true;
    }
  }

  ngOnDestroy(): void {
    this.externalSubscriptions.unsubscribe();
  }

  onSelectedQuoteLine(selectedQuoteLine: QuoteReviewQuoteLine): void {
    if (this.selectedQuoteLine == selectedQuoteLine) {
      this.selectedQuoteLine = null
    } else {
      this.selectedQuoteLine = selectedQuoteLine;
    }
  }

  onClick(button: ActionButtons): void {
    if (button === ActionButtons.pendingInternalAction) {
      this.disablePendingButton = true;
      this.pendingInternalActionWorking = true;
      this.externalSubscriptions.add(this.quoteMutationApiService.updateQuoteReview(
        { repairOrderLineId: this.quoteLines[0].quote.repairOrderLineId } as QuoteReviewUpdate).pipe(take(1), finalize(() => {
          this.disablePendingButton = false, this.pendingInternalActionWorking = false
        })).subscribe((update:UpdateQuoteReview) => {
          this.quoteLines.forEach(x => x.quote.pendingInternalActionDateTime = update.pendingInternalActionDateTime);
          this.quoteReviewEventService.updatedQuoteLine.publish({ pendingInternalActionDateTime: update.pendingInternalActionDateTime });
          if (update.pendingInternalActionDateTime) {
            this.piaButtonText = this.piaButtonTextUnset;
          } else {
            this.piaButtonText = this.piaButtonTextDefault;
          }
        }));
    }
  }
}