import { Component, Input } from '@angular/core';
import * as XLSX from 'xlsx';
import * as _ from 'lodash';

const getFileName = (name: string) => {
  const timeSpan = new Date().toISOString();
  const sheetName = name || 'ExportResult';
  const fileName = `${sheetName}-${timeSpan}`;
  return {
    sheetName,
    fileName
  };
};

export interface ExcelExportColumnMap{
  dataColumn:string;
  columnName:string;
}

@Component({
  selector: 'aar-export-excel',
  styleUrls: ['export-excel.component.scss'],
  templateUrl: 'export-excel.component.html'
})
export class ExportExcelComponent {
  @Input() dataSource: any[];
  @Input() fileName: string;
  @Input() buttonTitle:string;
  @Input() columnMap:ExcelExportColumnMap[];
  
  working:boolean = false;

  exportArrayToExcel() {
  
    this.working = true;
  
    const { sheetName, fileName } = getFileName(this.fileName);
    const wb = XLSX.utils.book_new();
    const subset = _.map(this.dataSource, (item: any) => _.pick(item, this.columnMap.map(x=>x.dataColumn)));
    const ws = XLSX.utils.json_to_sheet(subset);
    const headers = [...this.columnMap.map(x=>x.columnName)];

    XLSX.utils.sheet_add_aoa(ws, [headers], { origin: "A1" });

    XLSX.utils.book_append_sheet(wb, ws, sheetName);
    XLSX.writeFile(wb, `${fileName}.xlsx`);
    
    this.working = false;
  }
}
