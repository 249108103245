<div style="border-bottom:1px solid #232328 "></div>
<table mat-table [dataSource]="dataSource" class="mat-elevation-z8" matSort>
  <ng-container matColumnDef="{{column.name}}" *ngFor="let column of displayColumns">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> {{column.header}} </th>
    <td mat-cell *matCellDef="let element" (click)="onSelectionChange(element)"> {{element[column.name]}} </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
  <tr mat-row *matRowDef="let row; columns: columnsToDisplay;" class="highlight-hover"
    [class.highlight]="isSelected(row)"></tr>
</table>
<mat-paginator [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions" showFirstLastButtons>
</mat-paginator>