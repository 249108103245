import { DatevalidationService } from './../../shared/services/datevalidation.service';
import { AuthService } from './../../auth/services/auth/auth.service';
import { formatDate } from '@angular/common';
import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, HostListener, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatExpansionPanel } from '@angular/material/expansion';
import { MatSnackBar } from '@angular/material/snack-bar';
import { getFormFielErrorMessage, isEmpty } from '@app/common/utility';
import { Pricing, RepairOrder, RepairOrderLine, User, Currency, WorkScope, HoldTag } from '@app/shared/services/api/api.models';
import { ApiService } from '@app/shared/services/api/api.service';
import { SharedService } from '@app/shared/services/shared.service';
import { Subscription } from 'rxjs';
import { PersonaService } from '@app/shared/services/rcm/persona.service';
import { ComponentType } from '@app/shared/services/rcm/persona.models';
import { DataLookupService } from '@app/shared/services/rcm/data-lookup/data-lookup.service';
import { pairwise, startWith } from 'rxjs/operators';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { LoadingLocalComponent } from '@app/shared/components/loading/loading-local.component';

import { User as AuthUser } from '@app/auth/auth.models';
import * as moment from 'moment';
import { getCurrencySymbol } from '@angular/common';
import { QuoteService } from '@app/shared/services/rcm/quote/quote.service';
import { DecimalLocalisedPipe } from '@app/shared/custom-pipe/decimal-pipe/decimal-localised.pipe';
import { ComponentPortal } from '@angular/cdk/portal';
import { RcmStatusStepperService } from '../rcm-stepper/rcm-status-stepper.service';
//Commented as part of bug #43045
//import { ActivityService } from '@app/shared/services/rcm/activity/activity.service';
import { URL_ARGUMENTS } from '@app/core/constants/globalConstants';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'aar-rcm-header',
  templateUrl: './rcm-header.component.html',
  styleUrls: ['./rcm-header.component.scss']
})
export class RcmHeaderComponent implements OnInit, OnDestroy {
  @Input('roDetails') roDetails: RepairOrder;
  subscription: Subscription;
  cost: Pricing;
  user: AuthUser;
  assignedUser: User;
  panelOpenState: boolean;
  roEdit: boolean;
  priorities = [];
  workscopes: WorkScope[] = [];
  formattedWorkscope: string;
  currencies: Currency[];
  curSupplier: number;
  roTopForm: UntypedFormGroup;
  roDetailsForm: UntypedFormGroup;
  nonEditableFields: string[] = [];
  fieldsToHide: string[] = [];
  selectedTabIndex: number = 0;
  canEdit: boolean = true;
  isSupplier: boolean = true;
  isCustomer: boolean = true;
  private readonly documentTabIndex = 4;
  expand = false;
  /* Commented under #40361
  totalSupplierCost: string = '0.00';
  mismatchReasons = [];
  showFFPAndWarrantyOnSameLine:boolean = false;
  */

  showEditButton: boolean = true;
  personaService: PersonaService;
  formValidity: boolean = true;

  dynamicWarranty: boolean = false;
  disableEditBtn = false;

  /* Commented under #40361
  @ViewChild('customerPOVInputElement') customerPOVInputElement:ElementRef;
  */
  @ViewChild('customerRVInputElement') customerRVInputElement:ElementRef;
  
  readonly outboundSerialNumberControlName = 'outboundSerialNumber';

  /* Commented under #40361
  readonly quotedToCustomerDateControlName = 'quotedToCustomerDate';
  */
/* Commented under #40361
  readonly customerROCreatedDtControlName = 'customerROCreatedDt';
  
  readonly customerROReceivedDtControlName = 'customerROReceivedDt';

  readonly outboundHoldTagControlName = 'outboundholdTag';
  readonly outboundHoldTagCreateDateControlName = 'outboundHoldTagCreateDate';
  readonly outboundHoldTagClosedDateControlName = 'outboundHoldTagClosedDate';
  readonly outboundHoldTagCommentsControlName = 'outboundHoldTagComments';
  readonly inboundHoldTagControlName = 'inboundholdTag';
  readonly inboundHoldTagCreateDateControlName = 'inboundHoldTagCreateDate';
  readonly inboundHoldTagClosedDateControlName = 'inboundHoldTagClosedDate';
  readonly inboundHoldTagCommentsControlName = 'inboundHoldTagComments';

  readonly grCompleteControlName = 'gRComplete'; 
  readonly contractedControlName = 'contracted';
  readonly directShipControlName = 'directShip';
  
  readonly totalSupplierCostControlName = 'totalSupplierCost';
  readonly customerPOVControlName = 'customerPOValue';
  readonly outboundSerialNumberChangeReasonIdControlName = 'outboundSerialNumberChangeReasonId';
  */

  readonly aogControlName = 'aOG';
  readonly aogAircraftTailNumberControlName = 'aogAircraftTailNumber';
  readonly aogAircraftSerialNumberControlName = 'aogAircraftSerialNumber';
   
  readonly warrantyControlName = 'warranty';
 
  readonly customerRVControlName = 'customerResponsibleValue';

  readonly accountControlName = 'account';
  readonly programmeControlName = 'programme';
  readonly legalEntityControlName = 'legalEntity';
  readonly obUnitCategoryControlName = 'outboundUnitCategory';
  readonly obWorkscopeControlName = 'outboundWorkscope';
  readonly obPartNumberControlName = 'outboundPartNumber';
  readonly obDescriptionControlName = 'outboundDescription';
  readonly tailNumberControlName = 'tailNumber';
  readonly scrdControlName = 'supplierConfirmedReceiptDate';
  readonly priorityControlName = 'priority';
  readonly requirementsControlName = 'requirements';
  readonly grossTatControlName = 'grossTat';
  readonly netTatControlName = 'netTat';
  readonly removalLocationControlName = 'removalLocation';
  readonly supplierCodeControlName = 'supplierCode';
  readonly oqpdControlName = 'originalQuoteProcessedDate';
  readonly lastActionDateControlName = 'lastActionDate';
  readonly lastActionUserIdControlName = 'lastActionUserId';
  readonly customerAccountNameControlName = 'customerAccountName';
  
/* Commented under #40361
  readonly dateControlNames = [
    this.customerROCreatedDtControlName
    
    this.customerROReceivedDtControlName
    
  ];*/

  readonly usDateFormat = 'M/d/yyyy';
  readonly usLocale = 'en-US';

  readonly dateValuesOnit = [];
  hasChanged = false;
  initialValue: any;
  today = new Date();

  /* Commented under #40361
  private quotedCustomerDateChangeSubscription: Subscription;

  private latestOutboundHoldTag: HoldTag;
  private latestInboundHoldTag: HoldTag;
  private hasOutboundHoldTag: boolean;
  private hasInboundHoldTag: boolean;
  private outboundHoldTagControl: AbstractControl;
  private inboundHoldTagControl: AbstractControl;
  private outboundHoldTagCreateDateControl: AbstractControl;
  private inboundHoldTagCreateDateControl: AbstractControl;
  private outboundHoldTagClosedDateControl: AbstractControl;
  private inboundHoldTagClosedDateControl: AbstractControl;
  private outboundHoldTagCommentsControl: AbstractControl;
  private inboundHoldTagCommentsControl: AbstractControl;

  private HOLD_TAG_OUTBOUND_DIRECTION_ID = 1;
  private HOLD_TAG_INBOUND_DIRECTION_ID = 2;

  */

  toggleComponentsWithCommonValueChanges = [
    /* Commented under #40361
    this.contractedControlName,
    this.directShipControlName,
    this.grCompleteControlName,
    */
    this.warrantyControlName
  ];

  toggleComponentsWithIndividualValueChanges = [
    this.aogControlName,
    /* Commented under #40361  
    this.inboundHoldTagControlName,
    this.outboundHoldTagControlName,
    */
    this.outboundSerialNumberControlName
  ];

  private overlayRef: OverlayRef;

  constructor(private api: ApiService, private snackBar: MatSnackBar, private sharedService: SharedService,
    private authService: AuthService, private dateValidator: DatevalidationService,
    private dataLookupService: DataLookupService,
    private quoteService:QuoteService,
    private decimalLocalisedPipe: DecimalLocalisedPipe,
    private statusStepperService: RcmStatusStepperService,
    //Commented as part of bug #43045
    //private activityService:ActivityService,
    public overlay: Overlay,
    private route: ActivatedRoute,
    ) {
    this.user = this.authService.getLoggedUser();
    this.personaService = new PersonaService(this.user.personaName);
    this.overlayRef = this.overlay.create({
      positionStrategy: this.overlay.position().global().centerHorizontally().centerVertically(),
      hasBackdrop: true});
  }

  ngOnInit(): void {
    this.panelOpenState = false;

    const defaultToDocumentTab = this.route.snapshot.queryParamMap.get(URL_ARGUMENTS.NavigateToDocumentTab);
    if(defaultToDocumentTab){
      this.panelOpenState = true;
      this.expand = true;
      this.selectedTabIndex = this.documentTabIndex;
    }

    this.roEdit = false;
    this.curSupplier = 0;
    this.fieldsToHide = this.personaService.getHiddenFields(ComponentType.RCMHeader);

    this.nonEditableFields = this.personaService.getNonEditableFields(ComponentType.RCMHeader);

    this.roTopForm = new UntypedFormGroup({
      assignedUserID: new UntypedFormControl({ value: this.roDetails.assignedUserID, disabled: false }, [Validators.required])
    });

    if (this.roDetails) {


      this.getCosts();
      this.assignedUser = this.dataLookupService.cachedUserForUserId(this.roDetails.assignedUserID);
      this.workscopes = this.dataLookupService.cachedWorkscopes;
      /* Commented under #40361
      this.mismatchReasons = this.dataLookupService.cachedMismatchReasonsForRepairOrderSerialNumberChanged();
      */
      this.currencies = this.dataLookupService.cachedCurrencies;

      /* Commented under #40361  
      this.formattedWorkscope = this.dataLookupService.cachedWorkscopeForId(this.roDetails.repairOrderLine.workScope.id).formattedName;
      this.latestOutboundHoldTag = this.getLatestHoldTag(this.HOLD_TAG_OUTBOUND_DIRECTION_ID);
      this.latestInboundHoldTag = this.getLatestHoldTag(this.HOLD_TAG_INBOUND_DIRECTION_ID);
      this.hasOutboundHoldTag = this.hasHoldTag(this.HOLD_TAG_OUTBOUND_DIRECTION_ID);
      this.hasInboundHoldTag = this.hasHoldTag(this.HOLD_TAG_INBOUND_DIRECTION_ID);
      */
    }

    this.canEdit = this.user.roleName.toUpperCase() === "CUSTOMER" || this.user.roleName.toUpperCase() === "SUPPLIER" ? false : true;
    this.showEditButton = this.canEdit && !this.personaService.hideEditButton();

    this.isCustomer = this.user.roleName.toUpperCase() === "SUPPLIER" ? false : true;
    this.isSupplier = this.user.roleName.toUpperCase() === "CUSTOMER" ? false : true;

    /* Commented under #40361
    this.showFFPAndWarrantyOnSameLine = userRoleName === ROLE_NAMES.CUSTOMER;
    */

    this.roTopForm = new UntypedFormGroup({
      assignedUserID: new UntypedFormControl({ value: this.roDetails.assignedUserID, disabled: false }, [Validators.required])
    });

    // Add form controls
    this.roDetailsForm = this.createFormGroupControls();

    /* Commented under #40361
    let masterQuotedToCustomerDate: any = null;
    if (this.roDetails) {
      if (this.roDetails.repairOrderLine) {
        if (this.roDetails.repairOrderLine.masterQuotedToCustomerDate) {
          masterQuotedToCustomerDate = formatDate(this.roDetails.repairOrderLine.masterQuotedToCustomerDate, this.usDateFormat, this.usLocale);
        }
      }
    }
      
    this.roDetailsForm.controls[this.quotedToCustomerDateControlName].setValue(masterQuotedToCustomerDate);
    */

    this.dynamicWarranty = this.roDetails.warranty;
    this.effectRoEdit(false);
    this.getOnitDateValues(this.roDetails);

    Object.keys(this.roDetailsForm.controls).forEach(key => {
      this.roDetailsForm.get(key).disable();
    });

    /* Commented under #40361
    this.subscribeToOutboundSerialNumberValueChanges();


    this.prepDateValidation();
    */
    this.sharedService.setSelectedRepairOrderHasWarranty(this.roDetails.warranty);

    /* Commented under #40361
    this.subscribeToQuotedToCustomer();
    this.subscribeToTotalSupplierCostFromQuote();
    */

    this.subscribeToAogToggleValueChanges();

    this.subscribeToGroupFormValueChanges();

    /* Commented under #40361
    this.createCustomerValueOperations();
    */

    this.subscribeToToggleControlCommonValueChanges();

    /* Commented under #40361  
    this.createReferencesToHoldTagControls();
    this.subscribeToHoldTagControlChanges();
    */
  }

  private createFormGroupControls(): UntypedFormGroup {
    return new UntypedFormGroup({
      partNumber: new UntypedFormControl({ value: this.roDetails.partNumber, disabled: true },
        { updateOn: 'blur', validators: [Validators.required] }),
      description: new UntypedFormControl({ value: this.roDetails.repairOrderLine.description, disabled: true },
        { updateOn: 'blur', validators: [Validators.required] }),
      serialNumber: new UntypedFormControl({ value: this.roDetails.serialNumber, disabled: true },
        { updateOn: 'blur', validators: [Validators.required] }),
      quantity: new UntypedFormControl({ value: this.roDetails.quantity, disabled: true },
        { updateOn: 'blur', validators: [Validators.required] }),
      warranty: new UntypedFormControl({ value: this.roDetails.warranty, disabled: true },
        { validators: [Validators.required] }),
      repairOrderDefect: new UntypedFormControl({ value: this.roDetails.repairOrderDefect, disabled: true },
        { updateOn: 'blur', validators: [Validators.required] }),
      supplierName: new UntypedFormControl({ value: this.roDetails.supplier.name, disabled: true },
        { updateOn:'blur', validators: [Validators.required] }),
        /* Commented under #40361
      supplierLocation: new FormControl({ value: this.roDetails.supplier.location, disabled: true },
        { updateOn: 'blur', validators: [Validators.required] }),
         */
      workScopeID: new UntypedFormControl({ value: this.roDetails.workScopeID, disabled: true },
        { updateOn: 'blur', validators: [Validators.required] }),
        /* Commented under #40361
      totalSupplierCost: new FormControl({ value: this.totalSupplierCost, disabled: true },
        { updateOn: 'blur', validators: [Validators.required] }),
      contracted: new FormControl({ value: this.roDetails.repairOrderLine.contract, disabled: true }),
        */
      airCraftType: new UntypedFormControl({ value: this.roDetails.repairOrderLine.aircraftTypeId, disabled: true },
        { updateOn: 'blur', validators: [Validators.required] }),
      supplierPromisedShipDt: new UntypedFormControl({ value: moment(this.roDetails.repairOrderLine.supplierPromisedShipDt).utc(true),
        disabled: true }, { updateOn: 'blur' }),
      closedDate: new UntypedFormControl({ value: moment(this.roDetails.repairOrderLine.closedDate).utc(true), disabled: true },
      { updateOn: 'blur' }),
      customerAccountName: new UntypedFormControl({ value: this.roDetails.customerAccountName, disabled: true },
        {validators: Validators.maxLength(100), updateOn: 'blur'}),
      customerReference: new UntypedFormControl({ value: this.roDetails.repairOrderLine.customerReference, disabled: false },
        {validators: Validators.maxLength(50),updateOn: 'blur'}),
          /* Commented under #40361
      customerROCreatedDt: new FormControl({ value: moment(this.roDetails.customerROCreatedDate).utc(true),
        disabled: false },{ updateOn: 'blur' }),
        */
      customerAccountManager: new UntypedFormControl({ value: this.roDetails.customerAccountName, disabled: true }, { updateOn: 'blur' }),
      /* Commented under #40361
      gRComplete: new FormControl({ value: this.roDetails.repairOrderLine.gRComplete, disabled: true }),
      inboundGRComplete: new FormControl({ value: this.roDetails.repairOrderLine.inboundGRComplete ? moment(this.roDetails.repairOrderLine.inboundGRComplete).utc(true) : null, disabled: true }),
      outboundGRCompleteDate: new FormControl({ value: this.roDetails.repairOrderLine.outboundGRCompleteDate ? moment(this.roDetails.repairOrderLine.outboundGRCompleteDate).utc(true):null, disabled: true }),
      inboundGRCompleteDate: new FormControl({ value: this.roDetails.repairOrderLine.inboundGRCompleteDate, disabled: true }),
      */
      /* Commented under #40361
      supplierRMANumber: new FormControl({ value: this.roDetails.repairOrderLine.supplierRMANumber, disabled: false },
        {validators: Validators.maxLength(50), updateOn: 'blur'}),
      */
      aOG: new UntypedFormControl({ value: this.roDetails.repairOrderLine.aOG, disabled: false }),
      aogAircraftTailNumber: new UntypedFormControl({ value: this.roDetails.repairOrderLine.aogAircraftTailNumber, disabled: false },
        {updateOn: 'blur'}),
      aogAircraftSerialNumber: new UntypedFormControl({ value: this.roDetails.repairOrderLine.aogAircraftSerialNumber, disabled: false },
        {updateOn: 'blur'}),
      /* Commented under #40361
      directShip: new FormControl({ value: this.roDetails.repairOrderLine.directShip, disabled: false }),
      */
       /* Commented under #40361
      customerPOValue: new FormControl({ value: this.decimalLocalisedPipe.transform(this.roDetails.repairOrderLine.customerPOValue),
        disabled: false }, { updateOn: 'blur' }),
      customerPOValueCurrency: new FormControl({ value: this.roDetails.repairOrderLine.customerPOValueCurrency, disabled: false },
        { updateOn: 'blur' }),
      customerResponsibleValue: new FormControl({ value: this.decimalLocalisedPipe
        .transform(this.roDetails.repairOrderLine.customerResponsibleValue), disabled: false }, { updateOn: 'blur' }),
      customerResponsibleValueCurrency: new FormControl({ value: this.roDetails.repairOrderLine.customerResponsibleValueCurrency,
        disabled: false }, { updateOn: 'blur' }),
      customerRMANumber: new FormControl({ value: this.roDetails.repairOrderLine.customerRMANumber, disabled: false },
        {validators: Validators.maxLength(50), updateOn: 'blur'}),
      customerROReceivedDt: new FormControl({ value: this.roDetails.repairOrderLine.customerROReceivedDt ? moment(this.roDetails.repairOrderLine.customerROReceivedDt).utc(true) : null,
        disabled: false }, { updateOn: 'blur' }),
        */
      roNumber: new UntypedFormControl({ value: this.roDetails.repairOrderNumber, disabled: true }, { updateOn: 'blur' }),
      roCreatedAt: new UntypedFormControl({ value: moment(this.roDetails.roCreatedAt).utc(true), disabled: true }, { updateOn: 'blur' }),
      repairLineStatus: new UntypedFormControl({ value: this.roDetails.repairOrderLine.repairLineStatus, disabled: true }, { updateOn: 'blur' }),
      statusDescription: new UntypedFormControl({ value: this.roDetails.repairOrderLine.repairLineStatusDescription, disabled: true },
        { updateOn: 'blur' }),
      quoteStatus: new UntypedFormControl({ value: this.roDetails.repairOrderLine.quoteStatusDescription, disabled: true }, { updateOn: 'blur' }),
      logisticStatus: new UntypedFormControl({ value: this.roDetails.repairOrderLine.logisticStatus, disabled: true }, { updateOn: 'blur' }),
      outboundSerialNumber: new UntypedFormControl({ value: this.roDetails.repairOrderLine.outboundSerialNumber, disabled: false }, {validators: Validators.maxLength(30), updateOn: 'blur'}),

      /* Commented under #40361
      outboundSerialNumberChangeReasonId: new FormControl({ value: this.roDetails.repairOrderLine.outboundSerialNumberChangeReason,
        disabled: true }, { updateOn: 'blur'}),
        */
      inboundUnitCategory: new UntypedFormControl({ value: this.roDetails.repairOrderLine.inboundUnitCategory, disabled: true },
        { updateOn: 'blur' }),
      /* Commented under #40361
      poNumber: new FormControl({ value: this.roDetails.pONumber, disabled: true }, {updateOn: 'blur'}),
      

      customerInternalRefNumberDate: new FormControl({value: this.roDetails.customerInternalRefNumberDate
        ? formatDate(this.roDetails.customerInternalRefNumberDate, this.usDateFormat, this.usLocale) : undefined, disabled: false},
        { updateOn: 'blur' })
        
      quotedToCustomerDate: new FormControl({value: null}, {updateOn: 'blur'}),
      outboundholdTag: new FormControl({ value: this.hasOutboundHoldTag, disabled: false}),
      inboundholdTag: new FormControl({ value: this.hasInboundHoldTag, disabled: false}),
      outboundHoldTagCreateDate: new FormControl({ value: this.latestOutboundHoldTag.createDate != null
        ? moment(this.latestOutboundHoldTag.createDate).utc(true) : null, disabled: true }, {updateOn: 'blur'}),
      inboundHoldTagCreateDate: new FormControl({ value: this.latestInboundHoldTag.createDate != null
        ? moment(this.latestInboundHoldTag.createDate).utc(true) : null, disabled: true }, {updateOn: 'blur'}),
      outboundHoldTagClosedDate: new FormControl({ value: this.latestOutboundHoldTag.closedDate != null
        ? moment(this.latestOutboundHoldTag.closedDate).utc(true) : null, disabled: true }, {updateOn: 'blur'}),
      inboundHoldTagClosedDate: new FormControl({ value: this.latestInboundHoldTag.closedDate != null
        ? moment(this.latestInboundHoldTag.closedDate).utc(true) : null, disabled: true }, {updateOn: 'blur'}),
      outboundHoldTagComments: new FormControl({ value: this.latestOutboundHoldTag != null
        ? this.latestOutboundHoldTag.comments
        : null, disabled: true }, {validators: Validators.maxLength(1000), updateOn: 'blur'}),
      inboundHoldTagComments: new FormControl({ value: this.latestInboundHoldTag != null
        ? this.latestInboundHoldTag.comments
        : null, disabled: true }, {validators: Validators.maxLength(1000), updateOn: 'blur'}),
        */


        tailNumber: new UntypedFormControl({value:this.roDetails.repairOrderLine.tailNumber,disabled:false}),
        outboundUnitCategory: new UntypedFormControl({value:this.roDetails.repairOrderLine.outboundUnitCategory?.value,disabled:false}),
        outboundDescription: new UntypedFormControl({value:this.roDetails.repairOrderLine.outboundPartDescription,disabled:false}),
        outboundPartNumber: new UntypedFormControl({value: this.roDetails.repairOrderLine.outboundPartNumber,disabled:false}),
        outboundWorkscope: new UntypedFormControl({value:this.roDetails.repairOrderLine.outboundWorkScope?.name,disabled:false}),
        priority: new UntypedFormControl({value:this.roDetails.repairOrderLine.priority,disabled:false}),
        requirements: new UntypedFormControl({value:this.roDetails.repairOrderLine.workRequired,disabled:false}),
        removalLocation: new UntypedFormControl({value:this.roDetails.repairOrderLine.removalLocation,disabled:false}),
        supplierConfirmedReceiptDate: new UntypedFormControl({value:this.roDetails.repairOrderLine.supplierConfirmedReceiptDateTime,disabled:false}),
        originalQuoteProcessedDate: new UntypedFormControl({value:this.roDetails.repairOrderLine.originalQuoteProcessedDateTime,disabled:false}),
        grossTat: new UntypedFormControl({value:this.roDetails.repairOrderLine.gtat, disabled:false}),
        netTat: new UntypedFormControl({value:this.roDetails.repairOrderLine.ntat, disabled:false}),
        lastActionDate: new UntypedFormControl({value:null, disabled:false}),
        lastActionUserId: new UntypedFormControl({value:null, disabled:false})

    });
  }

  subscribeToGroupFormValueChanges() {
    Object.keys(this.roDetailsForm.value).forEach(key => {
      if (!this.toggleComponentsWithCommonValueChanges.includes(key) && !this.toggleComponentsWithIndividualValueChanges.includes(key)) {
        this.roDetailsForm.controls[key].valueChanges.pipe(startWith(this.getFormControl(key).value), pairwise())
          .subscribe(([prev, cur]) => {
            this.hasChanged = prev !== cur;
          });
      }
    });
  }

  private subscribeToToggleControlCommonValueChanges() {
    this.toggleComponentsWithCommonValueChanges.forEach(key => {
      this.roDetailsForm.controls[key].valueChanges.pipe(startWith(this.getFormControl(key).value), pairwise()).subscribe(([prev, cur]) => {
        this.hasChanged = prev !== cur;
        if (this.hasChanged) {
          this.saveRODetails();
        }
      });
    });
  }

 
  private subscribeToAogToggleValueChanges() {
    this.roDetailsForm.controls[this.aogControlName].valueChanges
      .pipe(startWith(this.getFormControl(this.aogControlName).value), pairwise()).subscribe(([prev, cur]) => {
        if (prev === cur) {
          return;
        }

        const aogAircraftTailNumberControl = this.roDetailsForm.controls[this.aogAircraftTailNumberControlName];
        const aogAircraftSerialnumberControl = this.roDetailsForm.controls[this.aogAircraftSerialNumberControlName];

        this.updateControlState(aogAircraftTailNumberControl, cur, [Validators.required, Validators.maxLength(50)], '');
        this.updateControlState(aogAircraftSerialnumberControl, cur, [Validators.required, Validators.maxLength(50)], '');
        if (cur) {
          aogAircraftTailNumberControl.markAsTouched();
          aogAircraftSerialnumberControl.markAsTouched();
        }
        this.saveRODetails();
    });
  }

 

  ngOnDestroy(): void {
    /* Commented under #40361
    this.quotedCustomerDateChangeSubscription.unsubscribe();
    */
    this.overlayRef.dispose();
  }

  getCurrencySymbology(controlName:string):string{
    const currencyISOName = this.currencies.find(x=>x.id === this.roDetailsForm.controls[controlName].value).name;
    const symbol = getCurrencySymbol(currencyISOName,'narrow');
    return symbol;
  }

  

  getOnitDateValues(values: RepairOrder) {
    this.dateValuesOnit.push(values.repairOrderLine.customerROCreatedDt)
    /* Commented under #40361
    this.dateValuesOnit.push(values.repairOrderLine.customerROReceivedDt);
    */
  }

 /* Commented under #40361
  prepDateValidation(){
    this.dateControlNames.forEach((element, index) => {
      this.roDetailsForm.controls[element].valueChanges.subscribe((changeValue) => {
        const resp = this.dateValidator.validateDateIsBeforeOrEqualToDate(changeValue, new Date());
        if(resp){
          this.getFormControl(element).setErrors(null);
        }else{
          this.getFormControl(element).setErrors({'invalid': true});
        }
      })
    });
  }
  */

  getFormControl(controlName:string):UntypedFormControl{
    return this.roDetailsForm.get(controlName) as UntypedFormControl;
  }

  togglePanelOpen(): void {
    this.panelOpenState = !this.panelOpenState;
  }

  toggleRoEdit(isToggleOn: boolean) {
    if (this.disableEditBtn) {
      this.openSnackBar('Incorrect data. Please correct data before turning off Editor.');
      return;
    }

    this.roEdit = isToggleOn;
    this.effectRoEdit(this.roEdit);
  }

  effectRoEdit(save:boolean): void {
    // enable or disable fields based on roEdit flag
    if (save) {
      Object.keys(this.roDetailsForm.controls).forEach(key => {
        // skip non editable fields
        if (!this.nonEditableFields.includes(key)) {
            this.roDetailsForm.get(key).enable();
        }

        const isAog = this.roDetailsForm.controls[this.aogControlName].value;
        if (!isAog) {
          this.roDetailsForm.controls[this.aogAircraftTailNumberControlName].disable();
          this.roDetailsForm.controls[this.aogAircraftSerialNumberControlName].disable();
        }

        /* Commented under #40361  
        this.setStateOfOutboundHoldTagControls(this.outboundHoldTagControl.enabled);
        this.setStateOfInboundHoldTagControls(this.inboundHoldTagControl.enabled);
        */

      });
    } else {
      if(this.formValidity || this.formValidity === undefined){
        Object.keys(this.roDetailsForm.controls).forEach(key => {
          this.roDetailsForm.get(key).disable();
        });
      }
    }
  }

 

  expandPanel(matExpansionPanel: MatExpansionPanel, event: Event) {
    event.stopPropagation();

    if (!this._isExpansionIndicator(event.target)) {
      matExpansionPanel.toggle();
    }
  }

  private _isExpansionIndicator(target): boolean {
    const expansionIndicatorClass = 'mat-expansion-indicator';
    return (target.classList && target.classList.contains(expansionIndicatorClass));
  }

  getCosts(): void {
    //Fix this later
    //this.cost = this.roDetails.historicalPricingList.length ?
    //   this.roDetails.historicalPricingList[this.roDetails.historicalPricingList.length - 1] :
    // {id: null, contracted: 'n/a', cost: 0, date: null, supplierID: null, supplierName: null, supplier: null};
    this.cost = { id: null, contracted: 'n/a', cost: 0, date: null, supplierID: null, supplierName: null, supplier: null };
  }

  hasError(form: UntypedFormGroup, control: string): boolean {
    return !form.controls[control].valid;
  }

  getErrorMessage(form: UntypedFormGroup, control: string): string {
    return getFormFielErrorMessage(form, control);
  }

  saveRODetails(): void {
    if (!this.checkFormValid()) {
       return;
     }

     this.overlayRef.detach();
     this.overlayRef.attach(new ComponentPortal(LoadingLocalComponent));

     /* Commented under #40361
     let customerPOV = this.roDetailsForm.getRawValue().customerPOValue;
     let customerRV = this.roDetailsForm.getRawValue().customerResponsibleValue;
     
     if (isNaN(customerPOV)) {
      customerPOV = this.removeNonNumericCharacters(customerPOV);
     }
     
     if (isNaN(customerRV)) {
      customerRV = this.removeNonNumericCharacters(customerRV);
     }
      */

    const tmpModel = {
      'repairOrderLineId': this.roDetails.repairOrderLineId,
      'repairOrderId': this.roDetails.repairOrderId,
      'repairOrderNumber': this.roDetails.repairOrderNumber,
      'description': this.roDetailsForm.getRawValue().description,
      'partNumber': this.roDetailsForm.getRawValue().partNumber,
      'repairOrderStatusID': this.roDetails.repairOrderStatusID,
      'approvalStatusID': this.roDetails.approvalStatusID,
      'notes': '', // this.roDetails.notes,
      'quantity': parseInt(this.roDetailsForm.getRawValue().quantity),
      'workScopeID': this.roDetailsForm.getRawValue().workScopeID,
      'repairOrderDefect': this.roDetailsForm.getRawValue().repairOrderDefect,
      'serialNumber': this.roDetailsForm.getRawValue().serialNumber,
      'tailNumber': this.roDetails.repairOrderLine.tailNumber,
      'warranty': this.roDetailsForm.getRawValue().warranty,
      'gtat': this.roDetails.repairOrderLine.gtat,
      'ntat': this.roDetails.repairOrderLine.ntat,
      'createdAt': this.roDetails.roCreatedAt,
      'updatedAt': new Date(),
      'supplierId': this.curSupplier,
      /* Commented under #40361
      'contract': this.roDetailsForm.getRawValue().contracted ? 'Contracted' : '',
      */
      'airCraftType': this.roDetailsForm.getRawValue().airCraftType,
      'assignedUserID': this.roTopForm.getRawValue().assignedUserID,
      'cost': 100,

      /* 
      Commented under #40361
      customerROCreatedDt: this.roDetailsForm.getRawValue().customerROCreatedDt ? moment(this.roDetailsForm.getRawValue().customerROCreatedDt).utc(true) : null,
      */
      customerReference: this.roDetailsForm.getRawValue().customerReference,
      closedDate: moment().utc(true),
      supplierPromisedShipDt: this.roDetailsForm.getRawValue().supplierPromisedShipDt ? moment(this.roDetailsForm.getRawValue().supplierPromisedShipDt).utc(true):null,
      /*
      Commented under #40361
      directShip: this.roDetailsForm.getRawValue().directShip,
      */
      aOG: this.roDetailsForm.controls[this.aogControlName].value &&
        isEmpty(this.roDetailsForm.controls[this.aogAircraftTailNumberControlName].value) &&
        isEmpty(this.roDetailsForm.controls[this.aogAircraftSerialNumberControlName].value)
          ? this.roDetails.repairOrderLine.aOG : this.roDetailsForm.getRawValue().aOG,
      aogAircraftTailNumber: this.roDetailsForm.getRawValue().aogAircraftTailNumber,
      aogAircraftSerialNumber: this.roDetailsForm.getRawValue().aogAircraftSerialNumber,
      /*
          Commented under #40361
      supplierRMANumber: this.roDetailsForm.getRawValue().supplierRMANumber,
      */
      logisticStatus: "2345",
      //customerAccountName: this.roDetails.customerAccountName,
      /* Commented under #40361
      customerROReceivedDt: this.roDetailsForm.getRawValue().customerROReceivedDt ? moment(this.roDetailsForm.getRawValue().customerROReceivedDt).utc(true) : null,
      customerRMANumber: this.roDetailsForm.getRawValue().customerRMANumber,
      customerPOValue: Number(customerPOV),
      customerPOValueCurrency: this.roDetailsForm.getRawValue().customerPOValueCurrency,
      customerResponsibleValue: Number(customerRV),
      customerResponsibleValueCurrency: this.roDetailsForm.getRawValue().customerResponsibleValueCurrency,
      */
      outboundSerialNumber: this.roDetailsForm.getRawValue().outboundSerialNumber,
      /* Commented under #40361
      outboundSerialNumberChangeReason: this.roDetailsForm.getRawValue().outboundSerialNumberChangeReasonId,
      */
      /* Commented under #40361
      holdTags: [...this.roDetails.repairOrderLine.holdTags]
      */
    };

    /* Commented under #40361  
    if (this.outboundHoldTagControl.value && this.latestOutboundHoldTag.id === 0)  {
      tmpModel.holdTags.push(this.latestOutboundHoldTag);
    }

    if (this.inboundHoldTagControl.value && this.latestInboundHoldTag.id === 0)  {
      tmpModel.holdTags.push(this.latestInboundHoldTag);
    }
      */

    // I don't like this solution to omit the __typename property from the mutation request, but I couldn't find a better solution.
    // There is a method in api.service called removeTypename which I thought did the same thing, but it serializes object properties!
    // We do not want this as date properties are quite often Moment objects.
    this.api.updateRepairOrder(JSON.parse(JSON.stringify(tmpModel), this.omitTypename)).subscribe((holdTags:HoldTag[]) => {
      this.statusStepperService.currentStepChange(this.roDetails.repairOrderNumber, this.roDetails.rOLineNumber);
      //Commented as part of bug #43045
      //this.activityService.activityChangePublish(this.roDetails.repairOrderNumber, this.roDetails.rOLineNumber);

      this.dynamicWarranty = this.getFormControl('warranty').value;

      this.sharedService.setSelectedRepairOrderHasWarranty(this.dynamicWarranty);
      this.hasChanged = false;

      /* Commented under #40361  
      this.roDetails.repairOrderLine.holdTags = [...holdTags];
      this.latestOutboundHoldTag = this.getLatestHoldTag(this.HOLD_TAG_OUTBOUND_DIRECTION_ID);
      this.latestInboundHoldTag = this.getLatestHoldTag(this.HOLD_TAG_INBOUND_DIRECTION_ID);
      */

      const obSerialNumber = this.roDetailsForm.controls[this.outboundSerialNumberControlName].value;
      this.sharedService.checkSerialNumberPublish(obSerialNumber)    

      this.overlayRef.detach();
      this.openSnackBar('Repair Order successfully updated');
      }, error => {
      this.overlayRef.detach();
      this.openSnackBar('Error updating repair order. Please try again later.');
    });
  }

  private omitTypename(key, value) {
    return key === '__typename' ? undefined : value;
  }

  openSnackBar(message: string): void {
    this.snackBar.open(message, null, {
      duration: 3000
    });
  }

  updateRepairOrder(): void {
    this.api.updateRepairOrder(this.cleanUpRepairOrder())
      .subscribe(repairOrder => {
        //console.log(repairOrder);
      });  
  }

  cleanUpRepairOrder(): any {
    const roInput = { ...this.roDetails };
    delete roInput.repairOrderPriorityName;
    delete roInput.workScopeName;
    delete roInput.supplierName;
    delete roInput.repairOrderStatusName;
    delete roInput.supplier;
    // delete roInput.historicalPricingList;
    return this.api.removeTypename(roInput);
  }

  isFieldHidden(control: string): boolean {
    return this.fieldsToHide.includes(control);
  }

  truthy(value: string): boolean {
    return value === "true"
  }

  @HostListener('focusout')
  onFocusout() {
    if (this.hasChanged && this.roEdit) {
      this.saveRODetails();
    }
  }

  checkFormValid(): boolean {
    const invalidControls = [];
    for (const control in this.roDetailsForm.controls) {
      const controlToCheck = this.roDetailsForm.controls[control];
      if(controlToCheck.enabled){
        if (this.roDetailsForm.controls[control].errors) {
            console.log('invalid control value', control);
            invalidControls.push(control);
          }
        }
    }

    const result = invalidControls.length === 0;
    if (result) {
      this.roDetailsForm.markAllAsTouched();
    }
    this.formValidity = result;
    this.disableEditBtn = !(result);
    return result;
  }

  private updateControlState(control: AbstractControl, shouldEnable: boolean, validators: any, emptyValue: any): void {
    if (shouldEnable) {
      control.enable();
      control.setValidators(validators);
    } else {
      control.clearValidators();
      control.setValue(emptyValue);
      control.disable();
    }
    control.updateValueAndValidity();
  }

  /* Commented under #40361
  private subscribeToTotalSupplierCostFromQuote():void{
    this.quoteService.masterTotalSupplierCostSubscription().subscribe((masterTotalCost:string)=>{
      this.totalSupplierCost = masterTotalCost;
      this.getFormControl(this.totalSupplierCostControlName).setValue(masterTotalCost);
    });
  }

  private subscribeToQuotedToCustomer():void{
    this.quotedCustomerDateChangeSubscription = this.sharedService.getEmittedMasterOptionQuoteToCustomerDate().subscribe(
      value => {
        if (value) {
          this.getFormControl(this.quotedToCustomerDateControlName).setValue(formatDate(value, this.usDateFormat, this.usLocale));
        }
      }
    );
  }

  private subscribeToTotalSupplierCostFromQuote():void{
    this.quoteService.masterTotalSupplierCostSubscription().subscribe((masterTotalCost:string)=>{
      this.totalSupplierCost = masterTotalCost;
      this.getFormControl(this.totalSupplierCostControlName).setValue(masterTotalCost);
    });
  }

  private subscribeToQuotedToCustomer():void{
    this.quotedCustomerDateChangeSubscription = this.sharedService.getEmittedMasterOptionQuoteToCustomerDate().subscribe(
      value => {
        if (value) {
          this.getFormControl(this.quotedToCustomerDateControlName).setValue(formatDate(value, this.usDateFormat, this.usLocale));
        }
      }
    );
  }   
  private subscribeToOutboundSerialNumberValueChanges(): void {
    const outboundSerialNumberControl = this.roDetailsForm.controls[this.outboundSerialNumberControlName];
    const outboundSerialNumberChangeReasonIdControl = this.roDetailsForm.controls[this.outboundSerialNumberChangeReasonIdControlName];
    outboundSerialNumberControl.valueChanges.pipe(startWith(outboundSerialNumberControl.value), pairwise()).subscribe(([prev, cur]) => {

        if(prev !== cur)
        {
          if(!isEmpty(cur)){
            this.updateControlState(outboundSerialNumberChangeReasonIdControl,true,[Validators.required],'');
            outboundSerialNumberChangeReasonIdControl.markAsTouched();
            outboundSerialNumberChangeReasonIdControl.setValue('');
          }else{
            outboundSerialNumberChangeReasonIdControl.setValue('');
            outboundSerialNumberChangeReasonIdControl.disable();
          }
          outboundSerialNumberChangeReasonIdControl.updateValueAndValidity();
          this.saveRODetails();
        }
    });
  }
   
  private createReferencesToHoldTagControls(): void {
    this.outboundHoldTagControl = this.roDetailsForm.controls[this.outboundHoldTagControlName];
    this.inboundHoldTagControl = this.roDetailsForm.controls[this.inboundHoldTagControlName];
    this.outboundHoldTagCreateDateControl = this.roDetailsForm.controls[this.outboundHoldTagCreateDateControlName];
    this.inboundHoldTagCreateDateControl = this.roDetailsForm.controls[this.inboundHoldTagCreateDateControlName];
    this.outboundHoldTagClosedDateControl = this.roDetailsForm.controls[this.outboundHoldTagClosedDateControlName];
    this.inboundHoldTagClosedDateControl = this.roDetailsForm.controls[this.inboundHoldTagClosedDateControlName];
    this.outboundHoldTagCommentsControl = this.roDetailsForm.controls[this.outboundHoldTagCommentsControlName];
    this.inboundHoldTagCommentsControl = this.roDetailsForm.controls[this.inboundHoldTagCommentsControlName];
  }

  private subscribeToHoldTagControlChanges(): void {
    this.subscribeToOutboundHoldTagToggleChanges();
    this.subscribeToInboundHoldTagToggleChanges();
    this.subscribeToOutboundHoldTagCreateDateChanges();
    this.subscribeToInboundHoldTagCreateDateChanges();
    this.subscribeToOutboundHoldTagClosedDateChanges();
    this.subscribeToInboundHoldTagClosedDateChanges();
    this.subscribeToOutboundHoldTagCommentsChanges();
    this.subscribeToInboundHoldTagCommentsChanges();
  }

  private subscribeToOutboundHoldTagToggleChanges(): void {
    this.outboundHoldTagControl.valueChanges.pipe(startWith(this.outboundHoldTagControl.value), pairwise()).subscribe(([prev, cur]) => {
      if (prev === cur) {
        return;
      }

      if (cur) {
        if (this.outboundHoldTagCreateDateControl.value === null || !moment(this.outboundHoldTagCreateDateControl.value).isValid) {
          this.outboundHoldTagCreateDateControl.setValue(moment().utc(true));
        }
        this.updateControlState(this.outboundHoldTagCreateDateControl, true, Validators.required,
          this.outboundHoldTagCreateDateControl.value);
        this.outboundHoldTagClosedDateControl.enable();
        this.outboundHoldTagCommentsControl.enable();
      } else {
        this.updateControlState(this.outboundHoldTagCreateDateControl, false, null, null);
        this.outboundHoldTagClosedDateControl.disable();
        this.outboundHoldTagCommentsControl.disable();
      }
      this.latestOutboundHoldTag.createDate = this.outboundHoldTagCreateDateControl.value;
      this.saveRODetails();
    });
  }

  private subscribeToInboundHoldTagToggleChanges(): void {
    this.inboundHoldTagControl.valueChanges.pipe(startWith(this.inboundHoldTagControl.value), pairwise()).subscribe(([prev, cur]) => {
      if (prev === cur) {
        return;
      }

      if (cur) {
        if (this.inboundHoldTagCreateDateControl.value === null || !moment(this.inboundHoldTagCreateDateControl.value).isValid) {
          this.inboundHoldTagCreateDateControl.setValue(moment().utc(true));
        }

        this.updateControlState(this.inboundHoldTagCreateDateControl, true, Validators.required, null);
        this.inboundHoldTagClosedDateControl.enable();
        this.inboundHoldTagCommentsControl.enable();
      } else {
        this.updateControlState(this.inboundHoldTagCreateDateControl, false, null, null);
        this.inboundHoldTagClosedDateControl.disable();
        this.inboundHoldTagCommentsControl.disable();
      }
      this.latestInboundHoldTag.createDate = this.inboundHoldTagCreateDateControl.value;
      this.saveRODetails();
    });
  }

  private subscribeToOutboundHoldTagCreateDateChanges(): void {
    this.outboundHoldTagCreateDateControl.valueChanges.subscribe((newValue) => {
      this.latestOutboundHoldTag.createDate = moment(newValue).utc(true);
    });
  }

  private subscribeToInboundHoldTagCreateDateChanges(): void {
    this.inboundHoldTagCreateDateControl.valueChanges.subscribe((newValue) => {
      this.latestInboundHoldTag.createDate = moment(newValue).utc(true);
    });
  }

  private subscribeToOutboundHoldTagClosedDateChanges(): void {
    this.outboundHoldTagClosedDateControl.valueChanges.subscribe((newValue) => {
      this.latestOutboundHoldTag.closedDate = moment(newValue).utc(true);
    });
  }

  private subscribeToInboundHoldTagClosedDateChanges(): void {
    this.inboundHoldTagClosedDateControl.valueChanges.subscribe((newValue) => {
      this.latestInboundHoldTag.closedDate = moment(newValue).utc(true);
    });
  }

  private subscribeToOutboundHoldTagCommentsChanges(): void {
    this.outboundHoldTagCommentsControl.valueChanges.subscribe((newValue) => {
      this.latestOutboundHoldTag.comments = newValue;
    });
  }

  private subscribeToInboundHoldTagCommentsChanges(): void {
    this.inboundHoldTagCommentsControl.valueChanges.subscribe((newValue) => {
      this.latestInboundHoldTag.comments = newValue;
    });
  }

  private createCustomerValueOperations():void{
    const customerPOValueControl = this.getFormControl(this.customerPOVControlName);
    const customerRVControl = this.getFormControl(this.customerRVControlName);

    customerPOValueControl.registerOnDisabledChange(
      (change) =>
      this.updateCustomerValuesOnDisabledChangeListener(change, customerPOValueControl, this.customerPOVControlName));
    
    customerRVControl.registerOnDisabledChange(
      (change) =>
        this.updateCustomerValuesOnDisabledChangeListener(change, customerRVControl, this.customerRVControlName));
  }

  private updateCustomerValuesOnDisabledChangeListener(isDisabled:boolean, formControl:FormControl, controlName:string){

    const numberElementType = 'number';
    const textElementType = 'text'

    if(!isDisabled){
      let formattedValue = formControl.value;
      if(isNaN(formControl.value)){
         formattedValue = this.removeNonNumericCharacters(formControl.value);
      }
      formControl.setValue(formattedValue);

      this.setTypeElement(numberElementType, controlName);

    }else{
      this.setTypeElement(textElementType, controlName);

      let formattedValue = formControl.value;
      if(!isNaN(formControl.value)){
        formattedValue = this.decimalLocalisedPipe.transform(formControl.value);
      }

      formControl.setValue(formattedValue,{onlySelf:true, emitEvent:false});
    }
  }

  private setTypeElement(elementType:string, controlName:string): void{
    if(controlName === this.customerPOVControlName){
      this.customerPOVInputElement.nativeElement.type = elementType;
    }

    if(controlName === this.customerRVControlName){
      this.customerRVInputElement.nativeElement.type = elementType;
    }
  }

  private removeNonNumericCharacters(sourceString:string):number{
    const converted = sourceString.split(',').join('');
    return Number(converted);
  }
    
  private sortHoldTagsBySysCreateDate(directionId: number): HoldTag[] {
    return this.roDetails.repairOrderLine.holdTags
      .filter(h => h.directionId === directionId)
      .sort((a: HoldTag, b: HoldTag) => new Date(b.sysCreateDate).getTime() - new Date(a.sysCreateDate).getTime());
  }

  private hasHoldTag(directionId: number): boolean {
    return this.getLatestHoldTag(directionId).createDate != null;
  }

  private getLatestHoldTag(directionId: number): HoldTag {
    const holdTags = this.sortHoldTagsBySysCreateDate(directionId);
    return holdTags.length > 0
      ? holdTags[0]
      : <HoldTag> {
        id: 0,
        closedDate: null,
        comments: null,
        directionId: directionId,
        createDate: null,
        sysCreateDate: moment().utc(true).toDate()
      };
  }

  private setStateOfOutboundHoldTagControls(isEnabled: boolean): void {
    const hasOutboundHoldTag = this.outboundHoldTagControl.value;

    if (isEnabled && hasOutboundHoldTag) {
      this.outboundHoldTagClosedDateControl.enable();
      this.outboundHoldTagCommentsControl.enable();
      this.outboundHoldTagCreateDateControl.enable();
      this.outboundHoldTagCreateDateControl.setValidators(Validators.required);
    } else {
      this.outboundHoldTagClosedDateControl.disable();
      this.outboundHoldTagCommentsControl.disable();
      this.outboundHoldTagCreateDateControl.disable();
      this.outboundHoldTagCreateDateControl.clearValidators();
    }
  }

  private setStateOfInboundHoldTagControls(isEnabled: boolean): void {
    const hasinboundHoldTag = this.inboundHoldTagControl.value;

    if (isEnabled && hasinboundHoldTag) {
      this.inboundHoldTagClosedDateControl.enable();
      this.inboundHoldTagCommentsControl.enable();
      this.inboundHoldTagCreateDateControl.enable();
      this.inboundHoldTagCreateDateControl.setValidators(Validators.required);
    } else {
      this.inboundHoldTagClosedDateControl.disable();
      this.inboundHoldTagCommentsControl.disable();
      this.inboundHoldTagCreateDateControl.disable();
      this.inboundHoldTagCreateDateControl.clearValidators();
    }
  }
    */
}