<div class="rcm-upload-document">
  <h2 class="rcm-upload-document-title">Upload</h2>

  <div class="rcm-upload-document-body">
    <div class="rcm-upload-document-body-form">
      



      <div class="rcm-upload-document-body-form-row">
        <div>
          <mat-form-field>
            <mat-select placeholder="Tag" [(ngModel)]="tagOption">
              <mat-option [value]="tag.documentClassId" *ngFor="let tag of tagModel">{{tag.documentClassName}}</mat-option>
            </mat-select>
          </mat-form-field>

          <div class="rcm-comments">
            <div>COMMENTS</div><textarea matinput class="mat-textara-input" [(ngModel)]="comments"></textarea>
          </div>

        </div>

        <div class="rcm-drag" *ngIf="!fileSelected" aarNgFileDropDirective (fileDropped)="fileDrop($event)">
          <input type="file" class="hidden" #fileChooser name="testUpload" (change)="checkOnFileChoose($event)" />
          <span class="drag-tag">Drag & drop here to attach <br>or <button (click)="fileChooser.click()">choose a file on your computer</button></span>
        </div>

        <div class="rcm-drag new-doc" *ngIf="fileSelected" aarNgFileDropDirective (fileDropped)="fileDrop($event)">
          <div class="image-doc"></div>
          <p class="text-center">{{file.name}}</p>
        </div>

      </div>
      <aar-loading-local *ngIf="loading" [error]="loadingError"></aar-loading-local>

    </div>
  </div>

  <div class="rcm-upload-document-footer">
    <button mat-flat-button color="primary" class="aar-button" (click)="uploadDocument()">Upload</button>
    <button mat-button color="primary" (click)="onClose()">Cancel</button>
  </div>
</div>
