import { Component, Input, OnChanges } from '@angular/core';
import { QuoteReviewQuoteLine } from '@app/shared/services/api/api.models';

interface CostBreakdown {
  chargeCodeDescription: string,
  chargeValue: string,
  currency: string;
}

@Component({
  selector: 'aar-rcm-quote-review-details-cost-breakdown',
  templateUrl: './rcm-quote-review-details-cost-breakdown.component.html',
  styleUrls: ['./rcm-quote-review-details-cost-breakdown.component.scss']
})

export class RcmQuoteReviewDetailsCostBreakdownComponent implements OnChanges {
  @Input('selectedQuoteLine') selectedQuoteLine: QuoteReviewQuoteLine;

  costBreakdown: CostBreakdown[] = [];

  columnsToDisplay = ['chargeCodeDescription', 'chargeValue', 'currency'];
  isLoading = false;
  displayColumns = [
    { name: "chargeCodeDescription", header: "Charge Type" },
    { name: "chargeValue", header: "Charge Amount" },
    { name: "currency", header: "Currency" }
  ];

  ngOnChanges(): void {
    if (this.selectedQuoteLine?.charges) {
      this.costBreakdown = this.selectedQuoteLine.charges.map((charge) => {
        return {
          chargeCodeDescription: charge.description,
          chargeValue: charge.value ? charge.value.toFixed(2) : '',
          currency: charge.currency
        } as CostBreakdown;
      });
    }
  }
}