import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InternalContactsComponent } from './internal-contacts/internal-contacts.component';
import { RouterModule } from '@angular/router';
import { ContactTableComponent } from './contact-table/contact-table.component';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { MatSortModule } from '@angular/material/sort';
import { MatLegacyPaginatorModule as MatPaginatorModule } from '@angular/material/legacy-paginator';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacySlideToggleModule as MatSlideToggleModule } from '@angular/material/legacy-slide-toggle';
import { CustomerContactsComponent } from './customer-contacts/customer-contacts.component';
import { SupplierContactsComponent } from './supplier-contacts/supplier-contacts.component';
import { AddEditContactComponent } from './add-edit-dialog/add-edit-contact.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ToggleGroupModule } from '@app/shared/components';
import { AppMaterialModule } from '@app/app-material.module';
import { SharedModule } from '@app/shared.module';
import { AddEditContactDialogComponent } from './add-edit-contact-dialog/add-edit-contact-dialog.component';

@NgModule({
    declarations: [
        CustomerContactsComponent,
        SupplierContactsComponent,
        InternalContactsComponent,
        ContactTableComponent,
        AddEditContactComponent,
        AddEditContactDialogComponent
    ],
    imports: [
        CommonModule,
        MatTableModule,
        MatSortModule,
        MatPaginatorModule,
        RouterModule,
        MatButtonModule,
        MatDialogModule,
        FormsModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatIconModule,
        MatButtonModule,
        ToggleGroupModule,
        AppMaterialModule,
        MatSlideToggleModule,
        SharedModule,
        ToggleGroupModule,
    ]
})
export class ContactModule { }
