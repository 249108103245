import { Component, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { AuthService } from '@app/auth/services/auth/auth.service';

export interface LoginAcceptance {
  userId: number;
  hasAcceptedTermsAndConditions: boolean;
  hasAcceptedPrivacyPolicy: boolean;
}

@Component({
  selector: 'aar-terms-conditions-privacy-acceptance',
  templateUrl: './terms-conditions-privacy-acceptance.component.html',
  styleUrls: ['./terms-conditions-privacy-acceptance.component.scss']
})

export class TermsConditionsPrivacyAcceptanceComponent {

  privacyPolicyNotice = 'Privacy Notice';
  termsAndConditionsNotice = 'Terms & Conditions of Use';

  loginAcceptance: LoginAcceptance = {
    userId: -1,
    hasAcceptedPrivacyPolicy: false,
    hasAcceptedTermsAndConditions: false
  };

  hasLinkBeenClicked = false;
  showConfirmation = false;
  showNotice = false;
  showOkButton = false;
  showYesNoButtons = true;
  hasSaveError = false;
  isSaving = false;
  showPrivacyPolicyAcceptance = false;
  showTermsAndConditionsAcceptance = false;

  getNotice() {
    if (!this.loginAcceptance.hasAcceptedTermsAndConditions) {
      return this.termsAndConditionsNotice;
    } else {
      return this.privacyPolicyNotice;
    }
  }

  constructor(
    public dialogRef: MatDialogRef<TermsConditionsPrivacyAcceptanceComponent>,
    @Inject(MAT_DIALOG_DATA) data: LoginAcceptance,
    private authService: AuthService) {
      this.loginAcceptance = data;
      this.showTermsAndConditionsAcceptance = !this.loginAcceptance.hasAcceptedTermsAndConditions;
      this.showPrivacyPolicyAcceptance = !this.showTermsAndConditionsAcceptance;
    }

  onYes() {
    this.hasSaveError = false;
    this.isSaving = true;
    const databaseSystemLoginAcceptance: LoginAcceptance = {
      userId: this.loginAcceptance.userId,
      hasAcceptedPrivacyPolicy: this.loginAcceptance.hasAcceptedPrivacyPolicy,
      hasAcceptedTermsAndConditions: this.loginAcceptance.hasAcceptedTermsAndConditions
    };
    if (this.showTermsAndConditionsAcceptance) {
      databaseSystemLoginAcceptance.hasAcceptedTermsAndConditions = true;
    } else {
      databaseSystemLoginAcceptance.hasAcceptedPrivacyPolicy = true;
    }

    this.authService.updateUserAcceptance(databaseSystemLoginAcceptance)
      .subscribe((acceptanceResult: boolean) => {
        if (acceptanceResult) {
          if (this.showTermsAndConditionsAcceptance) {
            this.loginAcceptance.hasAcceptedTermsAndConditions = true;
            this.displayPrivacyAcceptance();
          } else {
            this.loginAcceptance.hasAcceptedPrivacyPolicy = true;
            this.displayConfirmation();
          }
          this.isSaving = false;
          this.hasLinkBeenClicked = false;
        } else {
          this.hasSaveError = true;
          this.isSaving = false;
        }
      });
  }

  onNo() {
    this.hasLinkBeenClicked = false;
    this.displayNotice();

    this.showOkButton = this.showConfirmation || this.showNotice;
    this.showYesNoButtons = !this.showOkButton;
  }

  onOk() {
    this.dialogRef.close(this.loginAcceptance);
  }

  onTermsAndConditionsLinkClick() {
    this.hasLinkBeenClicked = true;
  }

  onPrivacyPolicyLinkClick() {
    this.hasLinkBeenClicked = true;
  }

  getHyperlinkTooltip() {
    if (this.hasLinkBeenClicked) {
      return '';
    } else {
      return `Please click on the '${this.getNotice()}' hyperlink`;
    }
  }

  displayConfirmation() {
    this.showConfirmation = true;
    this.showNotice = false;
    this.showPrivacyPolicyAcceptance = false;
    this.showTermsAndConditionsAcceptance = false;
    this.showOkButton = true;
    this.showYesNoButtons = false;
  }

  displayNotice() {
    this.showNotice = true;
    this.showConfirmation = false;
    this.showPrivacyPolicyAcceptance = false;
    this.showTermsAndConditionsAcceptance = false;
    this.showOkButton = true;
    this.showYesNoButtons = false;
  }

  displayPrivacyAcceptance() {
    this.showPrivacyPolicyAcceptance = true;
    this.showTermsAndConditionsAcceptance = false;
    this.showConfirmation = false;
    this.showNotice = false;
    this.showOkButton = false;
    this.showYesNoButtons = true;
  }

  displayTermsAndConditionsAcceptance() {
    this.showTermsAndConditionsAcceptance = true;
    this.showPrivacyPolicyAcceptance = false;
    this.showConfirmation = false;
    this.showNotice = false;
    this.showOkButton = false;
    this.showYesNoButtons = true;
  }
}
