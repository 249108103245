<div class="">
  <div class="card__content card__element">
    <div class="aar-communication-height">
      <div class="aar-timeline-filter">
        <label for="sort-select">Sort By</label>
        <mat-form-field floatLabel="never">
          <mat-select [(ngModel)]="selectedActivityFilter" (ngModelChange)="sortActivityByDate()">
            <mat-option *ngFor="let option of activityFilters" [value]="option.value">
              {{option.text}}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <div class="form-check form-check-inline">
          <label for="notify-toggle">Notify Me</label>
          <label class="toggle-switch">
            <input type="checkbox" id="notify-toggle" [(ngModel)]="notifyMe">
            <span class="slider round"></span>
          </label>
          <div style="display:inline-block">{{notifyMe?'Yes':'No'}}</div>
        </div>
        <div class="export">
          <!-- <aar-export-excel [dataSource]="filteredActivities" [fileName]="title" [displayedColumns]="displayedColumns">
          </aar-export-excel> -->
        </div>
      </div>
      <div class="aar-timeline">
        <div class="aar-timeline-line"></div>
        <ul class="aar-timeline-items">
          <li class="aar-timeline-item" *ngFor="let activity of filteredActivities">
              <div class="aar-timeline-icon">
                <mat-icon *ngIf="activity.activityDate">check</mat-icon>
              </div>
              <div class="aar-timeline-details">
                <p class="aar-timeline-text">{{activity.activityType.description.toUpperCase()}} </p>
                <span class="aar-timeline-date">{{activity.displayDate ? activity.displayDate : 'N/A'}}</span>
              </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <aar-loading-local *ngIf="loading" [error]="loadingError"></aar-loading-local>
</div>