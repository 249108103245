import { Component, OnInit, NgModule, ViewChild, ElementRef, OnDestroy, Renderer2 } from '@angular/core';

import { AppMaterialModule } from '@app/app-material.module';
import { ActivatedRoute, NavigationEnd, Router, RouterModule, Scroll } from '@angular/router';
import { BreadcrumbModule } from '@app/shared/components/breadcrumb/breadcrumb.component';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatExpansionPanel } from '@angular/material/expansion';
import { SidePanelRightComponent, SidePanelModule } from '../side-panel-right/side-panel-right.component';

import { Observable, fromEvent, Subject } from 'rxjs';
import { debounceTime, tap } from 'rxjs/operators';
import { Subscription } from 'apollo-client/util/Observable';
import { SharedService } from '@app/shared/services/shared.service';
import { CommonModule } from '@angular/common';
import { AuthService } from '@app/auth/services/auth/auth.service';


@Component({
  selector: 'aar-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {

  @ViewChild('panelexpand') seachPanel: MatExpansionPanel;
  @ViewChild('searchContainer') _elRef: ElementRef;

  private listening = false;
  private eventSubscription: Subscription = null;
  private searchString : string = null;

  legalEntity:string;

  private subject: Subject<string> = new Subject<string>();
  showSearch: boolean = true;
  constructor(
    private dialog: MatDialog, renderer: Renderer2,
    private sharedServices: SharedService,
    public router: Router,
    private route: ActivatedRoute,
    private authService: AuthService) {
      let currentRoute = (this.route.snapshot as any)._routerState.url;
      this.showSearch = this.isURLSearchable(currentRoute);
      this.router.events.subscribe(val => {
        if(val instanceof NavigationEnd){
          this.showSearch = this.isURLSearchable(val.url);
        }
      });
    }

  private isURLSearchable(urlFragment: string) : boolean
  {
    let searchableURLs: string[] = ['/rcm', '/history', '/supplier', '/contacts/internals', '/contacts/customers', '/contacts/suppliers'];
    return searchableURLs.includes(urlFragment.split('?')[0]);
  }

  ngOnInit() {
    // this.eventSubscription = fromEvent(document, 'click').pipe(debounceTime(10), tap(() => this.listening = true))
    // .subscribe((event: MouseEvent) => {
    //   this.documentClick(event);
    // });
    
    this.legalEntity = this.authService.getCompanyDetails().legalEntity;

    this.subject.pipe(
      debounceTime(250)
    ).subscribe(x => this.triggerSearch(x));

  }

  expandPanel() {
    this.seachPanel.expanded = true;
  }

  contractPanel() {
    this.seachPanel.expanded = false;
  }

  seachListItemClick() {
    this.contractPanel();
    SidePanelRightComponent.show(this.dialog);
  }

  isDescendant(parent, child) {
    let node = child;
    while (node !== null) {
      if (node === parent) {
        return true;
      } else {
        node = node.parentNode;
      }
    }
    return false;
  }
  triggerSearch(searchString: string)
  {
     this.sharedServices.search(searchString);
  }

  onKeyUp(searchTextValue: string){
    this.subject.next(searchTextValue);
  }

  documentClick(event: MouseEvent) {
    if (event instanceof MouseEvent && this.listening === true) {
      if (this.isDescendant(this._elRef.nativeElement, event.target) === true) {
        // Do something
      } else {
        this.contractPanel();
      }
    }
  }

  ngOnDestroy(): void {
    if (this.listening === true) {
      this.eventSubscription.unsubscribe();
    }
  }
}

@NgModule({
  imports: [ AppMaterialModule, RouterModule, BreadcrumbModule, SidePanelModule, CommonModule ],
  exports: [ HeaderComponent],
  declarations: [ HeaderComponent]
})
export class HeaderModule {}
