import { SelectionModel } from '@angular/cdk/collections';
import { CommonModule } from '@angular/common';
import {
  Component, EventEmitter, Input, NgModule, OnChanges, OnInit, Output,
  SimpleChanges, ViewChild
} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { RouterModule } from '@angular/router';
import { AuthService } from '@app/auth/services/auth/auth.service';
import { PERSONA_NAMES } from '@app/core/constants/persona-constants';
import { SharedModule } from '@app/shared.module';
import { RepairOrder } from '@app/shared/services/api/api.models';
import { LoadingService } from '@app/shared/services/loading/loading.service';
import { DataTableService } from './data-table.service';
import { TableFilterComponent, TableFilterModule } from './table-filter/table-filter.component';


@Component({
  selector: 'aar-data-table',
  templateUrl: './data-table.component.html',
  styleUrls: ['./data-table.component.scss']
})

export class DataTableComponent implements OnInit, OnChanges {

  @Input() title;
  @Input() data = [];
  @Input() columnsSettings = [];
  @Input() selectable = false;
  @Input() selectOnClick = false;
  @Input() multiSelect = true;
  @Input() pageSizeOptions = [5, 10, 20];
  @Input() filterSettings = null;
  @Input() showPagination = true;
  @Input() showHeader = true;
  @Input() linkPath = '';
  @Input() highlightSelection = true;
  @Input() highlightHover = true;
  @Input() oversizeRow = false;
  @Input() showAvatar = false;
  @Input() openButton = false;
  @Input() enableROSearch = false;
  @Input() loading: boolean;
  @Input() repairOrderLineIdColumnName: string;
  @Output() clickItem = new EventEmitter();
  @Output() selectionChange = new EventEmitter();
  searchQuery: string;
  displayedColumns = [];
  excelColumns = [];
  roleName: string = "";
  pageSize = 10;
  dataSource = new MatTableDataSource<any>(this.data);
  selection = new SelectionModel<any>(true, []);
  currentPersona: string = "";
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  hoveredRepairOrderLineId:number;

  constructor(private dataTableService: DataTableService, private authService: AuthService, private loadingService: LoadingService) { }

  ngOnInit() {
    this.selection = new SelectionModel<any>(this.multiSelect, []);
    if (!this.repairOrderLineIdColumnName) {
      this.repairOrderLineIdColumnName = 'repairOrderLineId';
    }
    //This sets the role which can be used to apply filers
    this.roleName = this.authService.getLoggedUser().roleName;

    this.displayedColumns = this.columnsSettings.map(item => item.name);
    this.excelColumns = this.columnsSettings.map(item => item.name !== 'clipIcon' ? item.name : null);
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.currentPersona = this.authService.getLoggedUser().personaName.toUpperCase();
    this.dataSource.filterPredicate = (data: any, filterSettings: any) => {
      let match = true;
      for (const key in filterSettings) {
        if (key !== 'searchQuery' && filterSettings[key] && filterSettings[key] !== data[key]) {
          match = false;
          break;
        } else if (key === 'searchQuery' && filterSettings[key] &&
          data.repairOrderNumber.indexOf(filterSettings[key]) < 0 &&
          data.partNumber.indexOf(filterSettings[key]) < 0 &&
          data.serialNumber.indexOf(filterSettings[key]) < 0 &&
          data.repairOrderLine.directShip.indexOf(filterSettings[key]) < 0) {
          match = false;
          break;
        }
      }
      return match;
    };

    this.dataSource.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'supplier': return item.supplier.name;
        case 'assignedUser': return item.assignedUser.name;
        default: return item[property];
      }
    };

    this.dataTableService.onFilterUpdate$
      .subscribe(tableFilters => {
        if (this.filterSettings) {
          this.applyFilter(tableFilters);
        }
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    this.loadingService.stopLoading();
    const { filterPredicate, sortingDataAccessor } = this.dataSource;
    this.dataSource = new MatTableDataSource(this.data);
    this.dataSource.filterPredicate = filterPredicate;
    this.dataSource.sortingDataAccessor = sortingDataAccessor;
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    if (this.selectable && this.displayedColumns[0] !== 'select') {
      this.displayedColumns = ['select', ...this.displayedColumns];
      this.excelColumns = ['select', ...this.displayedColumns];
      const iconIdx = this.excelColumns.find(icon=>icon === 'Document(s)');
      if(iconIdx > -1){
        this.excelColumns.splice(iconIdx,1);
      }
    }
    this.fillFilters();
    this.loadingService.stopLoading();
  }

  setHover(repairOrderLineId:number|null){
    this.hoveredRepairOrderLineId = repairOrderLineId;
  }

  isHovered(repairOrderLineId:number|null):boolean{
    return repairOrderLineId === this.hoveredRepairOrderLineId;
  }

  /** Fill the grid filters but limit the options to the distinct available values */
  fillFilters() {
    const reverseOrderFilters = ['directShip', 'cancelationRequired', 'grComplete', 'inboundGrComplete', 'logisticStatus'];
    if (this.filterSettings && this.dataSource.filteredData && this.dataSource.filteredData.length > 0) {

      this.filterSettings.forEach(element => {
        const reverseSort = reverseOrderFilters.indexOf(element.name) > -1;
        element.options = Array.from(new Set(
          this.dataSource.filteredData.map(function (item)
          {
            return {
              value: item[element.name],
              label: item[element.name]
            } }))).filter((obj, pos, arr) => { return arr.map(mapObj => mapObj['label']).indexOf(obj['label']) === pos; })
            .sort((a, b) => (reverseSort ? (a.label< b.label) : (a.label > b.label)) ? 1 : -1); //Reverse the sort direction if the filter name appears on the list of filters to do in reverse (most likely because they are yes/no filters)
      });
    }
  }

  applyFilter(filters) {
    this.dataSource.filter = filters;
    this.fillFilters();
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row));
    this.selectionChange.emit(this.selection.selected);
  }

  isNotification() {
    return this.columnsSettings.filter((item) => {
      return (item.type === 'notify');
    }).length > 0;
  }

  selectItem(item, commonClick = true) {

    if (commonClick) {
      this.clickItem.emit(item);
      if (this.selectOnClick) {
        this.selection.toggle(item);
        this.selectionChange.emit(this.selection.selected);
      }
    }
  }

  capitalizeText(text): string {
    if (text) {
      const regex = /_/g;
      const cleanText = text.replace(regex, ' ');
      return cleanText.toLowerCase()
        .split(' ')
        .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
        .join(' ');
    }
  }

  calcTATAverage(element: RepairOrder): number {
    return Math.round((element.repairOrderLine.gtat + element.repairOrderLine.ntat) / 2);
  }

  alignStatus(roStatus: string): string {
    if ('QUOTE_PROCESSING' === roStatus) {
      return 'Under Evaluation';
    }

    return this.capitalizeText(roStatus);
  }

  isRowClickable(element, column) {
    const isClickable = element[this.repairOrderLineIdColumnName] >= 0 && column.name !== 'select';
    return isClickable;
  }

  getRepairOrderNumber(element) {
    let repairOrderNumber = ''
    if (this.repairOrderLineIdColumnName === 'repairOrderLineId') {
      repairOrderNumber = element.repairOrderNumber
    }
    return repairOrderNumber;
  }

  truncateDisplayValue(elementValue, length) {
    return elementValue.toString().substring(0, length);
  }
}

@NgModule({
  imports: [
    CommonModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    MatInputModule,
    MatFormFieldModule,
    TableFilterModule,
    MatSelectModule,
    MatCheckboxModule,
    MatButtonModule,
    ReactiveFormsModule,
    RouterModule,
    FormsModule,
    MatIconModule,
    MatProgressSpinnerModule,
    SharedModule
  ],
  providers: [DataTableService],
  exports: [DataTableComponent, TableFilterComponent],
  declarations: [
    DataTableComponent
  ]
})
export class DataTableModule { }
