import { Component, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Observable } from 'apollo-link';

@Component({
  selector: 'aar-rcm-add-communication',
  templateUrl: './rcm-add-communication.component.html',
  styleUrls: ['./rcm-add-communication.component.scss']
})
export class RcmAddCommunicationComponent implements OnInit {
  option = 'option';

  public static openDialog(dialog: MatDialog) {
    const dialogRef = dialog.open(RcmAddCommunicationComponent);
    return dialogRef.afterClosed(); // .subscribe(result => { });
  }


  constructor(public dialogRef: MatDialogRef<RcmAddCommunicationComponent>) { }

  ngOnInit() { }

  onClose() {
    this.dialogRef.close();
  }

}
