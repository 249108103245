import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from '@app/shared/services/api/api.service';
import { Email, QuoteLine, QuoteReviewQuoteLine, RepairOrder, Status } from '@app/shared/services/api/api.models';
import { SharedService } from '@app/shared/services/shared.service';
import { LoadingService } from '@app/shared/services/loading/loading.service';
import { Subscription } from 'rxjs';
import { interval } from 'rxjs';
import { MatLegacyTabGroup as MatTabGroup } from '@angular/material/legacy-tabs';
import { zip } from 'rxjs';
import { AuthService } from './../../auth/services/auth/auth.service';
import { User } from '@app/auth/auth.models';
import { DataLookupService } from '@app/shared/services/rcm/data-lookup/data-lookup.service';
import { PERSONA_NAMES, ROLE_NAMES } from '@app/core/constants/persona-constants';
import { RcmStatusStepperService } from '../rcm-stepper/rcm-status-stepper.service';
import { RcmStepType } from '@app/core/constants/enums';
//Commented as part of bug #43045
//import { ActivityService } from '@app/shared/services/rcm/activity/activity.service';

@Component({
  selector: 'aar-rcm-details',
  templateUrl: './rcm-details.component.html',
  styleUrls: ['./rcm-details.component.scss']
})

export class RcmDetailsComponent implements OnInit, OnDestroy {
  @ViewChild('tabs') tabs: MatTabGroup;
    
  cases: RepairOrder[];
  quoteLines:QuoteReviewQuoteLine[]=[];
  repairOrderDetails: RepairOrder;
  repairOrderCommunications: any[];
  loading: boolean;
  message: String;
  emailRefresh: Subscription;
  subscription: Subscription;
  steps:string[];
  currentStepSelected: number;
  stepError: boolean;
  roStatuses: Status[];
  stepsXs: boolean;
  selectedTabsIndex = 0;
  roLine: number;
  roNum: string;
  roLineNumber:number;
  user: User;
  RcmStepType = RcmStepType;
  canEdit: boolean = true; //TODO: Can this share the same one in rcm-header.component.ts ?
  repairOrderNumberAndLine:string;
  
  quoteProcessingDisplayedColumns = [
    { name: 'repairOrderNumber', header: 'Repair Order #' },
    { name: 'status', header: 'Status' },
    { name: 'priority', header: 'Priority' },
  ];

  casesDisplayedColumns = [
    { name: 'caseType', header: 'Type' },
    { name: 'caseStatus', header: 'Status' },
    { name: 'createdDate', header: 'Date' },
    { name: 'currentOwner', header: 'Owner' }
  ];

  statuses = {
    NEW_RO: 0,
    UNIT_SHIPPED_OUTBOUND: 1,
    SUPPLIER_RECEIPT: 2,
    QUOTE_PROCESSING: 3,
    QUOTE_REVIEW: 4,
    QUOTE_APPROVED: 4,
    RECEIPT_INSPECTION: 5,
    IN_FINAL_REPAIR: 5,
    UNIT_SHIPPED_INBOUND: 6,
    'RECEIPT_&_CLOSE': 7,
    RO_CLOSED: 8,
  };

  constructor(
    private router: Router,
    private apiService: ApiService,
    private sharedService: SharedService,
    private loadingService: LoadingService,
    private route: ActivatedRoute,
    private authService: AuthService,
    private datalookupService: DataLookupService,
    private statusStepperService: RcmStatusStepperService
    //Commented as part of bug #43045
    //private activityService: ActivityService
  ) {
    this.repairOrderNumberAndLine = this.route.snapshot.queryParamMap.get('ro');
    this.roNum = this.route.snapshot.queryParamMap.get('ro');
    var hyphen = this.roNum.lastIndexOf("-");
    if (hyphen > -1) {
      this.roNum = this.roNum.substring(0, hyphen);
      const lineNumber = this.route.snapshot.queryParamMap.get('ro').substring(hyphen+1);
      this.roLineNumber = parseInt(lineNumber);
    }
    this.roLine = parseInt(this.route.snapshot.queryParamMap.get('rol'));
    this.subscription = this.sharedService.getMessage().subscribe(message => {
      this.message = message;
      if (this.message == 'EmailSent') {
        this.getCommunications();
      }
    });
    this.user = this.authService.getLoggedUser();
  }

  ngOnInit() {    
    this.canEdit = [
      PERSONA_NAMES.BUYER,
      PERSONA_NAMES.CAM,
      PERSONA_NAMES.WAREHOUSE,
      PERSONA_NAMES.PROGRAMMANAGER,
    ].includes(this.user.personaName) || this.user.roleName === ROLE_NAMES.SUPPLIER;

    this.loadingService.startLoading();
    this.loading = true;
    this.stepsXs = false;
    this.stepError = false;
    this.steps = [
      'new ro',
      'shipping',
      'supplier receipt',
      'quote processing',
      //'quote approval',
      'quote review',
      'in final repair',
      'receipt & close',
      'ro closed',
    ];

    this.route.queryParams
      .subscribe(params => {
        return params;
      }
      );
    const apiCalls = zip(
      this.apiService.getRepairOrderByLineNumber(this.roLine, this.roNum),
      this.apiService.getProcessedQuotes(this.roNum, this.roLineNumber.toString()),
      this.apiService.getStatuses(),
      this.apiService.getEmailByRO(this.roNum),
      this.datalookupService.getLookupData()
    );

    const that = this;
    apiCalls.subscribe(([repairOrder, quoteLines, statuses, communications, lookupResult]) => {
      // Repair order.
      that.repairOrderDetails = repairOrder;
      that.setStep(that.statuses[that.repairOrderDetails.repairOrderStatusName], true);

      this.quoteLines = quoteLines;
      
      //statuses
      this.roStatuses = statuses;

      // communications
      that.repairOrderCommunications = communications

      if(!lookupResult){
        //This should be recorded somewhere.  Until that's implemented
        //show this in the console
      }

      this.statusStepperService.currentStepChange(repairOrder.repairOrderNumber, repairOrder.rOLineNumber);

      // loading
      that.loading = false;
      that.loadingService.stopLoading();
      //Commented as part of bug #43045
      //this.activityService.activityChangePublish(repairOrder.repairOrderNumber, repairOrder.rOLineNumber);
    });

    this.emailRefresh = interval(120000).subscribe(x => {
      try {
        this.getCommunications();
      } catch {
      }
    });
  }

  ngOnDestroy() {
    // unsubscribe to ensure no memory leaks
    this.subscription.unsubscribe();
    this.emailRefresh.unsubscribe();
  }


  getCommunications(): void {
    this.apiService.getEmailByRO(this.roNum)
      .subscribe(communications => this.repairOrderCommunications = communications,
        error => {
          console.log(error);
        }
      );
  }

  prevStep() {
    if (this.currentStepSelected > 0) {
      this.currentStepSelected--;
    }
  }

  nextStep() {
    if (this.currentStepSelected < this.steps.length) {
      this.currentStepSelected++;
    }
  }

  setStep(step, fistTime = false) {
    // TODO this should be only this.currentStepSelected = step;
    // This below is for demo only

    if (isNaN(step) && step === 'error') {
      if (!this.stepError) {
        this.toggleStepError();
      }
    } else if (isNaN(step) && step === 'remove error') {
      if (this.stepError) {
        this.toggleStepError();
      }
    } else {
      this.currentStepSelected = step;
      if (this.stepError) {
        this.toggleStepError();
      }
    }

    if (!fistTime) {
      this.updateStatus();
    }

    this.realignInkBarForSelectedTab();
  }

  // Re-aligns the ink bar to the selected tab element.
  realignInkBarForSelectedTab() {
    setTimeout(() => {
      if (this.tabs) {
        this.tabs.realignInkBar();
      }
    }, 100);
  }

  toggleStepError() {
    this.stepError = !this.stepError;
  }

  toggleXs() {
    this.stepsXs = !this.stepsXs;
  }


  //Demo only
  hideContent(visibleStep = 2): boolean {
    //return this.currentStepSelected < visibleStep;
    return false;
  }
  
  updateStatus(): void {
    if (this.roStatuses) {
      const status = this.steps[this.currentStepSelected].toUpperCase().replace(' ', '_');
      let selectStatusId = null;

      if (this.currentStepSelected === 7) {
        this.roStatuses.map(roStatus => {
          if (roStatus.name === 'RECEIPT_INSPECTION') {
            selectStatusId = roStatus.id;
          }
        });
      } else {
        this.roStatuses.map(roStatus => {
          if (roStatus.name === status) {
            selectStatusId = roStatus.id;
          }
        });
      }
      if (selectStatusId) {
        this.apiService.changeRepairOrderLineStatus(Number(this.repairOrderDetails.repairOrderLineId), selectStatusId)
          .subscribe(() => {
          }, error => {
            console.log(`ROL Status Change Error: ${error}`);
          });
      }
    }
  }
}
