import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { CriticalStatRepairOrder, CriticalStats, RcmRepairOrder, RepairOrder, RepairOrderLineCriticalStat, ShippingType } from '@app/shared/services/api/api.models';
import { User } from '@app/auth/auth.models';
import { ApiService } from '@app/shared/services/api/api.service';
import { LoadingService } from '@app/shared/services/loading/loading.service';
import { RcmService } from '@app/shared/services/rcm/rcm.service';
import { DataTableService } from '@app/shared/components/data-table/data-table.service';
import { AuthService } from '@app/auth/services/auth/auth.service';
import { SharedService } from '@app/shared/services/shared.service';
import { CriticalStatsService } from '@app/shared/services/criticalstats.service';
import { ICriticalStatsCard } from '@app/core/models/critical-stats-card';
import { PERSONA_NAMES, ROLE_NAMES } from '@app/core/constants/persona-constants';
import { CRITICAL_STATS_IDS, CUSTOMER_STATS, SUPPLIER_STATS, REPAIR_MANAGER_STATS } from '@app/core/constants/critical-stats-constants';
import { of, forkJoin, Subscription } from 'rxjs';
import { PersonaService } from '@app/shared/services/rcm/persona.service';
import { ComponentType } from '@app/shared/services/rcm/persona.models';
import { isEmpty } from '@app/common/utility';
import { DataLookupService } from '@app/shared/services/rcm/data-lookup/data-lookup.service';
import { TableFilterConfig } from '@app/shared/components/data-table/table-filter/table-filter.component';
import { REPAIRORDERLINE_STATUS_DESCRIPTIONS } from '@app/core/constants/globalConstants';
import { finalize, map, observeOn, switchMap, take, tap } from 'rxjs/operators';
import { DocumentQueryApiService } from '@app/shared/services/api/document-query-api/document-query-api.service';
import { DocumentEventService } from '@app/shared/services/rcm/event/event-service/document/document-event.service';
import { DocumentUpdate } from '@app/shared/services/rcm/event/event-service/document/event-model/document-update';
import { RepairOrderEventService } from '@app/shared/services/rcm/event/event-service/repairorder/repairorder-event.service';
import { NO_VALUE_TEXT, RcmGridColumnDetails, RcmGridData } from './rcm-data-table/rcm-data-table/rcm-data-table.component';

@Component({
  selector: "aar-rcm",
  templateUrl: "./rcm.component.html",
  styleUrls: ["./rcm.component.scss"]
})
export class RcmComponent implements OnInit, OnDestroy {
  repairOrders: RcmRepairOrder[] = [];
  rcmGridOrders: RcmGridData[] = [];
  repairOrdersFilteredForGrid: RcmRepairOrder[] = [];
  repairOrdersFilteredForCriticalStats: RcmRepairOrder[] = [];
  repairOrdersCardFilter: RcmRepairOrder[] = [];
  tableFilterSettings: any[];
  gridPageSizeOptions = [5, 10, 20, 50];
  loadingROs: boolean;
  assignedUsers: User[];
  customerList: any[] = [];
  criticalstats: RepairOrderLineCriticalStat[] = [];
  buyerCodeList: any[] = [];
  userList: any[] = [];
  
  filterConfig: TableFilterConfig = {
    active: false,
    filter: null,
  };

  showOnly: string;

  currentUser: User;
  columnsSettings: RcmGridColumnDetails[] = [];
  /*Commented under #40361
  directShip = [];
  */
  filters = {};

  criticalStatsIdAllRo = CRITICAL_STATS_IDS.ALL_RO;
  criticalStatsIdPendingCustomerApproval =
    CRITICAL_STATS_IDS.PENDING_CUSTOMER_APPROVAL;

  criticalStatsCards: ICriticalStatsCard[];
  private personaService: PersonaService;
  private userIsCustomer: boolean;
  private searchShippingResult: ShippingType[] = [];

  private externalSubscriptions$ = new Subscription;
  private authServiceUserSubscription$: Subscription;
  private routerEventsSubscription$: Subscription;
  private filterSelectedSubscription$: Subscription;
  private routeQueryParamsSubscription$: Subscription;
  private sharedServiceGetObjectSubscription$: Subscription;
  private apiCallsSubscription$: Subscription;

  searchKeyword: string = "";

  constructor(
    public router: Router,
    public route: ActivatedRoute,
    private apiService: ApiService,
    private loadingService: LoadingService,
    private authService: AuthService,
    private rcmService: RcmService,
    private criticalStatsService: CriticalStatsService,
    private sharedService: SharedService,
    private dataTableService: DataTableService,
    private datalookupService: DataLookupService,
    private documentQueryApiService: DocumentQueryApiService,
    private documentEventService: DocumentEventService
  ) {
    const user = this.authService.getLoggedUser();
    this.personaService = new PersonaService(user.personaName);
  }

  getCurrentUser(): void {
    if (this.authService.isUserLoggedIn()) {
      this.currentUser = this.authService.getLoggedUser();

      if (this.currentUser) {
      }
    } else {
    }

    this.authServiceUserSubscription$ = this.authService.user$.subscribe((user: User) => {
      this.currentUser = user;
    });
  }

  setGridHeaders(): void {

    this.columnsSettings = [
      { name: 'repairOrderNumber', label: 'Repair Order', filterable: true, exportable: true, showInGrid: true },
      { name: 'programmeDescription', label: 'Programme', filterable: true, exportable: true, showInGrid: true },
      { name: 'partNumber', label: 'Part Number', filterable: true, exportable: true, showInGrid: true },
      { name: 'description', label: 'Description', filterable: true, exportable: true, showInGrid: true },
      { name: 'serialNumber', label: 'Serial Number', filterable: true, exportable: true, showInGrid: true },
      { name: 'workscopeName', label: 'Workscope', filterable: true, exportable: true, showInGrid: true },
      { name: 'customerAccountManager', label: 'User', filterable: true, exportable: true, showInGrid: true },
      { name: 'supplierName', label: 'Supplier', filterable: true, exportable: true, showInGrid: true },
      { name: 'repairLineStatus', label: 'Status', filterable: true, exportable: true, showInGrid: true },
      { name: 'priority', label: 'Priority', filterable: true, exportable: true, showInGrid: true },
      { name: 'nTat', label: 'Net TAT', filterable: true, exportable: true, showInGrid: true },
      { name: 'gTat', label: 'Gross TAT', filterable: true, exportable: true, showInGrid: true },
      { name: 'documents', label: 'Document(s)', filterable: true, exportable: true, showInGrid: true },
      { name: 'repairOrderLineId', label: 'RepairOrderLineId', filterable: true, exportable: false, showInGrid: false }
    ]
  }

  ngOnInit() {
    this.subscribeToExternalEvents();
    this.userIsCustomer = this.authService.getUserInfo().roleName === ROLE_NAMES.CUSTOMER;

    this.routerEventsSubscription$ = this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd && val.url === "/rcm") {
        let ros = this.repairOrdersFilteredForGrid;

        this.repairOrdersFilteredForGrid = [];
        setTimeout(() => {
          this.repairOrdersFilteredForGrid = ros;
          ros = null;
        });
      }
    });

    this.filterSelectedSubscription$ = this.rcmService.filterSelected$.subscribe(filterBy => this.filterByStatus(filterBy));
    this.routeQueryParamsSubscription$ = this.route.queryParams.subscribe(params => {
      this.showOnly = params.showOnly;
      if (params.filterBy) {
        this.filterByStatus(params.filterBy);
      }
    });

    // TODO This subscription is for fixing the error with table, otherwise will show all ROs without pagination
    this.getCurrentUser();
    this.setGridHeaders();
    this.getRepairOrders();


    if (this.currentUser.roleName.toUpperCase() === ROLE_NAMES.CUSTOMER) {
      this.criticalStatsCards = CUSTOMER_STATS;
    } else if (this.currentUser.roleName.toUpperCase() === ROLE_NAMES.SUPPLIER) {
      this.criticalStatsCards = SUPPLIER_STATS;
    } else {
      this.criticalStatsCards = REPAIR_MANAGER_STATS;
    }

    this.subscribeToKeywordSearch();

    this.sharedServiceGetObjectSubscription$ = this.sharedService.getObject().subscribe((filters) => {
      this.filters = filters;

      this.filterByKeyWord();
      this.filterByDropdownFilters();
      this.calculateCriticalStats(this.repairOrdersFilteredForCriticalStats);
      this.filterByCards();
    });
  }

  ngOnDestroy(): void {

    this.unsubscribeFromAllExternalEvents();

    if (this.apiCallsSubscription$ !== undefined) {
      this.apiCallsSubscription$.unsubscribe();
    }
    if (this.authServiceUserSubscription$ !== undefined) {
      this.authServiceUserSubscription$.unsubscribe();
    }
    if (this.routerEventsSubscription$ !== undefined) {
      this.routerEventsSubscription$.unsubscribe();
    }
    if (this.filterSelectedSubscription$ !== undefined) {
      this.filterSelectedSubscription$.unsubscribe();
    }
    if (this.routeQueryParamsSubscription$ !== undefined) {
      this.routeQueryParamsSubscription$.unsubscribe();
    }
    if (this.sharedServiceGetObjectSubscription$ !== undefined) {
      this.sharedServiceGetObjectSubscription$.unsubscribe();
    }
  }

  private subscribeToExternalEvents() {

    const findRol = (roLineId: number): number => {
      return this.repairOrders.findIndex(ro => ro.repairOrderLineId === roLineId);
    }

    this.externalSubscriptions$.add(this.documentEventService.documentUpdated.subscribe((documentUpdateDetails: DocumentUpdate) => {
      const idx = findRol(documentUpdateDetails.repairorderLineId);

      if (idx > -1) {
        const rol = this.repairOrders[idx] as any;
        rol.paperClip = documentUpdateDetails.hasDocuments;
        this.repairOrders.splice(idx, 0, rol);
      }
    }));
  }

  private unsubscribeFromAllExternalEvents() {
    this.externalSubscriptions$.unsubscribe();
  }

  private subscribeToKeywordSearch() {

    const getSearchKeyword$ = this.sharedService.getSearchKeyWord().pipe(tap(() => this.loadingService.startLoading()));

    if (this.userIsCustomer) {

      const searchOps = getSearchKeyword$.pipe(
        switchMap((searchString) => {
          this.searchKeyword = searchString;
          if (searchString) {
            return this.apiService.searchShipping(searchString);
          } else {
            let shipArr = {} as ShippingType[];
            shipArr = [];
            return of(shipArr);
          }
        })
      )

      searchOps.pipe(finalize(() => this.loadingService.stopLoading())).subscribe((shippingTypes) => {
        this.searchShippingResult = shippingTypes;
        this.filterByKeyWord();
        this.filterByDropdownFilters();
        this.calculateCriticalStats(this.repairOrdersFilteredForCriticalStats);
        this.filterByCards();
        this.loadingService.stopLoading();
      }, err => {
        console.log(`Error in keyword search: ${err}`);
        this.loadingService.stopLoading();
      });

    } else {
      getSearchKeyword$.pipe(finalize(() => this.loadingService.stopLoading())).subscribe((message) => {
        this.searchKeyword = message;

        this.filterByKeyWord();
        this.filterByDropdownFilters();
        this.calculateCriticalStats(this.repairOrdersFilteredForCriticalStats);
        this.filterByCards();
        this.loadingService.stopLoading();
      });
    }

  }

  private filterShipping(): RcmRepairOrder[] {

    if (this.searchShippingResult) {
      if (this.searchShippingResult.length > 0) {
        return this.repairOrders.filter(ro => {
          return this.searchShippingResult.some(f => {
            return f.rONumber === ro.repairOrderNumber.substring(0, ro.repairOrderNumber.indexOf('-'));
          });
        });
      }
    }
    return null;
  }

  calculateCriticalStats(repairOrders: RcmRepairOrder[]): void {

    const criticalStatsRepairOrders = repairOrders.map((csro) => {
      return <CriticalStatRepairOrder>{
        repairOrderLineId: csro.repairOrderLineId,
        repairLineStatusDescription: csro.repairOrderLine.repairLineStatusDescription
      }
    });

    const statuses = this.criticalStatsService.calculateCriticalStats(
      this.currentUser.roleName,
      this.criticalstats,
      criticalStatsRepairOrders
    );
    this.criticalStatsCards.forEach((card) => {
      card.value = statuses[card.id];
    });
  }

  getRepairOrders(): void {
    this.getCurrentUser();

    const apiCalls = forkJoin({
      repairOrderOperation: this.apiService.getRcmRepairOrders().pipe(tap(() => this.loadingROs = true), take(1)),
      criticalStatsOperation: this.apiService.getCriticalStats().pipe(tap(() => this.loadingROs = true), take(1)),
      documentOperation: this.documentQueryApiService.getAllRepairOrderLinesAssociatedToDocuments().pipe(tap(() => this.loadingROs = true), take(1))
    });

    this.apiCallsSubscription$ = apiCalls.pipe(finalize(() => this.loadingROs = false)).subscribe((operationResults) => {
      this.getRepairOrdersFilters();

      this.repairOrders = operationResults.repairOrderOperation.map((order: RcmRepairOrder) => {
        var orders = Object.assign({}, order);
        orders.repairOrderLine.hasDocuments = operationResults.documentOperation.findIndex((d) => d === order.repairOrderLineId) > -1
        return orders;
      });

      this.buildGridData(this.repairOrders);

      /* Commented under #40361
        customerROCreatedDt: order.customerRoCreatedDate,
      */

      this.repairOrdersFilteredForGrid = this.filterOrdersNotClosedOrCancelled(this.repairOrders);
      this.repairOrdersFilteredForCriticalStats = this.repairOrders;
      this.criticalstats = operationResults.criticalStatsOperation;
      this.calculateCriticalStats(this.repairOrdersFilteredForCriticalStats);
      this.filterByCards();
      this.loadingROs = false;
    },
      (err) => {
        this.loadingService.loadingError();
      }
    );
  }

  private buildGridData(orders: RcmRepairOrder[]): void {
    this.rcmGridOrders = orders.map((order) => ({
      repairOrderId: order.repairOrderId,
      repairOrderNumber: order.repairOrderNumber,
      supplierName: order.supplierName,
      programmeDescription: order.programmeDescription,
      repairOrderLineId: order.repairOrderLineId,
      nTat: order.repairOrderLine.nTat.toString(),
      description: order.repairOrderLine.description,
      gTat: order.repairOrderLine.gTat.toString(),
      partNumber: order.repairOrderLine.partNumber,
      priority: order.repairOrderLine.priority ? order.repairOrderLine.priority : NO_VALUE_TEXT,
      repairLineStatus: order.repairOrderLine.repairLineStatus,
      serialNumber: order.repairOrderLine.serialNumber ? order.repairOrderLine.serialNumber : NO_VALUE_TEXT,
      workscopeName: order.repairOrderLine.workscopeName,
      repairLineStatusDescription: order.repairOrderLine.repairLineStatusDescription,
      documents: order.repairOrderLine.hasDocuments ? 'Yes':'No',
      paperClipImage: 'assets/images/fpo/suppliers/paperclip.png',
      customerAccountManager: order.repairOrderLine.customerAccountManager ? order.repairOrderLine.customerAccountManager : NO_VALUE_TEXT
    } as RcmGridData));
  }

  private filterOrdersNotClosedOrCancelled(repairOrdersToFilter: RcmRepairOrder[]): RcmRepairOrder[] {
    return repairOrdersToFilter.filter(
      (x) =>
        x.repairOrderLine.repairLineStatusDescription !== REPAIRORDERLINE_STATUS_DESCRIPTIONS.orderCanceled &&
        x.repairOrderLine.repairLineStatusDescription !== REPAIRORDERLINE_STATUS_DESCRIPTIONS.orderClosed
    );
  }

  private filterOrdersNotCancelled(repairOrdersToFilter: RcmRepairOrder[]): RcmRepairOrder[] {
    return repairOrdersToFilter.filter(
      (x) => x.repairOrderLine.repairLineStatusDescription !== REPAIRORDERLINE_STATUS_DESCRIPTIONS.orderCanceled
    );
  }

  //This is where filter logic goes
  getRepairOrdersFilters(): void {
    if (this.currentUser && this.currentUser.roleName === ROLE_NAMES.CUSTOMER) {
      this.tableFilterSettings = [
        {
          name: "repairLineStatus",
          type: "dropdown",
          placeholder: "Status",
          options: [], //this.convertFilters(filterData.repairOrderStatuses)
        }
      ];
    } else if (
      this.currentUser &&
      [ROLE_NAMES.REPAIR_MANAGER, ROLE_NAMES.REPAIR_ADMIN].includes(
        this.currentUser.roleName
      )
    ) {
      this.tableFilterSettings = [
        {
          name: "supplierName",
          type: "dropdown",
          placeholder: "Supplier",
          options: [], //this.convertFilters(filterData.suppliers)
        },
        {
          name: "customerAccountName",
          type: "dropdown",
          placeholder: "Customer",
          options: [], //this.convertFilters(this.customerList)
        },
        /*Commented under #40361
        {
          name: "directShip",
          type: "dropdown",
          placeholder: "Direct Ship",
          options: [], //this.convertFilters(this.directShip)
        },
        */
      ];
      if ([PERSONA_NAMES.BUYER, PERSONA_NAMES.CAM, PERSONA_NAMES.PROGRAMMANAGER].includes(this.personaService.currentPersona)) {
        this.tableFilterSettings.unshift({
          name: 'repairLineStatus',
          type: 'dropdown',
          placeholder: 'Status',
          options: [],
        });

        this.tableFilterSettings.splice(2, 0,
          {
            name: 'buyerName',
            type: 'dropdown',
            placeholder: 'Buyer',
            options: [],
          }
        );

        this.tableFilterSettings.splice(4, 0,
          {
            name: 'customerAccountManager',
            type: 'dropdown',
            placeholder: 'CAM',
            options: [],
          }
        );
      } else if (this.personaService.currentPersona === PERSONA_NAMES.WAREHOUSE) {
        this.tableFilterSettings.unshift({
          name: 'logisticStatus',
          type: 'dropdown',
          placeholder: 'Logistics Status',
          options: [],
        });

        this.tableFilterSettings.splice(3, 0,
          {
            name: 'grComplete',
            type: 'dropdown',
            placeholder: 'Outbound GR Complete',
            options: [],
          },
          {
            name: 'inboundGrComplete',
            type: 'dropdown',
            placeholder: 'Inbound GR Complete',
            options: [],
          }
        );
      }
    }

    if (this.personaService.currentPersona === 'CAM' || this.personaService.currentPersona === 'WAREHOUSE') {
      this.tableFilterSettings.push(
        {
          name: "cancelationRequired",
          type: "dropdown",
          placeholder: "Cancelation Required",
          options: [],
        })
    }
  }

  convertFilters(filters): any[] {
    try {
      const regex = /_/g;
      return filters.map((filter) => {
        if (filter.name !== undefined) {
          return {
            label: filter.name.replace(regex, " "),
            value: filter.name,
          };
        } else {
          return {
            label: "",
            value: "",
          };
        }
      });
    } catch (e) { }
  }

  filterByStatus(filter: string = null): void {
    if (filter == null || filter === this.criticalStatsIdAllRo) {
      this.filterConfig.filter = null;
    } else {
      this.filterConfig.filter = filter;
    }
    this.filterConfig.active = true;
    this.filterByKeyWord();
    this.filterByDropdownFilters();
    this.filterByCards();
  }

  filterByDropdownFilters() {
    Object.keys(this.filters).map((key) => {
      if (!isEmpty(this.filters[key])) {
        this.repairOrdersFilteredForGrid = this.repairOrdersFilteredForGrid.filter(
          (order) => order[key] === this.filters[key]
        );
        this.repairOrdersFilteredForCriticalStats = this.repairOrdersFilteredForCriticalStats.filter(
          (order) => order[key] === this.filters[key]
        );
      }
    });
  }

  filterByCards(): void {
    if (this.filterConfig.active && this.filterConfig.filter !== null) {
      const filteredIds = this.criticalstats
        .filter((p) => p.criticalStats.name === this.filterConfig.filter)
        .map((s) => s.repairOrderLineId);

      let filteredOrders = [...this.repairOrdersFilteredForCriticalStats];
      const filter = this.filterConfig.filter;

      if (this.criticalStatsService.statsUsingOpenOrders.includes(filter)) {
        filteredOrders = this.filterOrdersNotClosedOrCancelled(filteredOrders);
      }
      if (this.criticalStatsService.statsUsingOpenAndClosedOrders.includes(filter)) {
        filteredOrders = this.filterOrdersNotCancelled(filteredOrders);
      }
      this.repairOrdersFilteredForGrid = [...filteredOrders].filter((ro) =>
        filteredIds.includes(ro.repairOrderLineId)
      );
    }

    this.dataTableService.updateTable(this.filters);
    this.buildGridData(this.repairOrdersFilteredForGrid);
  }

  filterByKeyWord() {
    const message = this.searchKeyword || '';

    let filteredOrders = this.repairOrders.filter(
      (x) =>
        (x.repairOrderNumber &&
          x.repairOrderNumber.toLowerCase().includes(message.toLowerCase()))||
        (x.repairOrderLine.partNumber &&
          x.repairOrderLine.partNumber.toLowerCase().includes(message.toLowerCase())) ||
        (x.repairOrderLine.description &&
          x.repairOrderLine.description.toLowerCase().includes(message.toLowerCase())) ||
        (x.repairOrderLine.serialNumber &&
          x.repairOrderLine.serialNumber.toLowerCase().includes(message.toLowerCase())) ||
        (x.repairOrderLine.workscopeName &&
          x.repairOrderLine.workscopeName.toLowerCase().includes(message.toLowerCase())) ||
        (x.repairOrderLine.customerAccountManager && 
          x.repairOrderLine.customerAccountManager.toLowerCase().includes(message.toLowerCase())) ||
        (x.repairOrderLine.repairLineStatus &&
          x.repairOrderLine.repairLineStatus.toLowerCase().includes(message.toLowerCase())) ||
        (x.repairOrderLine.priority &&
          x.repairOrderLine.priority.toLowerCase().includes(message.toLowerCase())) ||
        (x.supplierName && 
          x.supplierName.toLowerCase().includes(message.toLowerCase()))

          /* Commented under #40361
      (x.poNumber &&
        x.poNumber.toLowerCase().includes(message.toLowerCase())) ||
        */
    );

    if (this.userIsCustomer) {
      filteredOrders.push(...this.filterShipping());
      filteredOrders = [...filteredOrders].filter((value, index, array) =>
        array.indexOf(value) === index);
    }

    this.repairOrdersFilteredForCriticalStats = filteredOrders;
    this.repairOrdersFilteredForGrid = this.filterOrdersNotClosedOrCancelled(filteredOrders);
    this.buildGridData(this.repairOrdersFilteredForGrid);
  }

  isCardActive(cardId: string): boolean {
    return (
      (this.filterConfig.active && this.filterConfig.filter === cardId) ||
      (cardId === this.criticalStatsIdAllRo &&
        this.filterConfig.filter === null)
    );
  }
}
