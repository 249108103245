import { Injectable } from '@angular/core';
import { forkJoin, Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { Carrier, Currency, MismatchReason, MismatchReasonCategory, MismatchReasonType, QuoteWarrantyStatus, RepairCategory, User, WorkScope } from '../../api/api.models';
import { ApiService } from '../../api/api.service';

@Injectable({
  providedIn: 'root'
})
export class DataLookupService {

  constructor(private apiService: ApiService) { }

  private currencies: Currency[] = [];
  private carriers: Carrier[] = [];
  private workscopes: WorkScope[] = [];
  private repairCategories: RepairCategory[] = [];
  private mismatchReasons: MismatchReason[] = [];
  private users: User[] = [];
  private quoteWarrantyStatuses: QuoteWarrantyStatus[] = [];
  private readonly workscopeFormattableNames: string[] = ['OHL&MODIFY'];

  private currency$ = this.apiService.getCurrencies().pipe(take(1));
  private carrier$ = this.apiService.getCarriers().pipe(take(1));
  private workscope$ = this.apiService.getWorkscopes().pipe(take(1));
  private repairCategory$ = this.apiService.getRepairCategories().pipe(take(1));
  private mismatchReasons$ = this.apiService.getMismatchReasons().pipe(take(1));
  private users$ = this.apiService.getUsers().pipe(take(1));
  private quoteWarrantyStatuses$ = this.apiService.getQuoteWarrantyStatuses().pipe(take(1));

  getLookupData(): Observable<boolean> {

    const lookupOperations = forkJoin({

      currencyOperation: this.currency$,
      carrierOperation: this.carrier$,
      workscopeOperation: this.workscope$,
      repairCategoryOperation: this.repairCategory$,
      mismatchReasonOperation: this.mismatchReasons$,
      usersOperation: this.users$,
      quoteWarrantyStatusesOperation: this.quoteWarrantyStatuses$

    });

    return lookupOperations.pipe(map((opReturn) => {

      this.carriers = opReturn.carrierOperation;
      this.currencies = opReturn.currencyOperation;
      this.workscopes = opReturn.workscopeOperation;
      this.repairCategories = opReturn.repairCategoryOperation;
      this.mismatchReasons = opReturn.mismatchReasonOperation;
      this.users = opReturn.usersOperation;
      this.quoteWarrantyStatuses = opReturn.quoteWarrantyStatusesOperation;

      this.workscopes.forEach((workscopeItem) => {
        workscopeItem.formattedName = workscopeItem.name;
        let formattableName = this.workscopeFormattableNames.find((formatName) => formatName === workscopeItem.workScopeId);
        if (formattableName) {
          workscopeItem.formattedName = workscopeItem.formattedName = `${workscopeItem.workScopeId} (${workscopeItem.name})`;
        }
      });

      return true;

    }));

  }

  private mismatchReasonsFilter(categoryId: MismatchReasonCategory, typeId: MismatchReasonType, includeSystem:boolean=false): MismatchReason[] {

    const filterFunction = (): MismatchReason[] => {
      return this.mismatchReasons.filter((f) => f.categoryId === categoryId && f.typeId === typeId && f.internalOnly === includeSystem);
    }

    if (this.mismatchReasons.length === 0) {
      this.mismatchReasons$.subscribe((reasonItems) => {
        this.mismatchReasons = reasonItems;
        return filterFunction();
      });
    } else {
      return filterFunction();
    }

  }

  cachedMismatchReasonsForRepairOrderSerialNumberChanged(includeSystem:boolean=false): MismatchReason[] {
    return this.mismatchReasonsFilter(MismatchReasonCategory.SerialNumber, MismatchReasonType.RepairOrder, includeSystem);
  }

  cachedMismatchReasonsForQuoteSerialNumberChanged(includeSystem:boolean=false): MismatchReason[] {
    return this.mismatchReasonsFilter(MismatchReasonCategory.SerialNumber, MismatchReasonType.Quote, includeSystem);
  }

  get cachedCurrencies(): Currency[] {

    const currencyOrderFunction = (): Currency[] => {
      let sortedArr: Currency[] = [];
      let usdCurr = this.currencies.find(c => c.name.toLowerCase() === 'usd');
      if (usdCurr) {
        sortedArr.push(usdCurr as Currency)
      }
      this.currencies.forEach(c => c.name.toLowerCase() !== 'usd' ? sortedArr.push(c) : undefined)
      return sortedArr;
    };

    if (this.currencies.length > 0) {
      return currencyOrderFunction();
    } else {
      this.currency$.subscribe((currencyItems) => {
        this.currencies = currencyItems;

        return currencyOrderFunction();
      });
    }
  }

  get cachedCarriers(): Carrier[] {
    if (this.carriers.length > 0) {
      return this.carriers;
    } else {
      this.carrier$.subscribe((carrierItems) => {
        this.carriers = carrierItems;

        return this.carriers
      });
    }
  }

  get cachedWorkscopes(): WorkScope[] {
    if (this.workscopes.length > 0) {
      return this.workscopes;
    } else {
      this.workscope$.subscribe((workscopeItems) => {
        this.workscopes = workscopeItems;

        return this.workscopes;
      });
    }
  }

  get cachedRepairCategories(): RepairCategory[] {
    if (this.repairCategories.length > 0) {
      return this.repairCategories;
    } else {
      this.repairCategory$.subscribe((repairCategoryItems) => {
        this.repairCategories = repairCategoryItems;

        return this.repairCategories;
      });
    }
  }

  get cachedUsers(): User[] {
    if (!(this.users === undefined) && this.users.length > 0) {
      return this.users;
    } else {
      this.users$.subscribe((userItems) => {
        this.users = userItems;
        return this.users;
      });
    }
  }

  get cachedQuoteWarrantyStatuses(): QuoteWarrantyStatus[] {
    if (this.quoteWarrantyStatuses.length > 0) {
      return this.quoteWarrantyStatuses;
    } else {
      this.quoteWarrantyStatuses$.subscribe((quoteWarrantyStatusItems) => {
        this.quoteWarrantyStatuses = quoteWarrantyStatusItems;
        return this.quoteWarrantyStatuses;
      });
    }
  }

  cachedUserForUserId(userId: number): User | undefined {
    if (this.cachedUsers === undefined) {
      return {} as User;
    }
    return this.cachedUsers.find(user => user.id === userId);
  }

  cachedCurrencyForId(currencyId: number): Currency | undefined {
    return this.cachedCurrencies.find(curr => curr.id === currencyId);
  }

  cachedWorkscopeForId(workscopeId: number): WorkScope | undefined {
    return this.cachedWorkscopes.find(wscope => wscope.id === workscopeId);
  }

}