<mat-tab-group [selectedIndex]="selectedTabIndex">
    <mat-tab label="Quote Line">
        <aar-rcm-quote-review-details-quote-line
            [selectedQuoteLine]="selectedQuoteLine"></aar-rcm-quote-review-details-quote-line>
    </mat-tab>
    <mat-tab label="General">
        <aar-rcm-quote-review-details-general
            [selectedQuoteLine]="selectedQuoteLine"></aar-rcm-quote-review-details-general>
    </mat-tab>
    <mat-tab label="Milestones">
        <aar-rcm-quote-review-details-milestones
            [selectedQuoteLine]="selectedQuoteLine"></aar-rcm-quote-review-details-milestones>
    </mat-tab>
    <mat-tab label="Cost Breakdown">
        <aar-rcm-quote-review-details-cost-breakdown
            [selectedQuoteLine]="selectedQuoteLine"></aar-rcm-quote-review-details-cost-breakdown>
    </mat-tab>
    <mat-tab label="Pricing">
        <div style="margin-top:30px">
            <aar-rcm-quote-review-details-pricing [selectedQuoteLine]="selectedQuoteLine">
            </aar-rcm-quote-review-details-pricing>
            <div style="margin-top:30px">
                <aar-rcm-quote-value-details-threshold
                    [selectedQuoteLine]="selectedQuoteLine"></aar-rcm-quote-value-details-threshold>
            </div>
        </div>
    </mat-tab>
    <mat-tab label="Queries"></mat-tab>
    <mat-tab label="Documents"></mat-tab>
    <mat-tab label="SPT"></mat-tab>
    <mat-tab label="Repair History"></mat-tab>
    <mat-tab label="Contracts"></mat-tab>
</mat-tab-group>