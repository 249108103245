import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { QuoteReviewUpdate, QuoteReviewUpdateMutation, UpdateQuoteReview } from './quote-mutation-models';

@Injectable({
  providedIn: 'root'
})
export class QuoteMutationApiService {

  constructor(private apollo: Apollo) { }

  updateQuoteReview(updateQuoteReview: QuoteReviewUpdate): Observable<UpdateQuoteReview> {

    return this.apollo.mutate<QuoteReviewUpdateMutation>({
      mutation: gql`
      mutation updateQuoteReview($updateQuoteReview: QuoteReviewUpdateType!){
          updateQuoteReview(quoteReviewUpdate: $updateQuoteReview){
            pendingInternalActionDateTime
        }
      }
      `,
      variables: {
        updateQuoteReview
      }
    })
      .pipe(
        map(result => result.data.updateQuoteReview));
  }
}