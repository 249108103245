<div style="margin-top:20px">
    <div class="aar-display-label-and-value-container">
        <div class="aar-display-label-and-value">
            <span class="aar-display-label">Category</span>
            <span class="aar-display-label-value">{{quoteDetails.category}}</span>
        </div>
        <div class="aar-display-label-and-value">
            <span class="aar-display-label">Part Number</span>
            <span class="aar-display-label-value">{{quoteDetails.partNumber}}</span>
        </div>
        <div class="aar-display-label-and-value">
            <span class="aar-display-label">Part Number Description</span>
            <span class="aar-display-label-value">{{quoteDetails.partNumberDescription}}</span>
        </div>
        <div class="aar-display-label-and-value">
            <span class="aar-display-label">Serial Number</span>
            <span class="aar-display-label-value">{{quoteDetails.serialNumber}}</span>
        </div>
        <div class="aar-display-label-and-value">
            <span class="aar-display-label">Workscope</span>
            <span class="aar-display-label-value">{{quoteDetails.workscope}}</span>
        </div>
    </div>

    <div class="aar-display-label-and-value-container">
        <div class="aar-display-label-and-value">
            <span class="aar-display-label">Unit Cost</span>
            <span class="aar-display-label-value">{{quoteDetails.unitCost}}</span>
        </div>
        <div class="aar-display-label-and-value">
            <span class="aar-display-label">Qty</span>
            <span class="aar-display-label-value">{{quoteDetails.quantity}}</span>
        </div>
        <div class="aar-display-label-and-value">
            <span class="aar-display-label">Total Supplier Cost</span>
            <span class="aar-display-label-value">{{quoteDetails.totalSupplierCost}}</span>
        </div>
        <div class="aar-display-label-and-value">
            <span class="aar-display-label">Currency</span>
            <span class="aar-display-label-value">{{quoteDetails.currency}}</span>
        </div>
        <div class="aar-display-label-and-value">
            <span class="aar-display-label">Quote Status</span>
            <span class="aar-display-label-value">{{quoteDetails.quoteStatus}}</span>
        </div>
    </div>

    <div class="aar-display-label-and-value-container">
        <div class="aar-display-label-and-value">
            <span class="aar-display-label">Quote Queue</span>
            <span class="aar-display-label-value">{{quoteDetails.quoteQueue}}</span>
        </div>
        <div class="aar-display-label-and-value">
            <span class="aar-display-label">Master Option</span>
            <span class="aar-display-label-value">{{quoteDetails.masterOption}}</span>
        </div>
        <div class="aar-display-label-and-value">
            <span class="aar-display-label">Multi Option</span>
            <span class="aar-display-label-value">{{quoteDetails.multiOption}}</span>
        </div>
        <div class="aar-display-label-and-value">
            <span class="aar-display-label">RAI</span>
            <span class="aar-display-label-value">{{quoteDetails.rai}}</span>
        </div>
        <div class="aar-display-label-and-value">
            <span class="aar-display-label">SOS</span>
            <span class="aar-display-label-value">{{quoteDetails.sos}}</span>
        </div>
    </div>
</div>