export const environment = {
  production: true,
  fileTransferEndpoint: 'https://file-transfer-api.uat.getairvolution.com/api/file',
  graphqlEndpoint: 'https://airvolution-ss-gateway-api-temp.azurewebsites.net/graphql/',
  apolloClientStorageNameNetworkError: 'apolloClient:networkError',
  apolloClientStorageNameGraphQlErrors: 'apolloClient:graphQlErrors',
  apolloClientRetryLinkExclusions: ['changePassword', 'specialCase', 'login'],
  clientId: '1ca820fa-c0b1-40ae-a054-f2b2c3e36898',
  authority: 'https://login.microsoftonline.com/common/',
  redirectUrl: 'https://uat.getairvolution.com/',
  samlIdpLoginErrorStorageName: 'samlIdpLoginError',
  domoEmbedUrlDashboardReport: `<iframe src="https://public.domo.com/embed/pages/EREY4" width="100%" height="100%"
   marginheight="0" marginwidth="0" frameborder="0"></iframe>`,
  domoEmbedUrlStatusReport: `<iframe src="https://public.domo.com/embed/pages/XDOwk" width="100%" height="100%"
   marginheight="0" marginwidth="0" frameborder="0"></iframe>`,
  domoEmbedUrlTatReport: `<iframe src="https://public.domo.com/embed/pages/G6qRK" width="100%" height="100%"
   marginheight="0" marginwidth="0" frameborder="0"></iframe>`,
   repairCycleCompanyStorage:'repairCycleCompanyStorage'
};
