import { Component, Input, OnChanges } from '@angular/core';
import { QuoteReviewQuoteLine } from '@app/shared/services/api/api.models';

interface QuoteMilestones {
  dateQuoteReceived: Date;
  dateOnQuote: Date;
  quoteDaysElapsed: string;
  createdDate: Date;
  processedDate: Date;
  sentForAuthorisationDate: Date;
  authorisedDate: Date;
  authorisedBy: string;
  authorisedOnSupplierDate: Date;
  rejectedDate: Date;
  rejectedOnSupplierDate: Date;
  supersededDate: Date;
  cancelledDate: Date;
  cancellationReason: string;

}

@Component({
  selector: 'aar-rcm-quote-review-details-milestones',
  templateUrl: './rcm-quote-review-details-milestones.component.html',
  styleUrls: ['./rcm-quote-review-details-milestones.component.scss']
})
export class RcmQuoteReviewDetailsMilestonesComponent implements OnChanges {
  @Input('selectedQuoteLine') selectedQuoteLine: QuoteReviewQuoteLine;

  quoteMilestoneData: QuoteMilestones = null;

  ngOnChanges(): void {
    if (this.selectedQuoteLine) {
      this.quoteMilestoneData = {
        dateQuoteReceived: this.selectedQuoteLine?.quote?.dateReceived,
        dateOnQuote: this.selectedQuoteLine?.quote?.datePosted,
        quoteDaysElapsed: '',
        createdDate: this.selectedQuoteLine?.quote?.createdDate,
        processedDate: this.selectedQuoteLine?.quote?.processedDate,
        sentForAuthorisationDate: this.selectedQuoteLine?.quote?.quotedToCustomerDate,
        authorisedDate: this.selectedQuoteLine?.quote?.customerApprovedDate,
        authorisedBy: null,
        authorisedOnSupplierDate: this.selectedQuoteLine?.quote?.authorizedOnSupplierDate,
        rejectedDate: this.selectedQuoteLine?.quote?.rejectedDateTime,
        rejectedOnSupplierDate: this.selectedQuoteLine?.quote?.rejectedOnSupplierDateTime,
        supersededDate: this.selectedQuoteLine?.quote?.supersededDateTime,
        cancelledDate: this.selectedQuoteLine?.quote?.canceledDate,
        cancellationReason: this.selectedQuoteLine?.quote?.cancelationReason
      }
    }
  }
}