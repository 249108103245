import { Component, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Subscription } from 'rxjs';
import { AuthService } from '@app/auth/services/auth/auth.service';
import { User } from '@app/auth/auth.models';
import { environment } from '@env/environment';

@Component({
    selector: 'aar-tat-report',
    templateUrl: './tat-report.component.html',
    styleUrls: ['./tat-report.component.scss']
})

export class TatReportComponent implements OnInit {
  currentUser: User;

  breadcrumbSubscription: Subscription;

  domoEmbedUrl = environment.domoEmbedUrlTatReport;


  constructor(public dialog: MatDialog,
              public authService: AuthService
    ) {}


  ngOnInit() {
    this.getCurrentUser();

  }

  getCurrentUser(): void {

    if (this.authService.isUserLoggedIn()) {
      this.currentUser = this.authService.getLoggedUser();

      if (this.currentUser) {
      }
    } else {
    }

    this.authService.user$.subscribe((user: User) => {
      this.currentUser = user;
    });
  }

}
