import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';
import { CommonDialogComponent } from '@app/shared/components/common-dialog/common-dialog.component';
import { CommonContact } from '@app/shared/services/api/api.models';
import { ContactMode } from '../contact.constant';
import { getFormFielErrorMessage } from '@app/common/utility';

@Component({
  selector: 'aar-add-edit-contact',
  templateUrl: './add-edit-contact.component.html',
  styleUrls: ['./add-edit-contact.component.scss']
})
export class AddEditContactComponent implements OnInit {
  maintainContactForm: UntypedFormGroup;
  contact: CommonContact;
  private contactMode: ContactMode;

  readonly firstNameControlName = 'firstName';
  readonly lastNameControlName = 'lastName';
  readonly mainPhoneNumberControlName = 'mainPhoneNumber';
  readonly emailAddressControlName = 'emailAddress';
  readonly alternatePhoneNumberControlName = 'alternatePhoneNumber';
  readonly commentsControlName = 'comments';
  readonly titleControlName = 'title';

  constructor(
    public dialog: MatDialog,
    public router: Router,
    private formBuilder: UntypedFormBuilder,
    public dialogRef: MatDialogRef<AddEditContactComponent>,
    @Inject(MAT_DIALOG_DATA) data: any) {

      this.contact = data.contact;
      this.contactMode = data.contact.contactID === 0 ? ContactMode.Add : ContactMode.Edit;
  }

  ngOnInit() {
      this.maintainContactForm = this.formBuilder.group({
        firstName: [this.contact.firstName, [Validators.required, Validators.maxLength(30)]],
        lastName: [this.contact.lastName, [Validators.required, Validators.maxLength(30)]],
        title: [this.contact.title, Validators.maxLength(75)],
        emailAddress: [this.contact.emailAddress, [Validators.required, Validators.email, Validators.maxLength(50)]],
        mainPhoneNumber: [this.contact.mainPhoneNumber, Validators.maxLength(30)],
        comments: [this.contact.comments, Validators.maxLength(100)],
        alternatePhoneNumber: [this.contact.alternatePhoneNumber, [Validators.maxLength(30)]],
      }
    );
  }

  getTitle(): string {
    return this.contactMode === ContactMode.Add ? 'Add Contact' : 'Update Contact';
  }

  hasError(form: UntypedFormGroup, control: string): boolean {
    return !form.controls[control].valid;
  }

  getErrorMessage(form: UntypedFormGroup, control: string): string {
    return getFormFielErrorMessage(form, control);
  }

  onClose() {
    // Just close the form without a confirmation dialog if nothing has changed provided the data is valid.
    if (!this.maintainContactForm.dirty && this.maintainContactForm.valid) {
      this.dialogRef.close();
      return;
    }

    const results = CommonDialogComponent.openDialog(this.dialog, 'Confirm', 'Are you sure you want to discard the changes?', 'Yes', 'No');
    results.subscribe(shouldConfirm => {
      if (shouldConfirm === true) {
        this.dialogRef.close();
      }
    });
  }

  onSave() {
    if (this.maintainContactForm.valid) {
      this.dialogRef.close(Object.assign(this.contact, this.maintainContactForm.value));
    }
  }

}
