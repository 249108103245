<div style="margin-top:20px">
    <div class="aar-display-label-and-value-container">
        <div class="aar-display-label-and-value">
            <span class="aar-display-label">Quote Responsible User</span>
            <span class="aar-display-label-value">{{generalQuote.quoteResponsibleUser}}</span>
        </div>
        <div class="aar-display-label-and-value">
            <span class="aar-display-label">Pending Internal Action</span>
            <span class="aar-display-label-value">{{generalQuote.pendingInternalAction}}</span>
        </div>
        <div class="aar-display-label-and-value">
            <span class="aar-display-label">Pending Internal Action Date</span>
            <span class="aar-display-label-value">{{generalQuote.pendingInternalActiondDate | dateLocalisedPipe}}</span>
        </div>
        <div class="aar-display-label-and-value">
            <span class="aar-display-label">Quoted PN Change Reason</span>
            <span class="aar-display-label-value">{{generalQuote.quotedPnChangeReason}}</span>
        </div>
        <div class="aar-display-label-and-value">
            <span class="aar-display-label">Quoted SN Change Reason</span>
            <span class="aar-display-label-value">{{generalQuote.quotedSnChangeReason}}</span>
        </div>
    </div>
</div>

<div style="margin-top:20px">
    <div class="aar-display-label-and-value-container">
        <div class="aar-display-label-and-value">
            <span class="aar-display-label">Supplier Ref</span>
            <span class="aar-display-label-value">{{generalQuote.supplierRef}}</span>
        </div>
        <div class="aar-display-label-and-value">
            <span class="aar-display-label">Quoted TAT</span>
            <span class="aar-display-label-value">{{generalQuote.quotedTat}}</span>
        </div>
        <div class="aar-display-label-and-value">
            <span class="aar-display-label">Quoted Warranty Status</span>
            <span class="aar-display-label-value">{{generalQuote.quotedWarrantyStatus}}</span>
        </div>
        <div class="aar-display-label-and-value">
            <span class="aar-display-label">Quote Findings</span>
            <span class="aar-display-label-value">{{generalQuote.quoteFindings}}</span>
        </div>
        <div class="aar-display-label-and-value">
            <span class="aar-display-label">Aircraft Type</span>
            <span class="aar-display-label-value">{{generalQuote.aircraftName}}</span>
        </div>
    </div>
</div>

<div style="margin-top:20px">
    <div class="aar-display-label-and-value-container">
        <div class="aar-display-label-and-value">
            <span class="aar-display-label">Tail Number</span>
            <span class="aar-display-label-value">{{generalQuote.tailNumber}}</span>
        </div>
    </div>
</div>