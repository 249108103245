import { formatDate } from "@angular/common";
import { Inject, LOCALE_ID, Pipe, PipeTransform } from "@angular/core";
import { LOCALE_DATE_FORMAT, LOCALE_MOMENTDATE_FORMAT } from "@app/core/constants/globalConstants";
import * as moment from "moment";

@Pipe({ name: 'dateLocalisedPipe' })
export class DateLocalisedPipe implements PipeTransform {

    readonly locale: string;

    constructor(@Inject(LOCALE_ID) localeId) {

        this.locale = localeId.toString();
    }

    transform(value: string | number | Date, useUTC: boolean = false): string {
        return value ?
            useUTC ?
                moment.utc(value).format(LOCALE_MOMENTDATE_FORMAT[this.locale]) :
                formatDate(value, LOCALE_DATE_FORMAT[this.locale], this.locale)
            : '';
    }
}