import { Attribute, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { getFormFielErrorMessage, formatDropdownValues } from '@app/common/utility';
import { RepairOrderLineInput, UpdateQuoteLineInput, RcmQuote, QuoteLine, ActivityInputType, QuoteStatusIdType, Currency, WorkScope } from '@app/shared/services/api/api.models';
import { ApiService } from '@app/shared/services/api/api.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subscription } from 'rxjs';
import { SharedService } from '@app/shared/services/shared.service';
import { AuthService } from '@app/auth/services/auth/auth.service';
import { runInThisContext } from 'vm';
import { textChangeRangeIsUnchanged } from '@angular/typescript';
import { PersonaService } from '@app/shared/services/rcm/persona.service';
import { ComponentType } from '@app/shared/services/rcm/persona.models'
import { DataLookupService } from '@app/shared/services/rcm/data-lookup/data-lookup.service';
import { getCurrencySymbol } from '@angular/common';
import { QuoteService } from '@app/shared/services/rcm/quote/quote.service';
//Commented as part of bug #43045
//import { ActivityService } from '@app/shared/services/rcm/activity/activity.service';

@Component({
  selector: 'aar-rcm-review-workscope-cost',
  templateUrl: './rcm-review-workscope-cost.component.html',
  styleUrls: ['./rcm-review-workscope-cost.component.scss']
})

export class RcmReviewWorkscopeCostComponent implements OnInit {
  @Input('quoteLine') quoteLine: UpdateQuoteLineInput;
  @Output('updateQuoteLine') updateQuoteLine = new EventEmitter();
  @Output('previous') previous = new EventEmitter();
  @Output('stepForward') stepForward = new EventEmitter();
  quoteForm: UntypedFormGroup;
  currencies: Currency[] = [];
  QuoteProcessingUpdate: UpdateQuoteLineInput;
  comparisonModel: QuoteLine;
  message: string;
  subscription: Subscription;
  activity: ActivityInputType;
  displayWorkscope:string;

  private readonly totalCostControlName = 'totalCost';
  private readonly unitCostControlName = 'unitCost';
  private readonly internalCommentsControlName = 'internalComments';
  private readonly currencyControlName = 'currency';
  private readonly raiControlName = 'rai';
  private readonly sosControlName = 'sos';

  private readonly nonCreateStatusFieldGroup = [this.totalCostControlName, this.unitCostControlName, 
    this.internalCommentsControlName, this.currencyControlName, this.raiControlName, this.sosControlName];

  btnText: string = "Submit";
  nonEditableFields: string[] = [];
  fieldsToHide: string[] = [];
  personaService: PersonaService;

  constructor(
    private  authService: AuthService,
    private sharedService: SharedService,  private snackBar: MatSnackBar,
    private apiService: ApiService,
    private datalookupService: DataLookupService,
    private quoteService: QuoteService){
    //Commented as part of bug #43045
    //private activityService: ActivityService) {

    this.comparisonModel = new QuoteLine();

    this.subscription = this.sharedService.getMessage().subscribe(message => {
      this.message = message;

      if (this.message == 'SaveStep1') {

        if (this.checkFormValid()) {
          this.initializeUpdate();
          this.apiService.updateQuoteLine(this.QuoteProcessingUpdate).subscribe(result => {
            this.openSnackBar('Cost values successfully Saved.');
            this.sharedService.sendMessage('ShowQuoteProcessingList');
          },
            error => {
              this.openSnackBar(error);
            });
        }
      }

    });

    this.personaService = new PersonaService(this.authService.getLoggedUser().personaName);

  }

  setButtonTextFunctionality(quoteLineStatus: string){
    if(quoteLineStatus.toUpperCase() !== "CREATED"){
      this.btnText = "Next";

      this.effectRoEdit(quoteLineStatus);
    }else{
      this.btnText = "Submit";
    }
  }

  effectRoEdit(quoteLineStatus: string): void {
    // enable or disable fields based on roEdit flag
    if (quoteLineStatus.toUpperCase() !== "CREATED") {
      this.nonEditableFields.forEach(key => {
        this.quoteForm.controls[key].disable();
      })
    }
  }

  ngOnInit() {

    this.nonEditableFields=this.personaService.getNonEditableFields(ComponentType.RCMReviewWorkscopeCost);
    this.fieldsToHide = this.personaService.getHiddenFields(ComponentType.RCMReviewWorkscopeCost);
    this.currencies = this.datalookupService.cachedCurrencies;

    this.quoteForm = new UntypedFormGroup({
      rai: new UntypedFormControl(null),
      sos: new UntypedFormControl(null),
      currency: new UntypedFormControl('', [Validators.required]),
      unitCost: new UntypedFormControl(0, [Validators.required, Validators.min(0)]),
      totalCost: new UntypedFormControl(0),
      internalComments: new UntypedFormControl("",[Validators.maxLength(1000)])
    });

    this.populateQuoteInfo();
    
  }

  private subscribeToValueChangesObservables():void{

    this.quoteForm.controls[this.unitCostControlName].valueChanges.subscribe((changedUnitValue) => {
      this.calculateAndFormatTotalSupplierCost(changedUnitValue);
    });

    this.quoteForm.controls[this.currencyControlName].valueChanges.subscribe(()=> {
      this.calculateAndFormatTotalSupplierCost(this.getFormControl(this.unitCostControlName).value);
    });
  }

  private calculateAndFormatTotalSupplierCost(unitValue:number):void{
    const formattedValue = this.quoteService.formatValueAndCurrency(unitValue, this.comparisonModel.qTYRceived, 
      this.getFormControl(this.currencyControlName).value, this.comparisonModel.quote.masterOption)
    this.getFormControl(this.totalCostControlName).setValue(formattedValue);
  }

  getFormControl(controlName:string):UntypedFormControl{
    return this.quoteForm.get(controlName) as UntypedFormControl;
  }

  getCurrencySymbology(controlName:string):string{
    const controlValue = this.quoteForm.controls[controlName].value;
    if(controlValue){
    const currencyISOName = this.currencies.find(x=>x.id === this.quoteForm.controls[controlName].value).name;
    const symbol = getCurrencySymbol(currencyISOName,'narrow');
    return symbol;
    }else{
      return '';
    }
  }

  checkFormValid(): boolean {

    return true;

    //Shri I have hardcoded this method but you need to uncomment code below and get it to work
    //  return this.quoteForm.get('currency').valid && this.quoteForm.get('totalCost').valid &&
    // this.quoteForm.get('rai').valid && this.quoteForm.get('sos').valid;
  }

  openSnackBar(message: string): void {
    this.snackBar.open(message, null, {
      duration: 3000
    });
  }

  extractQuoteDetails(): void {

    this.apiService.extractQuotelineDetails(this.QuoteProcessingUpdate.id).subscribe(result => {

      this.comparisonModel = result;
      this.initializeInputs();
      this.openSnackBar('OCR/Text Capture Successful');
    },
      error => {

        this.openSnackBar('Failed to capture text using OCR/Text Capture');
      });
  }

  initializeInputs() {

    this.QuoteProcessingUpdate = {
      roLineNumber : this.comparisonModel.quote.rOLineNumber,
      updatedAt: new Date(this.comparisonModel.updatedAt),
      createdByUserName  : '',
      assignedUserId  : '',
      finalApproverId  : '',
      warrantyStatusId : 1, //set value from drop down
      masterOption : this.comparisonModel.quote.masterOption, //set this value from radio button
      qtyRceived  : this.comparisonModel.qTYRceived,
      mismatchReasonPnId  : this.comparisonModel.mismatchReasonPnId,
      mismatchReasonPn : this.comparisonModel.mismatchReasonPnId,
      repairCategoryValue  : this.comparisonModel.repairCategory.value,
      repairCategoryId : this.comparisonModel.repairCategory.repairCategoryId,
      proposedWorkscopeId  : this.comparisonModel.proposedWorkscopeId,
      mismatchReasonSn  : this.comparisonModel.mismatchReasonSnId,
      serialNumberReceived  : this.comparisonModel.serialNumberReceived,
      id  : this.comparisonModel.id,
      quoteId  : this.comparisonModel.quote.rCMQuoteId,
      currency  : this.comparisonModel.currencyType.id,
      quotedTat: this.comparisonModel.quotedTat,
      quoteOrderLineNum  :  this.comparisonModel.quoteOrderLineNum,
      quoteStatus  :  'Created',
      nff  :  '',
      unitCost : this.quoteForm.getRawValue().unitCost,
      nupCurrency : this.comparisonModel.nupCurrency,
      nUP : this.comparisonModel.nUP,
      nupPercent : this.comparisonModel.nupPercent,
      mvCurrency :  this.comparisonModel.mvCurrency,
      mV : this.comparisonModel.mV,
      mvPercent :  this.comparisonModel.mvPercent,
      material : this.comparisonModel.material,
      miscellaneous : this.comparisonModel.miscellaneous,
      rai : this.comparisonModel.rai,
      unitId  :  '',
      laborCost : this.comparisonModel.laborCost,
      sos : this.comparisonModel.sos,
      partNumberReceived  :  this.comparisonModel.partNumberReceived,
      mismatchReasonSnId  :  this.comparisonModel.mismatchReasonSnId,
      findings  :  this.comparisonModel.findings,
      dateReceived  :  new Date(this.comparisonModel.quote.dateReceived),
      supplierReference  :  this.comparisonModel.quote.supplierReference,
      datePosted  :  new Date(this.comparisonModel.quote.datePosted),
      vendAccount  :  '',
      vendName  :  this.comparisonModel.vendName,
      quoteLineStatus  :  '',
      webServiceDescription  :  '',
      roNumber  :  this.comparisonModel.quote.rONumber,
      canceledDate : this.comparisonModel.quote.canceledDate,
      cancelationReason : this.comparisonModel.quote.cancelationReason,
      authorizedOnSupplierDate :  this.comparisonModel.quote.authorizedOnSupplierDate,
      customerApprovedDate :  this.comparisonModel.quote.customerApprovedDate,
      quotedToCustomerDate:  this.comparisonModel.quote.quotedToCustomerDate,
      processedDate:  this.comparisonModel.quote.processedDate,
      createdDate: this.comparisonModel.quote.createdDate,
      internalComments: this.comparisonModel.quote.internalComments
    };

    //Ensure the currency is set before the unitCost
    this.getFormControl(this.currencyControlName).setValue(this.comparisonModel.currencyType.id);
    this.getFormControl(this.internalCommentsControlName).setValue(this.comparisonModel.quote.internalComments);
    this.getFormControl(this.totalCostControlName).setValue(this.comparisonModel.totalCost);
    this.getFormControl(this.unitCostControlName).setValue(this.comparisonModel.unitCost.toFixed(2));
    this.getFormControl(this.raiControlName).setValue(this.comparisonModel.rai);
    this.getFormControl(this.sosControlName).setValue(this.comparisonModel.sos);

    let workscope = this.datalookupService.cachedWorkscopeForId(this.comparisonModel.proposedWorkscopeId);
    this.displayWorkscope = `${workscope.workScopeId} (${workscope.name})`;

    this.setButtonTextFunctionality(this.comparisonModel.quote.quoteStatusName);
    
    this.disableNonCreateStatusFields();
    this.subscribeToValueChangesObservables();
    this.calculateAndFormatTotalSupplierCost(this.getFormControl(this.unitCostControlName).value);

  }

  private disableNonCreateStatusFields(){
    if(this.comparisonModel.quote.quoteStatus.id !== QuoteStatusIdType.Created){
      this.nonCreateStatusFieldGroup.forEach((fgItem)=>{
        this.getFormControl(fgItem).disable();
      });
    }
  }

  populateQuoteInfo() {
    if(this.sharedService.getSelectedQuoteLine() == null || this.sharedService.getSelectedQuoteLine() == undefined)
    {
      this.apiService.getPendngQuoteLineStagging(this.quoteLine.roNumber, this.quoteLine.roLineNumber).subscribe(result => {
        this.comparisonModel = result[0];
        this.initializeInputs();
        this.sharedService.setSelectedQuoteLine(this.comparisonModel);

        this.openSnackBar('Cost values successfully loaded');
      },
        error => {
          this.openSnackBar('Failure loading cost values');
        });
    }
    else
    {
      let rcmQuoteId  = this.sharedService.getSelectedQuoteLine().quote.rCMQuoteId;

      this.apiService.getQuotesLinesById(rcmQuoteId).subscribe(result => {
        this.comparisonModel = result[0];
        this.initializeInputs();
        this.sharedService.setSelectedQuoteLine(this.comparisonModel);

        this.openSnackBar('Cost values successfully loaded');
      },
        error => {
          this.openSnackBar('Failure loading cost values');
        });
    }
  }

  previousSelected(): void {
    this.previous.emit();
  }

  submitCost(event): void {
    if(this.btnText === "Next"){
      this.stepForward.emit();
    }else{
      if (this.checkFormValid()) {

        this.initializeUpdate();
        //this should be fixed Shri, I have hard coded these values to make thing work but
        //the three values below should come from the right place
        this.QuoteProcessingUpdate.mV = 0;
        this.QuoteProcessingUpdate.nUP = 0;
        this.QuoteProcessingUpdate.mvPercent = 0;
        this.apiService.updateQuoteLine(this.QuoteProcessingUpdate).subscribe(result => {
          //Commented as part of bug #43045
          //this.activityService.activityChangePublish(this.QuoteProcessingUpdate.roNumber, this.QuoteProcessingUpdate.roLineNumber);
          this.openSnackBar('Cost values updated successfully');
          this.updateQuoteLine.emit(this.QuoteProcessingUpdate);
          this.stepForward.emit();

        },
          error => {
            this.openSnackBar('Failure updated cost values.  Please try again later');
          });
      } else {

      }
    }
  }

  initializeUpdate(): void {
    this.quoteLine = {};

    const unitCostControlValue = this.getFormControl(this.unitCostControlName).value;
  
    this.quoteLine.currency = this.quoteForm.controls['currency'].value;
    const totalCost = Number((unitCostControlValue * this.comparisonModel.qTYRceived).toFixed(2));
    this.quoteLine.totalCost = totalCost;
    this.quoteLine.internalComments = this.getFormControl(this.internalCommentsControlName).value;
    this.quoteLine.unitCost = this.getFormControl(this.unitCostControlName).value;
    this.quoteLine.rai = this.getFormControl(this.raiControlName).value;
    this.quoteLine.sos = this.getFormControl(this.sosControlName).value;

    this.QuoteProcessingUpdate.currency = this.quoteLine.currency;
    this.QuoteProcessingUpdate.totalCost = this.quoteLine.totalCost;
    this.QuoteProcessingUpdate.rai =  this.quoteLine.rai;
    this.QuoteProcessingUpdate.sos = this.quoteLine.sos;
    this.QuoteProcessingUpdate.internalComments = this.quoteLine.internalComments;
    this.QuoteProcessingUpdate.unitCost = this.quoteLine.unitCost;

  }


  hasError(control: string): boolean {
    return !this.quoteForm.controls[control].valid;
  }

  getErrorMessage(control: string): string {
    return getFormFielErrorMessage(this.quoteForm, control);
  }

    isFieldHidden(control: string): boolean {
    return this.fieldsToHide.includes(control);
  }
}
