import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { AppMaterialModule } from '@app/app-material.module';
import { HeaderModule } from '@app/shared/components';
import { DashboardModule } from '@app/dashboard/dashboard.module';
import { CostControlModule } from '../cost-control/cost-control.module';
import { SuppliersModule } from '../suppliers/suppliers.module';
import { AnalyticsBIModule } from '../analytics-bi/analytics-bi.module';
import { RcmModule } from '../rcm/rcm.module';
import { NotificationsModule } from '../notifications/notifications.module';
import { MyAccountModule } from '../my-account/my-account.module';
import { LoadingModule } from '@app/shared/components/loading/loading.component';
import { SharedService } from '@app/shared/services/shared.service';
import { LoadingService } from '@app/shared/services/loading/loading.service';
import { RcmService } from '@app/shared/services/rcm/rcm.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MainComponent, DialogOverviewExampleDialog } from './main.component';
import { SidePanelModule, SidePanelRightComponent } from '@app/shared/components/side-panel-right/side-panel-right.component';
import { EmailInboxModule } from '@app/email-inbox/email-inbox.module';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import {MatLegacyAutocompleteModule as MatAutocompleteModule} from '@angular/material/legacy-autocomplete';
import { AboutModule } from '@app/about/about.component.module';
import { TermsModule } from '@app/terms/terms.component.module';
import { CriticalStatsService } from '@app/shared/services/criticalstats.service';
import { DataLookupService } from '@app/shared/services/rcm/data-lookup/data-lookup.service';
import { QuoteService } from '@app/shared/services/rcm/quote/quote.service';
import { DocumentQueryApiService} from '@app/shared/services/api/document-query-api/document-query-api.service';

@NgModule({
    imports: [
        AppMaterialModule,
        CommonModule,
        RouterModule,
        HttpClientModule,
        HeaderModule,
        SidePanelModule,
        DashboardModule,
        RcmModule,
        CostControlModule,
        SuppliersModule,
        AnalyticsBIModule,
        RcmModule,
        AboutModule,
        TermsModule,
        NotificationsModule,
        MyAccountModule,
        LoadingModule,
        EmailInboxModule,
        FormsModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatDialogModule,
        MatAutocompleteModule
    ],
    providers: [
        SharedService,
        CriticalStatsService,
        LoadingService,
        RcmService,
        DataLookupService,
        QuoteService,
        DocumentQueryApiService
    ],
    declarations: [
        DialogOverviewExampleDialog,
        MainComponent
    ]
})
export class MainModule { }
