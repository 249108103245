import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Apollo } from 'apollo-angular';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import gql from 'graphql-tag';

import * as Fragments from '@app/common/fragments';
import { User, AuthPayload, Query, UserByIdQuery, LoginMutation, RepairCycleCompany, repairCycleCompanyQuery, UpdateSystemAcceptanceMutation } from '@app/auth/auth.models';
import { LoginAcceptance } from '@app/auth/components/login/terms-conditions-privacy-acceptance.component';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  loggedUser: User;
  public user: Subject<User>;
  public user$: Observable<User>;

  constructor(private apollo: Apollo, private router: Router) {
    this.user = new Subject<User>();
    this.user$ = this.user.asObservable();
  }

  getRepairCycleCompanyId(): Observable<RepairCycleCompany> {
    return this.apollo
      .watchQuery<repairCycleCompanyQuery>({
        fetchPolicy: 'network-only',
        query: gql`
        query
        {
          repairCycleCompany{
            companyId
            companyIdRef
            legalEntity
          }
        }
      `,
      })
      .valueChanges
      .pipe(
        map(result => result.data.repairCycleCompany)
      );
  }

  getUserById(id: number): Observable<User> {
    return this.apollo
    .watchQuery<UserByIdQuery>({
      query: gql`
        query getUser($id: Int!) {
          userById(id: $id) {
            ... UserAttributes
          }
        }
        ${Fragments.User.attributes}
      `,
      variables: { id }
    })
    .valueChanges
    .pipe(
      map(result => result.data.getUser)
    );
  }

  login(username, password): Observable<User> {
    return this.apollo
        .watchQuery<any>({
        fetchPolicy: 'network-only',
        query: gql`
        query login($username: String!, $password: Password!) {
          signIn(userName: $username, password: $password) {
            ...UserAttributes
          }
        }
          ${Fragments.login.attributes}
        `,
        variables: {
          username,
          password
        }
      })
      .valueChanges
      .pipe(
        map(result => result.data.signIn)
      );
  }

  recoverPassword(userName: string): Observable<any> {
    return this.apollo.mutate<any>({
        mutation: gql`
          mutation recoverPassword($userName: String!) {
            recoverPassword(userName: $userName) {
              email
              recoverPassword
            }
          }
        `,
        variables: {
          userName
        }
      })
      .pipe(
        map(result => result.data.recoverPassword.email)
      );
  }

  changePassword(data): Observable<any> {
    return this.apollo.mutate<any>({
        mutation: gql`
          mutation changePassword($userName: String!, $currentPassword: String!, $newPassword: Password!) {
            changePassword(userName: $userName, currentPassword: $currentPassword, newPassword: $newPassword)
          }
        `,
        variables: data
      });
  }

  setUser(user: User): void {
    //console.log('setUser');
    this.loggedUser = user;
    this.user.next(user);
  }

  setCompanyDetails(company:RepairCycleCompany):void{
    localStorage.setItem(environment.repairCycleCompanyStorage, JSON.stringify(company));
  }

  getCompanyDetails():RepairCycleCompany{
    return JSON.parse(localStorage.getItem(environment.repairCycleCompanyStorage));
  }

  setUserToken(data: AuthPayload): void {
    //console.log('setUserToken');
    localStorage.setItem('aaruser:storage', JSON.stringify(data));
  }

  getUserToken(): string {
    //console.log('getUserToken');
    return JSON.parse(localStorage.getItem('token'));
  }

  getUserInfo(): User {
    //console.log('getUserInfo');
    const aaruser = JSON.parse(localStorage.getItem('aaruser:storage'));
    return aaruser && aaruser.user;
  }

  getLoggedUser(): User {
    //console.log('getLoggedUser');
    if (!this.loggedUser) {
      const aaruser = JSON.parse(localStorage.getItem('aaruser:storage'));
      this.loggedUser = aaruser.user;
    }

    return this.loggedUser;
  }

  processLogout(): void {
    //console.log('processLogout');
    localStorage.removeItem('aaruser:storage');
    localStorage.removeItem(environment.repairCycleCompanyStorage);
    this.loggedUser = null;
    this.user.next(this.loggedUser);
    this.router.navigate(['/login']);
  }

  isUserLoggedIn(): boolean {
    //console.log('isUserLoggedIn');
    return !!JSON.parse(localStorage.getItem('aaruser:storage'));
  }

  isUserInCompanyWithModule(moduleName: String): boolean {
    //console.log(`isUserInCompanyWithModule: ${moduleName}`);
    const loggedUser = this.getLoggedUser();
    if (!loggedUser || !loggedUser.features) {
      return false;
    }
    //console.log(JSON.stringify(loggedUser));
    const foundModule = loggedUser.features.find(m => m.module === moduleName);
    //console.log(`foundModule: ${JSON.stringify(foundModule)}`);
    const userInCompanyWithModule = foundModule ? true : false;
    //console.log(`userInCompanyWithModule: ${userInCompanyWithModule}`);
    return userInCompanyWithModule;
  }

  updateUserAcceptance(loginAcceptance: LoginAcceptance): Observable<boolean> {

    return this.apollo.mutate<UpdateSystemAcceptanceMutation>({
        mutation: gql`
        mutation updateSystemAcceptance($userId: Int!, $hasAcceptedTermsAndConditions: Boolean!, $hasAcceptedPrivacyPolicy: Boolean!) {
          updateSystemAcceptance(
            userId: $userId,
            hasAcceptedTermsAndConditions: $hasAcceptedTermsAndConditions,
            hasAcceptedPrivacyPolicy: $hasAcceptedPrivacyPolicy)
        }
        `,
        variables: loginAcceptance
      }).pipe(map((result)=>result.data.updateSystemAcceptance ));
  }
}
