import { BrowserModule } from '@angular/platform-browser';
import { LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { ApolloModule, Apollo } from 'apollo-angular';
import { HttpLinkModule, HttpLink } from 'apollo-angular-link-http';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { environment } from '@env/environment';
import { ApolloLink, concat } from 'apollo-link';
//import { HttpHeaders, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RetryLink } from 'apollo-link-retry';
import { AppMaterialModule } from '@app/app-material.module';
import { AppRoutingModule } from '@app/app-routing.module';
//import { MsalInterceptor, MsalModule } from '@azure/msal-angular';

import { AuthModule } from '@app/auth/auth.module';

import { AppComponent } from '@app/app.component';
import { MainModule } from '@app/main/main.module';
import { SharedModule } from '@app/shared.module';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { getLocalStorage } from '@app/common/utility';
import { createUploadLink } from 'apollo-upload-file';
import { CustomFormsModule } from 'ng2-validation';
import { onError } from 'apollo-link-error';
import { MatIconModule } from '@angular/material/icon';
import { MatMomentDateModule, MAT_MOMENT_DATE_ADAPTER_OPTIONS, MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter'; 
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { DecimalLocalisedPipe } from './shared/custom-pipe/decimal-pipe/decimal-localised.pipe';
import { DateLocalisedPipe } from './shared/custom-pipe/date-pipe/date-localised.pipe';
import { ContactModule } from './contacts/contact.module';
import { CurrencyLocalisedPipe } from './shared/custom-pipe/currency-pipe/currency-localised.pipe.';
import { TimezoneFromUtcLocalizedPipe } from './shared/custom-pipe/timezone-pipe/timezone-from-utc-localized.pipe';
import { LOCALE_MOMENTDATE_FORMAT } from './core/constants/globalConstants';

const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;


@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    FormsModule,
    CustomFormsModule,
    HttpClientModule,
    ApolloModule,
    HttpLinkModule,
    AppMaterialModule,
    AppRoutingModule,
    AuthModule,
    BrowserAnimationsModule,
    SharedModule,
    MainModule,
    MatIconModule,
    MatMomentDateModule,
    ContactModule
  ],
  providers: [
    DecimalLocalisedPipe,
    DateLocalisedPipe,
    CurrencyLocalisedPipe,
    TimezoneFromUtcLocalizedPipe,
    //Intentionally left commented as will be reinstated when
    //MSAL is updated.
  //   {
  //     provide: HTTP_INTERCEPTORS,
  //     useClass: MsalInterceptor,
  //     multi: true
  //  },
    {
      provide: DateAdapter, 
      useClass: MomentDateAdapter, 
      deps: [MAT_DATE_LOCALE]
    },
    {
      provide:LOCALE_ID, useValue:['en-GB']
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: {
        display:{
          dateInput: LOCALE_MOMENTDATE_FORMAT['en-GB']
        }
      }
    },
     {
      provide: MAT_DATE_LOCALE, 
      useValue: 'en-GB'
    },
     {
      provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, 
      useValue:{useUtc:true}
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(apollo: Apollo, httpLink: HttpLink) {

    const graphqlLink = createUploadLink({ uri: environment.graphqlEndpoint });

    const errorLink = onError(({ operation, graphQLErrors, networkError }) => {
      if (environment.production === false) {
        console.log(`[errorLink]: operation=${operation.operationName}`);
      }
      localStorage.setItem(environment.apolloClientStorageNameGraphQlErrors, '');
      localStorage.setItem(environment.apolloClientStorageNameNetworkError, '');
      localStorage.setItem(environment.samlIdpLoginErrorStorageName, '');

      if (graphQLErrors) {
        graphQLErrors.map(({ message, locations, path }) => {
          if (environment.production === false) {
            console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`);
          }
          localStorage.setItem(environment.apolloClientStorageNameGraphQlErrors, JSON.stringify(graphQLErrors));
        });
      }
      if (networkError) {
        if (environment.production === false) {
          console.log(`[Network error]: ${networkError.message}`);
        }
        localStorage.setItem(environment.apolloClientStorageNameNetworkError, networkError.message);
      }

      if (!!graphQLErrors && !!networkError) {
        console.log('[errorLink]: no error');
      }
    });

    const retryLink = new RetryLink({

      attempts: {
        max:0,
        retryIf: (error, _operation) => !!error
      },

      // attempts: (count, operation, error) => {
      //   if (environment.production === false) {
      //     console.log(`[retryLink]: operation=${operation.operationName}`);
      //     console.log(`[retryLink]: error=${error}`);
      //   }
      //   return !!operation && !!error && !environment.apolloClientRetryLinkExclusions.includes(operation.operationName);
      // },

      delay: (count, operation, error) => {
        return count * 1000 * Math.random();
      },
    });
      const operationsList = [
        'changePassword',
        'specialCase',
        'login'
      ];

    const authMiddleware = new ApolloLink((operation, forward) => {
      const currentUsertoken = this.getUserToken();
      // add the authorization to the headers
      if (currentUsertoken) {
        operation.setContext({
          headers: {
            Authorization: `Bearer ${currentUsertoken}`,
          },
        });
      }

      return forward(operation);
    });

    apollo.create({
      link: ApolloLink.from([errorLink, authMiddleware, graphqlLink]),
      cache: new InMemoryCache(),
      defaultOptions : {
        watchQuery : {
          errorPolicy : 'none'
        }
      }
    });
  }

  getUserToken() {
    const aaruser = getLocalStorage('aaruser:storage');
    return aaruser ? aaruser.token : null;
  }
}
