import { NgModule } from '@angular/core';

import { NotificationsComponent } from './notifications.component';
import { AppMaterialModule } from '@app/app-material.module';
import { CommonModule } from '@angular/common';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { FormsModule } from '@angular/forms';
import { DashCardModule, ToggleGroupModule, NotificationDataTableModule } from '@app/shared/components';
import { NotificationDialog } from './notification-dialog/notification-dialog.component';

@NgModule({
    imports: [
        AppMaterialModule,
        CommonModule,
        MatInputModule,
        FormsModule,
        DashCardModule,
        NotificationDataTableModule,
        ToggleGroupModule
    ],
    providers: [],
    declarations: [
        NotificationsComponent,
        NotificationDialog
    ]
})
export class NotificationsModule { }
