import { Component, OnInit, Input } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';

@Component({
  selector: 'aar-aar-domo-report',
  templateUrl: './aar-domo-report.component.html',
  styleUrls: ['./aar-domo-report.component.scss']
})

export class AarDomoReportComponent implements OnInit {

  constructor(private breakpointObserver: BreakpointObserver, public dialog: MatDialog) { }
  @Input() reportUrl: string;
  @Input() reportTitle: string;

  ngOnInit() {
    
  }

}
