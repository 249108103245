import { Component, OnInit, NgModule, Inject } from '@angular/core';
import { AppMaterialModule } from '@app/app-material.module';
import { RouterModule } from '@angular/router';
import { MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

@Component({
  selector: 'aar-side-panel-right',
  templateUrl: './side-panel-right.component.html',
  styleUrls: ['./side-panel-right.component.scss']
})
export class SidePanelRightComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<SidePanelRightComponent>,
    @Inject(MAT_DIALOG_DATA) public data) { }

  public static show(dialog: MatDialog) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    dialogConfig.panelClass = 'aar-side-panel-right';
    return dialog.open(SidePanelRightComponent, dialogConfig).afterClosed();
  }

  close() {
    this.dialogRef.close();
  }
  ngOnInit() {

  }
}


@NgModule({
  imports: [ AppMaterialModule, RouterModule ],
  exports: [ SidePanelRightComponent ],
  declarations: [ SidePanelRightComponent],
})
export class SidePanelModule {}
