<div class="aar-rcm aar-page">
  <ng-template [ngIf]="route.children.length === 0" [ngIfElse]="children">
    <div class="content">
      <div class="aar-rcm-cards-container">
        <div *ngFor="let card of criticalStatsCards" class="equalWidth">
          <aar-dash-card type="b" [criticalStatId]="card.id" label={{card.label}} [isEnabled]=card.isEnabled [value]="card.value" [active]="isCardActive(card.id)" (click)="filterByStatus(card.id)" [persona]="card.id==this.criticalStatsIdPendingCustomerApproval ? this.currentUser.roleName.toUpperCase() : null"></aar-dash-card>
        </div>
      </div>
      <aar-rcm-data-table [rcmData]="rcmGridOrders" [displayColumns]="columnsSettings"></aar-rcm-data-table>
    </div>
  </ng-template>

  <ng-template #children>
    <router-outlet></router-outlet>
  </ng-template>
</div>
