<mat-toolbar class="aar-header">
  <aar-breadcrumb></aar-breadcrumb>
  <div style="margin-right:20px">{{legalEntity}}</div>
 <div class="aar-search" *ngIf="showSearch" #searchContainer>
    <input type="text" placeholder="Search..." name="search"  autocomplete="off" (keyup)="onKeyUp(search.value)"  #search/>
 <!--<button mat-mini-fab class="recent-activity" (click)="triggerSearch(search.value)"><mat-icon svgIcon="recent-activity" class="icon-recent-activity"></mat-icon></button>-->

    <div class="aar-search-dropdown">
       <!-- <mat-accordion>
            <mat-expansion-panel #panelexpand>
                <ul class="arr-search-list">
                    <li (click)="seachListItemClick()" class="arr-search-list-item"><span><i class="material-icons">cached</i> Search RO Number</span></li>
                    <li (click)="seachListItemClick()" class="arr-search-list-item"><span><i class="material-icons">pie_chart</i>Search Part Number</span></li>
                    <li (click)="seachListItemClick()" class="arr-search-list-item"><span><i class="material-icons">assessment</i>Search Serial Number</span></li>
                    <li (click)="seachListItemClick()" class="arr-search-list-item arr-search-list-item-dark"><span><i class="material-icons">dns</i>Search Suppliers</span></li>
                    <li (click)="seachListItemClick()" class="arr-search-list-item arr-search-list-item-dark"><span><i class="material-icons">description</i>Search Documents</span></li>
                </ul>

            </mat-expansion-panel>
      </mat-accordion> -->
    </div>
  </div>
</mat-toolbar>
