import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { BooleanToYesNoPipe } from './boolean-to-yes-no.pipe';
import { SafePipe } from './safe.pipe';
import { ToggleComponent } from './shared/components/toggle/toggle.component';
import { AarInputWholeNumberDirective } from './shared/directives/aar-input-whole-number/aar-input-whole-number.directive';
import { DateLocalisedPipe } from './shared/custom-pipe/date-pipe/date-localised.pipe';
import { DecimalLocalisedPipe } from './shared/custom-pipe/decimal-pipe/decimal-localised.pipe';
import { CurrencyLocalisedPipe } from './shared/custom-pipe/currency-pipe/currency-localised.pipe.';
import { TimezoneFromUtcLocalizedPipe } from './shared/custom-pipe/timezone-pipe/timezone-from-utc-localized.pipe';
import { ExportExcelComponent } from './shared/components/export-excel/export-excel.component';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule, MatLegacySpinner as MatSpinner } from '@angular/material/legacy-progress-spinner';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatButtonModule,
    BrowserAnimationsModule,
    MatProgressSpinnerModule,
    MatButtonModule
  ],
  declarations: [
    /* declare it once, here */
    BooleanToYesNoPipe,
    SafePipe,
    ToggleComponent,
    AarInputWholeNumberDirective,
    DateLocalisedPipe,
    DecimalLocalisedPipe,
    CurrencyLocalisedPipe,
    TimezoneFromUtcLocalizedPipe,
    ExportExcelComponent
  ],
  exports: [
    /* then export it */
    BooleanToYesNoPipe,
    SafePipe,
    ToggleComponent,
    AarInputWholeNumberDirective,
    DateLocalisedPipe,
    DecimalLocalisedPipe,
    CurrencyLocalisedPipe,
    TimezoneFromUtcLocalizedPipe,
    ExportExcelComponent
  ]
})
export class SharedModule { }
