import { Component, Inject, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { Carrier, RepairOrder, ShippingStatus, ShippingType } from '@app/shared/services/api/api.models';
import { ApiService } from '@app/shared/services/api/api.service';
import { CustomValidators } from 'ng2-validation';
import { Subscription } from 'rxjs';
import { AuthService } from '@app/auth/services/auth/auth.service';
import { PersonaService } from '@app/shared/services/rcm/persona.service';
import { ComponentType } from '@app/shared/services/rcm/persona.models';
import { DatevalidationService } from '@app/shared/services/datevalidation.service';
import { User } from '@app/auth/auth.models';
import { getFormFielErrorMessage, setUtcFlag } from '@app/common/utility';
import * as moment from 'moment';
import { DataLookupService } from '@app/shared/services/rcm/data-lookup/data-lookup.service';


@Component({
  selector: 'aar-rcm-edit-shipment',
  templateUrl: './rcm-edit-shipment.component.html',
  styleUrls: ['./rcm-edit-shipment.component.scss']
})
export class RcmEditShipmentComponent implements OnInit, OnDestroy, OnChanges {
  option = 'option';
  shippingForm: UntypedFormGroup;
  id: Number;
  repairOrder: RepairOrder;
  shippingToUpdate: ShippingType;
  shippingStatuses: ShippingStatus[];
  carriers: Carrier[];
  direction: number; // default to outbound
  title: string;
  selectedCarrier: String;
  nonEditableFields: string[] = [];
  fieldsToHide: string[] = [];
  personaService: PersonaService;
  user: User;
  readonly today = new Date();
  private externalSubscription = new Subscription;

  readonly dateShippedCtlName = 'dateShipped';
  readonly dateDeliveredCtlName = 'dateDelivered';

  readonly dateControlNames = [
    this.dateDeliveredCtlName,
    this.dateShippedCtlName
  ];

  constructor(
    private apiService: ApiService, private fb: UntypedFormBuilder, 
    public dialogRef: MatDialogRef<RcmEditShipmentComponent>,
    @Inject(MAT_DIALOG_DATA) data: any, private authService: AuthService, 
    private dateValidator: DatevalidationService, private dataLookupService: DataLookupService) {
    this.repairOrder = data.repairOrder;
    this.direction = data.direction;
    this.id = data.shipping.id
    this.shippingToUpdate = data.shipping;
    this.shippingForm = this.fb.group({
      id: [this.id],
      rONumber: [this.repairOrder.repairOrderNumber, [Validators.required]],
      rOLineNumber: [Number(this.repairOrder.rOLineNumber), [Validators.required]],
      rOLNumber: [this.repairOrder.repairOrderLineId, [Validators.required]],
      carrierId: [this.shippingToUpdate.carrierId, []],
      trackingNumber: [this.shippingToUpdate.trackingNumber],
      address: [this.shippingToUpdate.address],
      directionId: [this.shippingToUpdate.directionId.toString(), [Validators.required]],
      trackingLink: [this.shippingToUpdate.trackingLink, [CustomValidators.url]],
      dateShipped: [this.shippingToUpdate.dateShipped ? moment(this.shippingToUpdate.dateShipped).utc(true) : null],
      dateDelivered: [this.shippingToUpdate.dateDelivered ? moment(this.shippingToUpdate.dateDelivered).utc(true) : null],
      shippingReference: [this.shippingToUpdate.shippingReference, [Validators.maxLength(24)]]
      /* Commented under #40361
      isPreventFeedUpdate: [this.shippingToUpdate.isPreventFeedUpdate, [Validators.required]],
      */
    });
    this.personaService = new PersonaService(this.authService.getLoggedUser().personaName);
  }

  dateExists(controlName:string): boolean{
    return this.shippingForm.controls[controlName].value !== null;
  }

  onDateClearClick(controlName:string):void{
    const formControl = this.shippingForm.controls[controlName];
    formControl.setValue(null);
    formControl.markAsDirty();
    formControl.updateValueAndValidity();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.init();
  }

  ngOnInit() {
    this.init();
    this.carriers = this.dataLookupService.cachedCarriers;
    this.nonEditableFields = this.personaService.getNonEditableFields(ComponentType.RCMEditShipping);
    this.fieldsToHide = this.personaService.getHiddenFields(ComponentType.RCMEditShipping);

    this.user = this.authService.getLoggedUser();

    //Extending the nonEditableFields
    this.personaService.shippingFieldsToNonEdit(this.shippingForm.getRawValue().directionId, this.user.roleName.toUpperCase()).forEach(
      (elem) => {
        this.nonEditableFields.push(elem);
      }
    )

    this.personaService.effectFieldDisables(this.shippingForm, this.nonEditableFields);

  }


  ngOnDestroy(): void {
    this.externalSubscription.unsubscribe();
  }
  
  /* Commented under #40361
  showPreventFeedUpdate(): boolean {
    return !this.personaService.shippingInfoUpdateRoles().includes(this.user.roleName);
  }
  */

  getFormControl(controlName: string): UntypedFormControl {
    return this.shippingForm.get(controlName) as UntypedFormControl;
  }

  init() {
    this.title = `Edit Shipment Details`;
    
    this.externalSubscription.add(this.apiService.getShippingStatuses().subscribe((shippingStatuses) => {
      this.shippingStatuses = shippingStatuses;
    }));
  }

  save() {
    if (this.shippingForm.dirty && this.shippingForm.valid) {
      /*
      The dateShipped and dateDelivered form controls have their values checked
      via a subscription to their ValueChanges observable.  This has the side effect
      of removing the utc flag from their moment value and needs to be added back
      in the setUtcFlag method
      */
      this.setShippedAndDeliveredUtcFlag();
      this.dialogRef.close(this.shippingForm.value);
    }
  }

  private setShippedAndDeliveredUtcFlag(): void {
    const dateShipped = this.getFormControl(this.dateShippedCtlName);
    const dateDelivered = this.getFormControl(this.dateDeliveredCtlName);
    if (dateShipped) {
      dateShipped.setValue(setUtcFlag(dateShipped.value));
    }
    if (dateDelivered) {
      dateDelivered.setValue(setUtcFlag(dateDelivered.value));
    }
  }

  onClose() {
    this.dialogRef.close();
  }

  isFieldHidden(control: string): boolean {
    return this.fieldsToHide.includes(control);
  }

  hasError(form: UntypedFormGroup, control: string): boolean {
    return !form.controls[control].valid;
  }

  getErrorMessage(form: UntypedFormGroup, control: string): string {
    return getFormFielErrorMessage(form, control);
  }

}
