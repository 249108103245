import { Component, OnInit, NgModule, Output, Input, EventEmitter } from '@angular/core';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyPaginatorModule as MatPaginatorModule } from '@angular/material/legacy-paginator';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatSortModule } from '@angular/material/sort';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { UntypedFormControl, UntypedFormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedService } from '@app/shared/services/shared.service';
import { DataTableService } from '../data-table.service';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { isEmpty } from '@app/common/utility';

export interface TableFilterConfig {
  active: boolean;
  filter: string;
}

@Component({
  selector: 'aar-table-filter',
  templateUrl: './table-filter.component.html',
  styleUrls: ['./table-filter.component.scss']
})
export class TableFilterComponent implements OnInit {

  @Input() columns: string[];
  @Input() settings: any[];
  @Input() enableSearch: boolean;

  filterForm: UntypedFormGroup;

  constructor(
    private dataTableService: DataTableService,
    private sharedService: SharedService){}

  ngOnInit() {
    this.filterForm = new UntypedFormGroup({});
    this.setupFilterForm();

    this.dataTableService.onFilterUpdate$
      .subscribe(tableFilters => {
        if (!Object.keys(tableFilters).length) {
          this.filterForm.reset();
        }
      });
  }

  setupFilterForm() {
    this.filterForm = this.toFormGroup();
    this.filterForm.valueChanges
      .subscribe(changes => {
        this.sharedService.sendObject(changes)
        this.dataTableService.updateTable(changes);
      });
  }

  toFormGroup(): UntypedFormGroup {
    const formFields = {};
    formFields['searchQuery'] = new UntypedFormControl({value
      : '', disabled: false});
    this.settings.forEach(field => {
      formFields[field.name] = new UntypedFormControl({value
        : '', disabled: false});
    });
    return new UntypedFormGroup(formFields);
  }

  filterOptions = (options)  => options.filter(({value,label}:any) => !isEmpty(value) )

}
@NgModule({
  imports: [
    CommonModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    MatInputModule,
    MatFormFieldModule,
    MatSelectModule,
    MatCheckboxModule,
    MatButtonModule,
    ReactiveFormsModule,
    RouterModule,
    FormsModule,
    MatIconModule,
    MatProgressSpinnerModule

  ],
  exports: [TableFilterComponent ],
  declarations: [
    TableFilterComponent
  ]
})
export class TableFilterModule {}
