export interface AirvolutionModule {
  id: number;
  module : string;
}

export interface User {
  id: number;
  fullName: string;
  email: string;
  roleName: string;
  password?: string;
  userName: string;
  token?: string;
  companyID: number;
  hasTemporaryPassword?: boolean;
  lastLoggedIn : Date;
  features: AirvolutionModule[];
  //Personas: Add Persona name to the User
  personaName: string;
  hasAcceptedPrivacyPolicy: boolean;
  hasAcceptedTermsAndConditions: boolean;
  repairCycleCompanyId: number;
}

export interface AuthPayload {
  token: string;
  user: User;
}

export interface repairCycleCompanyQuery{
  repairCycleCompany:RepairCycleCompany;
}

export interface Query {
  getUsers: User[];
}

export interface UserByIdQuery {
  getUser: User;
}

export interface LoginMutation {
  login: AuthPayload;
}

export interface UsersQuery {
  security_users: User[];
}

export interface RepairCycleCompany{
  companyId:number;
  companyIdRef:number;
  legalEntity;
}

export interface UpdateSystemAcceptanceMutation{
  updateSystemAcceptance:boolean;
}
